import types from '../types';

const initialState = {
  data: null,
  error: null,
  inProgress: false,
  lastUpdate: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.TAXES_CALCULATE_REQUESTED: {
      return {
        ...state,
        data: null,
        inProgress: true,
      };
    }
    case types.TAXES_CALCULATE_SUCCEEDED: {
      return {
        ...state,
        data: action.data,
        error: null,
        inProgress: false,
        lastUpdate: Date.now(),
      };
    }

    case types.TAXES_CALCULATE_FAILED: {
      return {
        ...state,
        data: null,
        error: action.error,
        inProgress: false,
        lastUpdate: Date.now(),
      };
    }

    case types.TAXES_CALCULATE_CLEAR: {
      return {
        ...state,
        data: null,
        error: null,
        inProgress: false,
        lastUpdate: null,
      };
    }

    default:
      return state;
  }
}
