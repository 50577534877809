import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RedeemForm from './RedeemForm';
import VouchersList from './VouchersList';
import { getVouchersListByUserId, redeemVoucherByCode } from '../../../../apis/activations';
import { OpenErrorSnackbar, OpenSuccessSnackbar } from '../../../common/Toast/Toast';
import { has } from 'lodash';
import { CLOUD_CLASSROOM, CLOUD_CREATE, ENTERPRISE_PRODUCT } from '../../../../utils/utilities';
import MakerPlanRedeemModal from './MakerPlanRedeemModal';
import EnterprisePlanRedeemModal from './EnterprisePlanRedeemModal';
import EnterprisePlanNewSharedSpaceModal from './EnterprisePlanNewSharedSpaceModal';
import classnames from 'classnames';
import './VouchersTab.scss';

const VouchersTabComponent = (props) => {
  const {
    readSubscriptionList,
    subscription,
    organizations,
    country,
    createOrganization,
    readOrganizationList,
    readCountries,
  } = props;
  /*TODO:
   *   - edu flow
   * */

  useEffect(() => {
    // Set Arduino logo in header
    localStorage.removeItem('path');
    (window as any).arduinoHeader.changeLogo({
      src: '<svg width="52" height="26" viewBox="0 0 52 26" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2848_54927)"><path fill-rule="evenodd" clip-rule="evenodd" d="M51.25 13C51.25 6.10219 45.5773 0.5 38.6242 0.5C37.984 0.5 37.3261 0.536496 36.6859 0.645985C31.2799 1.43066 27.6522 5.42701 25.625 8.4927C23.5978 5.42701 19.9701 1.43066 14.5641 0.645985C13.9239 0.554745 13.266 0.5 12.6258 0.5C5.65493 0.5 0 6.10219 0 13C0 19.8978 5.67271 25.5 12.6258 25.5C13.266 25.5 13.9239 25.4635 14.5819 25.354C19.9879 24.5511 23.6155 20.5547 25.6428 17.4891C27.67 20.5547 31.2977 24.5511 36.7037 25.354C37.3439 25.4453 38.0018 25.5 38.6598 25.5C45.5773 25.5 51.25 19.8978 51.25 13ZM13.9416 20.9744C13.4971 21.0474 13.0525 21.0657 12.6079 21.0657C8.01996 21.0657 4.30336 17.4343 4.30336 13C4.30336 8.54744 8.03774 4.9343 12.6257 4.9343C13.0703 4.9343 13.5148 4.9708 13.9594 5.02554C19.0631 5.77372 22.1751 10.9379 23.242 13C22.1573 15.0803 19.0275 20.2263 13.9416 20.9744ZM37.2905 5.02554C32.1868 5.77372 29.057 10.9379 28.0079 13C29.057 15.062 32.1868 20.2263 37.2905 20.9744C37.735 21.0292 38.1796 21.0657 38.6242 21.0657C43.1944 21.0657 46.9287 17.4525 46.9287 13C46.9287 8.56569 43.2121 4.9343 38.6242 4.9343C38.1796 4.9343 37.735 4.9708 37.2905 5.02554ZM9.06449 11.661H16.7004V14.0642H9.06449V11.661ZM42.1479 14.0817H39.5728V16.6077H37.1049V14.0817H34.5298V11.661H37.1049V9.13499H39.5728V11.661H42.1479V14.0817Z" fill="#00979D"/></g><defs><clipPath id="clip0_2848_54927"><rect width="51.25" height="25" fill="white" transform="translate(0 0.5)"/></clipPath></defs></svg>',
      inline: true,
      cssClass: 'digital-store',
    });
  });

  useEffect(() => {
    readSubscriptionList();
    readOrganizationList();
    readCountries();
  }, [readSubscriptionList, readOrganizationList, readCountries]);

  const [redeemLoading, setRedeemLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [voucherValue, setVoucherValue] = useState('');
  const [voucherData, setVoucherData] = useState(null);
  const [makerPlanModalOpen, setMakerPlanModalOpen] = useState(false);
  const [vouchersList, setVouchersList] = useState([]);

  const [enterpriseNewSharedSpaceModalOpen, setEnterpriseNewSharedSpaceModalOpen] = useState(false);
  const [enterprisePlanModalOpen, setEnterprisePlanModalOpen] = useState(false);
  const [enterpriseOrganizationSelected, setEnterpriseOrganizationSelected] = useState(false);

  const [cloudPopoverVisible, setCloudPopoverVisible] = useState(false);

  const getVouchersList = useCallback(async () => {
    setListLoading(true);
    try {
      const list = await getVouchersListByUserId();
      setVouchersList(list);
    } catch (e) {
      OpenErrorSnackbar(`Vouchers list error`);
    } finally {
      setListLoading(false);
    }
  }, []);

  const handleRedeem = useCallback(
    async (code, data) => {
      setRedeemLoading(true);
      setListLoading(true);
      try {
        await redeemVoucherByCode(code, { ...data });
        OpenSuccessSnackbar('Your voucher has been redeemed!');
        setVoucherValue('');
        await getVouchersList();
      } catch (e) {
        OpenErrorSnackbar(e.body.message);
      } finally {
        setRedeemLoading(false);
        setListLoading(false);
        setEnterprisePlanModalOpen(false);
        setEnterpriseOrganizationSelected(false);
        readSubscriptionList();
        setCloudPopoverVisible(true);
        setTimeout(() => setCloudPopoverVisible(false), 10000);
      }
    },
    [getVouchersList, readSubscriptionList]
  );

  useEffect(() => {
    getVouchersList();
  }, [getVouchersList]);

  const onRedeemVoucher = useCallback(
    async (data) => {
      switch (data?.product) {
        case CLOUD_CREATE:
          // check if user has already a plan
          if (has(subscription.byProduct, 'create') && !has(subscription.byPlan, 'create_free')) {
            setMakerPlanModalOpen(true);
          } else {
            await handleRedeem(data?.code, { voucher_id: data.voucher_id });
          }
          break;
        case ENTERPRISE_PRODUCT:
          if (organizations?.count === 0) {
            setEnterpriseNewSharedSpaceModalOpen(true);
          } else {
            if (!enterpriseOrganizationSelected) {
              setEnterprisePlanModalOpen(true);
            }
          }
          break;
        case CLOUD_CLASSROOM:
          // TODO
          break;
      }
    },
    [enterpriseOrganizationSelected, handleRedeem, organizations?.count, subscription.byPlan, subscription.byProduct]
  );

  const handleCloseMakerPlanRedeemModal = useCallback(() => {
    setMakerPlanModalOpen(false);
    setRedeemLoading(false);
  }, []);

  const getPlanName = useCallback((planId) => {
    if (planId?.includes('makerplus')) {
      return 'Maker plus plan';
    } else if (planId?.includes('entry')) {
      return 'Entry plan';
    } else {
      return 'Maker plan';
    }
  }, []);

  const currentMakerPlan = useMemo(() => {
    const planId = subscription.byProduct.create;
    const planData = subscription.byId[planId];
    return getPlanName(planData?.plan);
  }, [getPlanName, subscription]);

  const newMakerPlan = useMemo(() => getPlanName(voucherData?.plan_id), [getPlanName, voucherData]);

  return (
    <div className="vouchers-tab">
      <h4>Pre-Paid Plans</h4>
      <RedeemForm
        onSubmit={onRedeemVoucher}
        redeemLoading={redeemLoading}
        voucherValue={voucherValue}
        setVoucherValue={setVoucherValue}
        voucherData={voucherData}
        setVoucherData={setVoucherData}
      />
      <VouchersList list={vouchersList} loading={listLoading} organizations={organizations?.organizations} />
      <MakerPlanRedeemModal
        isOpen={makerPlanModalOpen}
        onClose={handleCloseMakerPlanRedeemModal}
        currentPlan={currentMakerPlan}
        newPlan={newMakerPlan}
        onConfirm={() => handleRedeem(voucherData?.code, { voucher_id: voucherData?.voucher_id })}
        loading={redeemLoading}
      />
      {organizations?.count === 0 ? (
        <EnterprisePlanNewSharedSpaceModal
          isOpen={enterpriseNewSharedSpaceModalOpen}
          onClose={() => setEnterpriseNewSharedSpaceModalOpen(false)}
          onConfirm={(organizationId) => {
            handleRedeem(voucherData?.code, { voucher_id: voucherData?.voucher_id, organization_id: organizationId });
            setEnterpriseNewSharedSpaceModalOpen(false);
          }}
          organizations={organizations?.organizations}
          createOrganization={createOrganization}
          readOrganizationList={readOrganizationList}
          country={country}
        />
      ) : (
        <EnterprisePlanRedeemModal
          isOpen={enterprisePlanModalOpen}
          onClose={() => {
            setEnterprisePlanModalOpen(false);
            setEnterpriseOrganizationSelected(false);
          }}
          onConfirm={(organizationId) => {
            handleRedeem(voucherData?.code, { voucher_id: voucherData?.voucher_id, organization_id: organizationId });
            setTimeout(() => {
              setEnterprisePlanModalOpen(false);
              setEnterpriseOrganizationSelected(false);
            }, 2000);
          }}
          organizations={organizations?.organizations}
          createOrganization={createOrganization}
          readOrganizationList={readOrganizationList}
          country={country}
          redeemLoading={redeemLoading}
        />
      )}
      <div
        className={classnames('vouchers-tab__cloud-popover', {
          visible: cloudPopoverVisible,
        })}
      >
        <b>Go to Arduino Cloud here!</b>
        Your plan has been activated. You can now Access to Arduino Cloud via this button.
      </div>
    </div>
  );
};

export default VouchersTabComponent;
