import { call, put, takeEvery } from 'redux-saga/effects';

import actions from './actions';
import countryTypes from './types';
import * as countryAPI from '../../apis/country';

const readCountries = function* readCountries() {
  yield put(actions.readCountriesRequest());
  try {
    const result = yield call(countryAPI.readCountries);
    yield put(actions.readCountriesSuccess(result));
  } catch (error) {
    yield put(actions.readCountriesFailure(error));
  }
};

const readCountryStates = function* readCountryStates({ countryCode }) {
  yield put(actions.readStatesRequest());
  try {
    const result = yield call(countryAPI.readCountryStates, countryCode);
    yield put(actions.readStatesSuccess(result));
  } catch (error) {
    yield put(actions.readStatesFailure(error));
  }
};

/**
 * Listens for a COUNTRIES_READ action to be dispatched. Once it is dispatched,
 * runs the readCountries*() generator to read a list of Things.
 */
const watchCountriesRead = function* watchCountriesRead() {
  yield takeEvery(countryTypes.COUNTRIES_READ, readCountries);
};

/**
 * Listens for a COUNTRIES_STATES_READ action to be dispatched. Once it is dispatched,
 * runs the readCountries*() generator to read a list of Things.
 */
const watchCountryStatesRead = function* watchCountryStatesRead() {
  yield takeEvery(countryTypes.COUNTRIES_STATES_READ, readCountryStates);
};

export default [watchCountriesRead, watchCountryStatesRead];
