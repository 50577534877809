import React, { useMemo } from 'react';
import { Modal, Button } from '@arduino/arc';
import { ReactComponent as Check } from '../../../../assets/plan_check.svg';
import CelebrationImage from '../../../../assets/celebration.png';
import './RedeemModal.scss';

const vantages = {
  entry: [
    `<b>10</b> things`,
    `<b>Unlimited</b> dashboards`,
    `<b>Unlimited storage</b> for sketches`,
    '15 days data retention',
    `<b>Unlimited</b> compilations`,
    'Machine Learning Tools',
    'APIs',
    'Over the Air Updates',
  ],
  maker: [
    `<b>25</b> things`,
    `<b>Unlimited</b> dashboards`,
    `<b>Unlimited storage</b> for sketches`,
    '90 days data retention',
    `<b>Unlimited</b> compilations`,
    'Machine Learning Tools',
    'APIs',
    'Over the Air Updates',
    'Dashboard sharing',
    'Cloud Triggers',
  ],
  maker_plus: [
    `<b>100</b> things`,
    `<b>Unlimited</b> dashboards`,
    `<b>Unlimited storage</b> for sketches`,
    '1 year data retention',
    `<b>Unlimited</b> compilations`,
    'Machine Learning Tools',
    'APIs',
    'Over the Air Updates',
    'Dashboard sharing',
    'Cloud Triggers',
  ],
};

const RedeemModal = ({ isOpen, onClose, activatePlan, planId, isRedeemInProgress, startDate, endDate }) => {
  const planType = useMemo(() => {
    if (planId.includes('makerplus')) {
      return { plan: 'maker_plus', title: 'Maker Plus Plan' };
    } else if (planId.includes('entry')) {
      return { plan: 'entry', title: 'Entry Plan' };
    } else {
      return { plan: 'maker', title: 'Maker Plan' };
    }
  }, [planId]);

  return (
    <Modal
      isOpen={isOpen}
      isDismissable={true}
      title="Start plan usage"
      onClose={() => onClose()}
      className="redeem-modal"
    >
      <img src={CelebrationImage} alt="celebration image" />
      <div className="redeem-modal__text-box">
        <span className="redeem-modal__duration">
          Available from{' '}
          <b>
            {startDate} to {endDate}
          </b>
        </span>
        <h4>Getting started with the {planType.title}</h4>
        <p>Take a look at all the vantage you have with this plan:</p>
        <ul>
          {vantages[planType.plan].map((vantage, index) => (
            <li key={`vantage_${index}`}>
              <Check />
              <span dangerouslySetInnerHTML={{ __html: vantage }} />
            </li>
          ))}
        </ul>
        <span className="redeem-modal__warning">
          Please note that activating this plan will replace any other previously active plan.{' '}
        </span>
      </div>
      <div className="modal__buttons">
        <Button type="button" onPress={activatePlan} loading={isRedeemInProgress}>
          Start plan usage
        </Button>
      </div>
    </Modal>
  );
};

export default RedeemModal;
