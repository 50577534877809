import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.ACTIVATION_LIST_READ_REQUESTED:
      return {
        ...state,
        activationList: [],
        readList: {
          error: null,
          inProgress: true,
        },
      };
    case types.ACTIVATION_LIST_READ_SUCCEEDED:
      return {
        ...state,
        activationList: action.data,
        readList: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.ACTIVATION_LIST_READ_FAILED:
      return {
        ...state,
        readList: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.ALL_ACTIVATION_LIST_READ_REQUESTED:
      return {
        ...state,
        allActivationList: [],
        readList: {
          error: null,
          inProgress: true,
        },
      };
    case types.ALL_ACTIVATION_LIST_READ_SUCCEEDED:
      return {
        ...state,
        allActivationList: action.data,
        readList: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.ALL_ACTIVATION_LIST_READ_FAILED:
      return {
        ...state,
        readList: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.ACTIVATION_RESELLERS_LIST_READ_REQUESTED:
      return {
        ...state,
        resellerList: [],
        readList: {
          error: null,
          inProgress: true,
        },
      };
    case types.ACTIVATION_RESELLERS_LIST_READ_SUCCEEDED:
      return {
        ...state,
        resellerList: action.data,
        readList: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.ACTIVATION_RESELLERS_LIST_READ_FAILED:
      return {
        ...state,
        readList: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
  }
}
