import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from '../../../components/common/Modal/Modal';

import './SimInfoModal.scss';

function SimInfoModal(props) {
  const { simData } = props;

  const heading = <h3 id="heading">{'SIM INFO AND USAGE'}</h3>;

  const body = (
    <>
      <div className="sim-modal-detail">
        <div className="sim-detail-row">
          <span className="key">{'Name'}</span>
          <span className="value">{simData.name}</span>
        </div>
        <div className="sim-detail-row">
          <span className="key">{'APN'}</span>
          <span className="value">{'prepay.pelion'}</span>
        </div>
        <div className="sim-detail-row">
          <span className="key">{'Username'}</span>
          <span className="value">{'arduino'}</span>
        </div>
        <div className="sim-detail-row">
          <span className="key">{'Password'}</span>
          <span className="value">{'arduino'}</span>
        </div>
      </div>
      <div className="sim-modal-usage">
        <div className="sim-modal-usage-detail">
          <div className="sim-detail-row">
            <span className="key">{'Traffic available'}</span>
            <span className="value">
              {simData.remaining_data ? Math.round((simData.remaining_data / 1000000) * 10) / 10 : 0}
              {' MB'}
            </span>
          </div>
          <div className="sim-detail-row">
            <span className="key">{'End of billing cycle'}</span>
            <span className="value">{moment(simData.renewal_date).format('LL')}</span>
          </div>
          <div className="spacer"></div>
          <div className="notes">
            {'Once the data limit is reached, the device will be paused for the remainder of its billing cycle.'}
          </div>
        </div>
        <div className="chart-container">
          <svg viewBox="-2 -2 40 40" className="circular-chart">
            <path className="circle-bg" d="M18 2.084a15.915 15.915 0 0 1 0 31.831 15.915 15.915 0 0 1 0-31.83" />
            <path
              className="circle"
              d="M18 2.084a15.915 15.915 0 0 1 0 31.831 15.915 15.915 0 0 1 0-31.83"
              strokeDasharray={`${
                simData.remaining_data > 0 ? (simData.remaining_data * 100) / simData.initial_data : 0
              },100`}
            />
            <text x="18" y="16" className="usage">
              {simData.remaining_data ? Math.round((simData.remaining_data / 1000000) * 10) / 10 : 0}
              {' MB'}
            </text>
            <text x="18" y="20" className="total">
              {'remaining'}
            </text>
            <text x="18" y="24" className="total">
              {'of '}
              {simData.remaining_data ? Math.round((simData.initial_data / 1000000) * 10) / 10 : 0}
              {' MB'}
            </text>
          </svg>
        </div>
      </div>
    </>
  );

  return (
    <Modal
      className="primary sim-info-modal"
      onClose={props.onToggle}
      heading={heading}
      body={body}
      closeIcon={true}
      {...props}
    ></Modal>
  );
}

SimInfoModal.propTypes = {
  simData: PropTypes.object,
  onToggle: PropTypes.func,
};

export default SimInfoModal;
