import { connect } from 'react-redux';
import { cardActions } from '../../../store/card';

import CreditCardList from './CreditCardList';

const mapStateToProps = (state) => {
  return {
    card: state.card,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCard: (cardId) => dispatch(cardActions.deleteCard(cardId)),
  };
};

const ConnectedCreditCardList = connect(mapStateToProps, mapDispatchToProps)(CreditCardList);

export default ConnectedCreditCardList;
