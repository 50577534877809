import readList from './readList';
import read from './read';
import create from './create';
import upgrade from './upgrade';
import reactivate from './reactivate';
import cancel from './cancel';

const initialState = {
  details: {},
  list: [],
  availableOrganizationList: [],
  byId: {},
  byProduct: {},
  byPlan: {},
  statesList: [],
  lastTotalUpgradePrice: {},
  create: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  createRequest: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  readList: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  read: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  upgrade: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  reactivate: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  updateCard: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  cancel: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  pause: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
};

export default function (state = initialState, action) {
  return (
    create(state, action) ||
    read(state, action) ||
    readList(state, action) ||
    upgrade(state, action) ||
    reactivate(state, action) ||
    cancel(state, action) ||
    state
  );
}
