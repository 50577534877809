import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from '../../Modal/Modal';
import moment from 'moment';
import { ReactComponent as DownArrow } from '../../../../assets/icons/downArrow.svg';
import { ReactComponent as CloseIconCircle } from '../../../../assets/icons/closeIconCircle.svg';

import './CancelButton.scss';

function CancelModal(props) {
  const handleFormSubmit = (event) => {
    event.preventDefault();
    props.onConfirmCancelSubscription();
  };

  const heading = <div className="heading">{'Cancel plan'}</div>;

  const fullDescription = (
    <div className="description">
      <div className="argument">
        <div className="argument--first">
          If you cancel your plan, all current users will continue using the premium features until the end of this
          payment period on <strong>{moment(props.subscription.renewal_date).format('MMMM DD, YYYY')}</strong>.
        </div>

        <div className="argument--second">
          After that, all current users of your plan will be downgraded to the Free plan features:
        </div>
      </div>
      <div className="feature-list">
        <div className="feature">
          <DownArrow />
          <div className="feature--text">2 Things</div>
        </div>
        <div className="feature">
          <DownArrow />
          <div className="feature--text">5 variables per Thing</div>
        </div>
        <div className="feature">
          <DownArrow />
          <div className="feature--text">100mb sketch storage</div>
        </div>
        <div className="feature">
          <DownArrow />
          <div className="feature--text">1 day data retention</div>
        </div>
        <div className="feature">
          <DownArrow />
          <div className="feature--text">25/day compilations</div>
        </div>
        <div className="feature">
          <CloseIconCircle />
          <div className="feature--text">No shareable dashboards</div>
        </div>
        <div className="feature">
          <CloseIconCircle />
          <div className="feature--text">No Over the Air updates</div>
        </div>
      </div>
    </div>
  );

  const buttons = (
    <div className="buttons">
      <button
        className={classnames('modal-cancel-button', { busy: props.busy })}
        type="button"
        onClick={handleFormSubmit}
        disabled={props.busy}
      >
        <span className="ardu-store-button-text">{'I Understand. Cancel My Plan'}</span>
        <div className="spinner-container">
          <div className="spinner" />
        </div>
      </button>
    </div>
  );

  return (
    <Modal
      id="cancel-modal"
      className="danger delete-thing"
      onClose={props.onToggle}
      heading={heading}
      fullDescription={fullDescription}
      buttons={buttons}
      closeIcon={true}
      {...props}
    ></Modal>
  );
}

CancelModal.propTypes = {
  busy: PropTypes.bool,
  onConfirmCancelSubscription: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  planDescription: PropTypes.object,
  subscription: PropTypes.object,
};

export default CancelModal;
