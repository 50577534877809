const CHARGE_CREATE = 'app/charge/CHARGE_CREATE';
const CHARGE_CREATE_REQUESTED = 'app/charge/CHARGE_CREATE_REQUESTED';
const CHARGE_CREATE_SUCCEEDED = 'app/charge/CHARGE_CREATE_SUCCEEDED';
const CHARGE_CREATE_FAILED = 'app/charge/CHARGE_CREATE_FAILED';
const CHARGE_CREATE_CLEAR_FAILED = 'app/charge/CHARGE_CREATE_CLEAR_FAILED';

export default {
  CHARGE_CREATE,
  CHARGE_CREATE_REQUESTED,
  CHARGE_CREATE_SUCCEEDED,
  CHARGE_CREATE_FAILED,
  CHARGE_CREATE_CLEAR_FAILED,
};
