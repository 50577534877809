import React from 'react';
import PropTypes from 'prop-types';
import './AwsSubscriptionList.scss';
import { CLOUD_CREATE, ENTERPRISE_AWS_PRODUCT } from '../../../../utils/utilities';
import { add, format } from 'date-fns';
import classnames from 'classnames';

const AwsSubscriptionList = function AwsSubscriptionList(props) {
  const { list, profile, organizations } = props;

  function calcDuration(duration: number) {
    if (duration < 12) return duration + ' MONTHS';
    else if (duration === 12) return '1 YEAR';
    else if (duration > 12 && duration % 12 === 0) return duration / 12 + ' YEARS';
    else if (duration > 12 && duration % 12 !== 0) return duration + ' MONTHS';
  }

  function getOrganizationName(organizationId: string) {
    const organization = organizations.find((org) => org.id === organizationId);
    return organization.name;
  }

  return (
    <div className="aws-list">
      {list?.map((awsSubscription, i) => (
        <div
          className={classnames('aws-list__card', {
            'aws-list__card--maker': awsSubscription.arduino_plan_id === CLOUD_CREATE,
            'aws-list__card--pro': awsSubscription.arduino_plan_id === ENTERPRISE_AWS_PRODUCT,
          })}
          key={i}
        >
          <div className="aws-list__content">
            <div className="aws-list__title">
              {awsSubscription.arduino_plan_id === ENTERPRISE_AWS_PRODUCT ? 'Enterprise Plan' : 'Maker Plan'}
              {awsSubscription.arduino_plan_id === CLOUD_CREATE && profile.email}
            </div>
            <div className="aws-list__plan-details">
              <h6 className="aws-list__sub-title">PLAN DETAILS</h6>
              <div className="aws-list__container">
                {awsSubscription.arduino_plan_id === ENTERPRISE_AWS_PRODUCT && (
                  <>
                    <div className="aws-list__box">{getOrganizationName(awsSubscription.organization_id)}</div>
                    <div className="aws-list__box">
                      {calcDuration(Number(awsSubscription.duration))} -{' '}
                      {format(new Date(awsSubscription.redeemed_date), 'dd/MM/yyyy')} »{' '}
                      {format(
                        add(new Date(awsSubscription.redeemed_date), { months: awsSubscription.duration }),
                        'dd/MM/yyyy'
                      )}
                    </div>
                    <div className="aws-list__box">{awsSubscription.quantity} Things</div>
                    <div className="aws-list__box">No add-ons</div>
                  </>
                )}
                {awsSubscription.arduino_plan_id === CLOUD_CREATE && (
                  <div className="aws-list__box">
                    {calcDuration(Number(awsSubscription.duration))} -{' '}
                    {format(new Date(awsSubscription.redeemed_date), 'dd/MM/yyyy')} »{' '}
                    {format(
                      add(new Date(awsSubscription.redeemed_date), { months: awsSubscription.duration }),
                      'dd/MM/yyyy'
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="aws-list__container">
              <div>
                <h6 className="aws-list__sub-title">PAYMENT METHOD</h6>
                <div className="aws-list__box">AWS Marketplace</div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

AwsSubscriptionList.propTypes = {
  list: PropTypes.array,
  profile: PropTypes.object,
  organizations: PropTypes.array,
};

export default AwsSubscriptionList;
