import { connect } from 'react-redux';
import { billingActions } from '../../../store/billing';
import { cardActions } from '../../../store/card';

import { chargeActions } from '../../../store/charge';
import { subscriptionActions } from '../../../store/subscription';
import { planActions } from '../../../store/plan';
import { authenticationActions } from '../../../store/authentication';
import { countryActions } from '../../../store/country';
import { cartActions } from '../../../store/cart';
import { checkUserCountryActions } from '../../../store/checkUserCountry';
import { couponActions } from '../../../store/coupon';

import Purchase from './PurchaseComponent';

const mapStateToProps = (state) => {
  return {
    card: state.card,
    cart: state.cart,
    charge: state.charge,
    product: state.product,
    plan: state.plan,
    subscription: state.subscription,
    profile: state.authentication.profile,
    organizations: state.authentication.organizations.data,
    certificates: state.authentication.certificates,
    country: state.country,
    billing: state.billing,
    userCountry: state.userCountry,
    coupon: state.coupon,
    taxes: state.taxes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    readCardList: () => dispatch(cardActions.readCardList()),
    createCard: (stripeToken) => dispatch(cardActions.createCard(stripeToken)),
    updateBillingInfo: (billingInfo) => dispatch(billingActions.updateBillingInfo(billingInfo)),
    updateBillingCountry: (country) => dispatch(billingActions.updateBillingCountry(country)),
    updateBillingState: (state) => dispatch(billingActions.updateBillingState(state)),
    createSubscription: (subscriptionParams) => dispatch(subscriptionActions.createSubscription(subscriptionParams)),
    createCharge: (chargeParams) => dispatch(chargeActions.createCharge(chargeParams)),
    upgradeSubscription: (subscriptionId, planId, quantity, prorationdate) =>
      dispatch(subscriptionActions.upgradeSubscription(subscriptionId, planId, quantity, prorationdate)),
    createSubscriptionClearError: () => dispatch(subscriptionActions.createSubscriptionClearError()),
    upgradeSubscriptionClearError: () => dispatch(subscriptionActions.upgradeSubscriptionClearError()),
    getPlanTrial: (planId, planConfig) => dispatch(planActions.getPlanTrial(planId, planConfig)),
    clearPlanTrial: () => dispatch(planActions.clearPlanTrial()),
    getProfile: () => dispatch(authenticationActions.getProfile()),
    readOrganizationList: () => dispatch(authenticationActions.readOrganizationList()),
    createOrganization: (organization) => dispatch(authenticationActions.createOrganization(organization)),
    createRequest: (subscriptionParams) => dispatch(subscriptionActions.createRequest(subscriptionParams)),
    readCountries: () => dispatch(countryActions.readCountries()),
    readStates: (country) => dispatch(countryActions.readStates(country)),
    setCart: (productId, planId, iccid, quantity, price) =>
      dispatch(cartActions.setCart(productId, planId, iccid, quantity, price)),
    unsetCart: () => dispatch(cartActions.unsetCart()),
    readAvailableCountriesList: () => dispatch(checkUserCountryActions.readAvailableCountries()),
    readSubscriptionList: () => dispatch(subscriptionActions.readSubscriptionList()),
    readSubscription: () => dispatch(subscriptionActions.readSubscription()),
    readUpgradePreview: (subscriptionId, planName, quantity, coupon) =>
      dispatch(subscriptionActions.readUpgradePreview(subscriptionId, planName, quantity, coupon)),
    clearCoupon: () => dispatch(couponActions.verifyCouponClear()),
    clearChargeError: () => dispatch(chargeActions.createChargeClearError()),
    updateSubscriptionCard: (subscriptionId, cardId, updateSubscriptionCard) =>
      dispatch(subscriptionActions.updateSubscriptionCard(subscriptionId, cardId, updateSubscriptionCard)),
    verifyCoupon: (coupon, planId) => dispatch(couponActions.verifyCoupon(coupon, planId)),
  };
};

const ConnectedPurchase = connect(mapStateToProps, mapDispatchToProps)(Purchase);

export default ConnectedPurchase;
