import { connect } from 'react-redux';

import ProductDetailsComponent from './ProductDetailsComponent';

import { couponActions } from '../../../../store/coupon';
import { taxesActions } from '../../../../store/taxes';

const mapStateToProps = (state) => {
  return {
    profile: state.authentication.profile,
    coupon: state.coupon,
    taxes: state.taxes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyCoupon: (coupon, planId) => dispatch(couponActions.verifyCoupon(coupon, planId)),
    calculateTaxes: (amount) => dispatch(taxesActions.calculateTaxes(amount)),
  };
};

const ConnectedProductDetailsComponent = connect(mapStateToProps, mapDispatchToProps)(ProductDetailsComponent);

export default ConnectedProductDetailsComponent;
