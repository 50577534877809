import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/common/Modal/Modal';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { simActions } from '../../../store/sim';

import './SimEditModal.scss';

function SimEditModal(props) {
  const { simData, updateDevice, update, onToggle } = props;

  const [name, setName] = useState(simData.name);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (submitted && !update.inProgress) {
      onToggle();
    }
  }, [update, submitted, onToggle]);

  const handleClick = () => {
    if (name === simData.name) {
      props.onToggle();
      return;
    }

    updateDevice(simData.iccid, { name });
    setSubmitted(true);
  };

  const handleBlur = (event) => {
    setName(event.target.value);
  };

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const heading = <h3 id="heading">{'ENTER A NEW SIM NAME'}</h3>;

  const body = (
    <div className="arduino-form">
      <div className="form-item">
        <div className="form-label">
          <label htmlFor={'name'}>{'Name'}</label>
        </div>
        <input type="text" name={'name'} onBlur={handleBlur} value={name} onChange={handleChange}></input>
      </div>
    </div>
  );

  const buttons = (
    <div className="buttons">
      <button
        className={classnames('modal-store-button', {
          busy: update.inProgress,
        })}
        disabled={update.inProgress || name === ''}
        onClick={handleClick}
        type="button"
      >
        <span className="ardu-store-button-text">{'Rename'}</span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    </div>
  );

  return (
    <Modal
      className="warning  sim-edit-modal"
      onClose={props.onToggle}
      heading={heading}
      body={body}
      closeIcon={true}
      buttons={buttons}
      {...props}
    ></Modal>
  );
}

SimEditModal.propTypes = {
  simData: PropTypes.object,
  onToggle: PropTypes.func,
  updateDevice: PropTypes.func,
  update: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    update: state.sim.update,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDevice: (iccid, payload) => dispatch(simActions.updateDevice(iccid, payload)),
  };
};

const ConnectedSimEditModal = connect(mapStateToProps, mapDispatchToProps)(SimEditModal);

export default ConnectedSimEditModal;
