import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Well.scss';

function Well(props) {
  const className = classnames('well', props.className);

  const contentClassName = classnames('well-content', {
    white: props.white,
  });

  return (
    <div className={className}>
      <h5 className="well-title">{props.title}</h5>
      <div className={contentClassName}>{props.children}</div>
    </div>
  );
}

Well.propTypes = {
  className: PropTypes.string,
  white: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Well;
