import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Formik } from 'formik';
import { ReactComponent as InstitutionIcon } from '../../../assets/InstitutionIcon.svg';
import SeatsQuantityPicker from '../SeatsQuantityPicker';
import { CLOUD_CLASSROOM, EDUCATION_MIN_SEATS } from '../../../utils/utilities';
import { MYPLANS_STORE_URL } from '../../../env';
import './EduPlanConfiguration.scss';

const FIELD_SEATS = 'seats';

const FormBody = (props) => {
  const {
    values,
    handleSubmit,
    setFieldValue,
    isEduUpgrading,
    changeQuantityHandler,
    subscription,
    organizations,
    getUpgradePreview,
    billingOrganization,
    planUpdated,
    queryStringOrganization,
    actualOrganization,
    handleEduConfigurePlanCta,
  } = props;

  let selectedOrganization = null;
  organizations?.organizations.forEach((organization) => {
    if (organization.id === billingOrganization.name) {
      selectedOrganization = organization;
    }
  });
  if (selectedOrganization === null) {
    selectedOrganization = {
      name: billingOrganization.name,
      country: billingOrganization.country,
      members: 1,
    };
  }

  const organization = selectedOrganization.name;
  const [quantity, setQuantity] = useState(values[FIELD_SEATS]);
  const minValue = actualOrganization?.members
    ? actualOrganization.members < actualOrganization?.plan_seats
      ? actualOrganization.plan_seats
      : actualOrganization?.members
    : selectedOrganization.members < selectedOrganization.plan_seats
    ? selectedOrganization.plan_seats
    : selectedOrganization.members;

  const actualQuantity = props.subscription?.quantity;
  localStorage.removeItem('organization');

  useEffect(() => {
    if (quantity === actualQuantity && !planUpdated) {
      changeQuantityHandler(quantity);
    } else if (quantity === selectedOrganization.members && !planUpdated) {
      changeQuantityHandler(quantity);
    }
  }, [quantity, actualQuantity, planUpdated, changeQuantityHandler, selectedOrganization.members]);

  let queryStringOrganizationName = '';
  if (queryStringOrganization !== '') {
    props?.organizations?.organizations.forEach((org) => {
      if (org.id === queryStringOrganization) {
        queryStringOrganizationName = org.name;
        if (quantity !== org.plan_seats) {
          localStorage.removeItem('organization');
        }
      }
    });
  }

  const handleQuantityUpdate = (value) => {
    setQuantity(value);
    setFieldValue(FIELD_SEATS, value);
    changeQuantityHandler(value);
    if (isEduUpgrading) {
      getUpgradePreview(subscription.id, CLOUD_CLASSROOM, value);
    }
  };

  const handleRedirectMyPlans = () => {
    window.location.replace(MYPLANS_STORE_URL);
  };

  const quantityAcceptable = quantity >= 5 && quantity >= minValue;
  // quantity >= selectedOrganization?.members && quantity >= 5 && quantity > selectedOrganization?.plan_seats;

  const zeroQuantity = quantity === 0;
  const submitDisabled =
    (isEduUpgrading && values[FIELD_SEATS] === actualQuantity) ||
    (isEduUpgrading && quantity < minValue) ||
    !quantityAcceptable ||
    zeroQuantity;

  const atLeast5 = quantity >= EDUCATION_MIN_SEATS;

  useEffect(() => {
    handleEduConfigurePlanCta(submitDisabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitDisabled]);

  return (
    <form className="form-fields" onSubmit={handleSubmit}>
      <div className="plan">
        {isEduUpgrading && organization !== '' && (
          <div className="plan-institution">
            <InstitutionIcon />
            <div className="plan-institution__box">
              <span className="plan-institution-text">{organization}</span>
              <span className="plan-institution-text__members">{selectedOrganization.members} active members</span>
            </div>
          </div>
        )}
        {isEduUpgrading && organization === '' && queryStringOrganizationName !== '' && (
          <div className="plan-institution">
            <InstitutionIcon />{' '}
            <div className="plan-institution__box">
              <span className="plan-institution-text">{queryStringOrganizationName}</span>{' '}
            </div>
          </div>
        )}
        <div className="plan-title">Plan price changes based on the number of members:</div>
        <div className="plan-seats">
          <div className={classnames('box', { active: values[FIELD_SEATS] <= 50 })}>
            <div className="title">Up to 50</div>
            <div className="price">
              <span className="dollars">$ 20</span> / member / year
            </div>
          </div>
          <div className={classnames('box', { active: values[FIELD_SEATS] > 50 && values[FIELD_SEATS] <= 100 })}>
            <div className="title">From 51 to 100</div>
            <div className="price">
              <span className="dollars">$ 18</span> / member / year
            </div>
          </div>
          <div className={classnames('box', { active: values[FIELD_SEATS] > 100 })}>
            <div className="title">101 onwards</div>
            <div className="price">
              <span className="dollars">$ 15</span> / member / year
            </div>
          </div>
        </div>
        <div className="plan-title">
          {isEduUpgrading
            ? 'Confirm or upgrade the number of members in your shared Space:'
            : 'Select the number of members in your shared Space:'}{' '}
        </div>
        <SeatsQuantityPicker value={quantity} minValue={0} maxValue={9999} onChange={(e) => handleQuantityUpdate(e)} />
        {/* I don't have a subscription, i'm in new organization and Q is not acceptable*/}
        {!isEduUpgrading && !atLeast5 && selectedOrganization.members === 1 && (
          <div className="helper-text">
            A school plan can be bought for a minimun of <strong>5 members</strong>.
          </div>
        )}
        {/* I have a subscription and the Q is not acceptable */}
        {isEduUpgrading && quantity < minValue && (
          <div className="plan-alert">
            You can not decrease the number of members in an active shared Space. If you want to cancel your plan, go to{' '}
            <span onClick={() => handleRedirectMyPlans()}>my plans</span>.
          </div>
        )}
        {/* I don't have a subscription and the Q is not acceptable */}
        {!isEduUpgrading && !quantityAcceptable && selectedOrganization.members !== 1 && (
          <div className="plan-alert">
            This shared Space already has <strong>{minValue}</strong> active members. You need to remove some members if
            you want to reduce the size of your shared Space.
          </div>
        )}
      </div>
    </form>
  );
};

FormBody.propTypes = {
  match: PropTypes.object,
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  subscription: PropTypes.object,
  isEduUpgrading: PropTypes.bool,
  changeQuantityHandler: PropTypes.func.isRequired,
  organizations: PropTypes.object,
  seatsQuantity: PropTypes.number,
  getUpgradePreview: PropTypes.func,
  lastTotalUpgradePrice: PropTypes.object,
  billingOrganization: PropTypes.object,
  planUpdated: PropTypes.bool,
  handleQueryStringPlan: PropTypes.func,
  queryStringOrganization: PropTypes.string,
  actualOrganization: PropTypes.object,
};

export default class EduPlanConfiguration extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const initialValues = {};
    let members = 0;

    //When coming from institution
    this.props.organizations?.organizations.forEach((organization) => {
      if (organization.id === this.props.billingOrganization.name) {
        if (organization.members < organization.plan_seats) members = organization.plan_seats;
        else members = organization.members;
      }
    });

    //When coming from myplans
    const search = new URLSearchParams(window.location.search);
    const myPlansOrganizationSelectedId = search.get('organization_id');
    const actualOrganization = {};
    this.props.organizations?.organizations.forEach((organization) => {
      if (organization.id === myPlansOrganizationSelectedId) {
        Object.assign(actualOrganization, organization);
        if (organization.members < organization.plan_seats) members = organization.plan_seats;
        else members = organization.members;
      }
    });

    //When coming from other domain with organization_id in URL
    this.props.organizations?.organizations.forEach((organization) => {
      if (organization.id === this.props.queryStringOrganization) {
        Object.assign(actualOrganization, organization);
        if (organization.members < organization.plan_seats) members = organization.plan_seats;
        else members = organization.members;
      }
    });

    const seatsQuantity = members;
    if (!seatsQuantity || seatsQuantity === null || !this.props.isEduUpgrading) {
      if (
        (seatsQuantity === 1 && this.props.seatsQuantity !== 0) ||
        (!seatsQuantity && this.props.seatsQuantity !== 0) ||
        (seatsQuantity > EDUCATION_MIN_SEATS && this.props.seatsQuantity > EDUCATION_MIN_SEATS)
      ) {
        initialValues[FIELD_SEATS] = this.props.seatsQuantity;
      } else if (seatsQuantity > EDUCATION_MIN_SEATS) {
        initialValues[FIELD_SEATS] = seatsQuantity;
      } else {
        initialValues[FIELD_SEATS] = EDUCATION_MIN_SEATS;
      }
    } else {
      if (seatsQuantity && this.props.seatsQuantity !== 0 && this.props.seatsQuantity > seatsQuantity) {
        initialValues[FIELD_SEATS] = this.props.seatsQuantity;
      } else {
        initialValues[FIELD_SEATS] = seatsQuantity;
      }
    }

    return (
      <div className="billing-info-form arduino-form">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.props.onSubmit}
          component={(formikProps) => (
            <FormBody actualOrganization={actualOrganization} {...formikProps} {...this.props} />
          )}
        />
      </div>
    );
  }
}

EduPlanConfiguration.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onViewButtonClick: PropTypes.func,
  plan: PropTypes.object,
  seatsQuantity: PropTypes.number,
  isEduUpgrading: PropTypes.bool,
  changeQuantityHandler: PropTypes.func,
  subscription: PropTypes.object,
  organizations: PropTypes.object,
  getUpgradePreview: PropTypes.func,
  lastTotalUpgradePrice: PropTypes.object,
  billingOrganization: PropTypes.object,
  planUpdated: PropTypes.bool,
  handleQueryStringPlan: PropTypes.func,
  queryStringOrganization: PropTypes.string,
  match: PropTypes.object,
  handleEduConfigurePlanCta: PropTypes.func,
};
