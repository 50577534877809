import { connect } from 'react-redux';

import { activationActions } from '../../../../store/activations';
import { subscriptionActions } from '../../../../store/subscription';
import { authenticationActions } from '../../../../store/authentication';
import { countryActions } from '../../../../store/country';

import VouchersTabComponent from './VouchersTabComponent';

const mapStateToProps = (state) => {
  return {
    subscription: state.subscription,
    plan: state.plan,
    product: state.product,
    organizations: state.authentication.organizations.data,
    profile: state.authentication.profile,
    availableOrganizationList: state.availableOrganizationList,
    country: state.country,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    readCountries: () => dispatch(countryActions.readCountries()),
    readSubscriptionList: () => dispatch(subscriptionActions.readSubscriptionList()),
    readOrganizationList: () => dispatch(authenticationActions.readOrganizationList()),
    createOrganization: (organization) => dispatch(authenticationActions.createOrganization(organization)),
    readAvailableOrganizations: (activationId) => dispatch(activationActions.readAvailableOrganizations(activationId)),
  };
};

const ConnectedPlansTabComponent = connect(mapStateToProps, mapDispatchToProps)(VouchersTabComponent);

export default ConnectedPlansTabComponent;
