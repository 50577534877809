import React, { useCallback } from 'react';
import { CLOUD_CLASSROOM, CLOUD_CREATE, ENTERPRISE_PRODUCT } from '../../../../utils/utilities';
import { Loader } from '@arduino/arc';
import { find, orderBy } from 'lodash';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as InfoCheckbox } from '../../../../assets/infoCheckbox.svg';
import './VouchersList.scss';

interface ListElement {
  code: string;
  date: string;
  duration: number;
  owner_email: string;
  plan_id: string;
  product: string;
  quantity: number;
  reseller_name: string;
  sku_id: string;
  valid: boolean;
  voucher_id: string;
  organization_id: string;
}

interface IVouchersList {
  list: ListElement[];
  loading: boolean;
  organizations: any[];
}

const VouchersList = ({ list, loading, organizations }: IVouchersList) => {
  const getProductType = useCallback((voucher) => {
    switch (voucher?.product) {
      case CLOUD_CLASSROOM:
        return 'School plan';
      case ENTERPRISE_PRODUCT:
        return 'Enterprise plan';
      case CLOUD_CREATE:
        if (voucher?.plan_id?.includes('makerplus')) {
          return 'Maker plus plan';
        } else if (voucher?.plan_id?.includes('entry')) {
          return 'Entry plan';
        } else {
          return 'Maker plan';
        }
      default:
        return '-';
    }
  }, []);

  const getExpirationDate = useCallback(
    (voucher) => moment(voucher.date).add(voucher.duration, 'M').format('DD/MM/YYYY'),
    []
  );

  function FeaturesTooltip(props) {
    const { url } = props;

    return (
      <Tooltip
        title={
          <>
            <b>Plan Details</b>
            <br />
            Explore all the features included in your plan at{' '}
            <a href={url} target="_blank" rel="noreferrer">
              cloud.arduino.cc
            </a>
          </>
        }
        classes={{ popper: 'vouchers-list__features-tooltip' }}
        placement="bottom-start"
      >
        <InfoCheckbox />
      </Tooltip>
    );
  }

  const getFeatures = useCallback((voucher) => {
    switch (voucher?.product) {
      case CLOUD_CLASSROOM: // TODO
        return (
          <>
            {voucher?.quantity} <FeaturesTooltip url={`${process.env.REACT_APP_CLOUD_LANDING_WEBSITE}plans#school`} />
          </>
        );
      case ENTERPRISE_PRODUCT:
        return (
          <>
            {voucher?.quantity} Things{' '}
            <FeaturesTooltip url={`${process.env.REACT_APP_CLOUD_LANDING_WEBSITE}plans#business`} />
          </>
        );
      case CLOUD_CREATE:
        if (voucher?.plan_id?.includes('makerplus')) {
          return (
            <>
              100 Things <FeaturesTooltip url={`${process.env.REACT_APP_CLOUD_LANDING_WEBSITE}plans`} />
            </>
          );
        } else if (voucher?.plan_id?.includes('entry')) {
          return (
            <>
              10 Things <FeaturesTooltip url={`${process.env.REACT_APP_CLOUD_LANDING_WEBSITE}plans`} />
            </>
          );
        } else {
          return (
            <>
              25 Things <FeaturesTooltip url={`${process.env.REACT_APP_CLOUD_LANDING_WEBSITE}plans`} />
            </>
          );
        }
      default:
        return '-';
    }
  }, []);

  const getSpace = useCallback(
    (voucher) => {
      switch (voucher?.product) {
        case CLOUD_CLASSROOM:
          return 'School'; //TODO
        case ENTERPRISE_PRODUCT: {
          const org = find(organizations, (o) => o?.id === voucher?.organization_id);
          return org?.name;
        }
        case CLOUD_CREATE:
        default:
          return 'Private Space';
      }
    },
    [organizations]
  );

  const getActiveMakerPlan = useCallback(() => {
    const makerList = orderBy(
      list.filter((i) => i.product === 'create'),
      'date',
      'desc'
    );
    return makerList[0]?.voucher_id;
  }, [list]);

  const getValidity = useCallback(
    (voucher) => {
      const active = (
        <>
          <span className="vouchers-list__dot vouchers-list__dot--active"></span>Active
        </>
      );
      const expired = (
        <>
          <span className="vouchers-list__dot vouchers-list__dot--expired"></span>Expired
        </>
      );
      if (voucher.product !== 'create') {
        return voucher.valid ? active : expired;
      } else {
        return voucher.valid && voucher.voucher_id === getActiveMakerPlan() ? active : expired;
      }
    },
    [getActiveMakerPlan]
  );

  if (loading) {
    return (
      <div className="vouchers-list">
        <Loader bgcolor="#fff" />{' '}
      </div>
    );
  }

  if (list.length > 0) {
    return (
      <div className="vouchers-list">
        <table className="vouchers-list__table">
          <thead>
            <tr className="vouchers-list__table-header">
              <th>
                <b>Plan name</b>
              </th>
              <th>Associated to</th>
              <th>Features</th>
              <th>Status</th>
              <th>Expire on</th>
            </tr>
          </thead>
          <tbody>
            {list.map((voucher) => (
              <tr key={voucher.voucher_id} className="vouchers-list__table-row">
                <td>
                  <b>Arduino Cloud - {getProductType(voucher)}</b>
                </td>
                <td>{getSpace(voucher)}</td>
                <td>
                  <div className="vouchers-list__features">{getFeatures(voucher)}</div>
                </td>
                <td>
                  <div className="vouchers-list__status">{getValidity(voucher)}</div>
                </td>
                <td>{getExpirationDate(voucher)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    return <div className="vouchers-list__empty-state">no voucher redeemed</div>;
  }
};

export default VouchersList;
