import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.ACTIVATION_READ_REQUESTED:
      return {
        ...state,
        read: {
          error: null,
          inProgress: true,
          id: action.activationId,
        },
      };
    case types.ACTIVATION_READ_SUCCEEDED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.id]: action.data,
        },
        read: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
          id: null,
        },
      };
    case types.ACTIVATION_READ_FAILED:
      return {
        ...state,
        read: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
          id: null,
        },
      };

    case types.ACTIVATION_READ_AVAILABLE_ORGANIZATION_REQUESTED:
      return {
        ...state,
        availableOrganizationList: [],
        read: {
          error: null,
          inProgress: true,
          id: action.subscriptionId,
        },
      };
    case types.ACTIVATION_READ_AVAILABLE_ORGANIZATION_SUCCEEDED:
      return {
        ...state,
        availableOrganizationList: action.data,
        read: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
          id: null,
        },
      };
    case types.ACTIVATION_READ_AVAILABLE_ORGANIZATION_FAILED:
      return {
        ...state,
        read: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
          id: null,
        },
      };
  }
}
