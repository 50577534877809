const SUBSCRIPTION_LIST_READ = 'app/subscription/SUBSCRIPTION_LIST_READ';
const SUBSCRIPTION_LIST_READ_REQUESTED = 'app/subscription/SUBSCRIPTION_LIST_READ_REQUESTED';
const SUBSCRIPTION_LIST_READ_SUCCEEDED = 'app/subscription/SUBSCRIPTION_LIST_READ_SUCCEEDED';
const SUBSCRIPTION_LIST_READ_FAILED = 'app/subscription/SUBSCRIPTION_LIST_READ_FAILED';

const SUBSCRIPTION_READ = 'app/subscription/SUBSCRIPTION_READ';
const SUBSCRIPTION_READ_REQUESTED = 'app/subscription/SUBSCRIPTION_READ_REQUESTED';
const SUBSCRIPTION_READ_SUCCEEDED = 'app/subscription/SUBSCRIPTION_READ_SUCCEEDED';
const SUBSCRIPTION_READ_FAILED = 'app/subscription/SUBSCRIPTION_READ_FAILED';

const SUBSCRIPTION_UPGRADE_PREVIEW = 'app/subscription/SUBSCRIPTION_UPGRADE_PREVIEW';
const SUBSCRIPTION_UPGRADE_PREVIEW_REQUESTED = 'app/subscription/SUBSCRIPTION_UPGRADE_PREVIEW_REQUESTED';
const SUBSCRIPTION_UPGRADE_PREVIEW_SUCCEEDED = 'app/subscription/SUBSCRIPTION_UPGRADE_PREVIEW_SUCCEEDED';
const SUBSCRIPTION_UPGRADE_PREVIEW_FAILED = 'app/subscription/SUBSCRIPTION_UPGRADE_PREVIEW_FAILED';

const SUBSCRIPTION_CREATE = 'app/subscription/SUBSCRIPTION_CREATE';
const SUBSCRIPTION_CREATE_REQUESTED = 'app/subscription/SUBSCRIPTION_CREATE_REQUESTED';
const SUBSCRIPTION_CREATE_SUCCEEDED = 'app/subscription/SUBSCRIPTION_CREATE_SUCCEEDED';
const SUBSCRIPTION_CREATE_FAILED = 'app/subscription/SUBSCRIPTION_CREATE_FAILED';
const SUBSCRIPTION_CREATE_CLEAR_FAILED = 'app/subscription/SUBSCRIPTION_CREATE_CLEAR_FAILED';

const SUBSCRIPTION_REQUEST_CREATE = 'app/subscription/SUBSCRIPTION_REQUEST_CREATE';
const SUBSCRIPTION_REQUEST_CREATE_REQUESTED = 'app/subscription/SUBSCRIPTION_REQUEST_CREATE_REQUESTED';
const SUBSCRIPTION_REQUEST_CREATE_SUCCEEDED = 'app/subscription/SUBSCRIPTION_REQUEST_CREATE_SUCCEEDED';
const SUBSCRIPTION_REQUEST_CREATE_FAILED = 'app/subscription/SUBSCRIPTION_REQUEST_CREATE_FAILED';
const SUBSCRIPTION_REQUEST_CREATE_CLEAR_FAILED = 'app/subscription/SUBSCRIPTION_REQUEST_CREATE_CLEAR_FAILED';

const SUBSCRIPTION_CANCEL = 'app/subscription/SUBSCRIPTION_CANCEL';
const SUBSCRIPTION_CANCEL_REQUESTED = 'app/subscription/SUBSCRIPTION_CANCEL_REQUESTED';
const SUBSCRIPTION_CANCEL_SUCCEEDED = 'app/subscription/SUBSCRIPTION_CANCEL_SUCCEEDED';
const SUBSCRIPTION_CANCEL_FAILED = 'app/subscription/SUBSCRIPTION_CANCEL_FAILED';

const SUBSCRIPTION_UPDATE_CARD = 'app/subscription/SUBSCRIPTION_UPDATE_CARD';
const SUBSCRIPTION_UPDATE_CARD_REQUESTED = 'app/subscription/SUBSCRIPTION_UPDATE_CARD_REQUESTED';
const SUBSCRIPTION_UPDATE_CARD_SUCCEEDED = 'app/subscription/SUBSCRIPTION_UPDATE_CARD_SUCCEEDED';
const SUBSCRIPTION_UPDATE_CARD_FAILED = 'app/subscription/SUBSCRIPTION_UPDATE_CARD_FAILED';

const SUBSCRIPTION_UPGRADE = 'app/subscription/SUBSCRIPTION_UPGRADE';
const SUBSCRIPTION_UPGRADE_REQUESTED = 'app/subscription/SUBSCRIPTION_UPGRADE_REQUESTED';
const SUBSCRIPTION_UPGRADE_SUCCEEDED = 'app/subscription/SUBSCRIPTION_UPGRADE_SUCCEEDED';
const SUBSCRIPTION_UPGRADE_FAILED = 'app/subscription/SUBSCRIPTION_UPGRADE_FAILED';
const SUBSCRIPTION_UPGRADE_CLEAR_FAILED = 'app/subscription/SUBSCRIPTION_UPGRADE_CLEAR_FAILED';

const SUBSCRIPTION_REACTIVATE = 'app/subscription/SUBSCRIPTION_REACTIVATE';
const SUBSCRIPTION_REACTIVATE_REQUESTED = 'app/subscription/SUBSCRIPTION_REACTIVATE_REQUESTED';
const SUBSCRIPTION_REACTIVATE_SUCCEEDED = 'app/subscription/SUBSCRIPTION_REACTIVATE_SUCCEEDED';
const SUBSCRIPTION_REACTIVATE_FAILED = 'app/subscription/SUBSCRIPTION_REACTIVATE_FAILED';

const SUBSCRIPTION_CHANGE = 'app/subscription/SUBSCRIPTION_CHANGE';

export default {
  SUBSCRIPTION_LIST_READ,
  SUBSCRIPTION_LIST_READ_REQUESTED,
  SUBSCRIPTION_LIST_READ_SUCCEEDED,
  SUBSCRIPTION_LIST_READ_FAILED,
  SUBSCRIPTION_READ,
  SUBSCRIPTION_READ_REQUESTED,
  SUBSCRIPTION_READ_SUCCEEDED,
  SUBSCRIPTION_READ_FAILED,
  SUBSCRIPTION_CREATE,
  SUBSCRIPTION_CREATE_REQUESTED,
  SUBSCRIPTION_CREATE_SUCCEEDED,
  SUBSCRIPTION_CREATE_FAILED,
  SUBSCRIPTION_CANCEL,
  SUBSCRIPTION_CANCEL_REQUESTED,
  SUBSCRIPTION_CANCEL_SUCCEEDED,
  SUBSCRIPTION_CANCEL_FAILED,
  SUBSCRIPTION_UPDATE_CARD,
  SUBSCRIPTION_UPDATE_CARD_REQUESTED,
  SUBSCRIPTION_UPDATE_CARD_SUCCEEDED,
  SUBSCRIPTION_UPDATE_CARD_FAILED,
  SUBSCRIPTION_UPGRADE,
  SUBSCRIPTION_UPGRADE_REQUESTED,
  SUBSCRIPTION_UPGRADE_SUCCEEDED,
  SUBSCRIPTION_UPGRADE_FAILED,
  SUBSCRIPTION_REACTIVATE,
  SUBSCRIPTION_REACTIVATE_REQUESTED,
  SUBSCRIPTION_REACTIVATE_SUCCEEDED,
  SUBSCRIPTION_REACTIVATE_FAILED,
  SUBSCRIPTION_CHANGE,
  SUBSCRIPTION_CREATE_CLEAR_FAILED,
  SUBSCRIPTION_UPGRADE_CLEAR_FAILED,
  SUBSCRIPTION_UPGRADE_PREVIEW,
  SUBSCRIPTION_UPGRADE_PREVIEW_REQUESTED,
  SUBSCRIPTION_UPGRADE_PREVIEW_SUCCEEDED,
  SUBSCRIPTION_UPGRADE_PREVIEW_FAILED,
  SUBSCRIPTION_REQUEST_CREATE,
  SUBSCRIPTION_REQUEST_CREATE_REQUESTED,
  SUBSCRIPTION_REQUEST_CREATE_SUCCEEDED,
  SUBSCRIPTION_REQUEST_CREATE_FAILED,
  SUBSCRIPTION_REQUEST_CREATE_CLEAR_FAILED,
};
