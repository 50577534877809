import types from './types';

const readCardList = (params) => ({
  type: types.CARD_LIST_READ,
  params,
});

const readCardListRequest = () => ({
  type: types.CARD_LIST_READ_REQUESTED,
});

const readCardListSuccess = (data) => ({
  type: types.CARD_LIST_READ_SUCCEEDED,
  data,
});

const readCardListFailure = (error) => ({
  type: types.CARD_LIST_READ_FAILED,
  error,
});

const createCard = (params) => ({
  type: types.CARD_CREATE,
  params,
});

const createCardRequest = () => ({
  type: types.CARD_CREATE_REQUESTED,
});

const createCardSuccess = (data) => ({
  type: types.CARD_CREATE_SUCCEEDED,
  data,
});

const createCardFailure = (error) => ({
  type: types.CARD_CREATE_FAILED,
  error,
});

const deleteCard = (id) => ({
  type: types.CARD_DELETE,
  id,
});

const deleteCardRequest = () => ({
  type: types.CARD_DELETE_REQUESTED,
});

const deleteCardSuccess = (data) => ({
  type: types.CARD_DELETE_SUCCEEDED,
  data,
});

const deleteCardFailure = (error) => ({
  type: types.CARD_DELETE_FAILED,
  error,
});

export default {
  readCardList,
  readCardListRequest,
  readCardListSuccess,
  readCardListFailure,
  createCard,
  createCardRequest,
  createCardSuccess,
  createCardFailure,
  deleteCard,
  deleteCardRequest,
  deleteCardSuccess,
  deleteCardFailure,
};
