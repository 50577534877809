import { COUNTRIES_API_URL } from '../env';
import fetch from '../utils/authenticatedFetch';
import { NetworkError } from './utils';

export async function readCountries() {
  const res = await fetch(`${COUNTRIES_API_URL}`);
  const data = await res.json();
  if (res.ok) {
    return data;
  }
  throw new NetworkError(res, data);
}

export async function readCountryStates(countryCode) {
  const res = await fetch(`${COUNTRIES_API_URL}/${countryCode}`);
  const data = await res.json();
  if (res.ok) {
    return data;
  }
  throw new NetworkError(res, data);
}
