import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './AbsoluteSpinner.scss';

class AbsoluteSpinner extends Component {
  shouldComponentUpdate = (nextProps) => {
    // Prevent the spinner to stutter and render for no reason
    if (this.props.hidden !== nextProps.hidden) {
      return true;
    }
    if (this.props.height !== nextProps.height) {
      return true;
    }

    return false;
  };

  render() {
    if (this.props.hidden) {
      return null;
    }

    return (
      <div className="absolute-spinner-container" style={{ height: this.props.height }}>
        <span className={classnames('absolute-spinner', { white: this.props.white, clouds: this.props.clouds })} />
      </div>
    );
  }
}

AbsoluteSpinner.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hidden: PropTypes.bool,
  white: PropTypes.bool,
  clouds: PropTypes.bool,
};

export default AbsoluteSpinner;
