import { getCookie, setCookie } from './cookies';

/**
 * Returns the "source" and "redirect" query params values.
 */
export function getRedirectParams() {
  function getParameterByName(param) {
    return new URLSearchParams(window.location.search).get(param);
  }
  const params = {
    source: getParameterByName('source'),
    redirect: getParameterByName('redirect'),
  };

  if (params.source && params.redirect) {
    setCookie('redirect-params', JSON.stringify(params), 180);
  } else {
    const redirectParamsCookies = getCookie('redirect-params');
    if (redirectParamsCookies) {
      const redirectParams = JSON.parse(redirectParamsCookies);
      if (redirectParams.source && redirectParams.redirect) {
        return redirectParams;
      }
    }
  }
  return params;
}

/**
 * Checks if the "source" query params matches one of the supported
 * source applications.
 */
export function validateRedirectSource(source) {
  if (!source) {
    return false;
  }

  return source.match(/^(webide|iotcloud|create|gettingstarted|devices|oplakit)$/) !== null;
}
