import create from './create';

const initialState = {
  create: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
};

export default function (state = initialState, action) {
  return create(state, action) || state;
}
