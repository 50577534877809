/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';

import { ReactComponent as InfoCheckbox } from '../../../../assets/icons/info-checkbox.svg';
import { ReactComponent as CreditCard } from '../../../../assets/credit-card.svg';
import Checkbox from '../../../common/Checkbox';

import { STORE_TERMS_URL } from '../../../../env';

import './AlternativePayment.scss';

function AlternativePaymentForm(props) {
  const { history, match, handlePaymentTerms, billingPageVisited } = props;
  const [terms, setTerms] = useState({ value: false, showError: false });

  if (!billingPageVisited) {
    return <Redirect to={`${props.match.path}/plan`} />;
  }

  const handleTermsChange = (event) => {
    setTerms({
      value: event.target.checked,
      showError: !event.target.checked,
    });
    handlePaymentTerms(event.target.checked);
  };
  let errorTermsMessage = null;

  if (terms.showError) {
    errorTermsMessage = (
      <span className="error-message">{'You need to accept the terms and conditions to proceed'}</span>
    );
  }

  return (
    <form className="alternative-payment">
      <h6>Alternative payment methods</h6>
      <div className="alternative-payment__body">
        <div>
          <InfoCheckbox className="alternative-payment__icon" />
        </div>
        <div className="alternative-payment__text">
          We accept other payment methods such as bank transfer, cheques, and more. <br />
          By clicking “Submit request”, you agree to be contacted by our sales representatives, which will provide
          information on how to complete the payment.
        </div>
      </div>
      <div className="alternative-payment__credit-card">
        <CreditCard className="alternative-payment__icon" /> Changed your mind?
        <div className="alternative-payment__credit-card--button">
          <button className="delete-button" onClick={() => history.push(`${match.path}/payment`)}>
            {'USE A CREDIT CARD'}
          </button>
        </div>
      </div>
      <hr className="hr" />
      <div className="terms-wrapper">
        <div className="checkbox-wrapper">
          <Checkbox
            name="terms"
            checkboxLabel={
              <span>
                {'I have read and accepted the '}
                <a
                  className="link-button terms-link"
                  href={`${STORE_TERMS_URL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {'Terms and Conditions'}
                </a>
              </span>
            }
            checked={terms.value}
            onChange={handleTermsChange}
            className={`left ${terms.showError ? 'error' : ''}`}
          />
          {errorTermsMessage}
        </div>
      </div>
    </form>
  );
}

AlternativePaymentForm.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  handlePaymentTerms: PropTypes.func,
  billingPageVisited: PropTypes.bool,
};

export default AlternativePaymentForm;
