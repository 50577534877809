import { call, put, takeEvery } from 'redux-saga/effects';

import actions from './actions';
import awsTypes from './types';
import * as awsAPI from '../../apis/aws';

const createAwsSubscription = function* createAwsSubscription({ params }) {
  yield put(actions.createAwsSubscriptionRequest());

  try {
    const result = yield call(awsAPI.createSubscriptionsFromToken, params);
    yield put(actions.createAwsSubscriptionSuccess(result));
  } catch (error) {
    yield put(actions.createAwsSubscriptionFailure(error));
  }
};

const readAwsSubscriptionList = function* readAwsSubscriptionList() {
  yield put(actions.readSubscriptionListRequest());
  try {
    const result = yield call(awsAPI.readAwsSubscriptions);
    yield put(actions.readSubscriptionListSuccess(result));
  } catch (error) {
    yield put(actions.readSubscriptionListFailure(error));
  }
};

/**
 * Listens for a ACTIVATION_READ action to be dispatched.
 */

const watchcreateSubscriptionsFromToken = function* watchcreateSubscriptionsFromToken() {
  yield takeEvery(awsTypes.AWS_CREATE_SUBSCRIPTION, createAwsSubscription);
};

const watchReadSubscriptionList = function* watchReadSubscriptionList() {
  yield takeEvery(awsTypes.AWS_GET_SUBSCRIPTION_LIST, readAwsSubscriptionList);
};

export default [watchReadSubscriptionList, watchcreateSubscriptionsFromToken];
