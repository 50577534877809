/* eslint-disable */
import config from '../../config';
import { Config } from '../../ConfigDef';

function isNil<T>(value: T | null | undefined): value is null | undefined {
  return value === null || value === undefined;
}

export enum Plans {
  NotLogged = -1,
  CloudFree,
  CloudEntryMonthly,
  CloudEntryYearly,
  CloudMakerMonthly,
  CloudMakerYearly,
  CloudMakerPlusMonthly,
  CloudMakerPlusYearly,
}

export enum PlanAction {
  NOT_LOGGED = -1,
  DOWNGRADE,
  CURRENT,
  UPGRADE,
  QUEUED,
  REACTIVATE,
}

export type PlanDetails = {
  id: Plans;
  name: string;
  color: string;
  title: string;
  yearly: boolean;
  features: string[];
  description: string;
};

export type Plan = {
  id: Plans;
  type: Config.Plans;
  available: Config.AvailablePlans;
  action: PlanAction;
  details: PlanDetails;
  user: PlanDetails;
  next?: PlanDetails;
  price: number;
  renewalDate?: string;
  disabled?: boolean;
};

const AvailableMap: { [key in Config.AvailablePlans]?: Plans } = {
  [Config.AvailablePlans.CREATE_FREE]: Plans.CloudFree,
  [Config.AvailablePlans.CREATE_ENTRY_MONTHLY]: Plans.CloudEntryMonthly,
  [Config.AvailablePlans.CREATE_ENTRY_YEARLY]: Plans.CloudEntryYearly,
  [Config.AvailablePlans.CREATE_MAKER_MONTHLY]: Plans.CloudMakerMonthly,
  [Config.AvailablePlans.CREATE_MAKER_YEARLY]: Plans.CloudMakerYearly,
  [Config.AvailablePlans.CREATE_MAKER_PLUS_MONTHLY]: Plans.CloudMakerPlusMonthly,
  [Config.AvailablePlans.CREATE_MAKER_PLUS_YEARLY]: Plans.CloudMakerPlusYearly,
};

const NameMap: { [key in Config.AvailablePlans]?: string } = {
  [Config.AvailablePlans.CREATE_FREE]: 'Free plan',
  [Config.AvailablePlans.CREATE_ENTRY_MONTHLY]: 'monthly Entry plan',
  [Config.AvailablePlans.CREATE_ENTRY_YEARLY]: 'yearly Entry plan',
  [Config.AvailablePlans.CREATE_MAKER_MONTHLY]: 'monthly Maker plan',
  [Config.AvailablePlans.CREATE_MAKER_YEARLY]: 'yearly Maker plan',
  [Config.AvailablePlans.CREATE_MAKER_PLUS_MONTHLY]: 'monthly Maker Plus plan',
  [Config.AvailablePlans.CREATE_MAKER_PLUS_YEARLY]: 'yearly Maker Plus plan',
};

function FromAvailable(available?: Config.AvailablePlans): Plans {
  return (available && AvailableMap[available]) ?? Plans.NotLogged;
}

const DetailsMap: { [key in Config.Plans]?: string[] } = {
  [Config.Plans.CREATE_ENTRY]: ['teal2', 'ENTRY'],
  [Config.Plans.CREATE_MAKER]: ['teal5', 'MAKER'],
  [Config.Plans.CREATE_MAKER_PLUS]: ['onyx', 'MAKER PLUS'],
};

function DetailsFrom(plan: Config.Plans): string[] {
  return DetailsMap[plan] || ['teal0', 'FREE'];
}

type PlanConstructor = { For: (current: Config.Plans, yearly: boolean, renewalDate?: string) => Plan };

export namespace Plans {
  export function detailsFor(plan: Config.AvailablePlans = Config.AvailablePlans.CREATE_FREE): PlanDetails {
    const name = NameMap[plan];
    const id = FromAvailable(plan);
    const { description } = config.plans[plan];
    const current = Config.AvailablePlans.For(plan);
    const [color, title] = DetailsFrom(current);
    const yearly = Config.AvailablePlans.isYearly(plan);
    const features = config.featureList[current] as string[];

    return { id, features, description, color, title, yearly, name };
  }

  export function From(userAvailable?: Config.AvailablePlans, nextPlan?: Config.AvailablePlans): PlanConstructor {
    const userPlan = FromAvailable(userAvailable);
    const next = nextPlan && FromAvailable(nextPlan);

    return {
      For: (type: Config.Plans, yearly: boolean, renewalDate?: string) => {
        const available = Config.AvailablePlans.From(type, yearly);
        const id = FromAvailable(available);

        const { price, disabled } = config.plans[available];

        let action = PlanAction.CURRENT;
        if (userPlan === Plans.NotLogged) action = PlanAction.NOT_LOGGED;
        else if (!isNil(next) && next === id) action = PlanAction.QUEUED;
        else if (userPlan < id) action = PlanAction.UPGRADE;
        else if (userPlan > id) action = PlanAction.DOWNGRADE;

        return {
          id,
          type,
          action,
          available,
          renewalDate,
          details: detailsFor(available),
          user: detailsFor(userAvailable),
          next: action === PlanAction.CURRENT && nextPlan && detailsFor(nextPlan),
          price: yearly ? Math.trunc((price * 100) / 12) / 100 : price,
          disabled,
        };
      },
    };
  }
}
