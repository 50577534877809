import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
//import PrivateRoute from '../../utils/PrivateRoute';

// import SimLanding from './SimLanding';
// import SimActivation from './activation';
// import SimThankYou from './SimThankYou';
// import SimEula from './eula';

function Sim() {
  return (
    <div>
      <Switch>
        {/* <Route exact path={path} component={SimLanding} /> */}
        {/* <Route path={`${path}/purchase-completed`} render={() => <Redirect to={`${path}/thank-you`} />} />
        <PrivateRoute path={`${path}/activation`} component={SimActivation} />
        <PrivateRoute path={`${path}/eula`} component={SimEula} /> */}
        {/* <Route path={`${path}/thank-you`} component={SimThankYou} /> */}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </div>
  );
}

Sim.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Sim;
