import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Checkbox.scss';

function Checkbox(props) {
  return (
    <label className={classnames('digital-store-checkbox', props.className)}>
      <input type="checkbox" checked={props.checked} onChange={props.onChange} />
      <span className="custom-checkbox"></span>
      {props.checkboxLabel}
    </label>
  );
}

Checkbox.propTypes = {
  checkboxLabel: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string.isRequired), PropTypes.string]),
};

export default Checkbox;
