import { call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import checkUserCountryTypes from './types';
import * as checkCountryApi from '../../apis/checkUserCountry';

// check User Country is valid

const checkUserCountry = function* checkUserCountry() {
  yield put(actions.checkUserCountryRequested());
  try {
    const flag = yield call(checkCountryApi.checkUserCountry);
    yield put(actions.checkUserCountrySucceeded(flag));
  } catch (error) {
    yield put(actions.checkUserCountryFailed(error));
  }
};

const readAvailableCountriesList = function* readAvailableCountriesList() {
  yield put(actions.readAvailableCountriesRequest());
  try {
    const result = yield call(checkCountryApi.readAvailableCountriesList);
    yield put(actions.readAvailableCountriesSuccess(result));
  } catch (error) {
    yield put(actions.readAvailableCountriesFailure(error));
  }
};

const watchCountriesAvailableRead = function* watchCountriesAvailableRead() {
  yield takeEvery(checkUserCountryTypes.COUNTRIES_AVAILABLE_READ, readAvailableCountriesList);
};

const watchCheckUserCountry = function* watchCheckUserCountry() {
  yield takeEvery(checkUserCountryTypes.CHECK_USER_COUNTRY, checkUserCountry);
};

export default [watchCheckUserCountry, watchCountriesAvailableRead];
