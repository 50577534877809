const CHECK_USER_COUNTRY = 'app/checkUserCountry/CHECK_USER_COUNTRY';
const CHECK_USER_COUNTRY_REQUESTED = 'app/checkUserCountry/CHECK_USER_COUNTRY_REQUESTED';
const CHECK_USER_COUNTRY_SUCCEDED = 'app/checkUserCountry/CHECK_USER_COUNTRY_SUCCEDED';
const CHECK_USER_COUNTRY_FAILED = 'app/checkUserCountry/CHECK_USER_COUNTRY_FAILED';

const COUNTRIES_AVAILABLE_READ = 'app/country/COUNTRIES_AVAILABLE_READ';
const COUNTRIES_AVAILABLE_READ_REQUESTED = 'app/country/COUNTRIES_READ_AVAILABLE_REQUESTED';
const COUNTRIES_AVAILABLE_READ_SUCCEEDED = 'app/country/COUNTRIES_READ_AVAILABLE_SUCCEEDED';
const COUNTRIES_AVAILABLE_READ_FAILED = 'app/country/COUNTRIES_READ_AVAILABLE_FAILED';

export default {
  CHECK_USER_COUNTRY,
  CHECK_USER_COUNTRY_REQUESTED,
  CHECK_USER_COUNTRY_SUCCEDED,
  CHECK_USER_COUNTRY_FAILED,
  COUNTRIES_AVAILABLE_READ,
  COUNTRIES_AVAILABLE_READ_REQUESTED,
  COUNTRIES_AVAILABLE_READ_SUCCEEDED,
  COUNTRIES_AVAILABLE_READ_FAILED,
};
