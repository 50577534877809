const COUPON_VERIFY = 'app/coupon/COUPON_VERIFY';
const COUPON_VERIFY_REQUESTED = 'app/coupon/COUPON_VERIFY_REQUESTED';
const COUPON_VERIFY_SUCCEEDED = 'app/coupon/COUPON_VERIFY_SUCCEEDED';
const COUPON_VERIFY_FAILED = 'app/coupon/COUPON_VERIFY_FAILED';
const COUPON_VERIFY_CLEAR = 'app/coupon/COUPON_VERIFY_CLEAR';

export default {
  COUPON_VERIFY,
  COUPON_VERIFY_REQUESTED,
  COUPON_VERIFY_SUCCEEDED,
  COUPON_VERIFY_FAILED,
  COUPON_VERIFY_CLEAR,
};
