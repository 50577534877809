import types from './types';

const updateBillingInfo = (billingInfo) => ({
  type: types.UPDATE_BILLING,
  billingInfo,
});

const updateBillingInfoRequested = () => ({
  type: types.UPDATE_BILLING_REQUEST,
});

const updateBillingInfoSucceeded = (profile) => ({
  type: types.UPDATE_BILLING_SUCCESS,
  profile,
});

const updateBillingCountry = (country) => ({
  type: types.UPDATE_BILLING_COUNTRY,
  country,
});

const updateBillingState = (state) => ({
  type: types.UPDATE_BILLING_STATE,
  state,
});

const updateBillingInfoFailed = (error) => ({
  type: types.UPDATE_BILLING_FAILURE,
  error,
});

const initializeBilling = (billingInfo) => ({
  type: types.INITIALIZE_BILLING_INFO,
  billingInfo,
});

export default {
  updateBillingInfo,
  updateBillingInfoRequested,
  updateBillingInfoSucceeded,
  updateBillingInfoFailed,
  updateBillingCountry,
  initializeBilling,
  updateBillingState,
};
