import { put, takeEvery } from '@redux-saga/core/effects';
import { readUserRecap } from '../../apis/userRecap';
import actions from './actions';
import types from './types';

function* loadUserRecap() {
  try {
    const data = yield readUserRecap();
    yield put(actions.loadUserRecapSucceeded(data));
  } catch (error) {
    yield put(actions.loadUserRecapFailed(error));
  }
}

function* watchLoadUserRecap() {
  yield takeEvery(types.LOAD_USER_RECAP, loadUserRecap);
}

export default [watchLoadUserRecap];
