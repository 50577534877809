import React from 'react';
import ProductCard from '../../components/common/ProductCard/ProductCard';
import { useHistory } from 'react-router-dom';
import { REACT_APP_CLOUD_LANDING_WEBSITE } from '../../env';

function CreateProductCard() {
  const title = <span>{'Create'}</span>;
  const history = useHistory();

  if (localStorage.getItem('aws_account_id') !== null && localStorage.getItem('aws_account_id') !== '') return null;

  return (
    <ProductCard
      productId="create"
      description="Get more from Arduino Cloud. Choose your plan to increase the number of sketches, the number of devices, compilation minutes and storage space."
      name={title}
      category="plans"
      path="/create"
      replace={REACT_APP_CLOUD_LANDING_WEBSITE}
      history={history}
    />
  );
}

CreateProductCard.propTypes = {};

export default CreateProductCard;
