import React from 'react';
import PropTypes from 'prop-types';
import { TooltipTrigger, TriggerIcon } from '@arduino/arc';
import { ReactComponent as InfoCheckbox } from '../../../../assets/infoCheckbox.svg';

import './Activations.scss';

function NotCompatibleComponent(props) {
  const { popupText } = props;

  return (
    <>
      <span className="organization__not-compatible">NOT COMPATIBLE</span>
      <TooltipTrigger
        TriggerElement={
          <TriggerIcon className="info-popover">
            <InfoCheckbox />
          </TriggerIcon>
        }
        isDismissable
        delay={0}
        tooltipPlacement="bottom-start"
        tooltipStrategy="absolute"
        tooltipType="popover"
        className="organization__popover"
      >
        <span
          className=""
          dangerouslySetInnerHTML={{
            __html: popupText,
          }}
        />
      </TooltipTrigger>
    </>
  );
}

NotCompatibleComponent.propTypes = {
  popupText: PropTypes.string,
};

export default NotCompatibleComponent;
