import types from './types';

const readSubscriptionList = (params) => ({
  type: types.AWS_GET_SUBSCRIPTION_LIST,
  params,
});

const readSubscriptionListRequest = () => ({
  type: types.AWS_GET_SUBSCRIPTION_LIST_REQUESTED,
});

const readSubscriptionListSuccess = (data) => ({
  type: types.AWS_GET_SUBSCRIPTION_LIST_SUCCEEDED,
  data,
});

const readSubscriptionListFailure = (error) => ({
  type: types.AWS_GET_SUBSCRIPTION_LIST_FAILED,
  error,
});

const createAwsSubscription = (params) => ({
  type: types.AWS_CREATE_SUBSCRIPTION,
  params,
});

const createAwsSubscriptionRequest = () => ({
  type: types.AWS_CREATE_SUBSCRIPTION_REQUESTED,
});

const createAwsSubscriptionSuccess = (data) => ({
  type: types.AWS_CREATE_SUBSCRIPTION_SUCCEEDED,
  data,
});

const createAwsSubscriptionFailure = (error) => ({
  type: types.AWS_CREATE_SUBSCRIPTION_FAILED,
  error,
});

export default {
  readSubscriptionList,
  readSubscriptionListRequest,
  readSubscriptionListSuccess,
  readSubscriptionListFailure,
  createAwsSubscription,
  createAwsSubscriptionRequest,
  createAwsSubscriptionSuccess,
  createAwsSubscriptionFailure,
};
