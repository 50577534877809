import list from './readList';

const initialState = {
  details: {},
  countriesList: [],
  statesList: [],
  readList: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  readStatesList: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
};

export default function (state = initialState, action) {
  return list(state, action) || state;
}
