import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SubscriptionCardContext } from '../SubscriptionCardContext';
import CancelModal from './CancelModal';
import { PRODUCT_SIM } from '../../../../utils/utilities';

import { subscriptionActions } from '../../../../store/subscription';
import { OpenSuccessNotification } from '../../Toast/Toast';
import { NODE_ENV } from '../../../../env';

import './CancelButton.scss';

function CancelButton(props) {
  const {
    plan,
    planDescription,
    subscription,
    stateSubscription,
    cancelSubscription,
    isEducationPlan,
    isOwner,
    isEnterprisePlan,
    activationId,
  } = props;

  // States for the delete subscription modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleProfitWellSurvey = function handleProfitWellSurvey() {
    if (props.subscription.product !== PRODUCT_SIM && NODE_ENV === 'production') {
      window.auth
        .init()
        .catch(() => null)
        .then((client) => [client, client && client.isAuthenticated().catch(() => false)])
        .then(([client, authenticated]) => authenticated && client.getUser())
        .catch(() => null)
        .then(() => window.dataLayer.push({ user_id: props.subscription.user_id }));

      window.profitwell('init_cancellation_flow', { subscription_id: props.subscription.id }).then((result) => {
        // The customer abort the cancellation - he still have the subscription
        if (result.status === 'retained' || result.status === 'aborted') {
          return;
        }

        // The customer end the cancellation - he don't have the subscription anymore
        //window.dataLayer.push('init_cancellation_flow', { subscription_id: props.subscription.id });
        setDeleteModalOpen(true);
      });
    } else {
      cancelSubscription(subscription.id, true);
    }
  };

  const handleCancelModalHide = function handleCancelModalHide() {
    setDeleteModalOpen(false);
  };

  const handleConfirmCancelSubscription = function handleConfirmCancelSubscription() {
    cancelSubscription(subscription.id, true);
  };

  // When `props.subscription.cancel.inProgress` changes, and the new value is false -> close the modal
  useEffect(() => {
    if (deleteModalOpen && !stateSubscription.cancel.inProgress) {
      handleCancelModalHide();
      OpenSuccessNotification('Your subscription has been cancelled.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubscription.cancel.inProgress]);

  if (activationId) return null;

  if (
    (plan.cancellable && !isEducationPlan && !isEnterprisePlan) ||
    (plan.cancellable && isEducationPlan && isOwner) ||
    (plan.cancellable && isEnterprisePlan && isOwner)
  ) {
    return (
      <>
        <button
          data-testid={`subscription-${subscription.id}`}
          className="cancel-button stack-card-action-button-danger"
          type="button"
          onClick={handleProfitWellSurvey}
        >
          {'Cancel Plan '}
        </button>
        <CancelModal
          open={deleteModalOpen}
          busy={stateSubscription.cancel.inProgress}
          onConfirmCancelSubscription={handleConfirmCancelSubscription}
          onToggle={handleCancelModalHide}
          subscription={subscription}
          planDescription={planDescription}
        />
      </>
    );
  }

  return null;
}

CancelButton.propTypes = {
  plan: PropTypes.object.isRequired,
  planDescription: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  stateSubscription: PropTypes.object.isRequired,
  cancelSubscription: PropTypes.func.isRequired,
  isEducationPlan: PropTypes.bool,
  isOwner: PropTypes.bool,
};

function CancelButtonConsumer({ stateSubscription, cancelSubscription }) {
  return (
    <SubscriptionCardContext.Consumer>
      {({ plan, planDescription, subscription, isEducationPlan, isOwner, isEnterprisePlan, activationId }) => (
        <CancelButton
          plan={plan}
          planDescription={planDescription}
          subscription={subscription}
          stateSubscription={stateSubscription}
          cancelSubscription={cancelSubscription}
          isOwner={isOwner}
          isEducationPlan={isEducationPlan}
          isEnterprisePlan={isEnterprisePlan}
          activationId={activationId}
        />
      )}
    </SubscriptionCardContext.Consumer>
  );
}

CancelButtonConsumer.propTypes = {
  stateSubscription: PropTypes.object.isRequired,
  cancelSubscription: PropTypes.func.isRequired,
  isEducationPlan: PropTypes.bool,
  isOwner: PropTypes.bool,
  isEnterprisePlan: PropTypes.bool,
  activationId: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    stateSubscription: state.subscription,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelSubscription: (id, atPeriodEnd) => dispatch(subscriptionActions.cancelSubscription(id, atPeriodEnd)),
  };
};

const ConnectedCancelButtonComponent = connect(mapStateToProps, mapDispatchToProps)(CancelButtonConsumer);

export default ConnectedCancelButtonComponent;
