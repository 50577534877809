import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import CertificationProductCard from './CertificationProductCard';

import './CertificationExamTypeConfiguration.scss';

const FIELD_PLAN_TYPE = 'type';

const FormBody = (props) => {
  const { values, setFieldValue, handleSubmit, resetFlow } = props;

  const handleSelectTypeCertification = (value) => {
    resetFlow();
    setFieldValue(FIELD_PLAN_TYPE, value);
  };

  return (
    <form id="form-certification-type-configuration" className="form-fields" onSubmit={handleSubmit}>
      <div className="plan">
        <div className="plan__type">
          <div className="plan-product">
            <CertificationProductCard
              name={'Arduino Certification Program'}
              selectedCertification={values[FIELD_PLAN_TYPE]}
              plan={'cert_fundamentals'}
              description={'Certify your knowledge of Arduino, electronics, programming and physical computing'}
              features={
                'Certification Program is a multiple-choice exam that can be taken from any computer with internet access. There are 36 questions and a 75-minute time limit. It is recommended for students aged 16+ who’ve been using Arduino for a year or more.'
              }
              changeCertification={(value) => handleSelectTypeCertification(value)}
            />
            <CertificationProductCard
              name={'Junior Certification'}
              selectedCertification={values[FIELD_PLAN_TYPE]}
              plan={'junior_certification'}
              description={'Certify students’ knowledge of Arduino, programming & electronics.'}
              features={
                'Junior Certification is a multiple-choice exam that can be taken from any computer with internet access. There are 25 questions and a 60-minute time limit. It is recommended for students aged 14+ and/or those just starting out with Arduino.'
              }
              changeCertification={(value) => handleSelectTypeCertification(value)}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default class CertificationExamTypeConfiguration extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const initialValues = {};

    initialValues[FIELD_PLAN_TYPE] = this.props.certificationType;

    return (
      <div className="billing-info-form arduino-form">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.props.onSubmit}
          resetFlow={this.props.resetFlow}
          component={(formikProps) => <FormBody {...formikProps} {...this.props} />}
        />
      </div>
    );
  }
}

CertificationExamTypeConfiguration.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  certificationType: PropTypes.string.isRequired,
  resetFlow: PropTypes.func.isRequired,
};
