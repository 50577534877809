import React from 'react';
import PropTypes from 'prop-types';

import { SubscriptionCardContext } from './SubscriptionCardContext';
import Well from '../Well/Well';

function FeaturesSection({ planFeatures }) {
  const features = planFeatures.map((feature) => (
    <li key={feature.description}>
      <b>{feature.amount}</b>
      <span>{` ${feature.description}`}</span>
    </li>
  ));

  return (
    <Well title={'Features recap'}>
      <ul className="features">{features}</ul>
    </Well>
  );
}

FeaturesSection.propTypes = {
  planFeatures: PropTypes.array.isRequired,
};

export default function FeaturesSectionConsumer() {
  return (
    <SubscriptionCardContext.Consumer>
      {({ planFeatures }) => <FeaturesSection planFeatures={planFeatures} />}
    </SubscriptionCardContext.Consumer>
  );
}
