import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import PaymentMethodSection from '../../components/common/SubscriptionCard/PaymentMethodSection';
import UpgradeButton from '../../components/common/SubscriptionCard/UpgradeButton';
import DowngradeButton from '../../components/common/SubscriptionCard/DowngradeButton';
import CancelButton from '../../components/common/SubscriptionCard/CancelButton/CancelButton';
import Features from '../../components/common/SubscriptionCard/Features';
import SubscriptionTitle from '../../components/common/SubscriptionCard/SubscriptionTitle';
import PauseButton from './PauseButton';
import InfoPanel from './InfoPanel';
import { OpenSuccessNotification } from '../../components/common/Toast/Toast.jsx';
import { ReactComponent as PendingPlanIcon } from '../../assets/pendingPlanIconNew.svg';

import { SubscriptionCardContext } from '../../components/common/SubscriptionCard/SubscriptionCardContext';
import StackCard from '../../components/common/StackCard/StackCard';

import './SimSubscriptionCard.scss';

function Subscription({ sim, subscription, readDevice, cancelChangeSubscription }) {
  let content = null;
  let buttons = null;
  let pendingChangeBanner = null;
  const [reactivateButtonClicked, setReactivateButtonClicked] = useState(false);

  useEffect(() => {
    if (reactivateButtonClicked) {
      setReactivateButtonClicked(false);
      OpenSuccessNotification('Plan change cancelled');
    }
  }, [reactivateButtonClicked]);

  const handlCancelChangeSubscription = function handlCancelChangeSubscription() {
    setReactivateButtonClicked(true);
    cancelChangeSubscription(subscription.id);
  };

  if (subscription.cancel_at_period_end) {
    pendingChangeBanner = (
      <div key="pending-change" className="new-pending-change">
        <div className="banner-row">
          <div className="banner-container-title">
            <PendingPlanIcon />
          </div>
          <div className="banner-description">
            On <strong>{moment(subscription.renewal_date).format('MMM Do YYYY')}</strong>, your sim subscription will be
            cancelled.
          </div>
        </div>
        <div className="cancel-button link-button" onClick={handlCancelChangeSubscription}>
          {'Cancel'}
        </div>
      </div>
    );
  }

  if (subscription.status !== 'past_due') {
    if (subscription.cancel_at_period_end) {
      buttons = [
        <PauseButton key="pause" />,
        <UpgradeButton key="upgrade" />,
        <DowngradeButton key="downgrade" />,
        pendingChangeBanner,
      ];
    } else {
      buttons = [
        <PauseButton key="pause" />,
        <UpgradeButton key="upgrade" />,
        <DowngradeButton key="downgrade" />,
        <CancelButton key="cancel" />,
      ];
    }
  } else {
    buttons = (
      <div className="past-due-banner">
        <div>{'SOMETHING WENT WRONG WITH YOUR PAYMENT'}</div>
        <div>{'Please make sure your credit card information is correct.'}</div>
      </div>
    );
  }

  // Fetch SIM status after mount
  useEffect(() => {
    if (!sim.byIccid[subscription.iccid]) {
      readDevice(subscription.iccid);
    }
  }, [sim.byIccid, subscription.iccid, readDevice]);

  if (!sim.read.inProgress && sim.byIccid[subscription.iccid]) {
    content = (
      <>
        <SubscriptionTitle subtitle={`ICCID ${subscription.iccid}`} />
        <Features />
        <PaymentMethodSection />
      </>
    );
  }

  return (
    <StackCard
      className="subscription-card sim-subscription-card"
      side={[<InfoPanel key="info-panel" />, ...buttons]}
      loading={sim.read.inProgress || !sim.byIccid[subscription.iccid]}
    >
      {content}
    </StackCard>
  );
}

Subscription.propTypes = {
  sim: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  readDevice: PropTypes.func.isRequired,
  cancelChangeSubscription: PropTypes.func.isRequired,
};

function SubscriptionConsumer(props) {
  return (
    <SubscriptionCardContext.Consumer>
      {({ subscription }) => <Subscription {...props} subscription={subscription} />}
    </SubscriptionCardContext.Consumer>
  );
}

SubscriptionConsumer.propTypes = {
  subscription: PropTypes.object,
};

export default SubscriptionConsumer;
