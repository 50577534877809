/* eslint-disable */
export function getCookie(name) {
  // eslint-disable-next-line no-useless-escape
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const setCookie = (name, value, maxAge = 31540000) => {
  document.cookie = `${name}=${encodeURIComponent(value)};max-age=${maxAge}`;
};
