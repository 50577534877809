import { applyMiddleware, compose, createStore as createReduxStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import sagas from './sagas';
import rootReducer from './reducers';

import defaultInitialState from './initialState';

import { NODE_ENV_DEV } from '../env';
import { products } from '../products';
import { productActions } from './product';

const sagaMiddleware = createSagaMiddleware();

const createStore = (initialState = defaultInitialState) => {
  // Middlewares list
  const middleware = [sagaMiddleware];

  // Store enhancers
  const enhancers = [];
  let composeEnhancers = compose;

  if (NODE_ENV_DEV) {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
  }

  // Store creation
  const store = createReduxStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );

  // Run the sagas
  sagas.forEach(sagaMiddleware.run);

  // Load the products in redux
  Object.values(products).forEach((product) => {
    store.dispatch(productActions.addProduct(product));
  });

  return store;
};

export default createStore;
