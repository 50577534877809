import { call, put, takeEvery } from 'redux-saga/effects';

import actions from './actions';
import chargeTypes from './types';
import * as paymentsAPI from '../../apis/payments';

const createCharge = function* createCharge({ params }) {
  yield put(actions.createChargeRequest());
  try {
    const result = yield call(paymentsAPI.createCharge, params);
    yield put(actions.createChargeSuccess(result));
  } catch (error) {
    yield put(actions.createChargeFailure(error));
  }
};

/**
 * Listens for a CHARGE_CREATE action to be dispatched. Once it is dispatched,
 * runs the createCharge*() generator to read create a charge.
 */
const watchChargesCreate = function* watchChargesCreate() {
  yield takeEvery(chargeTypes.CHARGE_CREATE, createCharge);
};

export default [watchChargesCreate];
