import React from 'react';
import { History } from 'history';
import classnames from 'classnames';
import { Route } from 'react-router';
import './PurchaseBreadcrumbs.scss';
import {
  CLOUD_PURCHASE_PLAN,
  CLOUD_PURCHASE_BILLING,
  CLOUD_PURCHASE_PAYMENT,
  CLOUD_PURCHASE_PAYMENT_INFO,
} from '../../../utils/utilities';

interface CloudBreadcrumbsProps {
  history: History;
  active: string;
  isCloudUpgrading: boolean;
  selectPlanPageVisited: boolean;
  billingPageVisited: boolean;
  paymentPageVisited: boolean;
  paymentInfoPageVisited: boolean;
}

export default function CloudBreadcrumbs(props: CloudBreadcrumbsProps) {
  const { history, active, isCloudUpgrading, selectPlanPageVisited, billingPageVisited, paymentPageVisited } = props;
  return (
    <div className="breadcrumbs">
      <ul>
        <Route>
          <li
            className={classnames('br-elem', {
              active: `${active}` === CLOUD_PURCHASE_PLAN,
              pointer: selectPlanPageVisited,
            })}
            onClick={() => (selectPlanPageVisited ? history.push(CLOUD_PURCHASE_PLAN) : null)}
          >
            <span>{'Plan details'}</span>
          </li>
        </Route>
        <li className="br-elem">
          <span>{'>'}</span>
        </li>

        {!isCloudUpgrading && (
          <>
            <Route>
              <li
                className={classnames('br-elem', {
                  active: `${active}` === CLOUD_PURCHASE_BILLING,
                  pointer: billingPageVisited,
                })}
                onClick={() => (billingPageVisited ? history.push(CLOUD_PURCHASE_BILLING) : null)}
              >
                <span>{'Billing information'}</span>
              </li>
            </Route>
            <li className="br-elem">
              <span>{'>'}</span>
            </li>
            <Route>
              <li
                className={classnames('br-elem', {
                  active: `${active}` === CLOUD_PURCHASE_PAYMENT,
                  pointer: paymentPageVisited,
                })}
                onClick={() => (paymentPageVisited ? history.push(CLOUD_PURCHASE_PAYMENT) : null)}
              >
                <span>{'Payment'}</span>
              </li>
            </Route>
          </>
        )}

        {isCloudUpgrading && (
          <Route>
            <li
              className={classnames('br-elem', {
                active: `${active}` === CLOUD_PURCHASE_PAYMENT_INFO,
                pointer: isCloudUpgrading && selectPlanPageVisited,
              })}
              onClick={() =>
                isCloudUpgrading && selectPlanPageVisited ? history.push(CLOUD_PURCHASE_PAYMENT_INFO) : null
              }
            >
              <span>{'Payment information'}</span>
            </li>
          </Route>
        )}
      </ul>
    </div>
  );
}
