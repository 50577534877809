import types from '../types';

const initialState = {
  product: null,
  price: null,
  quantity: null,
  plan: null,
  iccid: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CART_SET: {
      sessionStorage.setItem(
        'digital_cart',
        JSON.stringify({
          product: action.productId,
          quantity: action.quantity,
          plan: action.planId,
          iccid: action.iccid,
          price: action.price,
        })
      );
      return {
        ...state,
        product: action.productId,
        quantity: action.quantity,
        price: action.price,
        plan: action.planId,
        iccid: action.iccid,
      };
    }

    case types.CART_UNSET: {
      sessionStorage.removeItem('digital_cart');
      return {
        ...state,
        product: null,
        quantity: null,
        price: null,
        plan: null,
        iccid: null,
      };
    }

    default:
      return state;
  }
}
