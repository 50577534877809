import React, { useMemo, useState } from 'react';

import './Aws.scss';
import { useLocation } from 'react-router';
import { Switch, Redirect, Route } from 'react-router-dom';
import AwsRedeemContainer from './AwsRedeemContainer';
import PrivateRoute from '../../../utils/PrivateRoute';
import { AWS_CREATE_PATH } from '../../../utils/utilities';
import Blob1 from '../../../assets/blob1.svg';
import Blob2 from '../../../assets/blob2.svg';
import ArduinoLogo from '../../../assets/arduino-logo.svg';
import AwsInfo from './AwsInfo';
import classnames from 'classnames';

// TESTS
// /aws?aws_account_id=533267299563&aws_customer_id=b84gp1ckk17&product_code=77i734fkk9m10qrznbibd2jwq&plan_id=cloud-enterprise&quantity=500&duration=12&date=2025-07-02T14:53:44.542Z

const AwsComponent = function AwsComponent() {
  const location = useLocation();
  const [createAws, setCreateAws] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const _isLogged = (window as any).arduinoHeader?.init().then((auth) => {
    setIsAuthenticated(auth.isAuthenticated);
  });

  // Fetch resolved token from query string
  const awsAccountId = useMemo(() => {
    return new URLSearchParams(location.search).get('aws_account_id');
  }, [location.search]);
  const awsCustomerId = useMemo(() => {
    return new URLSearchParams(location.search).get('aws_customer_id');
  }, [location.search]);
  const productCode = useMemo(() => {
    return new URLSearchParams(location.search).get('product_code');
  }, [location.search]);
  const planId = useMemo(() => {
    return new URLSearchParams(location.search).get('plan_id');
  }, [location.search]);
  const quantity = useMemo(() => {
    return new URLSearchParams(location.search).get('quantity');
  }, [location.search]);
  const date = useMemo(() => {
    return new URLSearchParams(location.search).get('date');
  }, [location.search]);
  const duration = useMemo(() => {
    return new URLSearchParams(location.search).get('duration');
  }, [location.search]);

  // Save resolved token in query string
  if (awsAccountId && awsAccountId !== '') {
    localStorage.setItem('aws_account_id', awsAccountId);
  }
  if (awsCustomerId && awsCustomerId !== '') {
    localStorage.setItem('aws_customer_id', awsCustomerId);
  }
  if (productCode && productCode !== '') {
    localStorage.setItem('product_code', productCode);
  }
  if (planId && planId !== '') {
    localStorage.setItem('plan_id', planId);
  }
  if (quantity && quantity !== '') {
    localStorage.setItem('quantity', quantity);
  }
  if (date && date !== '') {
    localStorage.setItem('expiration_date', date);
  }
  if (duration && duration !== '') {
    localStorage.setItem('aws_duration', duration);
  }

  return (
    <section className="aws-page">
      {location.pathname !== AWS_CREATE_PATH && (
        <>
          <img src={Blob1} alt="blob1" className="aws-page__blob1" />
          <img src={Blob2} alt="blob2" className="aws-page__blob2" />
        </>
      )}
      <div
        className={classnames('aws-page__content', {
          'aws-page__no-content': !(location.pathname !== AWS_CREATE_PATH && (!isAuthenticated || !createAws)),
        })}
      >
        {location.pathname !== AWS_CREATE_PATH && (!isAuthenticated || !createAws) && (
          <>
            <img src={ArduinoLogo} alt="Arduino Logo" className="aws-page__logo" />
            <div className="aws-page__title">Welcome to Arduino</div>
            <div className="aws-page__description">Follow the instruction below to start using our Services</div>
            <ul>
              <li>Sign-in or Log-in to Arduino Cloud</li>
              <li>Assign the plan to a Shared Space or create a new one</li>
              <li>Confirm your choice and start using Arduino Cloud</li>
            </ul>
            <a className="aws-page__button" href="/aws/awsCreate" onClick={() => setCreateAws(true)}>
              CONTINUE
            </a>
          </>
        )}
      </div>
      <Switch>
        <Route exact path={'/aws'} render={() => <Redirect to={`/aws`} />} />
        <Route exact path="/aws/info" component={AwsInfo} />
        <PrivateRoute path={'/aws/awsCreate'} component={AwsRedeemContainer} />
      </Switch>
    </section>
  );
};

export default AwsComponent;
