import types from './types';

const readSubscriptionList = (params) => ({
  type: types.SUBSCRIPTION_LIST_READ,
  params,
});

const readSubscriptionListRequest = () => ({
  type: types.SUBSCRIPTION_LIST_READ_REQUESTED,
});

const readSubscriptionListSuccess = (data) => ({
  type: types.SUBSCRIPTION_LIST_READ_SUCCEEDED,
  data,
});

const readSubscriptionListFailure = (error) => ({
  type: types.SUBSCRIPTION_LIST_READ_FAILED,
  error,
});

const readUpgradePreview = (subscriptionId, planName, quantity, coupon) => ({
  type: types.SUBSCRIPTION_UPGRADE_PREVIEW,
  subscriptionId,
  planName,
  quantity,
  coupon,
});

const readUpgradePreviewRequest = (subscriptionId, planName, quantity, coupon) => ({
  type: types.SUBSCRIPTION_UPGRADE_PREVIEW_REQUESTED,
  subscriptionId,
  planName,
  quantity,
  coupon,
});

const readUpgradePreviewSuccess = (subscriptionId, planName, quantity, coupon, data) => ({
  type: types.SUBSCRIPTION_UPGRADE_PREVIEW_SUCCEEDED,
  subscriptionId,
  planName,
  quantity,
  coupon,
  data,
});

const readUpgradePreviewFailure = (error) => ({
  type: types.SUBSCRIPTION_UPGRADE_PREVIEW_FAILED,
  error,
});

const readSubscription = (subscriptionId) => ({
  type: types.SUBSCRIPTION_READ,
  subscriptionId,
});

const readSubscriptionRequest = (subscriptionId) => ({
  type: types.SUBSCRIPTION_READ_REQUESTED,
  subscriptionId,
});

const readSubscriptionSuccess = (subscriptionId, data) => ({
  type: types.SUBSCRIPTION_READ_SUCCEEDED,
  subscriptionId,
  data,
});

const readSubscriptionFailure = (error) => ({
  type: types.SUBSCRIPTION_READ_FAILED,
  error,
});

const createSubscription = (params) => ({
  type: types.SUBSCRIPTION_CREATE,
  params,
});

const createSubscriptionRequest = () => ({
  type: types.SUBSCRIPTION_CREATE_REQUESTED,
});

const createSubscriptionSuccess = (data) => ({
  type: types.SUBSCRIPTION_CREATE_SUCCEEDED,
  data,
});

const createSubscriptionFailure = (error) => ({
  type: types.SUBSCRIPTION_CREATE_FAILED,
  error,
});

const createRequest = (params) => ({
  type: types.SUBSCRIPTION_REQUEST_CREATE,
  params,
});

const createRequestRequest = () => ({
  type: types.SUBSCRIPTION_REQUEST_CREATE_REQUESTED,
});

const createRequestSuccess = (data) => ({
  type: types.SUBSCRIPTION_REQUEST_CREATE_SUCCEEDED,
  data,
});

const createRequestFailure = (error) => ({
  type: types.SUBSCRIPTION_REQUEST_CREATE_FAILED,
  error,
});

const cancelSubscription = (id, atPeriodEnd) => ({
  type: types.SUBSCRIPTION_CANCEL,
  id,
  atPeriodEnd,
});

const cancelSubscriptionRequest = () => ({
  type: types.SUBSCRIPTION_CANCEL_REQUESTED,
});

const cancelSubscriptionSuccess = (data) => ({
  type: types.SUBSCRIPTION_CANCEL_SUCCEEDED,
  data,
});

const cancelSubscriptionFailure = (error) => ({
  type: types.SUBSCRIPTION_CANCEL_FAILED,
  error,
});

const updateSubscriptionCard = (subscriptionId, cardId, stripeToken) => ({
  type: types.SUBSCRIPTION_UPDATE_CARD,
  subscriptionId,
  cardId,
  stripeToken,
});

const updateSubscriptionCardRequest = (subscriptionId, cardId, stripeToken) => ({
  type: types.SUBSCRIPTION_UPDATE_CARD_REQUESTED,
  subscriptionId,
  cardId,
  stripeToken,
});

const updateSubscriptionCardSuccess = (subscriptionId, data) => ({
  type: types.SUBSCRIPTION_UPDATE_CARD_SUCCEEDED,
  subscriptionId,
  data,
});

const updateSubscriptionCardFailure = (error) => ({
  type: types.SUBSCRIPTION_UPDATE_CARD_FAILED,
  error,
});

const upgradeSubscription = (id, planId, quantity, prorationdate) => ({
  type: types.SUBSCRIPTION_UPGRADE,
  id,
  planId,
  quantity,
  prorationdate,
});

const upgradeSubscriptionRequest = () => ({
  type: types.SUBSCRIPTION_UPGRADE_REQUESTED,
});

const upgradeSubscriptionSuccess = (data) => ({
  type: types.SUBSCRIPTION_UPGRADE_SUCCEEDED,
  data,
});

const upgradeSubscriptionFailure = (error) => ({
  type: types.SUBSCRIPTION_UPGRADE_FAILED,
  error,
});

const reactivateSubscription = (id) => ({
  type: types.SUBSCRIPTION_REACTIVATE,
  id,
});

const reactivateSubscriptionRequest = () => ({
  type: types.SUBSCRIPTION_REACTIVATE_REQUESTED,
});

const reactivateSubscriptionSuccess = (data) => ({
  type: types.SUBSCRIPTION_REACTIVATE_SUCCEEDED,
  data,
});

const reactivateSubscriptionFailure = (error) => ({
  type: types.SUBSCRIPTION_REACTIVATE_FAILED,
  error,
});

const changeSubscription = (productId, newPlanId, subscriptionParams) => ({
  type: types.SUBSCRIPTION_CHANGE,
  productId,
  newPlanId,
  subscriptionParams,
});

const createSubscriptionClearError = () => ({
  type: types.SUBSCRIPTION_CREATE_CLEAR_FAILED,
});

const upgradeSubscriptionClearError = () => ({
  type: types.SUBSCRIPTION_UPGRADE_CLEAR_FAILED,
});

export default {
  readSubscriptionList,
  readSubscriptionListRequest,
  readSubscriptionListSuccess,
  readSubscriptionListFailure,
  readSubscription,
  readSubscriptionRequest,
  readSubscriptionSuccess,
  readSubscriptionFailure,
  createSubscription,
  createSubscriptionRequest,
  createSubscriptionSuccess,
  createSubscriptionFailure,
  cancelSubscription,
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  changeSubscription,
  updateSubscriptionCard,
  updateSubscriptionCardRequest,
  updateSubscriptionCardSuccess,
  updateSubscriptionCardFailure,
  upgradeSubscription,
  upgradeSubscriptionRequest,
  upgradeSubscriptionSuccess,
  upgradeSubscriptionFailure,
  reactivateSubscription,
  reactivateSubscriptionRequest,
  reactivateSubscriptionSuccess,
  reactivateSubscriptionFailure,
  createSubscriptionClearError,
  upgradeSubscriptionClearError,
  readUpgradePreview,
  readUpgradePreviewRequest,
  readUpgradePreviewSuccess,
  readUpgradePreviewFailure,
  createRequest,
  createRequestRequest,
  createRequestSuccess,
  createRequestFailure,
};
