import types from './types';

const verifyCoupon = (coupon, planId) => ({
  type: types.COUPON_VERIFY,
  coupon,
  planId,
});

const verifyCouponRequest = () => ({
  type: types.COUPON_VERIFY_REQUESTED,
});

const verifyCouponSuccess = (data) => ({
  type: types.COUPON_VERIFY_SUCCEEDED,
  data,
});

const verifyCouponFailure = (error) => ({
  type: types.COUPON_VERIFY_FAILED,
  error,
});

const verifyCouponClear = () => ({
  type: types.COUPON_VERIFY_CLEAR,
});

export default {
  verifyCoupon,
  verifyCouponRequest,
  verifyCouponSuccess,
  verifyCouponFailure,
  verifyCouponClear,
};
