import { call, put, select, takeEvery } from 'redux-saga/effects';

import actions from './actions';
import cardActions from '../card/actions';
import subscriptionTypes from './types';
import * as paymentsAPI from '../../apis/payments';
import * as zapierAPI from '../../apis/zapier';

import { selectCurrentSubscription, selectCurrentPlan, selectPlanById } from './selectors';

const readSubscriptionList = function* readSubscriptionList() {
  yield put(actions.readSubscriptionListRequest());
  try {
    const result = yield call(paymentsAPI.readSubscriptions);
    yield put(actions.readSubscriptionListSuccess(result));
  } catch (error) {
    yield put(actions.readSubscriptionListFailure(error));
  }
};

const readSubscription = function* readSubscription({ subscriptionId }) {
  yield put(actions.readSubscriptionRequest(subscriptionId));
  try {
    const result = yield call(paymentsAPI.readSubscription, subscriptionId);
    yield put(actions.readSubscriptionSuccess(subscriptionId, result));
  } catch (error) {
    yield put(actions.readSubscriptionFailure(error));
  }
};

const readUpgradePreview = function* readUpgradePreview({ subscriptionId, planName, quantity, coupon }) {
  yield put(actions.readUpgradePreviewRequest(subscriptionId, planName, quantity, coupon));
  try {
    const result = yield call(paymentsAPI.readUpgradePreview, subscriptionId, planName, quantity, coupon);
    yield put(actions.readUpgradePreviewSuccess(subscriptionId, planName, quantity, coupon, result));
  } catch (error) {
    yield put(actions.readUpgradePreviewFailure(error));
  }
};

const createSubscription = function* createSubscription({ params }) {
  yield put(actions.createSubscriptionRequest());
  try {
    const result = yield call(paymentsAPI.putSubscription, params);
    yield put(actions.createSubscriptionSuccess(result));
  } catch (error) {
    yield put(actions.createSubscriptionFailure(error));
  }
};

const createRequest = function* createRequest({ params }) {
  yield put(actions.createRequestRequest());
  try {
    const result = yield call(zapierAPI.createRequest, params);
    yield put(actions.createRequestSuccess(result));
  } catch (error) {
    yield put(actions.createRequestFailure(error));
  }
};

const cancelSubscription = function* cancelSubscription({ id, atPeriodEnd }) {
  yield put(actions.cancelSubscriptionRequest());
  try {
    const result = yield call(paymentsAPI.deleteSubscription, id, atPeriodEnd);
    yield put(actions.cancelSubscriptionSuccess(result));
  } catch (error) {
    yield put(actions.cancelSubscriptionFailure(error));
  }
};

const updateSubscriptionCard = function* updateSubscriptionCard({ subscriptionId, cardId, stripeToken }) {
  yield put(actions.updateSubscriptionCardRequest(subscriptionId, cardId, stripeToken));
  let id = cardId;
  try {
    if (stripeToken) {
      yield put(cardActions.createCardRequest());
      try {
        const createCardResult = yield call(paymentsAPI.createCard, stripeToken);
        id = createCardResult.id;
        yield put(cardActions.createCardSuccess(createCardResult));
      } catch (cardError) {
        yield put(cardActions.createCardFailure(cardError));
        throw cardError;
      }
    }
    const result = yield call(paymentsAPI.updateSubscriptionCard, subscriptionId, id);
    yield call(readSubscription, { subscriptionId });
    yield put(actions.updateSubscriptionCardSuccess(subscriptionId, result));
  } catch (error) {
    yield put(actions.updateSubscriptionCardFailure(error));
  }
};

const upgradeSubscription = function* upgradeSubscription({ id, planId, quantity, prorationdate }) {
  yield put(actions.upgradeSubscriptionRequest());
  try {
    const result = yield call(paymentsAPI.upgradeSubscription, id, planId, quantity, prorationdate);
    yield put(actions.upgradeSubscriptionSuccess(result));
  } catch (error) {
    yield put(actions.upgradeSubscriptionFailure(error));
  }
};

const reactivateSubscription = function* reactivateSubscription({ id }) {
  yield put(actions.reactivateSubscriptionRequest());
  try {
    const result = yield call(paymentsAPI.reactivateSubscription, id);
    yield put(actions.reactivateSubscriptionSuccess(result));
  } catch (error) {
    yield put(actions.reactivateSubscriptionFailure(error));
  }
};

/**
 * Replaces the current subscription with another subscription.
 * The subscription 'change' on low-level doesn't really exists: changeSubscription()
 * just calls the DELETE for the old subscription and the PUT for the new one (this
 * APIs aren't called for the free plan).
 */
const changeSubscription = function* changeSubscription({ productId, newPlanId, subscriptionParams }) {
  const currentPlan = yield select(selectCurrentPlan, productId);
  const currentSubscription = yield select(selectCurrentSubscription, productId);
  const newPlan = yield select(selectPlanById, newPlanId);

  if (currentPlan.cancellable || currentPlan.price !== 0) {
    yield put(actions.cancelSubscription(currentSubscription.id));
  }

  if (newPlan.cancellable || newPlan.price !== 0) {
    yield put(actions.createSubscription(subscriptionParams));
  }
};

/**
 * Listens for a SUBSCRIPTIONS_READ action to be dispatched. Once it is dispatched,
 * runs the createSubscription*() generator to refresh the list of subscriptions
 */
const watchUpgradePreviewRead = function* watchUpgradePreviewRead() {
  yield takeEvery(subscriptionTypes.SUBSCRIPTION_UPGRADE_PREVIEW, readUpgradePreview);
};

/**
 * Listens for a SUBSCRIPTIONS_READ action to be dispatched. Once it is dispatched,
 * runs the createSubscription*() generator to refresh the list of subscriptions
 */
const watchSubscriptionListRead = function* watchSubscriptionListRead() {
  yield takeEvery(subscriptionTypes.SUBSCRIPTION_LIST_READ, readSubscriptionList);
};

/**
 * Listens for a SUBSCRIPTION_CREATE action to be dispatched. Once it is dispatched,
 * runs the createSubscription*() generator to read create a subscription.
 */
const watchSubscriptionsCreate = function* watchSubscriptionsCreate() {
  yield takeEvery(subscriptionTypes.SUBSCRIPTION_CREATE, createSubscription);
};

/**
 * Listens for a REQUEST_CREATE action to be dispatched. Once it is dispatched,
 * runs the createSubscription*() generator to read create a subscription.
 */
const watchRequestCreate = function* watchRequestCreate() {
  yield takeEvery(subscriptionTypes.SUBSCRIPTION_REQUEST_CREATE, createRequest);
};

/**
 * Listens for a SUBSCRIPTION_CANCEL action to be dispatched. Once it is dispatched,
 * runs the createSubscription*() generator to cancel a subscription.
 */
const watchSubscriptionsCancel = function* watchSubscriptionsCancel() {
  yield takeEvery(subscriptionTypes.SUBSCRIPTION_CANCEL, cancelSubscription);
};

/**
 * Listens for a SUBSCRIPTION_CREATE_SUCCEEDED action to be dispatched. Once it is dispatched,
 * runs the createSubscription*() generator to refresh the list of subscriptions
 */
const watchSubscriptionsCreateSuccess = function* watchSubscriptionsCreateSuccess() {
  yield takeEvery(subscriptionTypes.SUBSCRIPTION_CREATE_SUCCEEDED, readSubscriptionList);
};

/**
 * Listens for a SUBSCRIPTION_UPGRADE_SUCCEEDED action to be dispatched. Once it is dispatched,
 * runs the createSubscription*() generator to refresh the list of subscriptions
 */
const watchSubscriptionsUpgradeSuccess = function* watchSubscriptionsCreateSuccess() {
  yield takeEvery(subscriptionTypes.SUBSCRIPTION_UPGRADE_SUCCEEDED, readSubscriptionList);
};

/**
 * Listens for a SUBSCRIPTION_CANCEL action to be dispatched. Once it is dispatched,
 * runs the createSubscription*() generator to refresh the list of subscriptions
 */
const watchSubscriptionsCancelSuccess = function* watchSubscriptionsCancelSuccess() {
  yield takeEvery(subscriptionTypes.SUBSCRIPTION_CANCEL_SUCCEEDED, readSubscriptionList);
};

/**
 * Listens for a SUBSCRIPTION_CANCEL action to be dispatched. Once it is dispatched,
 * runs the updateSubscriptionCard*() generator to refresh the list of subscriptions
 */
const watchUpdateSubscriptionCard = function* watchUpdateSubscriptionCard() {
  yield takeEvery(subscriptionTypes.SUBSCRIPTION_UPDATE_CARD, updateSubscriptionCard);
};

/**
 * Listens for a SUBSCRIPTION_CHANGE action to be dispatched. Once it is dispatched,
 * runs the changeSubscription*() generator to change the subscription.
 */
const watchSubscriptionsChange = function* watchSubscriptionsChange() {
  yield takeEvery(subscriptionTypes.SUBSCRIPTION_CHANGE, changeSubscription);
};

/**
 * Listens for a SUBSCRIPTION_UPGRADE action to be dispatched. Once it is dispatched,
 * runs the upgradeSubscription*() generator to change the subscription.
 */
const watchSubscriptionsUpgrade = function* watchSubscriptionsUpgrade() {
  yield takeEvery(subscriptionTypes.SUBSCRIPTION_UPGRADE, upgradeSubscription);
};

/**
 * Listens for a SUBSCRIPTION_REACTIVATE action to be dispatched. Once it is dispatched,
 * runs the upgradeSubscription*() generator to change the subscription.
 */
const watchSubscriptionsReactivate = function* watchSubscriptionsReactivate() {
  yield takeEvery(subscriptionTypes.SUBSCRIPTION_REACTIVATE, reactivateSubscription);
};

/**
 * Listens for a SUBSCRIPTION_UPGRADE_SUCCEEDED action to be dispatched. Once it is dispatched,
 * runs the createSubscription*() generator to refresh the list of subscriptions
 */
const watchSubscriptionsReactivateSuccess = function* watchSubscriptionsReactivateSuccess() {
  yield takeEvery(subscriptionTypes.SUBSCRIPTION_REACTIVATE_SUCCEEDED, readSubscriptionList);
};

export default [
  watchSubscriptionListRead,
  watchSubscriptionsCreate,
  watchSubscriptionsCancel,
  watchUpdateSubscriptionCard,
  watchSubscriptionsCreateSuccess,
  watchSubscriptionsUpgradeSuccess,
  watchSubscriptionsCancelSuccess,
  watchSubscriptionsChange,
  watchSubscriptionsUpgrade,
  watchSubscriptionsReactivate,
  watchSubscriptionsReactivateSuccess,
  watchUpgradePreviewRead,
  watchRequestCreate,
];
