import types from '../types';

const initialState = {
  byIccid: {},
  read: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  pause: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  update: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  unpause: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  iccid: {
    validity: null,
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SIM_DEVICE_READ_REQUESTED: {
      return {
        ...state,
        read: {
          ...state.read,
          inProgress: true,
        },
      };
    }
    case types.SIM_DEVICE_READ_SUCCEEDED: {
      return {
        ...state,
        byIccid: {
          ...state.byIccid,
          [action.device.iccid]: action.device,
        },
        read: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    }
    case types.SIM_DEVICE_READ_FAILED: {
      return {
        ...state,
        read: {
          ...state.read,
          error: action.error,
          inProgress: false,
        },
      };
    }
    case types.SIM_DEVICE_UPDATE: {
      return {
        ...state,
        update: {
          ...state.read,
          inProgress: true,
        },
      };
    }
    case types.SIM_DEVICE_UPDATE_SUCCEEDED: {
      return {
        ...state,
        update: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    }
    case types.SIM_DEVICE_UPDATE_FAILED: {
      return {
        ...state,
        update: {
          ...state.update,
          error: action.error,
          inProgress: false,
        },
      };
    }

    case types.SIM_DEVICE_PAUSE_REQUESTED: {
      return {
        ...state,
        pause: {
          ...state.pause,
          inProgress: true,
        },
      };
    }
    case types.SIM_DEVICE_PAUSE_SUCCEEDED: {
      return {
        ...state,
        pause: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    }

    case types.SIM_DEVICE_PAUSE_FAILED: {
      return {
        ...state,
        pause: {
          ...state.pause,
          error: action.error,
          inProgress: false,
        },
      };
    }
    case types.SIM_DEVICE_UNPAUSE_REQUESTED: {
      return {
        ...state,
        unpause: {
          ...state.unpause,
          inProgress: true,
        },
      };
    }
    case types.SIM_DEVICE_UNPAUSE_SUCCEEDED: {
      return {
        ...state,
        unpause: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    }
    case types.SIM_DEVICE_UNPAUSE_FAILED: {
      return {
        ...state,
        unpause: {
          ...state.unpause,
          error: action.error,
          inProgress: false,
        },
      };
    }
    case types.SIM_ICCID_VERIFY_REQUESTED: {
      return {
        ...state,
        iccid: {
          ...state.iccid,
          validity: null,
          inProgress: true,
        },
      };
    }
    case types.SIM_ICCID_VERIFY_SUCCEEDED: {
      return {
        ...state,
        iccid: {
          validity: action.validity,
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    }

    case types.SIM_ICCID_VERIFY_FAILED: {
      return {
        ...state,
        iccid: {
          ...state.iccid,
          validity: null,
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    }

    default:
      return state;
  }
}
