import readList from './readList';
import create from './create';
import read from './read';

const initialState = {
  activationList: [],
  allActivationList: [],
  availableOrganizationList: [],
  resellerList: [],
  created: {},
  byId: {},
  create: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  readList: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  read: {
    error: null,
    inProgress: false,
    lastUpdate: null,
    id: null,
  },
};

export default function (state = initialState, action) {
  return create(state, action) || readList(state, action) || read(state, action) || state;
}
