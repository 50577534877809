import { createProduct, CreateProductCard, CreateRouter, CreateSubscriptionCard } from './create';
import { simProduct, SimRouter, SimSubscriptionCard } from './sim';

import { DISABLE_FEATURE_CREATE_PLANS, DISABLE_FEATURE_SIM } from '../env';

// Product cards to be shown in the home page
const productCards = {};

// Plan cards to be available for each product
const planCards = {};

// Products React Router routers
const productsRouters = {};

// Custom products subscription cards
const subscriptionCards = {};

// Products configurations
const products = {};

// Disable entirely the Create plans from env
if (DISABLE_FEATURE_CREATE_PLANS !== '1') {
  productCards['create'] = CreateProductCard;
  productsRouters['create'] = CreateRouter;
  products['createProduct'] = createProduct;
  subscriptionCards['create'] = CreateSubscriptionCard;
}

// Disable entirely the SIM from env
if (DISABLE_FEATURE_SIM !== '1') {
  //productCards['sim'] = SimProductCard;
  productsRouters['sim'] = SimRouter;
  products['simProduct'] = simProduct;
  subscriptionCards['sim'] = SimSubscriptionCard;
}

export { productCards, planCards, productsRouters, products, subscriptionCards };
