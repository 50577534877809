import { call, put, takeEvery } from 'redux-saga/effects';

import actions from './actions';
import invoiceTypes from './types';
import * as paymentsAPI from '../../apis/payments';

const readInvoiceList = function* readInvoiceList() {
  yield put(actions.readInvoiceListRequest());
  try {
    const result = yield call(paymentsAPI.readInvoices);
    yield put(actions.readInvoiceListSuccess(result));
  } catch (error) {
    yield put(actions.readInvoiceListFailure(error));
  }
};

const readInvoiceListBySubscription = function* readInvoiceListBySubscription({ subscriptionId }) {
  yield put(actions.readInvoiceListBySubscriptionRequest());
  try {
    const result = yield call(paymentsAPI.readInvoicesBySubscription, subscriptionId);
    yield put(actions.readInvoiceListBySubscriptionSuccess(result));
  } catch (error) {
    yield put(actions.readInvoiceListBySubscriptionFailure(error));
  }
};

/**
 * Listens for a INVOICES_READ action to be dispatched. Once it is dispatched,
 * runs the readInvoiceList*() generator to refresh the list of invoices
 */
const watchInvoiceListRead = function* watchInvoiceListRead() {
  yield takeEvery(invoiceTypes.INVOICE_LIST_READ, readInvoiceList);
};

/**
 * Listens for a INVOICE_LIST_BY_SUBSCRIPTION_READ action to be dispatched. Once it is dispatched,
 * runs the readInvoiceListBySubscription*() generator to refresh the list of invoices
 */
const watchInvoiceListBySubscriptionRead = function* watchInvoiceListBySubscriptionRead() {
  yield takeEvery(invoiceTypes.INVOICE_LIST_BY_SUBSCRIPTION_READ, readInvoiceListBySubscription);
};

export default [watchInvoiceListRead, watchInvoiceListBySubscriptionRead];
