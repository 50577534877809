import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './ProductCard.scss';

function ProductCard(props) {
  return (
    <a className="product-card-wrapper" href={props.path}>
      <div className={classnames('product-card', props.productId)}>
        <div className="card">
          <div className={classnames('product-card-image', props.productId)} />
          <div className="product-card-container">
            <h4 className="product-card-category">{props.category}</h4>
            <h2 className="product-card-title">{props.name}</h2>
            <p className="product-card-description">{props.description}</p>
          </div>
        </div>
      </div>
    </a>
  );
}

ProductCard.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.object,
  category: PropTypes.string.isRequired,
  path: PropTypes.string,
  replace: PropTypes.string,
  externalLink: PropTypes.string,
  productId: PropTypes.string.isRequired,
  history: PropTypes.object,
};

export default ProductCard;
