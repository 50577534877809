import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  CLOUD_CREATE,
  FREQUENCY_YEARLY,
  FREQUENCY_MONTHLY,
  CREATE_MAKER_MONTHLY,
  CREATE_MAKERPLUS_MONTHLY,
  CREATE_ENTRY_YEARLY,
  CREATE_MAKER_YEARLY,
  CREATE_MAKERPLUS_YEARLY,
  ENTERPRISE_PRODUCT,
} from '../../../utils/utilities';

import { SubscriptionCardContext } from './SubscriptionCardContext';

function UpgradeButton({ product, plan, subscription, isEducationPlan, isOwner, isEnterprisePlan }) {
  const orgId = subscription.organization_id;
  let planName = '';
  let planFrequency = '';
  if (product.id === CLOUD_CREATE) {
    planName = '';
    if (plan.id === CREATE_MAKER_MONTHLY) planName = 'maker';
    if (plan.id === CREATE_MAKERPLUS_MONTHLY) planName = 'makerplus';
    if (plan.id === CREATE_ENTRY_YEARLY) planName = 'entry';
    if (plan.id === CREATE_MAKER_YEARLY) planName = 'maker';
    if (plan.id === CREATE_MAKERPLUS_YEARLY) planName = 'makerplus';
    planFrequency = plan.id.substr(-6) === FREQUENCY_YEARLY ? FREQUENCY_YEARLY : FREQUENCY_MONTHLY;
  }
  const upgradeUrl =
    product.id === CLOUD_CREATE
      ? `/purchase/plan?plan=${planName}&frequency=${planFrequency}`
      : isEducationPlan
      ? `/education/purchase/plan?organization_id=${orgId}`
      : `/${product.id}`;
  if ((plan.upgradeable && !isEducationPlan) || (plan.upgradeable && isEducationPlan && isOwner)) {
    return (
      <Link
        to={upgradeUrl}
        className="upgrade-button stack-card-action-button"
        type="button"
        data-testid={`subscription-${subscription.id}`}
      >
        Modify plan
      </Link>
    );
  } else if (product.id === ENTERPRISE_PRODUCT && isOwner && isEnterprisePlan) {
    return (
      <div className="enterprise-banner">
        <div>
          To change the number of Things or configure add-ons, associated with your plan{' '}
          <a href="https://www.arduino.cc/pro/contact-us" target="_blank" rel="noreferrer">
            contact us
          </a>
          .{' '}
        </div>
      </div>
    );
  }
  return null;
}

UpgradeButton.propTypes = {
  plan: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  isEducationPlan: PropTypes.bool,
  isOwner: PropTypes.bool,
  isEnterprisePlan: PropTypes.bool,
  subscription: PropTypes.object,
};

export default function UpgradeButtonConsumer() {
  return (
    <SubscriptionCardContext.Consumer>
      {({ product, plan, isEducationPlan, subscription, isOwner, isEnterprisePlan }) => (
        <UpgradeButton
          subscription={subscription}
          isOwner={isOwner}
          isEducationPlan={isEducationPlan}
          isEnterprisePlan={isEnterprisePlan}
          product={product}
          plan={plan}
        />
      )}
    </SubscriptionCardContext.Consumer>
  );
}
