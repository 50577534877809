/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { differenceInDays, add, subDays, format } from 'date-fns';

import { SubscriptionCardContext } from './SubscriptionCardContext';
import { ReactComponent as PaymentIssue } from '../../../assets/icons/paymentIssue.svg';
import { ReactComponent as WarningIcon } from '../../../assets/warningIcon.svg';

import {
  ENTERPRISE_PRICE_ADDON_X8_MONTHLY,
  ENTERPRISE_PRICE_ADDON_X8_YEARLY,
  ENTERPRISE_PRICE_THING_MONTHLY,
  ENTERPRISE_PRICE_THING_YEARLY,
  ENTERPRISE_ADDON_X8_MONTHLY,
  ENTERPRISE_ADDON_X8_YEARLY,
  ENTERPRISE_PRICE_ADDON_EDGEIMPULSE_MONTHLY,
  ENTERPRISE_PRICE_ADDON_EDGEIMPULSE_YEARLY,
  ENTERPRISE_ADDON_EDGEIMPULSE_MONTHLY,
  ENTERPRISE_ADDON_EDGEIMPULSE_YEARLY,
} from '../../../utils/utilities';

import './SubscriptionCard.scss';

const NameMap = {
  create_free: ['FREE PLAN'],
  create_entry_monthly: ['ENTRY PLAN', 'monthly'],
  create_entry_yearly: ['ENTRY PLAN', 'yearly'],
  create_maker_monthly: ['MAKER PLAN', 'monthly'],
  create_maker_yearly: ['MAKER PLAN', 'yearly'],
  create_makerplus_monthly: ['MAKER PLUS PLAN', 'monthly'],
  create_makerplus_yearly: ['MAKER PLUS PLAN', 'yearly'],
  cloud_classroom: ['CLOUD CLASSROOM', 'yearly'],
  cloud_enterprise_monthly: ['ENTERPRISE PLAN', 'monthly'],
  cloud_enterprise_yearly: ['ENTERPRISE PLAN', 'yearly'],
};

function SubscriptionAmount({ subscription, plan, isEnterprisePlan }) {
  const cloudClassroomPrice =
    subscription.quantity < 51
      ? subscription.quantity * 20
      : subscription.quantity > 50 && subscription.quantity < 101
      ? 50 * 20 + (subscription.quantity - 50) * 18
      : 50 * 20 + 50 * 18 + (subscription.quantity - 100) * 15;

  let price =
    subscription?.quantity !== 1 && subscription.quantity ? cloudClassroomPrice.toFixed(2) : plan.price.toFixed(2);

  if (isEnterprisePlan) {
    let addonsPrice = 0;
    //Calculate addon pricing
    subscription?.addons?.forEach((addon) => {
      if (addon.addon_id === ENTERPRISE_ADDON_X8_MONTHLY)
        addonsPrice = addonsPrice + ENTERPRISE_PRICE_ADDON_X8_MONTHLY * addon.quantity;
      else if (addon.addon_id === ENTERPRISE_ADDON_X8_YEARLY)
        addonsPrice = addonsPrice + ENTERPRISE_PRICE_ADDON_X8_YEARLY * addon.quantity;
    });
    if (plan.billingPeriod === 'month') {
      price = subscription.quantity * ENTERPRISE_PRICE_THING_MONTHLY + addonsPrice;
    } else {
      price = subscription.quantity * ENTERPRISE_PRICE_THING_YEARLY + addonsPrice;
    }
  }

  if (subscription.status === 'trialing') {
    return (
      <h3 className="amount trialing">
        <div className="trial-amount">{'Free trial'}</div>
      </h3>
    );
  }

  if (subscription.status === 'past_due') {
    return (
      <div className="subscription-title">
        {`$ ${price}`}
        {` / ${plan.billingPeriod}`}
        <span className="subscription-icon">
          <PaymentIssue />
        </span>
      </div>
    );
  }

  let amountDescription = null;

  if (plan.subscriptionCardAmountDescription) {
    amountDescription = <h4>{plan.subscriptionCardAmountDescription}</h4>;
  }

  return (
    <>
      <div className="subscription-title">
        {`$ ${price}`}
        {` / ${plan.billingPeriod}`}
        {subscription.coupon && !subscription.payment_source && (
          <span className="subscription-coupon">
            <WarningIcon />
          </span>
        )}
      </div>
      <div className="subscription-email">Plus applicable taxes</div>
      {amountDescription}
    </>
  );
}

SubscriptionAmount.propTypes = {
  subscription: PropTypes.object.isRequired,
  plan: PropTypes.object.isRequired,
  isEnterprisePlan: PropTypes.bool,
};

function SubscriptionTitle({
  product,
  plan,
  subscription,
  planDescription,
  subtitle,
  profile,
  isEducationPlan,
  isEnterprisePlan,
  isAdmin,
  isProAdmin,
  activationId,
}) {
  let trialInfo = null;
  if (subscription.status === 'trialing') {
    trialInfo = <h4 className="trial-info">{`${moment(subscription.trial_end).diff(moment(), 'days')} days left`}</h4>;
  }
  const [planName, planPeriod] = NameMap[plan.id] || [planDescription?.name && `${planDescription.name} Plan`, ''];
  const email = profile?.data?.contact?.email || '';

  let subtitleElement = null;
  if (subtitle) {
    subtitleElement = <h4>{subtitle}</h4>;
  }

  let title = null;
  if (!isEducationPlan && !isEnterprisePlan) {
    title = (
      <div className="subscription-title">
        {planName}
        <div className="subscription-email">{email}</div>
      </div>
    );
  } else {
    title = (
      <div className="subscription-title">
        {product.name}
        <div className="subscription-email">{product.organizationName}</div>
      </div>
    );
  }

  if (activationId) {
    let dayLeft = null;
    title = (
      <span className="subscription-title">
        {product.id === 'create' ? (plan.id.includes('entry') ? 'Entry Plan' : plan.fullName) : product.name}
      </span>
    );
    if (subscription.activation_id === activationId) {
      const dayPassed = differenceInDays(new Date(), new Date(subscription.activation_date));
      dayLeft = differenceInDays(subDays(add(new Date(), { months: 12 }), dayPassed), new Date());
    }
    return (
      <div className="stack-card-title">
        <div>
          {title}
          {dayLeft <= 10 && dayLeft !== 0 && !isNaN(dayLeft) && (
            <span className="subscription-time">{dayLeft} days left</span>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="stack-card-title">
      <div>
        {title}
        {subtitleElement}
      </div>
      {((!isEnterprisePlan && !isEducationPlan && plan.id !== 'create_free') ||
        (isEducationPlan && isAdmin) ||
        (isEnterprisePlan && isProAdmin)) && (
        <div>
          <SubscriptionAmount subscription={subscription} plan={plan} isEnterprisePlan={isEnterprisePlan} />
          {trialInfo}
        </div>
      )}
    </div>
  );
}

SubscriptionTitle.propTypes = {
  product: PropTypes.object.isRequired,
  plan: PropTypes.object.isRequired,
  planDescription: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  subtitle: PropTypes.string,
  profile: PropTypes.object,
  isEducationPlan: PropTypes.bool,
  isEnterprisePlan: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default function SubscriptionTitleConsumer(props) {
  return (
    <SubscriptionCardContext.Consumer>
      {({
        product,
        plan,
        planDescription,
        subscription,
        profile,
        isAdmin,
        isEducationPlan,
        isEnterprisePlan,
        isProAdmin,
        activationId,
      }) => (
        <SubscriptionTitle
          product={product}
          plan={plan}
          planDescription={planDescription}
          subscription={subscription}
          profile={profile}
          isAdmin={isAdmin}
          isProAdmin={isProAdmin}
          isEducationPlan={isEducationPlan}
          isEnterprisePlan={isEnterprisePlan}
          activationId={activationId}
          {...props}
        />
      )}
    </SubscriptionCardContext.Consumer>
  );
}
