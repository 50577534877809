import React, { Component } from 'react';

import { APP_URL } from '../../../env';

/**
 * This uses Webpack's sass-loader to compile the arduino-sass
 * typography file in CSS first, and file-loader to transform the
 * compiled css into a file. The imported value will contain the
 * URL of the compiled CSS file, containing all the type-face rules.
 */
import ArduinoSASSTypography from '!file-loader!sass-loader!arduino-sass/src/_typography.scss'; //eslint-disable-line

/**
 * Inject into a Stripe Elements object all the fonts used in
 * arduino-sass.
 */
export default function withArduinoElementFonts(WrappedComponent) {
  return class WithArduinoStripeStyle extends Component {
    render() {
      const fonts = [
        {
          cssSrc: `${APP_URL}${ArduinoSASSTypography}`,
        },
      ];

      return <WrappedComponent fonts={fonts} locale={'en'} {...this.props} />;
    }
  };
}
