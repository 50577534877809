import React from 'react';
import PropTypes from 'prop-types';

import { SubscriptionCardContext } from './SubscriptionCardContext';

import './SubscriptionCard.scss';

function ShowAdmins({ isEducationPlan, isOwner, subscription, allMembers, isEnterprisePlan }) {
  const isNotOwner = !isOwner;

  let owner = null;
  const totalMembers = allMembers.flat();
  totalMembers.forEach((member) => {
    if (member.id === subscription.user_id) owner = member;
  });

  if (allMembers?.length > 0 && ((isNotOwner && isEducationPlan) || (isNotOwner && isEnterprisePlan))) {
    return (
      <div className="admins">
        <div className="admins--text">To manage this plan contact your subscription admin:</div>
        <div className="admins--list">
          <strong>{owner?.email}</strong>
        </div>
      </div>
    );
  }

  return null;
}

ShowAdmins.propTypes = {
  isEducationPlan: PropTypes.bool,
  isEnterprisePlan: PropTypes.bool,
  isEditor: PropTypes.bool,
  isOwner: PropTypes.bool,
  subscription: PropTypes.object,
  allMembers: PropTypes.array,
};

export default function UpgradeButtonConsumer() {
  return (
    <SubscriptionCardContext.Consumer>
      {({ isEducationPlan, isOwner, subscription, allMembers, isEnterprisePlan }) => (
        <ShowAdmins
          isOwner={isOwner}
          isEducationPlan={isEducationPlan}
          isEnterprisePlan={isEnterprisePlan}
          subscription={subscription}
          allMembers={allMembers}
        />
      )}
    </SubscriptionCardContext.Consumer>
  );
}
