import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import AbsoluteSpinner from '../../../common/AbsoluteSpinner';
import CreditCardAddForm from '../../../common/CreditCardAddForm/CreditCardAddForm';
import CreditCardList from '../../../common/CreditCardList';
import { OpenErrorNotification } from '../../../common/Toast/Toast';
import Checkbox from '../../../common/Checkbox';
import BillingInfoForm from '../../../common/BillingInfoForm';

import { STRIPE_PUBLIC_KEY, STORE_TERMS_URL, CONTACT_US_URL } from '../../../../env';

import './Payment.scss';

function PaymentForm(props) {
  const {
    profile,
    cloudPlanFrequency,
    cloudPlanType,
    cloudPath,
    match,
    handlePaymentInfoDisabled,
    handleBillingDisabled,
  } = props;
  const [useNewCard, setUseNewCard] = useState(true);
  const [terms, setTerms] = useState({ value: false, showError: false });
  const [selectedCreditCard, setSelectedCreditCard] = useState(null);
  const [addCardFormSubmit, setAddCardFormSubmit] = useState(null);
  const [updateBillingInfo, setUpdateBillingInfo] = useState(false);

  useEffect(() => {
    if (props.actionInProgress || props.card?.list?.length === 0 || !terms.value) {
      handlePaymentInfoDisabled(true);
    } else {
      handlePaymentInfoDisabled(false);
    }
  }, [handlePaymentInfoDisabled, props.actionInProgress, props.card?.list?.length, terms.value]);

  //Redirect if refresh
  if (cloudPath && cloudPlanFrequency === '' && cloudPlanType === '') {
    props.history.push(match.path);
  }

  const handleTermsChange = (event) => {
    setTerms({
      value: event.target.checked,
      showError: !event.target.checked,
    });
  };

  const handleCancel = (value) => {
    setUseNewCard(value);
  };

  const handleCreateCard = (token) => {
    props.createCard(token);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!terms.value) {
      setTerms({
        value: false,
        showError: true,
      });
    }

    if (useNewCard) {
      // Just tell the Add Card Form to trigger its own stripe submit
      // once stripe will be done this.handleAddCreditCardSubmit will be called
      if (addCardFormSubmit) {
        addCardFormSubmit();
      }
    } else {
      if (selectedCreditCard) {
        if (!terms.value) {
          return;
        }
        props.onExistingCardSubmit(selectedCreditCard);
      }
    }
  };

  const handleCancelInfoEditing = () => {
    setUpdateBillingInfo(false);
  };

  const handleAddCreditCardSubmit = (token) => {
    if (!terms.value) {
      return;
    }
    props.onNewCardSubmit(token);
  };

  const handleCloseNewCard = () => {
    setUseNewCard(false);
  };

  const handleUseNewCardChange = (useNewCard) => {
    setUseNewCard(useNewCard);
    setSelectedCreditCard(null);
  };

  const handleCreditCardSelectorChange = (selectedCreditCard) => {
    setSelectedCreditCard(selectedCreditCard);
    setUseNewCard(false);
  };

  const handleCreditCardListLoad = (cardId) => {
    setUseNewCard(false);
    setSelectedCreditCard(cardId);
  };

  const handleUpdateEditBillingInfo = (values) => {
    const billingValues = { name: values.firstName.concat(' ').concat(values.lastName), ...values };
    delete billingValues.firstName;
    delete billingValues.lastName;

    if (billingValues.country !== 'US') {
      billingValues.state = billingValues.region;
      delete billingValues.tax_certificate;
    }

    if (billingValues.type === 'P') {
      billingValues.company = '';
      billingValues.vat = '';
      delete billingValues.tax_certificate;
    }

    props.updateBillingInfoFunc(billingValues);
    setUpdateBillingInfo(false);
  };

  useEffect(() => {
    if (props.card.delete.error) {
      OpenErrorNotification(
        'This card could not be deleted because it is attached to an active plan. To delete this card, you need to modify that plan’s payment information.'
      );
    }
  }, [props.card.delete.error]);

  useEffect(() => {
    if (Object.values(props.card.byId).length > 0) {
      setUseNewCard(false);
    } else {
      setUseNewCard(true);
    }
  }, [props.card.byId]);

  useEffect(() => {
    if (props.errorOnBuying !== null) {
      if (props.errorOnBuying.status) {
        switch (props.errorOnBuying.status) {
          case 400:
            OpenErrorNotification(props.errorOnBuying.body?.detail || props.errorOnBuying.message);
            break;
          case 409:
            OpenErrorNotification('This SIM already has an active subscription');
            break;
          case 500:
            OpenErrorNotification(
              <span>
                An error occurred while purchasing, please retry later. If the problem persist{' '}
                <a target="_blank" rel="noreferrer" href={CONTACT_US_URL}>
                  contact the support
                </a>
                .
              </span>
            );
            break;
          default:
            OpenErrorNotification(
              <span>
                An error occurred while purchasing, please retry later. If the problem persist{' '}
                <a target="_blank" rel="noreferrer" href={CONTACT_US_URL}>
                  contact the support
                </a>
                .
              </span>
            );
        }
      }
    }
  }, [props.errorOnBuying]);

  useEffect(() => {
    if (props.errorOnCreateCard !== null) {
      OpenErrorNotification('Your card is incorrect. Please check your Expiration Date and security code.');
    }
  }, [props.errorOnCreateCard]);

  let creditCardSelection = null;

  if (Object.values(props.card.byId).length > 0) {
    creditCardSelection = (
      <CreditCardList
        onChange={handleCreditCardSelectorChange}
        onLoad={handleCreditCardListLoad}
        useNewCard={useNewCard}
      />
    );
  }

  if (props.card.readList.inProgress || props.card.delete.inProgress) {
    return (
      <div className="payment-spinner-wrapper">
        <AbsoluteSpinner height={'100%'} clouds />
      </div>
    );
  }

  let errorTermsMessage = null;

  if (terms.showError) {
    errorTermsMessage = (
      <span className="error-message">{'You need to accept the terms and conditions to proceed'}</span>
    );
  }

  return (
    <>
      <div className="payment-information-billing">
        <div className="main-title">Billing</div>
        {!updateBillingInfo ? (
          <div className="billing-form">
            <div className="billing-form-text">
              <div>
                {profile.data.billing.name}, {profile.data?.contact?.email}
              </div>
              <div>
                {profile.data.billing.street1}, {profile.data.billing.city} {profile.data.billing.postcode},{' '}
                {profile.data.billing.country} - {profile.data.billing.state}
              </div>
            </div>
            <div className="billing-form-button">
              <div className="delete-button" onClick={() => setUpdateBillingInfo(true)}>
                {'Update'}
              </div>
            </div>
          </div>
        ) : (
          <div className="billing-wrapper">
            <BillingInfoForm
              profile={props.profile}
              certificates={props.certificates}
              readStates={props.readStates}
              onSubmit={(values) => handleUpdateEditBillingInfo(values)}
              onSelectedCountry={props.onSelectedCountry}
              onSelectedState={props.onSelectedState}
              edit={updateBillingInfo}
              country={props.country}
              submitButtonText={'Add payment information'}
              viewButtonText={'Add payment information'}
              progress={props.progress}
              availableCountries={props.availableCountries}
              paymentInfoEditing={true}
              handleCancelInfoEditing={handleCancelInfoEditing}
              billingOrganization={{
                name: '',
                country: '',
              }}
              handleBillingDisabled={handleBillingDisabled}
            />
          </div>
        )}
      </div>
      <div className={classnames('arduino-form purchase-payment')}>
        <form id="payment-info-form" className={classnames('form-fields')} onSubmit={handleSubmit}>
          <div className="main-title">Payment Method</div>
          {creditCardSelection}
          <div className={classnames('use-new-card-section', { expanded: useNewCard === true })}>
            <div
              className={classnames('use-new-card-form', {
                visible: useNewCard === true,
                'no-cards': Object.values(props.card.byId).length === 0,
              })}
            >
              <div className="header-panel">
                <label className="ardu-radio">
                  <input
                    type="radio"
                    name="use-new-card"
                    onChange={() => handleUseNewCardChange(true)}
                    checked={useNewCard === true}
                  />
                  <span className="custom-radio"></span>
                  <h4>{'Add new Card'}</h4>
                </label>
                <div className="use-new-card" onClick={() => handleUseNewCardChange(true)}>
                  <h4>{'Add new Card'}</h4>
                </div>
              </div>
              <CreditCardAddForm
                stripePublicKey={STRIPE_PUBLIC_KEY}
                onSubmit={handleAddCreditCardSubmit}
                onCancel={() => handleCancel(false)}
                addSubmitHook={(fn) => setAddCardFormSubmit(() => fn)}
                useNewCard={() => handleCloseNewCard}
                onCreateCard={(stripeToken) => handleCreateCard(stripeToken)}
              />
            </div>
          </div>
          <div className="terms-wrapper">
            <div className="checkbox-wrapper">
              <Checkbox
                name="terms"
                checkboxLabel={
                  <span>
                    {'I have read and accepted the '}
                    <a
                      className="link-button terms-link"
                      href={`${STORE_TERMS_URL}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {'Terms and Conditions'}
                    </a>
                  </span>
                }
                checked={terms.value}
                onChange={handleTermsChange}
                className={`left ${terms.showError ? 'error' : ''}`}
              />
              {errorTermsMessage}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

PaymentForm.propTypes = {
  card: PropTypes.object,
  stripe: PropTypes.object,
  onNewCardSubmit: PropTypes.func.isRequired,
  onExistingCardSubmit: PropTypes.func.isRequired,
  actionInProgress: PropTypes.bool,
  errorOnBuying: PropTypes.object,
  errorOnCreateCard: PropTypes.any,
  profile: PropTypes.object,
  cloudPlanFrequency: PropTypes.string,
  cloudPlanType: PropTypes.string,
  /* Props for billing info form */
  country: PropTypes.shape({
    countriesList: PropTypes.array,
    readStatesList: PropTypes.object,
    statesList: PropTypes.array,
    readList: PropTypes.object.isRequired,
  }),
  edit: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onSelectedCountry: PropTypes.func,
  onSelectedState: PropTypes.func,
  readStates: PropTypes.func,
  certificates: PropTypes.array,
  submitButtonText: PropTypes.string,
  viewButtonText: PropTypes.string,
  progress: PropTypes.bool,
  availableCountries: PropTypes.array,
  createCard: PropTypes.func,
  updateBillingInfoFunc: PropTypes.func,
  cloudPath: PropTypes.bool,
  match: PropTypes.object,
  history: PropTypes.object,
  handlePaymentInfoDisabled: PropTypes.func,
  handleBillingDisabled: PropTypes.func,
};

export default PaymentForm;
