import { defaultJsonResponseHandler } from './utils';

//Contact us request is managed with Zapier
export async function createRequest(body) {
  const res = await fetch('https://hooks.zapier.com/hooks/catch/11899836/3bth2bq/', {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return await defaultJsonResponseHandler(res);
}
