const UPDATE_BILLING = 'app/billing/UPDATE_BILLING';
const UPDATE_BILLING_REQUEST = 'app/billing/UPDATE_BILLING_REQUEST';
const UPDATE_BILLING_SUCCESS = 'app/billing/UPDATE_BILLING_SUCCESS';
const UPDATE_BILLING_FAILURE = 'app/billing/UPDATE_BILLING_FAILURE';
const UPDATE_BILLING_COUNTRY = 'app/billing/UPDATE_BILLING_COUNTRY';
const UPDATE_BILLING_STATE = 'app/billing/UPDATE_BILLING_STATE';
const INITIALIZE_BILLING_INFO = 'app/billing/INITIALIZE_BILLING_INFO';

export default {
  UPDATE_BILLING,
  UPDATE_BILLING_REQUEST,
  UPDATE_BILLING_SUCCESS,
  UPDATE_BILLING_FAILURE,
  UPDATE_BILLING_COUNTRY,
  UPDATE_BILLING_STATE,
  INITIALIZE_BILLING_INFO,
};
