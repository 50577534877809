import React from 'react';
import { Switch, Redirect, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CloudLogo } from '../../../assets/cloud-logo';
import { CREATE_BASE_URL } from '../../../env';
import classnames from 'classnames';
import PlansTabContainer from './plans';
import VouchersTabContainer from './vouchers/VouchersTabContainer';
import './Subscriptions.scss';

const SubscriptionsComponent = function SubscriptionsComponent({ match: { path } }) {
  React.useEffect(() => {
    window.arduinoHeader.changeLogo({
      src: CloudLogo,
      inline: true,
      cssClass: 'digital-store',
      url: CREATE_BASE_URL,
    });
  });

  return (
    <div className="subscriptions-page">
      <div className="tabs">
        <div className="tabs-header">
          <Link
            key="subscriptions"
            className={classnames({ active: location.pathname === `${path}/plans` })}
            to={`${path}/plans`}
          >
            {'My Plans'}
          </Link>
          <Link
            key="redeem"
            className={classnames({ active: location.pathname === `${path}/redeem` })}
            to={`${path}/redeem`}
          >
            {'Redeem'}
          </Link>
        </div>
        <div className="tabs-content">
          <Switch>
            <Route exact path={path} render={() => <Redirect to={`${path}/plans`} />} />
            <Route path={`${path}/plans`} component={PlansTabContainer} />
            <Route path={`${path}/redeem`} component={VouchersTabContainer} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

SubscriptionsComponent.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default SubscriptionsComponent;
