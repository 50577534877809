import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { cartActions } from '../../../store/cart';
import { subscriptionActions } from '../../../store/subscription';
import AbsoluteSpinner from '../../common/AbsoluteSpinner';

import { Redirect } from 'react-router-dom';
import { CLOUD_CLASSROOM } from '../../../utils/utilities';

//We still use it for the School Plan
const PurchaseRedirect = function PurchaseRedirect(props) {
  const { readSubscriptionList } = props;
  // Fetch subscriptions list after mount

  useEffect(() => {
    readSubscriptionList();
  }, [readSubscriptionList]);

  if (!props.subscription.readList.lastUpdate) {
    return <AbsoluteSpinner height={'100%'} clouds />;
  }

  // The code below will check if there is a subscription matching the params in the URL
  // The subscription associated to the product
  const productSubscriptionId = props.subscription.byProduct[props.match.params.productId];

  if (productSubscriptionId) {
    const productSubscription = props.subscription.byId[productSubscriptionId];
    const subscriptionPlanId = productSubscription.plan;
    const subscriptionPlan = props.plan.plans[subscriptionPlanId];
    if (
      subscriptionPlanId !== CLOUD_CLASSROOM &&
      (subscriptionPlanId === props.match.params.planId ||
        (subscriptionPlan?.profile === props.plan.plans[props.match.params.planId]?.profile &&
          subscriptionPlan.billingPeriod === 'year'))
    ) {
      return <Redirect to={`/${props.match.params.productId}`} />;
    }
  }

  props.setCart(props.match.params.productId, props.match.params.planId, props.match.params.billingId);
  if (props.match.params.productId === CLOUD_CLASSROOM) {
    return <Redirect to="/education/purchase" />;
  }
  return <Redirect to="/purchase" />;
};

PurchaseRedirect.propTypes = {
  subscription: PropTypes.object,
  plan: PropTypes.object,
  match: PropTypes.object,
  setCart: PropTypes.func,
  readSubscriptionList: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    subscription: state.subscription,
    plan: state.plan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCart: (productId, planId) => dispatch(cartActions.setCart(productId, planId)),
    readSubscriptionList: () => dispatch(subscriptionActions.readSubscriptionList()),
  };
};

const ConnectedPurchaseRedirect = connect(mapStateToProps, mapDispatchToProps)(PurchaseRedirect);

export default ConnectedPurchaseRedirect;
