import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.CARD_LIST_READ_REQUESTED:
      return {
        ...state,
        list: [],
        readList: {
          error: null,
          inProgress: true,
        },
      };
    case types.CARD_LIST_READ_SUCCEEDED:
      return {
        ...state,
        list: action.data.map((card) => card.id),
        byId: action.data.reduce(
          (byId, card) => ({
            ...byId,
            [card.id]: card,
          }),
          {}
        ),
        readList: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.CARD_LIST_READ_FAILED:
      return {
        ...state,
        readList: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
  }
}
