import { call, put, takeEvery } from 'redux-saga/effects';

import actions from './actions';
import productTypes from '../product/types';
import planTypes from './types';
import * as paymentsAPI from '../../apis/payments';

/**
 * Puts into redux all the plans of the dispatched product
 * @param {Action} action The PRODUCT_ADD action.
 */
const addPlan = function* addPlan({ product }) {
  for (const planId in product.plans) {
    //eslint-disable-line no-unused-vars
    if (Object.prototype.hasOwnProperty.call(product.plans, planId)) {
      const plan = product.plans[planId];
      yield put(
        actions.addPlan({
          ...plan,
          productId: product.id,
        })
      );
    }
  }
  for (const planDescriptionId in product.planDescriptions) {
    //eslint-disable-line no-unused-vars
    if (Object.prototype.hasOwnProperty.call(product.planDescriptions, planDescriptionId)) {
      const planDescription = product.planDescriptions[planDescriptionId];
      yield put(actions.addPlanDescription(planDescription));
    }
  }

  for (const planFeaturesId in product.planFeatures) {
    //eslint-disable-line no-unused-vars
    if (Object.prototype.hasOwnProperty.call(product.planFeatures, planFeaturesId)) {
      const planFeatures = product.planFeatures[planFeaturesId];
      yield put(actions.addPlanFeatures(planFeaturesId, planFeatures));
    }
  }
};

const getPlanTrial = function* getPlanTrial({ planId, planConfig }) {
  yield put(actions.getPlanTrialRequest());
  try {
    const result = yield call(paymentsAPI.getPlanTrial, planId, planConfig);
    yield put(actions.getPlanTrialSuccess(result));
  } catch (error) {
    yield put(actions.getPlanTrialFailure(error));
  }
};

/**
 * Listens for a PRODUCT_ADD action to be dispatched. Once it is dispatched,
 * runs the addPlan*() generator to add all product plans to redux.
 */
const watchProductAdd = function* watchProductAdd() {
  yield takeEvery(productTypes.PRODUCT_ADD, addPlan);
};

const watchGetPlanTrial = function* watchGetPlanTrial() {
  yield takeEvery(planTypes.PLAN_TRIAL_READ, getPlanTrial);
};

export default [watchProductAdd, watchGetPlanTrial];
