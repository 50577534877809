import React from 'react';
import { History } from 'history';
import classnames from 'classnames';
import { Route } from 'react-router';
import './PurchaseBreadcrumbs.scss';
import {
  CERTIFICATION_PURCHASE_EXAM_TYPE,
  CERTIFICATION_PURCHASE_PLAN,
  CERTIFICATION_PURCHASE_BILLING,
  CERTIFICATION_PURCHASE_PAYMENT,
} from '../../../utils/utilities';

interface CertificationBreadcrumbsProps {
  path: string;
  active: string;
  history: History;
  examTypePageVisited: boolean;
  selectPlanPageVisited: boolean;
  billingPageVisited: boolean;
  paymentPageVisited: boolean;
}

export default function CertificationBreadcrumbs(props: CertificationBreadcrumbsProps) {
  const { active, history, examTypePageVisited, selectPlanPageVisited, billingPageVisited, paymentPageVisited } = props;
  return (
    <div className="breadcrumbs">
      <ul>
        <Route>
          <li
            className={classnames('br-elem', {
              active: `${active}` === CERTIFICATION_PURCHASE_EXAM_TYPE,
              pointer: examTypePageVisited,
            })}
            onClick={() => (examTypePageVisited ? history.push(CERTIFICATION_PURCHASE_EXAM_TYPE) : null)}
          >
            <span>{'Exam type'}</span>
          </li>
        </Route>
        <li className="br-elem">
          <span>{'>'}</span>
        </li>
        <Route>
          <li
            className={classnames('br-elem', {
              active: `${active}` === CERTIFICATION_PURCHASE_PLAN,
              pointer: selectPlanPageVisited,
            })}
            onClick={() => (selectPlanPageVisited ? history.push(CERTIFICATION_PURCHASE_PLAN) : null)}
          >
            <span>{'Select exams'}</span>
          </li>
        </Route>
        <li className="br-elem">
          <span>{'>'}</span>
        </li>
        <Route>
          <li
            className={classnames('br-elem', {
              active: `${active}` === CERTIFICATION_PURCHASE_BILLING,
              pointer: billingPageVisited,
            })}
            onClick={() => (billingPageVisited ? history.push(CERTIFICATION_PURCHASE_BILLING) : null)}
          >
            <span>{'Billing information'}</span>
          </li>
        </Route>
        <li className="br-elem">
          <span>{'>'}</span>
        </li>
        <Route>
          <li
            className={classnames('br-elem', {
              active: `${active}` === CERTIFICATION_PURCHASE_PAYMENT,
              pointer: paymentPageVisited,
            })}
            onClick={() => (paymentPageVisited ? history.push(CERTIFICATION_PURCHASE_PAYMENT) : null)}
          >
            <span>{'Payment'}</span>
          </li>
        </Route>
      </ul>
    </div>
  );
}
