/* eslint-disable */
import React from 'react';
import { useGA4 } from './useGA4';

const withGA4 =
  <T extends unknown>(eventGeneratorFunction: (args: T) => any, trackingFunctionName: string) =>
  (Component: typeof React.Component) => {
    const GetGA4 = (props) => {
      const trackWithGA4 = useGA4<T>(eventGeneratorFunction);
      const allProps = { [trackingFunctionName]: trackWithGA4, ...props };
      return <Component {...allProps} />;
    };

    return GetGA4;
  };

export { withGA4 };
