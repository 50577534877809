import types from './types';

const readCountries = () => ({
  type: types.COUNTRIES_READ,
});

const readCountriesRequest = () => ({
  type: types.COUNTRIES_READ_REQUESTED,
});

const readCountriesSuccess = (data) => ({
  type: types.COUNTRIES_READ_SUCCEEDED,
  data,
});

const readCountriesFailure = (error) => ({
  type: types.COUNTRIES_READ_FAILED,
  error,
});

const readStates = (countryCode) => ({
  type: types.COUNTRIES_STATES_READ,
  countryCode,
});

const readStatesRequest = () => ({
  type: types.COUNTRIES_STATES_READ_REQUESTED,
});

const readStatesSuccess = (data) => ({
  type: types.COUNTRIES_STATES_READ_SUCCEEDED,
  data,
});

const readStatesFailure = (error) => ({
  type: types.COUNTRIES_STATES_READ_FAILED,
  error,
});

export default {
  readCountries,
  readCountriesRequest,
  readCountriesSuccess,
  readCountriesFailure,
  readStates,
  readStatesRequest,
  readStatesSuccess,
  readStatesFailure,
};
