/* eslint-disable */
type WithTrial<T> = T | 'create_maker_trial';

export type Config = {
  id: string;
  name: string;
  choosePlanContainerId: string;
  plans: { [key in WithTrial<Config.AvailablePlans>]: Config.Plan };
  planFeatures: { [key in WithTrial<Config.Plans>]?: Config.Plan.Feature[] };
  features: Config.Feature[];
  planDescriptions: {
    [key in WithTrial<Config.Plans>]?: Config.Plan.Description;
  };
  featureList: { [key in WithTrial<Config.Plans>]?: string[] };
  featureListFootnotes?: string[];
  planIds: Config.Plan.ID[];
};

export namespace Config {
  export enum AvailablePlans {
    CREATE_FREE = 'create_free',
    CREATE_ENTRY_MONTHLY = 'create_entry_monthly',
    CREATE_ENTRY_YEARLY = 'create_entry_yearly',
    CREATE_MAKER_MONTHLY = 'create_maker_monthly',
    CREATE_MAKER_YEARLY = 'create_maker_yearly',
    CREATE_MAKER_PLUS_MONTHLY = 'create_makerplus_monthly',
    CREATE_MAKER_PLUS_YEARLY = 'create_makerplus_yearly',
    CLOUD_CLASSROOM_YEARLY = 'cloud_classroom_yearly',
    CLOUD_CLASSROOM_MONTHLY = 'cloud_classroom_monthly',
    CERTIFICATION_STARTERKIT = 'certifications_starterkit_bulk',
    JUNIOR_CERTIFICATION = "junior_certification",
    ENTERPRISE_MONTHLY = 'cloud_enterprise_monthly',
    ENTERPRISE_YEARLY = 'cloud_enterprise_yearly',
  }
  export namespace AvailablePlans {
    export function From(plan: Plans, yearly?: boolean): AvailablePlans {
      if (plan === Plans.CREATE_FREE) return AvailablePlans.CREATE_FREE;

      return `${plan}_${yearly ? 'yearly' : 'monthly'}` as AvailablePlans;
    }

    export function isYearly(plan: AvailablePlans): boolean {
      return plan.indexOf('yearly') > -1;
    }

    export function For(plan: AvailablePlans): Plans {
      return plan.replace(/_yearly|_monthly/g, '') as Plans;
    }
  }

  export enum Plans {
    CREATE_FREE = 'create_free',
    CREATE_ENTRY = 'create_entry',
    CREATE_MAKER = 'create_maker',
    CREATE_MAKER_PLUS = 'create_makerplus',
    CLOUD_CLASSROOM = 'cloud_classroom',
    CERTIFICATION_STARTERKIT = 'certifications_starterkit_bulk',
    JUNIOR_CERTIFICATION = "junior_certification",
    ENTERPRISE_MONTHLY = 'cloud_enterprise_monthly',
    ENTERPRISE_YEARLY = 'cloud_enterprise_yearly',
  }

  export type Plan = {
    id: string;
    billingPeriod: 'month' | 'year';
    cancellable: boolean;
    downgradeable?: boolean;
    fullName: string;
    planDescription: string;
    planFeatures: string;
    description: string;
    price: number;
    priceDescription: string;
    priceTrialDescription: string;
    profile: string;
    type: 'subscription';
    unique: boolean;
    upgradeable: boolean;
    promotion?: string;

    //  `disabled` is true if the current plan cannot be purchased anymore
    disabled?: boolean;
  };

  export namespace Plan {
    export type Feature = {
      amount?: string | number | boolean;
      description: string;
    };

    export type Description = {
      id: string;
      name: string;
    };

    export type ID = {
      id: string;
      title: string;
    };
  }

  export type AmountWithNotes = {
    value: boolean | string;
    notes: string;
  };

  export type Amount = string | boolean | AmountWithNotes;

  export type Feature = {
    title: string;
    description: string;
    amounts: { [key in WithTrial<Plans>]?: Amount };
  };
}
