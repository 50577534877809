import * as React from 'react';
import Slider from '@mui/material/Slider';
import './ArduinoSlider.scss';
import { marksEnterpriseThing } from '../../../utils/utilities';

function valuetext(value: number) {
  return `${value}`;
}

interface ArduinoSliderProps {
  enterprisePlanThings: number;
  handleChangeThings: (e: any) => any;
}

export default function ArduinoSlider(props: ArduinoSliderProps) {
  return (
    <div className="slider-container">
      <Slider
        aria-label="Always visible"
        defaultValue={props.enterprisePlanThings}
        getAriaValueText={valuetext}
        step={50}
        marks={marksEnterpriseThing}
        min={50}
        max={500}
        onChange={props.handleChangeThings}
      />
    </div>
  );
}
