import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as UpGreenArrow } from '../../../assets/icons/upGreenArrow.svg';
import { ReactComponent as DownGreenArrow } from '../../../assets/icons/downGreenArrow.svg';
import { ReactComponent as FeatureIcon } from '../../../assets/icons/featureIcon.svg';

import './CloudPlanProductCard.scss';

function CloudPlanProductCard(props) {
  const [featureOpen, setFeatureOpen] = useState(false);

  return (
    <div className="cloud-plan-product-card-wrapper">
      <div className="product-card">
        <label className="ardu-radio">
          <input
            type="radio"
            name="create-product"
            onChange={() => props.changePlan(props.plan)}
            onClick={() => props.changePlan(props.plan)}
            checked={props.selectedPlan === props.plan}
          />
          <span className="custom-radio"></span>
        </label>
        <div className="product-card__text" onClick={() => props.changePlan(props.plan)}>
          <span className="product-card__title">
            {props.name}{' '}
            {props.currentSubscription.plan === props.plan && (
              <span
                style={{
                  padding: '5px 8px',
                  background: '#ecf1f1',
                  borderRadius: '2px',
                  border: '1px solid #C9D2D2',
                  marginLeft: '12px',
                  color: 'black',
                  fontSize: '12px',
                  textTransform: 'none',
                  fontWeight: 'normal',
                }}
              >
                Your current plan
              </span>
            )}
          </span>
          <span className="product-card__sub-text" onClick={() => props.changePlan(props.plan)}>
            {props.features.map((feature) => {
              return feature + ', ';
            })}
          </span>
        </div>
        <div className="product-card__price" onClick={() => props.changePlan(props.plan)}>
          $ {props.price}
        </div>
        <div className="product-card__more">
          <button className={'more-button'} type="button" onClick={() => setFeatureOpen(!featureOpen)}>
            More {featureOpen ? <UpGreenArrow /> : <DownGreenArrow />}
          </button>
        </div>
      </div>

      {featureOpen && (
        <div className="feature-wrapper">
          <div className="feature__list">
            {props.features.map((feature) => {
              return (
                <div className="feature__item" key={feature}>
                  <div className="feature__item--box">
                    <FeatureIcon /> <span className="feature__item--space">{feature}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

CloudPlanProductCard.propTypes = {
  name: PropTypes.string.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  plan: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  price: PropTypes.number.isRequired,
  changePlan: PropTypes.func,
  currentSubscription: PropTypes.object.isRequired,
};

export default CloudPlanProductCard;
