import React from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import { SubscriptionCardContext } from './SubscriptionCardContext';

import './SubscriptionCard.scss';

function ExpirationDate(props) {
  const { subscription } = props;

  if (subscription.renewal_date && !subscription.cancel_at_period_end) {
    return (
      <div className="expiration-date">
        <div>Plan renewal on:</div>
        <div>{format(parseISO(subscription.renewal_date), 'MMMM dd, yyyy')}</div>
      </div>
    );
  } else if (
    (subscription.renewal_date && subscription.cancel_at_period_end) ||
    (subscription.cancel_at && !subscription.renewal_date)
  ) {
    return (
      <div className="expiration-date">
        <div>Active plan until:</div>
        <div>{format(parseISO(subscription.renewal_date), 'MMMM dd, yyyy')}</div>
      </div>
    );
  } else return null;
}

ExpirationDate.propTypes = {
  subscription: PropTypes.object,
};

export default function UpgradeButtonConsumer() {
  return (
    <SubscriptionCardContext.Consumer>
      {({ subscription }) => <ExpirationDate subscription={subscription} />}
    </SubscriptionCardContext.Consumer>
  );
}
