import types from './types';

const calculateTaxes = (amount) => ({
  type: types.TAXES_CALCULATE,
  amount,
});

const calculateTaxesRequest = () => ({
  type: types.TAXES_CALCULATE_REQUESTED,
});

const calculateTaxesSuccess = (data) => ({
  type: types.TAXES_CALCULATE_SUCCEEDED,
  data,
});

const calculateTaxesFailure = (error) => ({
  type: types.TAXES_CALCULATE_FAILED,
  error,
});

const calculateTaxesClear = () => ({
  type: types.TAXES_CALCULATE_CLEAR,
});

export default {
  calculateTaxes,
  calculateTaxesRequest,
  calculateTaxesSuccess,
  calculateTaxesFailure,
  calculateTaxesClear,
};
