import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AWS_CREATE_PATH } from '../utils/utilities';

import { authenticationActions } from '../store/authentication';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <ConnectedPrivateRouteGuard component={Component} {...props} />} />
);

const PrivateRouteGuard = ({ component: Component, loggedIn, onLogin, ...props }) => {
  if (loggedIn === true) {
    return <Component {...props} />;
  }

  onLogin({
    redirect_uri: window.location.pathname === AWS_CREATE_PATH ? window.location.href : window.location.origin,
  });

  return null;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

PrivateRouteGuard.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  loggedIn: PropTypes.bool,
  onLogin: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.authentication.login.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (redirect) => dispatch(authenticationActions.login(redirect)),
  };
};

const ConnectedPrivateRouteGuard = connect(mapStateToProps, mapDispatchToProps)(PrivateRouteGuard);

export default PrivateRoute;
