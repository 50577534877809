import types from './types';

const initialState = {
  source: null,
  redirect: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.NAVIGATION_ADD_SOURCE:
      return {
        ...state,
        source: action.source,
        redirect: action.redirect,
      };

    default:
      return state;
  }
}
