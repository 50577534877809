import { call, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import AuthenticationTypes from './types';
import BillingTypes from '../billing/types';
import {
  getProfile as getProfileApi,
  getOrganizations as getOrganizationsApi,
  getOrganizationsMembers as getMembersApi,
  createOrganization as createOrganizationApi,
} from '../../apis/profile';
import * as paymentsApi from '../../apis/payments';

/**
 * Checks if the user is authenticated. If not, tries
 * to authenticate via the header-footer Auth0 method.
 */
const login = function* login({ redirect }) {
  yield put(actions.loginRequested());
  try {
    const isAuthenticated = yield call(window.auth.isAuthenticated);
    if (isAuthenticated) {
      const profile = yield call(getProfileApi);
      yield put(actions.loginSucceeded(profile));
    } else {
      window.auth.login({
        redirect_uri: redirect.redirect_uri || window.location.origin,
        state: redirect.state,
      });
    }
  } catch (error) {
    yield put(actions.loginFailed(error));
  }
};

// get Profile
const getProfile = function* getProfile() {
  yield put(actions.getProfileRequested());
  try {
    const profile = yield call(getProfileApi);
    const certificates = yield call(paymentsApi.getUserCertificates);
    yield put(actions.getProfileSucceeded(profile, certificates));
  } catch (error) {
    yield put(actions.getProfileFailed(error));
  }
};

// get Organizations
const readOrganizationList = function* readOrganizationList() {
  yield put(actions.readOrganizationListRequested());
  try {
    const organizations = yield call(getOrganizationsApi);
    yield put(actions.readOrganizationListSucceeded(organizations));
  } catch (error) {
    yield put(actions.readOrganizationListFailed(error));
  }
};

//get organization members
const readOrganizationMembers = function* readOrganizationMembers(organizationId) {
  yield put(actions.readOrganizationMembersRequested());
  try {
    const organizations = yield call(getMembersApi, organizationId);
    yield put(actions.readOrganizationMembersSucceeded(organizations));
  } catch (error) {
    yield put(actions.readOrganizationMembersFailed(error));
  }
};

// create Organization
const createOrganization = function* createOrganization(param) {
  yield put(actions.createOrganizationRequested());
  try {
    const results = yield call(createOrganizationApi, param.organization);
    yield put(actions.createOrganizationSucceeded(results));
  } catch (error) {
    yield put(actions.createOrganizationFailed(error));
  }
};

const watchOrganizationsReadList = function* watchOrganizationsReadList() {
  yield takeEvery(AuthenticationTypes.ORGANIZATION_LIST_READ, readOrganizationList);
};

const watchOrganizationsReadMembers = function* watchOrganizationsReadMembers() {
  yield takeEvery(AuthenticationTypes.ORGANIZATION_MEMBERS_READ, readOrganizationMembers);
};

const watchLogin = function* watchLogin() {
  yield takeEvery(AuthenticationTypes.LOGIN, login);
};

const watchLoginSuccess = function* watchLoginSuccess() {
  yield takeEvery(AuthenticationTypes.LOGIN_SUCCEEDED, loginSuccess);
};

const loginSuccess = function* loginSuccess() {
  yield fork(getProfile);
};

const watchGetProfile = function* watchGetProfile() {
  yield takeEvery(AuthenticationTypes.GET_PROFILE, loginSuccess);
};

const watchUpdateBillingSuccessGetProfile = function* watchUpdateBillingSuccessGetProfile() {
  yield takeEvery(BillingTypes.UPDATE_BILLING_SUCCESS, getProfile);
};

const watchOrganizationCreate = function* watchOrganizationCreate() {
  yield takeEvery(AuthenticationTypes.ORGANIZATION_CREATE, createOrganization);
};

export default [
  watchLogin,
  watchLoginSuccess,
  watchGetProfile,
  watchUpdateBillingSuccessGetProfile,
  watchOrganizationsReadList,
  watchOrganizationCreate,
  watchOrganizationsReadMembers,
];
