import { call, put, takeEvery } from 'redux-saga/effects';

import actions from './actions';
import activationsTypes from './types';
import * as activationAPI from '../../apis/activations';

const readActivationList = function* readActivationList() {
  yield put(actions.readActivationListRequest());
  try {
    const result = yield call(activationAPI.readAvailableActivations);
    yield put(actions.readActivationListSuccess(result));
  } catch (error) {
    yield put(actions.readActivationListFailure(error));
  }
};

const readAllActivationList = function* readAllActivationList() {
  yield put(actions.readAllActivationListRequest());
  try {
    const result = yield call(activationAPI.readAllPlansActivations);
    yield put(actions.readAllActivationListSuccess(result));
  } catch (error) {
    yield put(actions.readAllActivationListFailure(error));
  }
};

const readActivationResellersList = function* readActivationResellersList() {
  yield put(actions.readActivationResellersListRequest());
  try {
    const result = yield call(activationAPI.readResellerList);
    yield put(actions.readActivationResellersListSuccess(result));
  } catch (error) {
    yield put(actions.readActivationResellersListFailure(error));
  }
};

const readActivation = function* readActivation({ activationId }) {
  yield put(actions.readActivationReadRequest(activationId));
  try {
    const result = yield call(activationAPI.readActivationById, activationId);
    yield put(actions.readActivationReadSuccess(activationId, result));
  } catch (error) {
    yield put(actions.readActivationReadFailure(error));
  }
};

const createActivationRedeem = function* createActivationRedeem({ params }) {
  yield put(actions.createActivationRedeemRequest());
  try {
    const result = yield call(activationAPI.redeemPlans, params);
    yield put(actions.createActivationRedeemSuccess(result));
  } catch (error) {
    yield put(actions.createActivationRedeemFailure(error));
  }
};

const createEnterpriseUpgradeRequest = function* createEnterpriseUpgradeRequest({ activationId }) {
  yield put(actions.createEnterpriseUpgradeRequestRequest());
  try {
    const result = yield call(activationAPI.createEnterpriseUpgradeRequest, activationId);
    yield put(actions.createEnterpriseUpgradeRequestSuccess(result));
  } catch (error) {
    yield put(actions.createEnterpriseUpgradeRequestFailure(error));
  }
};

const createEducationUpgradeRequest = function* createEducationUpgradeRequest({ activationId }) {
  yield put(actions.createEducationUpgradeRequest());
  try {
    const result = yield call(activationAPI.createEducationUpgradeRequest, activationId);
    yield put(actions.createEducationUpgradeSuccess(result));
  } catch (error) {
    yield put(actions.createEducationUpgradeFailure(error));
  }
};

const readAvailableOrganizations = function* readAvailableOrganizations({ activationId }) {
  yield put(actions.readAvailableOrganizationsRequest(activationId));
  try {
    const result = yield call(activationAPI.readAvailableOrganizations, activationId);
    yield put(actions.readAvailableOrganizationsSuccess(result));
  } catch (error) {
    yield put(actions.readAvailableOrganizationsFailure(error));
  }
};

/**
 * Listens for a ACTIVATION_READ action to be dispatched.
 */
const watchActivationListRead = function* watchActivationListRead() {
  yield takeEvery(activationsTypes.ACTIVATION_LIST_READ, readActivationList);
};

const watchAllActivationListRead = function* watchAllActivationListRead() {
  yield takeEvery(activationsTypes.ALL_ACTIVATION_LIST_READ, readAllActivationList);
};

const watchActivationResellersListRead = function* watchActivationResellersListRead() {
  yield takeEvery(activationsTypes.ACTIVATION_RESELLERS_LIST_READ, readActivationResellersList);
};

const watchActivationRead = function* watchActivationRead() {
  yield takeEvery(activationsTypes.ACTIVATION_READ, readActivation);
};

const watchActivationRedeemCreate = function* watchActivationRedeemCreate() {
  yield takeEvery(activationsTypes.ACTIVATION_CREATE_REDEEM, createActivationRedeem);
};

const watchActivationEnterpriseUpgradeCreate = function* watchActivationEnterpriseUpgradeCreate() {
  yield takeEvery(activationsTypes.ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST, createEnterpriseUpgradeRequest);
};

const watchActivationEducationUpgradeCreate = function* watchActivationEducationUpgradeCreate() {
  yield takeEvery(activationsTypes.ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST, createEducationUpgradeRequest);
};

const watchReadAvailableOrganizations = function* watchReadAvailableOrganizations() {
  yield takeEvery(activationsTypes.ACTIVATION_READ_AVAILABLE_ORGANIZATION, readAvailableOrganizations);
};

export default [
  watchActivationListRead,
  watchActivationRedeemCreate,
  watchActivationRead,
  watchActivationResellersListRead,
  watchAllActivationListRead,
  watchActivationEnterpriseUpgradeCreate,
  watchActivationEducationUpgradeCreate,
  watchReadAvailableOrganizations,
];
