import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import PaymentMethodSection from '../../components/common/SubscriptionCard/PaymentMethodSection';
import UpgradeButton from '../../components/common/SubscriptionCard/UpgradeButton';
import ExpirationDate from '../../components/common/SubscriptionCard/ExpirationDate';
import ExtendActivationButton from '../../components/common/SubscriptionCard/ExtendActivationButton/ExtendActivationButton';
import ShowAdmins from '../../components/common/SubscriptionCard/ShowAdmins';
import CancelButton from '../../components/common/SubscriptionCard/CancelButton/CancelButton';
import SubscriptionTitle from '../../components/common/SubscriptionCard/SubscriptionTitle';
import { SubscriptionCardContext } from '../../components/common/SubscriptionCard/SubscriptionCardContext';
import StackCard from '../../components/common/StackCard/StackCard';
import { OpenSuccessNotification } from '../../components/common/Toast/Toast.jsx';

import { ReactComponent as PendingPlanIcon } from '../../assets/pendingPlanIconNew.svg';

import AddPaymentInfoButton from '../../components/common/SubscriptionCard/AddPaymentInfoButton';
import {
  CREATE_FREE,
  CREATE_MAKER_MONTHLY,
  CREATE_MAKERPLUS_MONTHLY,
  CREATE_ENTRY_YEARLY,
  CREATE_MAKER_YEARLY,
  CREATE_MAKERPLUS_YEARLY,
  CLOUD_CLASSROOM,
  SPACE_EDUCATION_PLAN,
  SPACE_ENTERPRISE_PLAN,
  ENTERPRISE_PRODUCT,
  CLOUD_CREATE,
} from '../../utils/utilities';

import './CreateSubscriptionCard.scss';

function Subscription(props) {
  const { activationId } = props;
  const type = () => {
    switch (props.isActivation) {
      case CLOUD_CLASSROOM:
        return SPACE_EDUCATION_PLAN;
      case ENTERPRISE_PRODUCT:
        return SPACE_ENTERPRISE_PLAN;
      case CLOUD_CREATE:
        return 'maker';
      default:
        return null;
    }
  };
  const { readOrganizationMembers, isEnterprisePlan, isProAdmin } = props;
  const [reactivateButtonClicked, setReactivateButtonClicked] = useState(false);
  const isOwner = props.isOwner;
  const isEducationPlan = props.isEducationPlan;
  const isCloudPlan = props.isCloudPlan;

  useEffect(() => {
    if (
      !props.subscriptionList.reactivate.inProgress &&
      !props.subscriptionList.reactivate.error &&
      reactivateButtonClicked
    ) {
      setReactivateButtonClicked(false);
      OpenSuccessNotification('Plan change cancelled');
    }
  }, [props.subscriptionList.reactivate.inProgress, props.subscriptionList.reactivate.error, reactivateButtonClicked]);

  useEffect(() => {
    if (props.subscription.organization_id) readOrganizationMembers(props.subscription.organization_id);
  }, [props.subscription.organization_id, readOrganizationMembers]);

  const handlCancelChangeSubscription = function handlCancelChangeSubscription() {
    setReactivateButtonClicked(true);
    props.cancelChangeSubscription(props.subscription.id);
  };

  let pendingChangeBanner = null;
  let planTypeAndFrequency = null;
  if (props.subscription.next_plan && isCloudPlan) {
    switch (props.subscription.next_plan) {
      case CREATE_FREE:
        planTypeAndFrequency = 'Monthly, Free Plan';
        break;
      case CREATE_MAKER_MONTHLY:
        planTypeAndFrequency = 'Monthly, Maker Plan';
        break;
      case CREATE_MAKERPLUS_MONTHLY:
        planTypeAndFrequency = 'Monthly, Maker Plus Plan';
        break;
      case CREATE_ENTRY_YEARLY:
        planTypeAndFrequency = 'Yearly, Entry Plan';
        break;
      case CREATE_MAKER_YEARLY:
        planTypeAndFrequency = 'Yearly, Maker Plan';
        break;
      case CREATE_MAKERPLUS_YEARLY:
        planTypeAndFrequency = 'Yearly, Maker Plus Plan';
        break;
      default:
        planTypeAndFrequency = 'Monthly, Free Plan';
    }
  }

  if (props.subscription.cancel_at_period_end && !isEducationPlan) {
    pendingChangeBanner = (
      <div key="pending-change" className="new-pending-change">
        <div className="banner-row">
          <div className="banner-container-title">
            <PendingPlanIcon />
          </div>
          <div className="banner-description">
            On <strong>{moment(props.subscription.renewal_date).format('MMM Do YYYY')}</strong>, your plan will be
            changed to <strong>Monthly, Free Plan</strong>.
          </div>
        </div>
        <div className="cancel-button link-button" onClick={handlCancelChangeSubscription}>
          {'Cancel'}
        </div>
      </div>
    );
  } else if (props.subscription.next_plan && isCloudPlan) {
    pendingChangeBanner = (
      <div key="pending-change" className="new-pending-change">
        <div className="banner-row">
          <div className="banner-container-title">
            <PendingPlanIcon />
          </div>
          <div className="banner-description">
            On <strong>{moment(props.subscription.renewal_date).format('MMM Do YYYY')}</strong>, your plan will be
            changed to <strong>{planTypeAndFrequency}</strong>.
          </div>
        </div>
        <div className="cancel-button link-button" onClick={handlCancelChangeSubscription}>
          {'Cancel'}
        </div>
      </div>
    );
  } else if (props.subscription.cancel_at_period_end && isEducationPlan && isOwner) {
    pendingChangeBanner = (
      <div key="pending-change" className="new-pending-change" data-testid={`subscription-${props.subscription.id}`}>
        <div className="banner-row">
          <div className="banner-container-title">
            <PendingPlanIcon />
          </div>
          <div className="banner-description">
            On <strong>{moment(props.subscription.renewal_date).format('MMM Do YYYY')}</strong>, your plan will be
            changed to <strong>Yearly, Free Plan</strong>.
          </div>
        </div>
        <div
          className="cancel-button link-button"
          onClick={handlCancelChangeSubscription}
          data-testid={`subscription-${props.subscription.id}`}
        >
          {'Cancel'}
        </div>
      </div>
    );
  }

  let enterprisePurchasedWithCoupon = null;
  if (props.subscription.coupon && !props.subscription.payment_source && isEnterprisePlan && isProAdmin) {
    enterprisePurchasedWithCoupon = (
      <div key="purchased-coupon" className="new-pending-change coupon-banner">
        <div className="banner-row">
          <div className="banner-container-title">
            <PendingPlanIcon />
          </div>
          <div className="banner-description">Update your payment information to allow automatic plan renewal.</div>
        </div>
      </div>
    );
  }

  // detect have user selected country and active card for current plan
  const isNeedAddPaypentInfo =
    (!props?.billingInfo?.country || !props.subscription.payment_source) && props.subscription.status === 'trialing';

  let sideButtons = null;

  const wrongState = (
    <div className="past-due-banner" key="wrongState">
      <div>{'SOMETHING WENT WRONG WITH YOUR PAYMENT'}</div>
      <div>{'Please make sure your credit card information is correct.'}</div>
    </div>
  );

  if (props.subscription.status !== 'past_due') {
    if (!isNeedAddPaypentInfo && pendingChangeBanner === null) {
      sideButtons = [
        <UpgradeButton key="upgrade" />,
        <CancelButton key="cancel" />,
        <ShowAdmins key="admins" />,
        pendingChangeBanner,
        enterprisePurchasedWithCoupon,
      ];
    } else if (!isNeedAddPaypentInfo) {
      sideButtons = [pendingChangeBanner];
    } else {
      sideButtons = [<AddPaymentInfoButton key="new-buttom" subscription={props.subscription} />];
    }
  } else {
    if (!props.subscription.payment_source) {
      sideButtons = [
        wrongState,
        <AddPaymentInfoButton key="new-buttom" subscription={props.subscription} hideDescription={true} />,
      ];
    } else {
      sideButtons = [wrongState];
    }
  }

  if (activationId) {
    sideButtons = [<ExtendActivationButton key="extend" />];
  }

  sideButtons.unshift(<ExpirationDate subscription={props.subscription} />);
  return (
    <StackCard
      className={`subscription-card subscription-create-card ${type()}`}
      side={sideButtons}
      loading={props.subscriptionList.reactivate.inProgress}
    >
      <SubscriptionTitle />
      <PaymentMethodSection />
    </StackCard>
  );
}

Subscription.propTypes = {
  subscription: PropTypes.object.isRequired,
  billingInfo: PropTypes.object,
  coupon: PropTypes.object,
  subscriptionList: PropTypes.object.isRequired,
  cancelChangeSubscription: PropTypes.func.isRequired,
  invoice: PropTypes.object,
  isOwner: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isTeacher: PropTypes.bool,
  isEducationPlan: PropTypes.bool,
  isCloudPlan: PropTypes.bool,
  members: PropTypes.array,
  readOrganizationMembers: PropTypes.func,
  isActivation: PropTypes.string,
};

function SubscriptionConsumer(props) {
  return (
    <SubscriptionCardContext.Consumer>
      {({
        coupon,
        subscription,
        billingInfo,
        invoices,
        isAdmin,
        isTeacher,
        isEducationPlan,
        isEnterprisePlan,
        isProAdmin,
        members,
        readOrganizationMembers,
        isOwner,
        isCloudPlan,
        verifyCoupon,
        activationId,
        createEnterpriseUpgradeRequest,
        createEducationUpgradeRequest,
      }) => (
        <Subscription
          {...props}
          billingInfo={billingInfo}
          subscription={subscription}
          invoices={invoices}
          isEducationPlan={isEducationPlan}
          isEnterprisePlan={isEnterprisePlan}
          isOwner={isOwner}
          isAdmin={isAdmin}
          isProAdmin={isProAdmin}
          isTeacher={isTeacher}
          members={members}
          readOrganizationMembers={readOrganizationMembers}
          isCloudPlan={isCloudPlan}
          coupon={coupon}
          verifyCoupon={verifyCoupon}
          activationId={activationId}
          createEnterpriseUpgradeRequest={createEnterpriseUpgradeRequest}
          createEducationUpgradeRequest={createEducationUpgradeRequest}
        />
      )}
    </SubscriptionCardContext.Consumer>
  );
}

SubscriptionConsumer.propTypes = {
  subscription: PropTypes.object,
  billingInfo: PropTypes.object,
  invoice: PropTypes.object,
  isOwner: PropTypes.object,
  isAdmin: PropTypes.bool,
  isProAdmin: PropTypes.bool,
  isTeacher: PropTypes.bool,
  isEducationPlan: PropTypes.bool,
  isEnterprisePlan: PropTypes.bool,
  members: PropTypes.object,
  readOrganizationMembers: PropTypes.func,
  coupon: PropTypes.bool,
  verifyCoupon: PropTypes.func,
  activationId: PropTypes.string,
  createEnterpriseUpgradeRequest: PropTypes.func,
  createEducationUpgradeRequest: PropTypes.func,
};

export default SubscriptionConsumer;
