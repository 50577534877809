import React, { Component, useMemo } from 'react';
import { Input, Select } from '@arduino/arc';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Formik } from 'formik';
import { useGA4 } from '../../../lib/hooks/useGA4';
import { Redirect } from 'react-router-dom';
import './BillingInfoForm.scss';

import { currencyCountries } from '../../../currencyCountries';
import { ReactComponent as UploadOk } from '../../../assets/UploadOk.svg';
import { ReactComponent as UploadFail } from '../../../assets/UploadFail.svg';
import { ReactComponent as DeletePDF } from '../../../assets/DeletePDF.svg';
import {
  SIM_CONNECTIVITY,
  SUBSCRIPTIONS_PLANS,
  FREQUENCY_MONTHLY,
  EDUCATION_PURCHASE_PAYMENT_INFO,
  CLOUD_PURCHASE_PAYMENT_INFO,
  CREATE_MAKER_MONTHLY,
  CREATE_MAKER_YEARLY,
  CREATE_MAKERPLUS_MONTHLY,
  CREATE_MAKERPLUS_YEARLY,
} from '../../../utils/utilities';
import { planBillingInfo } from '../../../lib/tracking/eventGenerator';

const FIELD_BILLING_TYPE = 'type';
const FIELD_COMPANY = 'company';
const FIELD_VAT = 'vat';
const FIELD_TAX_CERTIFICATE = 'tax_certificate';
const FIELD_BILLING_CONTACT = 'email';
const FIELD_FIRST_NAME = 'firstName';
const FIELD_LAST_NAME = 'lastName';
const FIELD_STREET1 = 'street1';
const FIELD_CITY = 'city';
const FIELD_STATE = 'state';
const FIELD_POSTCODE = 'postcode';
const FIELD_COUNTRY = 'country';
const FIELD_REGION = 'region';

const isEuCountry = (countryCode) => {
  return currencyCountries.euCountries.findIndex((code) => code === countryCode) > -1;
};

const validate = (countriesList, educationPath) => (values) => {
  const errors = {};

  if (!values[FIELD_FIRST_NAME].trim()) {
    errors[FIELD_FIRST_NAME] = 'This field is required';
  }

  if (!values[FIELD_LAST_NAME].trim()) {
    errors[FIELD_LAST_NAME] = 'This field is required';
  }

  if (!values[FIELD_STREET1].trim()) {
    errors[FIELD_STREET1] = 'This field is required';
  }

  if (!values[FIELD_BILLING_CONTACT].trim()) {
    errors[FIELD_BILLING_CONTACT] = 'This field is required';
  }

  if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values[FIELD_BILLING_CONTACT])) {
    errors[FIELD_BILLING_CONTACT] = 'Please insert a correct email';
  }

  if (!values[FIELD_CITY].trim()) {
    errors[FIELD_CITY] = 'This field is required';
  }

  if (values[FIELD_COUNTRY] === 'US' && !values[FIELD_STATE].trim()) {
    errors[FIELD_STATE] = 'This field is required';
  }

  if (!values[FIELD_POSTCODE].trim()) {
    errors[FIELD_POSTCODE] = 'This field is required';
  }

  if ((values[FIELD_BILLING_TYPE] === 'C' || values[FIELD_BILLING_TYPE] === 'S') && !values[FIELD_COMPANY].trim()) {
    errors[FIELD_COMPANY] = 'This field is required';
  }

  if (values[FIELD_BILLING_TYPE] === 'P') {
    delete errors[FIELD_COMPANY];
  }

  // if (!values[FIELD_COMPANY].trim() || values[FIELD_COMPANY].length < 3) {
  //   errors[FIELD_COMPANY] = 'This field must be at least 3 characters';
  // }

  if (!values[FIELD_COUNTRY].trim()) {
    errors[FIELD_COUNTRY] = 'This field is required';
  } else if (
    countriesList.length &&
    !countriesList.find((country) => country.iso_code === values[FIELD_COUNTRY]).allowed
  ) {
    errors[FIELD_COUNTRY] = 'This product is not currently available in your country';
  }

  if (values[FIELD_BILLING_TYPE] === 'C' && isEuCountry(values[FIELD_COUNTRY]) && !values[FIELD_VAT].trim()) {
    errors[FIELD_VAT] = 'This field is required';
  }

  if (values[FIELD_TAX_CERTIFICATE]) {
    const fileSize = values[FIELD_TAX_CERTIFICATE].size / 1024 / 1024; // in MB
    if (fileSize > 10) {
      errors[FIELD_TAX_CERTIFICATE] = 'File exceed the maximum size';
    }
  }

  if (educationPath && sessionStorage.getItem('BIerrors') === 'true') {
    sessionStorage.removeItem('BIerrors');
    return errors;
  } else if (educationPath && sessionStorage.getItem('BIerrors') !== 'true') {
    return null;
  }

  return errors;
};

const FormBody = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    handleEdit,
    statesList,
    billingTypeList,
    country,
    submitButtonText,
    progress,
    setFieldValue,
    certificates,
    paymentInfoEditing,
    wellContent,
  } = props;
  const trackBillingInfoEvent = useGA4(planBillingInfo);

  //No tracking if we are on update or subscription
  if (
    sessionStorage.getItem('billingTrackingSended') === null &&
    window.location.pathname !== SUBSCRIPTIONS_PLANS &&
    window.location.pathname !== CLOUD_PURCHASE_PAYMENT_INFO &&
    window.location.pathname !== EDUCATION_PURCHASE_PAYMENT_INFO
  ) {
    sessionStorage.setItem('billingTrackingSended', 'billingTrackingSended');
    const formattedCloudPlanType =
      props.cloudPlanType === '' ||
      props.cloudPlanType === CREATE_MAKER_MONTHLY ||
      props.cloudPlanType === CREATE_MAKER_YEARLY
        ? 'maker'
        : props.cloudPlanType === CREATE_MAKERPLUS_MONTHLY || props.cloudPlanType === CREATE_MAKERPLUS_YEARLY
        ? 'maker plus'
        : 'entry';
    trackBillingInfoEvent({
      plan_type: props.educationPath ? 'school' : 'individuals',
      plan_level: props.educationPath ? '' : formattedCloudPlanType,
      billing_type: props.educationPath ? '' : props.cloudPlanFrequency ? props.cloudPlanFrequency : FREQUENCY_MONTHLY,
    });
  }

  const hideCancelButton = useMemo(() => {
    return (
      props.profile.data.billing.name !== '' &&
      props.profile.data.billing.type !== '' &&
      props.profile.data.billing.street1 !== '' &&
      props.profile.data.billing.postcode !== '' &&
      props.profile.data.billing.country !== ''
    );
  }, [props.profile.data]);

  const stateOptions = statesList.map((e) => {
    return { label: e.name, value: e.code };
  });

  const formattedCountryOption = country.countriesList.map((e) => {
    return { label: e.name, value: e.code };
  });

  let saveButton = null;

  if (submitButtonText) {
    saveButton = (
      <button
        className={classnames('submit-button', {
          busy: progress,
        })}
        type="button"
        onClick={() => {
          handleSubmit(), setTimeout(() => (Object.keys(errors).length === 0 ? handleEdit(false) : null), 2000);
        }}
      >
        <span className="ardu-store-button-text">
          {window.location.pathname === SUBSCRIPTIONS_PLANS ? 'Update' : 'Save'}
        </span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  }

  const cancelButton = (
    <>
      {!props.firstPurchase && (
        <button className={'unedit-button'} type="button" disabled={progress} onClick={() => props.handleEdit(false)}>
          <span className="ardu-store-button-text">Cancel</span>
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        </button>
      )}
    </>
  );

  const handleCountryChange = (value) => {
    if (value) {
      props.readStates(value);

      if (typeof props.onSelectedCountry === 'function') {
        props.onSelectedCountry(value);
      }
      props.readStates(value);
    }

    handleChange(value);
  };

  const handleStateChange = (value) => {
    if (value) {
      if (typeof props.onSelectedState === 'function') {
        props.onSelectedState(value);
      }
    }

    handleChange(event);
  };

  let stateSelect = null;

  if (values[FIELD_COUNTRY] === 'US') {
    stateSelect = (
      <div className="form-item">
        <Select
          label="State"
          placeholder="Select your state"
          name={FIELD_STATE}
          value={values[FIELD_STATE]}
          options={stateOptions}
          onChange={(value) => {
            setFieldValue(FIELD_STATE, value), handleStateChange(value);
          }}
          onBlur={handleBlur}
          error={errors[FIELD_STATE] && touched[FIELD_STATE] && errors[FIELD_STATE]}
        />
      </div>
    );
  } else {
    stateSelect = (
      <div className="form-item">
        <Input
          label="Region/Province"
          value={values[FIELD_REGION]}
          name={FIELD_REGION}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    );
  }

  let companyField = null;
  if (values[FIELD_BILLING_TYPE] === 'C' || values[FIELD_BILLING_TYPE] === 'S') {
    companyField = (
      <div className="form-item">
        <Input
          id={FIELD_COMPANY}
          label={values[FIELD_BILLING_TYPE] === 'C' ? 'Company name' : 'Institution name'}
          value={values[FIELD_COMPANY]}
          name={FIELD_COMPANY}
          error={errors[FIELD_COMPANY] && touched[FIELD_COMPANY] && errors[FIELD_COMPANY]}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    );
  }

  let vatField = null;

  if (
    (values[FIELD_BILLING_TYPE] === 'C' || values[FIELD_BILLING_TYPE] === 'S') &&
    isEuCountry(values[FIELD_COUNTRY])
  ) {
    vatField = (
      <div className="form-item">
        <Input
          label={values[FIELD_BILLING_TYPE] === 'C' ? 'VAT Number*' : 'VAT Number'}
          value={values[FIELD_VAT]}
          name={FIELD_VAT}
          error={errors[FIELD_VAT] && touched[FIELD_VAT] && errors[FIELD_VAT]}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    );
  }

  //India GSTIN
  if ((values[FIELD_BILLING_TYPE] === 'C' || values[FIELD_BILLING_TYPE] === 'S') && values[FIELD_COUNTRY] === 'IN') {
    vatField = (
      <div className="form-item">
        <div className="form-label">
          <label htmlFor={FIELD_VAT}>{'GSTIN'}</label>
        </div>
        <input
          type="text"
          className={classnames({ error: errors[FIELD_VAT] && touched[FIELD_VAT] })}
          name={FIELD_VAT}
          onBlur={handleBlur}
          value={values[FIELD_VAT]}
          onChange={handleChange}
        ></input>
        <div className="error-message">{errors[FIELD_VAT] && touched[FIELD_VAT] && errors[FIELD_VAT]}</div>
      </div>
    );
  }

  let certificateField = null;

  if ((values[FIELD_BILLING_TYPE] === 'C' || values[FIELD_BILLING_TYPE] === 'S') && values[FIELD_COUNTRY] === 'US') {
    certificateField = (
      <div className="form-group form-group-12-12">
        <div className={classnames('form-item certificate-upload', { active: values[FIELD_TAX_CERTIFICATE] })}>
          <div className="tax-desc form-label-custom">
            <label>{values[FIELD_BILLING_TYPE] === 'C' ? 'Resale certificate' : 'Tax Exception Certificate'}</label>
          </div>
          <div className="custom-input">
            <label className="label" htmlFor={FIELD_TAX_CERTIFICATE}>
              <div className="button-upload">
                {(values[FIELD_BILLING_TYPE] === 'C' && certificates['resale']) ||
                (values[FIELD_BILLING_TYPE] === 'S' && certificates['tax_exempt'])
                  ? 'UPDATE'
                  : 'UPLOAD'}

                <input
                  className="upload-input"
                  type="file"
                  accept=".pdf, .PDF"
                  id={FIELD_TAX_CERTIFICATE}
                  name={FIELD_TAX_CERTIFICATE}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  onChange={(event) => {
                    setFieldValue(FIELD_TAX_CERTIFICATE, event.currentTarget.files[0]);
                  }}
                ></input>
              </div>
            </label>
            <div className="update-message">
              {(values[FIELD_BILLING_TYPE] === 'C' && certificates['resale']) ||
              (values[FIELD_BILLING_TYPE] === 'S' && certificates['tax_exempt'])
                ? ''
                : ''}
            </div>
            <span className="file-name">{values[FIELD_TAX_CERTIFICATE] ? values[FIELD_TAX_CERTIFICATE].name : ''}</span>
            <span
              aria-label="Remove file"
              className="remove-button"
              onClick={() => setFieldValue(FIELD_TAX_CERTIFICATE, undefined)}
            >
              {values[FIELD_TAX_CERTIFICATE]?.name ? <DeletePDF /> : ''}
            </span>
          </div>

          <div className="certificate-information">
            {values[FIELD_TAX_CERTIFICATE] ? (
              <div className="certificate-uploaded">
                <UploadOk /> <span className="certificate-text">File uploaded</span>
              </div>
            ) : (
              <div></div>
            )}
            <div className="max-size">{'(Max Size: 10MB, Supported Format: PDF)'}</div>
          </div>

          {errors[FIELD_TAX_CERTIFICATE] && values[FIELD_TAX_CERTIFICATE] && (
            <div className="certificate-error">
              <UploadFail />
              <span className="certificate-text">
                {errors[FIELD_TAX_CERTIFICATE] && values[FIELD_TAX_CERTIFICATE] && errors[FIELD_TAX_CERTIFICATE]}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <form id="billing-form" className={classnames('form-fields')} onSubmit={handleSubmit} onReset={handleReset}>
      <div style={{ width: '100%', padding: '24px' }} className={classnames({ 'billing-wrapper': wellContent })}>
        <div className="billing-container">
          <div className="form-group form-group-6-6">
            <div className="form-item">
              <Select
                label="Billing type"
                placeholder="Select your billing type"
                name={FIELD_BILLING_TYPE}
                value={values[FIELD_BILLING_TYPE]}
                options={billingTypeList}
                onChange={(value) => setFieldValue(FIELD_BILLING_TYPE, value)}
                onBlur={handleBlur}
                error={errors[FIELD_BILLING_TYPE] && touched[FIELD_BILLING_TYPE] && errors[FIELD_BILLING_TYPE]}
              />
            </div>
            {companyField}
          </div>
        </div>

        <div className="billing-container">
          <div className="billing-title">Billing address</div>
          <div className="form-group form-group-6-6">
            <div className="form-item">
              <Select
                label="Country"
                placeholder="Select your country"
                name={FIELD_COUNTRY}
                value={values[FIELD_COUNTRY]}
                options={formattedCountryOption}
                onChange={(value) => {
                  setFieldValue(FIELD_COUNTRY, value), handleCountryChange(value);
                }}
                onBlur={handleBlur}
              />
            </div>
            {stateSelect}
          </div>
          {errors[FIELD_COUNTRY] && touched[FIELD_COUNTRY] && errors[FIELD_COUNTRY] && (
            <div className="form-item-error">
              Sorry, we can&apos;t process payments for this country. Please select another or contact support.
            </div>
          )}
          <div className="form-group form-group-12-12">
            <div className="form-item">
              <Input
                label="Address"
                value={values[FIELD_STREET1]}
                name={FIELD_STREET1}
                error={errors[FIELD_STREET1] && touched[FIELD_STREET1] && errors[FIELD_STREET1]}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="form-group form-group-6-6">
            <div className="form-item">
              <Input
                label="City"
                value={values[FIELD_CITY]}
                name={FIELD_CITY}
                error={errors[FIELD_CITY] && touched[FIELD_CITY] && errors[FIELD_CITY]}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-item">
              <Input
                label="Zip Code"
                value={values[FIELD_POSTCODE]}
                name={FIELD_POSTCODE}
                error={errors[FIELD_POSTCODE] && touched[FIELD_POSTCODE] && errors[FIELD_POSTCODE]}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
        </div>

        <div className="billing-container">
          <div className="billing-title">Billing contact</div>
          <div className="form-group form-group-6-6">
            <div className="form-item">
              <Input
                label="First name"
                value={values[FIELD_FIRST_NAME]}
                name={FIELD_FIRST_NAME}
                error={errors[FIELD_FIRST_NAME] && touched[FIELD_FIRST_NAME] && errors[FIELD_FIRST_NAME]}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-item">
              <Input
                label="Last name"
                value={values[FIELD_LAST_NAME]}
                name={FIELD_LAST_NAME}
                error={errors[FIELD_LAST_NAME] && touched[FIELD_LAST_NAME] && errors[FIELD_LAST_NAME]}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="form-group form-group-12-12">
            <div className="form-item">
              <Input
                label="Billing email"
                value={values[FIELD_BILLING_CONTACT]}
                name={FIELD_BILLING_CONTACT}
                error={errors[FIELD_BILLING_CONTACT] && touched[FIELD_BILLING_CONTACT] && errors[FIELD_BILLING_CONTACT]}
                onChange={handleChange}
                onBlur={handleBlur}
                infoMsg="Invoices will be sent to this email. You can change it anytime."
              />
            </div>
          </div>
        </div>

        <div className="form-group form-group-12-12">{vatField}</div>
        <div className="form-group form-group-12-12">{certificateField}</div>

        {!paymentInfoEditing ? (
          <div className="submit-buttons-container">
            {hideCancelButton && (
              <>
                {cancelButton}
                {saveButton}
              </>
            )}
          </div>
        ) : (
          <div className="submit-edit-button">
            <button
              className={'secondary-button'}
              type="button"
              disabled={progress}
              onClick={props.handleCancelInfoEditing}
            >
              <span className="ardu-store-button-text">Cancel</span>
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            </button>
            <button
              className={classnames('submit-button', { busy: progress })}
              type="submit"
              disabled={progress}
              onClick={handleSubmit}
            >
              <span className="ardu-store-button-text">Update</span>
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

FormBody.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  statesList: PropTypes.array.isRequired,
  billingTypeList: PropTypes.array.isRequired,
  country: PropTypes.object.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  progress: PropTypes.bool,
  onSelectedCountry: PropTypes.func,
  onSelectedState: PropTypes.func,
  readStates: PropTypes.func.isRequired,
  certificates: PropTypes.object,
  paymentInfoEditing: PropTypes.bool,
  handleCancelInfoEditing: PropTypes.func,
  handleEditOnSubmit: PropTypes.func,
  handleEdit: PropTypes.func,
  changeButtonText: PropTypes.string,
  onCancel: PropTypes.func,
  wellContent: PropTypes.bool,
  firstPurchase: PropTypes.bool,
  enterprisePath: PropTypes.bool,
  location: PropTypes.object,
  billingTrackingSended: PropTypes.bool,
};

export default class BillingInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: true,
    };

    this.handleEdit = this.handleEdit.bind(this);
  }

  componentDidMount() {
    this.setState({ edit: this.props.edit });
    this.props?.organizations?.organizations.forEach((organization) => {
      if (this.props.billingOrganization.name === organization.id) {
        this.props.readStates(organization.country);
      }
    });
    if (this.props.billingOrganization.country === 'US') {
      this.props.readStates('US');
    }
    if (!this.props.country.readStatesList.lastUpdate) {
      const billingInfo = this.props.profile.data.billing;
      if (Object.entries(billingInfo).length !== 0) {
        if (typeof this.props.onSelectedCountry === 'function') {
          this.props.onSelectedCountry(billingInfo.country);
        }
        if (billingInfo.country === 'US') {
          this.props.readStates('US');
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.country.readList.inProgress && !this.props.country.readList.inProgress) {
      if (!this.props.country.readStatesList.lastUpdate) {
        const billingInfo = this.props.profile.data.billing;

        if (billingInfo && billingInfo.country === 'US') {
          this.props.readStates('US');
        }
      }
    }

    if (prevProps.edit !== this.props.edit) {
      this.setState({ edit: this.props.edit });
    }
  }

  handleEdit(flag) {
    this.setState({ edit: flag });
    if (this.props.onSelectedCountry && this.props.onSelectedState) {
      const billingInfo = this.props.profile.data.billing;
      this.props.onSelectedCountry(billingInfo.country);
      this.props.onSelectedState(billingInfo.state);
    }
  }

  render() {
    //Redirect to begin of checkout if refresh (only if the user is in broken state)
    if (
      (this.props?.cart?.plan !== SIM_CONNECTIVITY &&
        this.props.cloudPath &&
        this.props.cloudPlanFrequency === '' &&
        this.props.cloudPlanType === '') ||
      (this.props.enterprisePath && !this.props.enterprisePlanSelected)
    ) {
      return <Redirect push to={this.props.match.path} />;
    }

    const statesList = [
      {
        name: '',
        code: null,
      },
      ...this.props.country.statesList,
    ];

    const billingTypeList = [
      {
        label: 'Personal',
        value: 'P',
      },
      {
        label: 'Business',
        value: 'C',
      },
      {
        label: 'Educational institution',
        value: 'S',
      },
    ];

    const initialValues = {};
    const billingInfo = this.props.profile.data.billing;
    const billingInfoContact = this.props.profile?.data?.contact?.email || '';

    let firstPurchase = true;

    const certificates = this.props.certificates.reduce((acc, curr) => {
      return { ...acc, [curr.certificate_type]: curr.updated_at };
    }, {});

    let institutionName = this.props.billingOrganization.name;
    let institutionCountry = this.props.billingOrganization.country;

    if (institutionCountry === null) {
      this.props.organizations.organizations.forEach((organization) => {
        if (organization.id === institutionName) {
          institutionName = organization.name;
          institutionCountry = organization.country;
        }
      });
    }

    if ((Object.entries(billingInfo).length === 0 || billingInfo.name === '') && billingInfo.constructor === Object) {
      firstPurchase = true;
      initialValues[FIELD_BILLING_TYPE] = this.props.educationPath ? 'S' : this.props.enterprisePath ? 'C' : 'P';
      initialValues[FIELD_COMPANY] = institutionName;
      initialValues[FIELD_VAT] = '';
      initialValues[FIELD_FIRST_NAME] = '';
      initialValues[FIELD_LAST_NAME] = '';
      initialValues[FIELD_LAST_NAME] = '';
      initialValues[FIELD_BILLING_CONTACT] = '';
      initialValues[FIELD_STREET1] = '';
      initialValues[FIELD_CITY] = '';
      initialValues[FIELD_STATE] = '';
      initialValues[FIELD_POSTCODE] = '';
      initialValues[FIELD_COUNTRY] = institutionCountry;
      initialValues[FIELD_REGION] = '';
    } else {
      firstPurchase = false;
      initialValues[FIELD_BILLING_TYPE] = billingInfo.type; //this.props.enterprisePath ? 'C' : billingInfo.type;
      initialValues[FIELD_COMPANY] = billingInfo.company || institutionName;
      initialValues[FIELD_VAT] = billingInfo.vat || '';
      initialValues[FIELD_FIRST_NAME] = billingInfo.name ? billingInfo.name.trim().replace(/ .*/, '') : '';
      initialValues[FIELD_LAST_NAME] = billingInfo.name
        ? billingInfo.name.replace(initialValues[FIELD_FIRST_NAME], '').trim()
        : '';
      initialValues[FIELD_BILLING_CONTACT] = billingInfoContact ? billingInfoContact : '';
      initialValues[FIELD_STREET1] = billingInfo.street1 ? billingInfo.street1 : '';
      initialValues[FIELD_CITY] = billingInfo.city ? billingInfo.city : '';
      initialValues[FIELD_STATE] = billingInfo.state && billingInfo.country === 'US' ? billingInfo.state : '';
      initialValues[FIELD_REGION] = billingInfo.state && billingInfo.country !== 'US' ? billingInfo.state : '';
      initialValues[FIELD_POSTCODE] = billingInfo.postcode ? billingInfo.postcode : '';
      initialValues[FIELD_COUNTRY] = billingInfo.country ? billingInfo.country : institutionCountry;
    }

    if (!this.state.edit && isBillingComplete(billingInfo, this.props.enterprisePath)) {
      return (
        <div className="billing-form">
          <div className="billing-form-text">
            <div>
              {billingInfo.name}, {this.props?.profile?.data?.contact?.email}
            </div>
            <div> </div>
            <div>
              {billingInfo.street1}, {billingInfo.city} {billingInfo.postcode}, {billingInfo.country} -{' '}
              {billingInfo.state}
            </div>
          </div>
          {this.props.changeButtonText ? (
            <div className="billing-form-button">
              <div className="change-button" onClick={() => this.handleEdit(true)}>
                {this.props.changeButtonText}
              </div>
            </div>
          ) : (
            <div className="billing-form-button">
              <button className="delete-button" onClick={() => this.handleEdit(true)}>
                {'Update'}
              </button>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="billing-info-form arduino-form">
        <Formik
          initialValues={initialValues}
          validate={validate(this.props.availableCountries || [], this.props.educationPath)}
          onSubmit={this.props.onSubmit}
          onReset={() => {
            if (this.props.onReset) {
              return this.props.onReset();
            }
            return this.handleEdit(false);
          }}
          component={(formikProps) => (
            <FormBody
              {...formikProps}
              {...this.props}
              statesList={statesList}
              billingTypeList={billingTypeList}
              certificates={certificates}
              handleEditOnSubmit={this.props.onSubmit}
              handleEdit={(value) => this.handleEdit(value)}
              firstPurchase={firstPurchase}
              location={this.props.location}
            />
          )}
        />
      </div>
    );
  }
}

function isBillingComplete(billing, isEnterprisePath) {
  if (
    billing.name &&
    billing.name !== '' &&
    billing.name.split(' ').length > 1 &&
    billing.street1 &&
    billing.street1 !== '' &&
    billing.city &&
    billing.city !== '' &&
    billing.postcode &&
    billing.postcode !== '' &&
    billing.country &&
    billing.country !== '' &&
    billing.type !== ''
  ) {
    if (
      (isEnterprisePath && billing.type !== 'C') ||
      (isEnterprisePath && billing.company && billing.type === 'C') ||
      !isEnterprisePath
    )
      return true;
  }
  return false;
}

BillingInfo.propTypes = {
  country: PropTypes.shape({
    countriesList: PropTypes.array,
    readStatesList: PropTypes.object,
    statesList: PropTypes.array,
    readList: PropTypes.object.isRequired,
  }),
  cart: PropTypes.object,
  edit: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onSelectedCountry: PropTypes.func,
  onSelectedState: PropTypes.func,
  readStates: PropTypes.func,
  profile: PropTypes.object,
  certificates: PropTypes.array,
  submitButtonText: PropTypes.string,
  viewButtonText: PropTypes.string,
  progress: PropTypes.bool,
  availableCountries: PropTypes.array,
  paymentInfoEditing: PropTypes.bool,
  handleCancelInfoEditing: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  educationPath: PropTypes.bool,
  enterprisePath: PropTypes.bool,
  billingOrganization: PropTypes.object,
  organizations: PropTypes.object,
  history: PropTypes.object,
  changeButtonText: PropTypes.string,
  wellContent: PropTypes.bool,
  cloudPlanFrequency: PropTypes.string,
  cloudPlanType: PropTypes.string,
  cloudPath: PropTypes.bool,
  handleBillingDisabled: PropTypes.func,
  billingTrackingSended: PropTypes.bool,
  enterprisePlanSelected: PropTypes.bool,
};
