import { call, put, takeEvery } from 'redux-saga/effects';

import actions from './actions';
import taxesTypes from './types';
import * as paymentsApi from '../../apis/payments';

const calculateTaxes = function* calculateTaxes({ amount }) {
  yield put(actions.calculateTaxesRequest());
  try {
    const data = yield call(paymentsApi.calculateTaxes, amount);
    yield put(actions.calculateTaxesSuccess(data));
  } catch (error) {
    yield put(actions.calculateTaxesFailure(error));
  }
};

const watchCalculateTaxes = function* watchCalculateTaxes() {
  yield takeEvery(taxesTypes.TAXES_CALCULATE, calculateTaxes);
};

export default [watchCalculateTaxes];
