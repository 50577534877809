const simDataAmount = '5MB';
const simTrialDataAmount = '10MB';
const simSuspendendPrice = 0.3;
const simMonthlyPrice = 1.5;

export default {
  id: 'sim',
  name: 'SIM',
  plans: {
    sim_connectivity_plan: {
      id: 'sim_connectivity_plan',
      planDescription: 'sim_connectivity_plan',
      planFeatures: 'sim_connectivity_plan',
      upgradeable: false,
      downgradeable: false,
      cancellable: true,
      pauseable: true,
      unique: false,
      price: 1.5,
      priceDescription: `${simDataAmount} per month`,
      billingPeriod: 'month',
      type: 'subscription',
      fullName: 'Monthly SIM',
    },
    sim_suspended: {
      id: 'sim_suspended',
      planDescription: 'sim_connectivity_plan',
      planFeatures: 'sim_connectivity_plan',
      upgradeable: false,
      downgradeable: false,
      cancellable: true,
      pauseable: true,
      unique: false,
      price: simSuspendendPrice,
      subscriptionCardAmountDescription: 'Sim Paused Fee',
      priceDescription: 'Sim Paused Fee',
      priceTrialDescription: `${simTrialDataAmount} per `,
      billingPeriod: 'month',
      type: 'subscription',
      fullName: 'Monthly SIM (paused)',
    },
  },
  planFeatures: {
    sim_connectivity_plan: [
      {
        amount: simDataAmount,
        description: 'of traffic',
        hint: 'Fusce scelerisque lectus eu nibh eleifend lacinia. Duis rutrum, libero id suscipit consectetur, sem odio interdum sapien, a venenatis magna felis lacinia quam.',
      },
    ],
  },
  planDescriptions: {
    sim_connectivity_plan: {
      id: 'sim_connectivity_plan',
      name: '',
      simDataAmount,
      simTrialDataAmount,
      shortPriceDescription: `${simDataAmount} per month`,
      shortPriceTrialDescription: `${simTrialDataAmount} per `,
      description:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
      cancelSubscriptionMessage:
        'If you cancel your SIM subscription, your data traffic will be interrupted and the SIM will stop working immediately. You will not be able to use your SIM in the future. \n\n Do you still want to proceed?',
      pauseSubscriptionMessage: `If you pause your SIM, it stops working immediately, and from the next billing cycle, until your SIM is paused, you will pay a fee of $${simSuspendendPrice.toFixed(
        2
      )} per month.\n\nYou can pause your SIM for a maximum of 6 months.\n\nAre you sure you want to pause this SIM?`,
      resumeSubscriptionMessage: `Resuming your subscription, your SIM will be reactivated immediately, and from the next billing cycle, your payment plan will be switched from $${simSuspendendPrice.toFixed(
        2
      )} of the pause fee back to $${simMonthlyPrice.toFixed(2)} monthly.`,
    },
  },
  coverageCountries: [
    {
      name: 'Afghanistan',
      covered: false,
    },
    {
      name: 'Åland Islands',
      covered: false,
    },
    {
      name: 'Albania',
      covered: true,
    },
    {
      name: 'Algeria',
      covered: true,
    },
    {
      name: 'American Samoa',
      covered: false,
    },
    {
      name: 'Andorra',
      covered: false,
    },
    {
      name: 'Angola',
      covered: false,
    },
    {
      name: 'Anguilla',
      covered: true,
    },
    {
      name: 'Antarctica',
      covered: false,
    },
    {
      name: 'Antigua and Barbuda',
      covered: false,
    },
    {
      name: 'Argentina',
      covered: false,
    },
    {
      name: 'Armenia',
      covered: true,
    },
    {
      name: 'Aruba',
      covered: true,
    },
    {
      name: 'Australia',
      covered: true,
    },
    {
      name: 'Austria',
      covered: true,
    },
    {
      name: 'Azerbaijan',
      covered: true,
    },
    {
      name: 'Bahamas',
      covered: false,
    },
    {
      name: 'Bahrain',
      covered: true,
    },
    {
      name: 'Bangladesh',
      covered: true,
    },
    {
      name: 'Barbados',
      covered: false,
    },
    {
      name: 'Belarus',
      covered: true,
    },
    {
      name: 'Belgium',
      covered: true,
    },
    {
      name: 'Belize',
      covered: false,
    },
    {
      name: 'Benin',
      covered: false,
    },
    {
      name: 'Bermuda',
      covered: false,
    },
    {
      name: 'Bhutan',
      covered: false,
    },
    {
      name: 'Bolivia, Plurinational State of',
      covered: true,
    },
    {
      name: 'Bonaire, Sint Eustatius and Saba',
      covered: true,
    },
    {
      name: 'Bosnia and Herzegovina',
      covered: false,
    },
    {
      name: 'Botswana',
      covered: false,
    },
    {
      name: 'Bouvet Island',
      covered: false,
    },
    {
      name: 'Brazil',
      covered: false,
    },
    {
      name: 'British Indian Ocean Territory',
      covered: false,
    },
    {
      name: 'Brunei Darussalam',
      covered: true,
    },
    {
      name: 'Bulgaria',
      covered: true,
    },
    {
      name: 'Burkina Faso',
      covered: false,
    },
    {
      name: 'Burundi',
      covered: false,
    },
    {
      name: 'Cambodia',
      covered: true,
    },
    {
      name: 'Cameroon',
      covered: false,
    },
    {
      name: 'Canada',
      covered: true,
    },
    {
      name: 'Cape Verde',
      covered: false,
    },
    {
      name: 'Cayman Islands',
      covered: false,
    },
    {
      name: 'Central African Republic',
      covered: false,
    },
    {
      name: 'Chad',
      covered: false,
    },
    {
      name: 'Chile',
      covered: false,
    },
    {
      name: 'China',
      covered: true,
    },
    {
      name: 'Christmas Island',
      covered: false,
    },
    {
      name: 'Cocos (Keeling) Islands',
      covered: false,
    },
    {
      name: 'Colombia',
      covered: false,
    },
    {
      name: 'Comoros',
      covered: false,
    },
    {
      name: 'Congo',
      covered: false,
    },
    {
      name: 'Congo, the Democratic Republic of the',
      covered: false,
    },
    {
      name: 'Cook Islands',
      covered: false,
    },
    {
      name: 'Costa Rica',
      covered: false,
    },
    {
      name: "Côte d'Ivoire",
      covered: false,
    },
    {
      name: 'Croatia',
      covered: true,
    },
    {
      name: 'Cuba',
      covered: false,
    },
    {
      name: 'Curaçao',
      covered: false,
    },
    {
      name: 'Cyprus',
      covered: true,
    },
    {
      name: 'Czech Republic',
      covered: true,
    },
    {
      name: 'Denmark',
      covered: true,
    },
    {
      name: 'Djibouti',
      covered: false,
    },
    {
      name: 'Dominica',
      covered: false,
    },
    {
      name: 'Dominican Republic',
      covered: false,
    },
    {
      name: 'Ecuador',
      covered: false,
    },
    {
      name: 'Egypt',
      covered: false,
    },
    {
      name: 'El Salvador',
      covered: true,
    },
    {
      name: 'Equatorial Guinea',
      covered: false,
    },
    {
      name: 'Eritrea',
      covered: false,
    },
    {
      name: 'Estonia',
      covered: true,
    },
    {
      name: 'Ethiopia',
      covered: false,
    },
    {
      name: 'Falkland Islands (Malvinas)',
      covered: false,
    },
    {
      name: 'Faroe Islands',
      covered: true,
    },
    {
      name: 'Fiji',
      covered: false,
    },
    {
      name: 'Finland',
      covered: true,
    },
    {
      name: 'France',
      covered: true,
    },
    {
      name: 'French Guiana',
      covered: true,
    },
    {
      name: 'French Polynesia',
      covered: false,
    },
    {
      name: 'French Southern Territories',
      covered: false,
    },
    {
      name: 'Gabon',
      covered: false,
    },
    {
      name: 'Gambia',
      covered: false,
    },
    {
      name: 'Georgia',
      covered: true,
    },
    {
      name: 'Germany',
      covered: true,
    },
    {
      name: 'Ghana',
      covered: true,
    },
    {
      name: 'Gibraltar',
      covered: true,
    },
    {
      name: 'Greece',
      covered: true,
    },
    {
      name: 'Greenland',
      covered: false,
    },
    {
      name: 'Grenada',
      covered: false,
    },
    {
      name: 'Guadeloupe',
      covered: false,
    },
    {
      name: 'Guam',
      covered: false,
    },
    {
      name: 'Guatemala',
      covered: false,
    },
    {
      name: 'Guernsey',
      covered: false,
    },
    {
      name: 'Guinea',
      covered: false,
    },
    {
      name: 'Guinea-Bissau',
      covered: false,
    },
    {
      name: 'Guyana',
      covered: true,
    },
    {
      name: 'Haiti',
      covered: true,
    },
    {
      name: 'Heard Island and McDonald Islands',
      covered: false,
    },
    {
      name: 'Holy See (Vatican City State)',
      covered: false,
    },
    {
      name: 'Honduras',
      covered: true,
    },
    {
      name: 'Hong Kong',
      covered: true,
    },
    {
      name: 'Hungary',
      covered: true,
    },
    {
      name: 'Iceland',
      covered: true,
    },
    {
      name: 'India',
      covered: true,
    },
    {
      name: 'Indonesia',
      covered: true,
    },
    {
      name: 'Iran, Islamic Republic of',
      covered: false,
    },
    {
      name: 'Iraq',
      covered: false,
    },
    {
      name: 'Ireland',
      covered: true,
    },
    {
      name: 'Isle of Man',
      covered: true,
    },
    {
      name: 'Israel',
      covered: true,
    },
    {
      name: 'Italy',
      covered: true,
    },
    {
      name: 'Jamaica',
      covered: false,
    },
    {
      name: 'Japan',
      covered: true,
    },
    {
      name: 'Jersey',
      covered: true,
    },
    {
      name: 'Jordan',
      covered: false,
    },
    {
      name: 'Kazakhstan',
      covered: true,
    },
    {
      name: 'Kenya',
      covered: false,
    },
    {
      name: 'Kiribati',
      covered: false,
    },
    {
      name: "Korea, Democratic People's Republic of",
      covered: false,
    },
    {
      name: 'Korea, Republic of',
      covered: true,
    },
    {
      name: 'Kuwait',
      covered: true,
    },
    {
      name: 'Kyrgyzstan',
      covered: true,
    },
    {
      name: "Lao People's Democratic Republic",
      covered: true,
    },
    {
      name: 'Latvia',
      covered: true,
    },
    {
      name: 'Lebanon',
      covered: false,
    },
    {
      name: 'Lesotho',
      covered: false,
    },
    {
      name: 'Liberia',
      covered: false,
    },
    {
      name: 'Libya',
      covered: false,
    },
    {
      name: 'Liechtenstein',
      covered: true,
    },
    {
      name: 'Lithuania',
      covered: true,
    },
    {
      name: 'Luxembourg',
      covered: true,
    },
    {
      name: 'Macao',
      covered: true,
    },
    {
      name: 'Macedonia, Republic of North',
      covered: true,
    },
    {
      name: 'Madagascar',
      covered: false,
    },
    {
      name: 'Malawi',
      covered: false,
    },
    {
      name: 'Malaysia',
      covered: true,
    },
    {
      name: 'Maldives',
      covered: false,
    },
    {
      name: 'Mali',
      covered: false,
    },
    {
      name: 'Malta',
      covered: true,
    },
    {
      name: 'Marshall Islands',
      covered: false,
    },
    {
      name: 'Martinique',
      covered: false,
    },
    {
      name: 'Mauritania',
      covered: false,
    },
    {
      name: 'Mauritius',
      covered: false,
    },
    {
      name: 'Mayotte',
      covered: false,
    },
    {
      name: 'Mexico',
      covered: false,
    },
    {
      name: 'Micronesia, Federated States of',
      covered: false,
    },
    {
      name: 'Moldova, Republic of',
      covered: true,
    },
    {
      name: 'Monaco',
      covered: false,
    },
    {
      name: 'Mongolia',
      covered: true,
    },
    {
      name: 'Montenegro',
      covered: true,
    },
    {
      name: 'Montserrat',
      covered: false,
    },
    {
      name: 'Morocco',
      covered: false,
    },
    {
      name: 'Mozambique',
      covered: false,
    },
    {
      name: 'Myanmar',
      covered: false,
    },
    {
      name: 'Namibia',
      covered: false,
    },
    {
      name: 'Nauru',
      covered: false,
    },
    {
      name: 'Nepal',
      covered: false,
    },
    {
      name: 'Netherlands',
      covered: true,
    },
    {
      name: 'New Caledonia',
      covered: false,
    },
    {
      name: 'New Zealand',
      covered: true,
    },
    {
      name: 'Nicaragua',
      covered: false,
    },
    {
      name: 'Niger',
      covered: false,
    },
    {
      name: 'Nigeria',
      covered: true,
    },
    {
      name: 'Niue',
      covered: false,
    },
    {
      name: 'Norfolk Island',
      covered: false,
    },
    {
      name: 'Northern Mariana Islands',
      covered: false,
    },
    {
      name: 'Norway',
      covered: true,
    },
    {
      name: 'Oman',
      covered: false,
    },
    {
      name: 'Pakistan',
      covered: true,
    },
    {
      name: 'Palau',
      covered: false,
    },
    {
      name: 'Palestine, State of',
      covered: true,
    },
    {
      name: 'Panama',
      covered: true,
    },
    {
      name: 'Papua New Guinea',
      covered: false,
    },
    {
      name: 'Paraguay',
      covered: true,
    },
    {
      name: 'Peru',
      covered: true,
    },
    {
      name: 'Philippines',
      covered: true,
    },
    {
      name: 'Pitcairn',
      covered: false,
    },
    {
      name: 'Poland',
      covered: true,
    },
    {
      name: 'Portugal',
      covered: true,
    },
    {
      name: 'Puerto Rico',
      covered: false,
    },
    {
      name: 'Qatar',
      covered: false,
    },
    {
      name: 'Réunion',
      covered: false,
    },
    {
      name: 'Romania',
      covered: true,
    },
    {
      name: 'Russian Federation',
      covered: true,
    },
    {
      name: 'Rwanda',
      covered: false,
    },
    {
      name: 'Saint Barthélemy',
      covered: false,
    },
    {
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      covered: false,
    },
    {
      name: 'Saint Kitts and Nevis',
      covered: false,
    },
    {
      name: 'Saint Lucia',
      covered: false,
    },
    {
      name: 'Saint Martin (French part)',
      covered: false,
    },
    {
      name: 'Saint Pierre and Miquelon',
      covered: false,
    },
    {
      name: 'Saint Vincent and the Grenadines',
      covered: false,
    },
    {
      name: 'Samoa',
      covered: false,
    },
    {
      name: 'San Marino',
      covered: false,
    },
    {
      name: 'Sao Tome and Principe',
      covered: false,
    },
    {
      name: 'Saudi Arabia',
      covered: true,
    },
    {
      name: 'Senegal',
      covered: false,
    },
    {
      name: 'Serbia',
      covered: false,
    },
    {
      name: 'Seychelles',
      covered: false,
    },
    {
      name: 'Sierra Leone',
      covered: false,
    },
    {
      name: 'Singapore',
      covered: true,
    },
    {
      name: 'Sint Maarten (Dutch part)',
      covered: false,
    },
    {
      name: 'Slovakia',
      covered: true,
    },
    {
      name: 'Slovenia',
      covered: true,
    },
    {
      name: 'Solomon Islands',
      covered: false,
    },
    {
      name: 'Somalia',
      covered: false,
    },
    {
      name: 'South Africa',
      covered: true,
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      covered: false,
    },
    {
      name: 'South Sudan',
      covered: false,
    },
    {
      name: 'Spain',
      covered: true,
    },
    {
      name: 'Sri Lanka',
      covered: false,
    },
    {
      name: 'Sudan',
      covered: false,
    },
    {
      name: 'Suriname',
      covered: true,
    },
    {
      name: 'Svalbard and Jan Mayen',
      covered: false,
    },
    {
      name: 'Swaziland',
      covered: false,
    },
    {
      name: 'Sweden',
      covered: true,
    },
    {
      name: 'Switzerland',
      covered: true,
    },
    {
      name: 'Syrian Arab Republic',
      covered: false,
    },
    {
      name: 'Taiwan',
      covered: true,
    },
    {
      name: 'Tajikistan',
      covered: true,
    },
    {
      name: 'Tanzania, United Republic of',
      covered: true,
    },
    {
      name: 'Thailand',
      covered: true,
    },
    {
      name: 'Timor-Leste',
      covered: false,
    },
    {
      name: 'Togo',
      covered: false,
    },
    {
      name: 'Tokelau',
      covered: false,
    },
    {
      name: 'Tonga',
      covered: false,
    },
    {
      name: 'Trinidad and Tobago',
      covered: true,
    },
    {
      name: 'Tunisia',
      covered: true,
    },
    {
      name: 'Turkey',
      covered: true,
    },
    {
      name: 'Turkmenistan',
      covered: false,
    },
    {
      name: 'Turks and Caicos Islands',
      covered: false,
    },
    {
      name: 'Tuvalu',
      covered: false,
    },
    {
      name: 'Uganda',
      covered: false,
    },
    {
      name: 'Ukraine',
      covered: true,
    },
    {
      name: 'United Arab Emirates',
      covered: false,
    },
    {
      name: 'United Kingdom',
      covered: true,
    },
    {
      name: 'United States',
      covered: true,
    },
    {
      name: 'United States Minor Outlying Islands',
      covered: false,
    },
    {
      name: 'Uruguay',
      covered: false,
    },
    {
      name: 'Uzbekistan',
      covered: true,
    },
    {
      name: 'Vanuatu',
      covered: false,
    },
    {
      name: 'Venezuela, Bolivarian Republic of',
      covered: false,
    },
    {
      name: 'Viet Nam',
      covered: true,
    },
    {
      name: 'Virgin Islands, British',
      covered: true,
    },
    {
      name: 'Virgin Islands, U.S.',
      covered: false,
    },
    {
      name: 'Wallis and Futuna',
      covered: false,
    },
    {
      name: 'Western Sahara',
      covered: false,
    },
    {
      name: 'Yemen',
      covered: false,
    },
    {
      name: 'Zambia',
      covered: false,
    },
    {
      name: 'Zimbabwe',
      covered: false,
    },
  ],
};
