import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.CARD_CREATE:
      return {
        ...state,
        create: {
          ...state.create,
          inProgress: true,
        },
      };

    case types.CARD_CREATE_REQUESTED:
      return {
        ...state,
        create: {
          ...state.create,
          inProgress: true,
        },
      };

    case types.CARD_CREATE_SUCCEEDED:
      return {
        ...state,
        list: state.list.concat([action.data]),
        byId: {
          ...state.byId,
          [action.data.id]: action.data,
        },
        create: {
          ...state.create,
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.CARD_CREATE_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
  }
}
