import { API_URL } from '../env';

import fetch from '../utils/authenticatedFetch';
import { defaultJsonResponseHandler, awsJsonResponseHandler } from './utils';

const AWS_MARKETPLACE_SUBSCRIPTION_API = `${API_URL}/aws-marketplace/v1/subscription/`;

export async function createSubscriptionsFromToken({
  awsAccountId,
  awsCustomerId,
  organizationId,
  productCode,
  planId,
  quantity,
  expirationDate,
  duration,
}) {
  const res = await fetch(`${AWS_MARKETPLACE_SUBSCRIPTION_API}create`, {
    method: 'POST',
    body: JSON.stringify({
      aws_account_id: awsAccountId,
      aws_customer_id: awsCustomerId,
      organization_id: organizationId,
      product_code: productCode,
      plan_id: planId,
      quantity: quantity,
      expiration_date: expirationDate,
      duration: duration,
    }),
    headers: { 'Content-Type': 'application/json' },
  });
  return await awsJsonResponseHandler(res);
}

export async function readAwsSubscriptions() {
  const res = await fetch(`${AWS_MARKETPLACE_SUBSCRIPTION_API}list`);
  return await defaultJsonResponseHandler(res);
}
