const COUNTRIES_READ = 'app/country/COUNTRIES_READ';
const COUNTRIES_READ_REQUESTED = 'app/country/COUNTRIES_READ_REQUESTED';
const COUNTRIES_READ_SUCCEEDED = 'app/country/COUNTRIES_READ_SUCCEEDED';
const COUNTRIES_READ_FAILED = 'app/country/COUNTRIES_READ_FAILED';

const COUNTRIES_STATES_READ = 'app/country/COUNTRIES_STATES_READ';
const COUNTRIES_STATES_READ_REQUESTED = 'app/country/COUNTRIES_STATES_READ_REQUESTED';
const COUNTRIES_STATES_READ_SUCCEEDED = 'app/country/COUNTRIES_STATES_READ_SUCCEEDED';
const COUNTRIES_STATES_READ_FAILED = 'app/country/COUNTRIES_STATES_READ_FAILED';

export default {
  COUNTRIES_READ,
  COUNTRIES_READ_REQUESTED,
  COUNTRIES_READ_SUCCEEDED,
  COUNTRIES_READ_FAILED,
  COUNTRIES_STATES_READ,
  COUNTRIES_STATES_READ_REQUESTED,
  COUNTRIES_STATES_READ_SUCCEEDED,
  COUNTRIES_STATES_READ_FAILED,
};
