import { connect } from 'react-redux';

import { subscriptionActions } from '../../../store/subscription';
import { awsActions } from '../../../store/aws';
import AwsRedeemComponent from './AwsRedeemComponent';
import { authenticationActions } from '../../../store/authentication';
import { countryActions } from '../../../store/country';

const mapStateToProps = (state) => {
  return {
    subscription: state.subscription,
    organizations: state.authentication.organizations.data,
    country: state.country,
    profile: state.authentication.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    readCountries: () => dispatch(countryActions.readCountries()),
    readSubscriptionList: () => dispatch(subscriptionActions.readSubscriptionList()),
    readOrganizationList: () => dispatch(authenticationActions.readOrganizationList()),
    createOrganization: (organization) => dispatch(authenticationActions.createOrganization(organization)),
    createAwsSubscription: (params) => dispatch(awsActions.createAwsSubscription(params)),
    onLogin: (redirect) => dispatch(authenticationActions.login(redirect)),
  };
};

const ConnectedAwsRedeemComponent = connect(mapStateToProps, mapDispatchToProps)(AwsRedeemComponent);

export default ConnectedAwsRedeemComponent;
