import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.SUBSCRIPTION_REACTIVATE:
      return {
        ...state,
        reactivate: {
          ...state.reactivate,
          inProgress: true,
        },
      };

    case types.SUBSCRIPTION_REACTIVATE_REQUESTED:
      return {
        ...state,
        reactivate: {
          ...state.reactivate,
          inProgress: true,
        },
      };

    case types.SUBSCRIPTION_REACTIVATE_SUCCEEDED:
      return {
        ...state,
        reactivate: {
          ...state.reactivate,
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.SUBSCRIPTION_REACTIVATE_FAILED:
      return {
        ...state,
        reactivate: {
          ...state.reactivate,
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
  }
}
