import React from 'react';
import PropTypes from 'prop-types';
import './Activations.scss';
import ActivationsWrapperComponent from './ActivationWrapper';

const ActivationsComponent = function ActivationsComponent(props) {
  const {
    activations,
    redeem,
    organizations,
    readActivationList,
    createOrganization,
    country,
    readOrganizationList,
    setOrg,
    resellers,
    availableOrganizationList,
    readAvailableOrganizations,
    activationReadProcess,
    setCreateRedeem,
  } = props;

  return (
    <div className="activations">
      {activations?.map((activation) => (
        <ActivationsWrapperComponent
          key={activation.activation_id}
          activation={activation}
          resellers={resellers}
          redeem={redeem}
          organizations={organizations}
          readActivationList={readActivationList}
          createOrganization={createOrganization}
          country={country}
          readOrganizationList={readOrganizationList}
          readAvailableOrganizations={readAvailableOrganizations}
          availableOrganizationList={availableOrganizationList}
          setOrg={setOrg}
          activationReadProcess={activationReadProcess}
          setCreateRedeem={setCreateRedeem}
        />
      ))}
    </div>
  );
};

ActivationsComponent.propTypes = {
  activations: PropTypes.array,
  redeem: PropTypes.func,
  organizations: PropTypes.array,
  readActivationList: PropTypes.func,
  createOrganization: PropTypes.func,
  readOrganizationList: PropTypes.func,
  setOrg: PropTypes.func,
  resellers: PropTypes.array,
  availableOrganizationList: PropTypes.array,
  readAvailableOrganizations: PropTypes.func,
  activationReadProcess: PropTypes.object,
  setCreateRedeem: PropTypes.func,
  country: PropTypes.shape({
    countriesList: PropTypes.array,
    readStatesList: PropTypes.object,
    statesList: PropTypes.array,
    readList: PropTypes.object.isRequired,
  }),
};

export default ActivationsComponent;
