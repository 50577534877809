import { Config } from '../../products/create/ConfigDef';
import { Plan, Plans } from '../../products/create/CreateLanding/ChoosePlan/Plans';
import { SubscriptionProduct } from '../../products/create/types';

export type TrackFreeUserPurchaseProps = {
  subscription: SubscriptionProduct;
  plan: Plan;
};

type UpgradePlanCompletedProps = {
  item_name: string;
  item_id: string;
  price: number;
  taxes: number;
  total: number;
  coupon: string;
};

type PlanCheckoutProps = {
  plan_type: string;
  plan_level: string;
  billing_type: string;
};

type PlanPurchaseProps = {
  transaction_id: string;
  value: number;
  tax: number;
  currency: string;
  plan_type: string;
  plan_level: string;
  billing_type: string;
  is_update: boolean;
};

type CouponValidityProps = {
  name: string;
  coupon_validity: string;
};

const genericEvent = (name: string, plan: Plan) => {
  const planDetails = Plans.detailsFor(plan.available);
  return {
    event: name,
    site_area: 'cloud',
    ecommerce: {
      items: {
        item_name: planDetails.name,
        item_id: plan.available,
        price: plan.price,
        index: 1,
        quantity: 1,
      },
    },
  };
};

const getDowngradePlanEvent = (plan: Plan) => {
  return genericEvent('downgrade_plan', plan);
};

const getFreeUserPurchaseEvent = ({ subscription, plan }: TrackFreeUserPurchaseProps) => {
  if (subscription.plan === Config.Plans.CREATE_FREE.toString()) {
    return genericEvent('begin_checkout_plan', plan);
  }
};

const getUpgradePlanComplete = (data: UpgradePlanCompletedProps) => {
  return {
    event: 'upgrade_plan',
    site_area: 'cloud',
    ecommerce: {
      items: {
        item_name: data.item_name,
        item_id: data.item_id,
        price: data.price,
        taxes: data.taxes,
        total: data.total,
        coupon: data.coupon,
        index: 1,
        quantity: 1,
      },
    },
  };
};

const planBeginCheckout = (data: PlanCheckoutProps) => {
  return {
    event: 'plan_begin_checkout',
    site_area: 'cloud',
    plan_type: data.plan_type,
    plan_level: data.plan_level,
    billing_type: data.billing_type,
  };
};

const eduPlanConfiguration = () => {
  return {
    event: 'plan_details_info',
    site_area: 'cloud',
    plan_type: 'school',
  };
};

const planBillingInfo = (data: PlanCheckoutProps) => {
  return {
    event: 'plan_billing_info',
    site_area: 'cloud',
    plan_type: data.plan_type,
    plan_level: data.plan_level,
    billing_type: data.billing_type,
  };
};

const planPaymentInfo = (data: PlanCheckoutProps) => {
  return {
    event: 'plan_payment_info',
    site_area: 'cloud',
    plan_type: data.plan_type,
    plan_level: data.plan_level,
    billing_type: data.billing_type,
  };
};

const planPurchase = (data: PlanPurchaseProps) => {
  return {
    event: 'plan_purchase',
    site_area: 'cloud',
    transaction_id: data.transaction_id,
    value: data.value,
    tax: data.tax,
    currency: data.currency,
    plan_type: data.plan_type,
    plan_level: data.plan_level,
    billing_type: data.billing_type,
    is_update: data.is_update,
  };
};

const planDowngrade = (data: PlanPurchaseProps) => {
  return {
    event: 'downgrade_plan',
    site_area: 'cloud',
    items: {
      transaction_id: data.transaction_id,
      value: data.value,
      tax: data.tax,
      currency: data.currency,
      plan_type: data.plan_type,
      plan_level: data.plan_level,
      billing_type: data.billing_type,
    },
  };
};

const couponValidity = (data: CouponValidityProps) => {
  return {
    event: 'coupon_add',
    site_area: 'cloud',
    name: data.name,
    coupon_validity: data.coupon_validity,
  };
};

export {
  getDowngradePlanEvent,
  getFreeUserPurchaseEvent,
  getUpgradePlanComplete,
  planBeginCheckout,
  planBillingInfo,
  planPaymentInfo,
  planPurchase,
  planDowngrade,
  couponValidity,
  eduPlanConfiguration,
};
