import types from '../types';

const initialState = {
  plans: {},
  planDescriptions: {},
  planFeatures: {},
  trial: {
    data: {},
    inProgress: false,
    lastUpdate: null,
    error: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.PLAN_ADD: {
      // Push the product from the action into the plans
      // object. Keep only the plans IDs
      return {
        ...state,
        plans: {
          ...state.plans,
          [action.plan.id]: action.plan,
        },
      };
    }
    case types.PLAN_DESCRIPTION_ADD: {
      // Push the product from the action into the plans
      // object. Keep only the plans IDs
      return {
        ...state,
        planDescriptions: {
          ...state.planDescriptions,
          [action.planDescription.id]: action.planDescription,
        },
      };
    }

    case types.PLAN_FEATURES_ADD: {
      return {
        ...state,
        planFeatures: {
          ...state.planFeatures,
          [action.planFeaturesId]: action.planFeatures,
        },
      };
    }

    case types.PLAN_TRIAL_READ_REQUESTED:
      return {
        ...state,
        trial: {
          error: null,
          inProgress: true,
        },
      };
    case types.PLAN_TRIAL_READ_SUCCEEDED:
      return {
        ...state,
        trial: {
          data: action.data,
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.PLAN_TRIAL_READ_FAILED:
      return {
        ...state,
        trial: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.PLAN_TRIAL_CLEAR:
      return {
        ...state,
        trial: {
          data: {},
          inProgress: false,
          lastUpdate: null,
          error: null,
        },
      };

    default:
      return state;
  }
}
