import { connect } from 'react-redux';

import { activationActions } from '../../../../store/activations';
import { subscriptionActions } from '../../../../store/subscription';
import { cardActions } from '../../../../store/card';
import { countryActions } from '../../../../store/country';
import { invoiceActions } from '../../../../store/invoice';
import { billingActions } from '../../../../store/billing';
import { authenticationActions } from '../../../../store/authentication';
import { awsActions } from '../../../../store/aws';
import { couponActions } from '../../../../store/coupon';

import PlansTabComponent from './PlansTabComponent';

const mapStateToProps = (state) => {
  return {
    card: state.card,
    subscription: state.subscription,
    plan: state.plan,
    product: state.product,
    invoice: state.invoice,
    coupon: state.coupon,

    organizations: state.authentication.organizations.data,
    members: state.authentication.members.data,
    billing: state.billing,
    billingInfo: state.billing.billingInfo,
    profile: state.authentication.profile,
    certificates: state.authentication.certificates,
    country: state.country,

    activation: state.activation,
    created: state.created,

    availableOrganizationList: state.availableOrganizationList,

    awsSubscriptionList: state.aws.awsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    readCardList: () => dispatch(cardActions.readCardList()),
    readInvoiceListBySubscription: (subscriptionId) =>
      dispatch(invoiceActions.readInvoiceListBySubscription(subscriptionId)),
    cancelSubscription: (id) => dispatch(subscriptionActions.cancelSubscription(id)),
    readSubscriptionList: () => dispatch(subscriptionActions.readSubscriptionList()),
    readAwsSubscriptionList: () => dispatch(awsActions.readSubscriptionList()),
    readInvoiceList: () => dispatch(invoiceActions.readInvoiceList()),
    readCountries: () => dispatch(countryActions.readCountries()),
    readStates: (country) => dispatch(countryActions.readStates(country)),
    updateBillingInfo: (billingInfo) => dispatch(billingActions.updateBillingInfo(billingInfo)),
    readOrganizationList: () => dispatch(authenticationActions.readOrganizationList()),
    readOrganizationMembers: (organizationId) =>
      dispatch(authenticationActions.readOrganizationMembers(organizationId)),
    clearCoupon: () => dispatch(couponActions.verifyCouponClear()),
    verifyCoupon: (coupon, planId) => dispatch(couponActions.verifyCoupon(coupon, planId)),
    readActivationList: () => dispatch(activationActions.readActivationList()),
    createRedeemActivation: (params) => dispatch(activationActions.createActivationRedeem(params)),
    createOrganization: (organization) => dispatch(authenticationActions.createOrganization(organization)),
    readActivation: (id) => dispatch(activationActions.readActivationRead(id)),
    readResellerList: () => dispatch(activationActions.readActivationResellersList()),
    readAvailableOrganizations: (activationId) => dispatch(activationActions.readAvailableOrganizations(activationId)),
    createEnterpriseUpgradeRequest: (activationId) =>
      dispatch(activationActions.createEnterpriseUpgradeRequest(activationId)),
    createEducationUpgradeRequest: (activationId) =>
      dispatch(activationActions.createEducationUpgradeRequest(activationId)),
  };
};

const ConnectedPlansTabComponent = connect(mapStateToProps, mapDispatchToProps)(PlansTabComponent);

export default ConnectedPlansTabComponent;
