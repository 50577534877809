/* eslint react-hooks/exhaustive-deps: 'off' */
import React, { Component, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import { Formik } from 'formik';
import {
  FREQUENCY_MONTHLY,
  FREQUENCY_YEARLY,
  ENTRY_FEATURES,
  MAKER_FEATURES,
  MAKERPLUS_FEATURES,
  CREATE_MAKER_YEARLY,
  CREATE_MAKERPLUS_MONTHLY,
  CREATE_MAKER_MONTHLY,
  CREATE_ENTRY_YEARLY,
  CREATE_MAKERPLUS_YEARLY,
} from '../../../utils/utilities';
import CloudPlanProductCard from '../CloudPlanProductCard/CloudPlanProductCard';
import './CloudPlanConfiguration.scss';

const FIELD_FREQUENCY = 'frequency';
const FIELD_PLAN_TYPE = 'type';

const FormBody = (props) => {
  const {
    values,
    currentCloudSubscription,
    setFieldValue,
    handleSubmit,
    handleUpdateCloudPlanFrequency,
    handleUpdateCloudPlanName,
    handleChangeCloudDowngrading,
    cloudPlanFrequency,
    cloudPlanType,
    isCloudUpgrading,
    getUpgradePreview,
    handleCloudPlanDisabled,
  } = props;

  const showAlert = useMemo(() => {
    /* Downgrade rules:
     * CreateEntryMonthly:     1,
     * CreateEntryYearly:      2,
     * CreateMakerMonthly:     3,
     * CreateMakerYearly:      4,
     * CreateMakerPlusMonthly: 5,
     * CreateMakerPlusYearly:  6,
     */

    if (
      currentCloudSubscription.plan === CREATE_MAKERPLUS_YEARLY &&
      values[FIELD_PLAN_TYPE] !== CREATE_MAKERPLUS_YEARLY &&
      values[FIELD_PLAN_TYPE] !== ''
    ) {
      handleChangeCloudDowngrading(true);
      return true;
    } else if (
      currentCloudSubscription.plan === CREATE_MAKERPLUS_MONTHLY &&
      values[FIELD_PLAN_TYPE] !== '' &&
      (values[FIELD_PLAN_TYPE] !== CREATE_MAKERPLUS_MONTHLY || values[FIELD_PLAN_TYPE] !== CREATE_MAKERPLUS_YEARLY)
    ) {
      handleChangeCloudDowngrading(true);
      return true;
    } else if (
      currentCloudSubscription.plan === CREATE_MAKER_YEARLY &&
      (values[FIELD_PLAN_TYPE] === CREATE_MAKER_MONTHLY || values[FIELD_PLAN_TYPE] === CREATE_ENTRY_YEARLY)
    ) {
      handleChangeCloudDowngrading(true);
      return true;
    } else if (
      currentCloudSubscription.plan === CREATE_MAKER_MONTHLY &&
      values[FIELD_PLAN_TYPE] === CREATE_ENTRY_YEARLY
    ) {
      handleChangeCloudDowngrading(true);
      return true;
    }
    handleChangeCloudDowngrading(false);
    return false;
  }, [currentCloudSubscription, values[FIELD_FREQUENCY], values[FIELD_PLAN_TYPE]]);

  useEffect(() => {
    if (
      !values[FIELD_FREQUENCY] ||
      !values[FIELD_PLAN_TYPE] ||
      values[FIELD_PLAN_TYPE] === currentCloudSubscription.plan
    ) {
      handleCloudPlanDisabled(true);
    } else {
      handleCloudPlanDisabled(false);
    }
  }, []);

  const handleSelectFrequency = (value) => {
    if (value === props.cloudPlanFrequency) return;
    setFieldValue(FIELD_FREQUENCY, value);
    setFieldValue(FIELD_PLAN_TYPE, 'create_maker_' + value);
  };

  const handleSelectTypePlan = (value) => {
    setFieldValue(FIELD_PLAN_TYPE, value);
  };

  useEffect(() => {
    if (cloudPlanFrequency !== values[FIELD_FREQUENCY] || cloudPlanType !== values[FIELD_PLAN_TYPE]) {
      handleUpdateCloudPlanFrequency(values[FIELD_FREQUENCY]);
      handleUpdateCloudPlanName(values[FIELD_PLAN_TYPE]);
      if (isCloudUpgrading && currentCloudSubscription?.id && values[FIELD_PLAN_TYPE] !== '') {
        getUpgradePreview(currentCloudSubscription.id, values[FIELD_PLAN_TYPE], 1);
      }
    }
  }, [values[FIELD_FREQUENCY], values[FIELD_PLAN_TYPE]]);

  return (
    <form id="form-cloud-plan-configuration" className="form-fields" onSubmit={handleSubmit}>
      <div className="plan">
        <div className="plan__frequency">
          <div className="plan-title">Choose billing frequency:</div>
          <div className="frequencies">
            <button
              type="button"
              className={classnames('card', { 'card-selected': values[FIELD_FREQUENCY] === FREQUENCY_MONTHLY })}
              onClick={() => handleSelectFrequency(FREQUENCY_MONTHLY)}
            >
              <div className="card-title">
                <strong>Monthly</strong>
              </div>
              <div className="card-subtitle">Pay once per month, cancel anytime</div>
            </button>
            <button
              type="button"
              className={classnames('card', { 'card-selected': values[FIELD_FREQUENCY] === FREQUENCY_YEARLY })}
              onClick={() => handleSelectFrequency(FREQUENCY_YEARLY)}
            >
              <div className="right">
                <div className="card__left">
                  <div className="card-title">
                    <strong>Yearly</strong>
                  </div>
                  <div className="card-subtitle">Pay once per year</div>
                </div>
                <div className="card__right">
                  <div className="card-box"> Save 15%</div>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div className="plan__type">
          <div className="plan-title">Choose plan:</div>
          <div className="plan-product">
            {values[FIELD_FREQUENCY] === FREQUENCY_YEARLY ? (
              <>
                <CloudPlanProductCard
                  name={'Entry'}
                  selectedPlan={values[FIELD_PLAN_TYPE]}
                  plan={'create_entry_yearly'}
                  features={ENTRY_FEATURES}
                  price={23.88}
                  changePlan={(value) => handleSelectTypePlan(value)}
                  currentSubscription={currentCloudSubscription === '' ? {} : currentCloudSubscription}
                />
                <CloudPlanProductCard
                  name={'Maker'}
                  selectedPlan={values[FIELD_PLAN_TYPE]}
                  plan={'create_maker_yearly'}
                  features={MAKER_FEATURES}
                  price={71.88}
                  changePlan={(value) => handleSelectTypePlan(value)}
                  currentSubscription={currentCloudSubscription === '' ? {} : currentCloudSubscription}
                />
                <CloudPlanProductCard
                  name={'Maker Plus'}
                  selectedPlan={values[FIELD_PLAN_TYPE]}
                  plan={'create_makerplus_yearly'}
                  features={MAKERPLUS_FEATURES}
                  price={239.98}
                  changePlan={(value) => handleSelectTypePlan(value)}
                  currentSubscription={currentCloudSubscription === '' ? {} : currentCloudSubscription}
                />
              </>
            ) : (
              <>
                <CloudPlanProductCard
                  name={'Maker'}
                  selectedPlan={values[FIELD_PLAN_TYPE]}
                  plan={'create_maker_monthly'}
                  features={MAKER_FEATURES}
                  price={6.99}
                  changePlan={(value) => handleSelectTypePlan(value)}
                  currentSubscription={currentCloudSubscription === '' ? {} : currentCloudSubscription}
                />
                <CloudPlanProductCard
                  name={'Maker Plus'}
                  selectedPlan={values[FIELD_PLAN_TYPE]}
                  plan={'create_makerplus_monthly'}
                  features={MAKERPLUS_FEATURES}
                  price={23.99}
                  changePlan={(value) => handleSelectTypePlan(value)}
                  currentSubscription={currentCloudSubscription === '' ? {} : currentCloudSubscription}
                />
              </>
            )}
          </div>
          {showAlert && (
            <div className="plan-alert">
              Your new plan will come into effect at the end of your current billing period on{' '}
              <strong>{moment(currentCloudSubscription.renewal_date).format('MMMM DD, YYYY')}</strong>. You will
              continue using your current plan until then.
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

FormBody.propTypes = {
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  progress: PropTypes.bool,
  currentCloudSubscription: PropTypes.object,
  handleUpdateCloudPlanFrequency: PropTypes.func.isRequired,
  handleUpdateCloudPlanName: PropTypes.func.isRequired,
  handleChangeCloudDowngrading: PropTypes.func.isRequired,
  cloudPlanFrequency: PropTypes.string,
  cloudPlanType: PropTypes.string,
  isCloudUpgrading: PropTypes.bool,
  getUpgradePreview: PropTypes.func,
  handleCloudPlanDisabled: PropTypes.func,
};

export default class CloudPlanConfiguration extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const initialValues = {};

    initialValues[FIELD_FREQUENCY] = this.props.cloudPlanFrequency;
    initialValues[FIELD_PLAN_TYPE] = this.props.cloudPlanType;

    return (
      <div className="billing-info-form arduino-form">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.props.onSubmit}
          component={(formikProps) => <FormBody {...formikProps} {...this.props} />}
        />
      </div>
    );
  }
}

CloudPlanConfiguration.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  cloudPlanFrequency: PropTypes.string,
  cloudPlanType: PropTypes.string,
  currentCloudSubscription: PropTypes.object,
  handleUpdateCloudPlanFrequency: PropTypes.func.isRequired,
  handleUpdateCloudPlanName: PropTypes.func.isRequired,
  handleChangeCloudDowngrading: PropTypes.func.isRequired,
  isCloudUpgrading: PropTypes.bool,
  getUpgradePreview: PropTypes.func,
};
