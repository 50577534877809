import types from './types';
import { getRedirectParams, validateRedirectSource } from '../../utils/getRedirectParams';

const addSource = () => {
  const redirectParams = getRedirectParams();

  if (validateRedirectSource(redirectParams.source)) {
    return {
      type: types.NAVIGATION_ADD_SOURCE,
      ...redirectParams,
    };
  }
  return {
    type: types.NAVIGATION_ADD_SOURCE,
    source: null,
    redirect: null,
  };
};

export default {
  addSource,
};
