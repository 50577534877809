import types from './types';

const addPlan = (plan) => ({
  type: types.PLAN_ADD,
  plan,
});

const addPlanDescription = (planDescription) => ({
  type: types.PLAN_DESCRIPTION_ADD,
  planDescription,
});

const addPlanFeatures = (planFeaturesId, planFeatures) => ({
  type: types.PLAN_FEATURES_ADD,
  planFeaturesId,
  planFeatures,
});

const getPlanTrial = (planId, planConfig) => ({
  type: types.PLAN_TRIAL_READ,
  planId,
  planConfig,
});

const getPlanTrialRequest = () => ({
  type: types.PLAN_TRIAL_READ_REQUESTED,
});

const getPlanTrialSuccess = (data) => ({
  type: types.PLAN_TRIAL_READ_SUCCEEDED,
  data,
});

const getPlanTrialFailure = (error) => ({
  type: types.PLAN_TRIAL_READ_FAILED,
  error,
});

const clearPlanTrial = () => ({
  type: types.PLAN_TRIAL_CLEAR,
});

export default {
  addPlan,
  addPlanDescription,
  addPlanFeatures,
  getPlanTrial,
  getPlanTrialRequest,
  getPlanTrialSuccess,
  getPlanTrialFailure,
  clearPlanTrial,
};
