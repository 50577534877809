/* eslint-disable no-unused-vars */
declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    dataLayer: any;
  }
}
/**
 * A decorator for methods used to track event in GA4.
 *
 * The function passed in as first parameter to the decorator will be used to generate the event.
 *
 * The decorated method will be used to prepare the arguments for the event generator function.
 * As a final step, the event will be added to window.dataLayer using `dataLayer.push`
 *
 * Usage:
 * ```
 *  @TrackWithGA4(myEventGenerator)
 *  trackUserName(data) {
 *    return {
 *      userName: data.user.name,
 *    };
 *  }
 * ```
 *
 * ```
 *  function myEventGenerator({ userName }) {
 *    return ({
 *      event: {
 *        user_name: userName,
 *      }
 *    });
 *  }
 * ```
 *
 * @param {(args: T) => any } eventGeneratorFunction a function which builds the event to send to GA4
 * @returns {(eventGenerator: (...args: any[]) => void)} a function which will be executed as part of the Decorator lifecycle
 */

export const TrackWithGA4 = (eventGenerator: (...args: any[]) => void) => {
  return function (_: any, __: string, descriptor: PropertyDescriptor): void {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: any[]) {
      const eventGeneratorParams = originalMethod(...args);
      const event = eventGenerator(eventGeneratorParams);
      // trackEvent
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(event);
    };
  };
};
