import { API_URL } from '../env';

import fetch from '../utils/authenticatedFetch';
import { defaultResponseHandler, defaultJsonResponseHandler } from './utils';

const SUBSCRIPTIONS_API = `${API_URL}/payments/v2/subscriptions`;
const CARDS_API = `${API_URL}/payments/v1/cards`;
const CHARGES_API = `${API_URL}/payments/v1/charges`;
const INVOICES_API = `${API_URL}/payments/v2/invoices`;
const PLANS_API = `${API_URL}/payments/v2/plans`;
const COUPONS_API = `${API_URL}/payments/v2/coupons`;
const TAXES_API = `${API_URL}/payments/v1/taxes/calculate`;
const CERTIFICATE_API = `${API_URL}/payments/v1/certificates`;

// #### CARDS ##################################################

export async function readCards() {
  const res = await fetch(CARDS_API);
  return await defaultJsonResponseHandler(res);
}

export async function createCard(stripeToken) {
  const res = await fetch(CARDS_API, {
    method: 'PUT',
    body: JSON.stringify({
      stripe_token: stripeToken?.id || stripeToken,
    }),
  });
  return await defaultJsonResponseHandler(res);
}

export async function deleteCard(id) {
  const res = await fetch(`${CARDS_API}/${id}`, {
    method: 'DELETE',
  });
  return await defaultResponseHandler(res);
}

// #### CHARGES ################################################

export async function createCharge(body) {
  const res = await fetch(CHARGES_API, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
  return await defaultJsonResponseHandler(res);
}

// #### SUBSCRIPTIONS ##########################################

export async function readSubscriptions() {
  const res = await fetch(SUBSCRIPTIONS_API);
  return await defaultJsonResponseHandler(res);
}

export async function readSubscription(id) {
  const res = await fetch(`${SUBSCRIPTIONS_API}/${id}`);
  return await defaultJsonResponseHandler(res);
}

export async function readAvailableOrganization(activationId) {
  const res = await fetch(`${SUBSCRIPTIONS_API}/available/organization/${activationId}`);
  return await defaultJsonResponseHandler(res);
}

export async function readUpgradePreview(subscriptionId, planName, quantity, coupon) {
  if (coupon !== null) {
    const res = await fetch(`${SUBSCRIPTIONS_API}/${subscriptionId}/upgradepreview/`, {
      method: 'POST',
      body: JSON.stringify({
        plan: planName,
        quantity,
        coupon,
      }),
    });
    return await defaultJsonResponseHandler(res);
  }
  const res = await fetch(`${SUBSCRIPTIONS_API}/${subscriptionId}/upgradepreview/`, {
    method: 'POST',
    body: JSON.stringify({
      plan: planName,
      quantity,
    }),
  });
  return await defaultJsonResponseHandler(res);
}

export async function putSubscription(body) {
  const res = await fetch(SUBSCRIPTIONS_API, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
  return await defaultJsonResponseHandler(res);
}

export async function updateSubscriptionCard(subscriptionId, cardId) {
  const res = await fetch(`${SUBSCRIPTIONS_API}/${subscriptionId}/creditcard`, {
    method: 'POST',
    body: JSON.stringify({
      default_source: cardId,
    }),
  });
  return await defaultJsonResponseHandler(res);
}

export async function deleteSubscription(id, atPeriodEnd) {
  const res = await fetch(`${SUBSCRIPTIONS_API}/${id}`, {
    method: 'DELETE',
    body: JSON.stringify({
      at_period_end: atPeriodEnd ? atPeriodEnd : false,
    }),
  });
  return await defaultResponseHandler(res);
}

export async function upgradeSubscription(id, newPlanId, quantity, prorationdate) {
  const res = await fetch(`${SUBSCRIPTIONS_API}/${id}`, {
    method: 'PUT',
    body: JSON.stringify({
      plan: newPlanId,
      quantity: quantity,
      upgradedate: prorationdate,
    }),
  });
  return await defaultResponseHandler(res);
}

export async function reactivateSubscription(id) {
  const res = await fetch(`${SUBSCRIPTIONS_API}/${id}/reactivate`, {
    method: 'PUT',
  });
  return await defaultResponseHandler(res);
}

// #### INVOICES ###############################################

export async function readInvoices() {
  const res = await fetch(INVOICES_API);
  return await defaultJsonResponseHandler(res);
}

export async function readInvoicesBySubscription(subscriptionId) {
  const res = await fetch(`${INVOICES_API}?subscription_id=${subscriptionId}`);
  return await defaultJsonResponseHandler(res);
}

// #### PLANS ##################################################

export async function getPlanTrial(planId, planConfig) {
  const url = new URL(`${PLANS_API}/${planId}`);
  url.search = new URLSearchParams(planConfig);
  const res = await fetch(url);
  return await defaultJsonResponseHandler(res);
}

// #### COUPON ##################################################

export async function validateCoupon(coupon, planId) {
  const url = new URL(`${COUPONS_API}/${coupon}`);
  url.search = new URLSearchParams({ plan: planId });
  const res = await fetch(url);
  return await defaultJsonResponseHandler(res);
}

// #### TAXES ##################################################

export async function calculateTaxes(amount) {
  const res = await fetch(TAXES_API, {
    method: 'POST',
    body: JSON.stringify({
      amount,
    }),
  });
  return await defaultJsonResponseHandler(res);
}

// #### CERTIFICATE ##################################################

export async function createPresignedUrl(certificateType) {
  const res = await fetch(CERTIFICATE_API, {
    method: 'POST',
    body: JSON.stringify({
      certificate_type: certificateType,
    }),
  });
  return await defaultJsonResponseHandler(res);
}

export async function getUserCertificates() {
  const res = await fetch(CERTIFICATE_API);
  return await defaultJsonResponseHandler(res);
}

// #############################################################
