import types from './types';

const createCharge = (params) => ({
  type: types.CHARGE_CREATE,
  params,
});

const createChargeRequest = () => ({
  type: types.CHARGE_CREATE_REQUESTED,
});

const createChargeSuccess = (data) => ({
  type: types.CHARGE_CREATE_SUCCEEDED,
  data,
});

const createChargeFailure = (error) => ({
  type: types.CHARGE_CREATE_FAILED,
  error,
});

const createChargeClearError = () => ({
  type: types.CHARGE_CREATE_CLEAR_FAILED,
});

export default {
  createCharge,
  createChargeRequest,
  createChargeSuccess,
  createChargeFailure,
  createChargeClearError,
};
