import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.SUBSCRIPTION_CANCEL:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          inProgress: true,
        },
      };

    case types.SUBSCRIPTION_CANCEL_REQUESTED:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          inProgress: true,
        },
      };

    case types.SUBSCRIPTION_CANCEL_SUCCEEDED:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.SUBSCRIPTION_CANCEL_FAILED:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.SUBSCRIPTION_CANCEL_CLEAR_FAILED:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          error: null,
        },
      };
  }
}
