import types from './types';

const setCart = (productId, planId, iccid = null, quantity = null, price = null) => {
  return {
    type: types.CART_SET,
    productId,
    planId,
    iccid,
    quantity,
    price,
  };
};

const unsetCart = () => ({
  type: types.CART_UNSET,
});

export default {
  setCart,
  unsetCart,
};
