import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.AWS_CREATE_SUBSCRIPTION_REQUESTED:
      return {
        ...state,
        created: {},
        create: {
          error: null,
          inProgress: true,
        },
      };
    case types.AWS_CREATE_SUBSCRIPTION_SUCCEEDED:
      return {
        ...state,
        created: action.data.states || {},
        create: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.AWS_CREATE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        create: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_REQUESTED:
      return {
        ...state,
        created: {},
        create: {
          error: null,
          inProgress: true,
        },
      };
    case types.ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_SUCCEEDED:
      return {
        ...state,
        created: action.data.states || {},
        create: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_FAILED:
      return {
        ...state,
        create: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_REQUESTED:
      return {
        ...state,
        created: {},
        create: {
          error: null,
          inProgress: true,
        },
      };
    case types.ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_SUCCEEDED:
      return {
        ...state,
        created: action.data.states || {},
        create: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_FAILED:
      return {
        ...state,
        create: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
  }
}
