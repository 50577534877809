import types from './types';

const initialState = {
  isValid: false,
  inProgress: false,
  lastUpdate: null,
  error: null,
  availableCountriesList: [],
  readList: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
};

// https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns

export default function example(state = initialState, action) {
  switch (action.type) {
    case types.CHECK_USER_COUNTRY_REQUESTED:
      return {
        ...state,
        inProgress: true,
      };
    case types.CHECK_USER_COUNTRY_SUCCEDED:
      return {
        isValid: action.flag,
        lastUpdate: Date.now(),
        inProgress: false,
      };
    case types.CHECK_USER_COUNTRY_FAILED:
      return {
        ...state,
        error: action.error,
        lastUpdate: Date.now(),
        inProgress: false,
      };
    case types.COUNTRIES_AVAILABLE_READ_REQUESTED:
      return {
        ...state,
        availableCountriesList: [],
        readList: {
          error: null,
          inProgress: true,
        },
      };
    case types.COUNTRIES_AVAILABLE_READ_SUCCEEDED:
      return {
        ...state,
        availableCountriesList: action.data,
        readList: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.COUNTRIES_AVAILABLE_READ_FAILED:
      return {
        ...state,
        readList: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    default:
      return state;
  }
}
