import React from 'react';
import { History } from 'history';
import { Formik } from 'formik';
import EnterprisePlanAddonCard from '../EnterprisePlanAddonCard/EnterprisePlanAddonCard';
import { ENTERPRISE_ADDONS } from '../../../utils/utilities';
import { Redirect } from 'react-router-dom';
import './EnterpriseAddonsConfiguration.scss';

const FIELD_ADDONS = 'addons';

interface Match {
  path: string;
}

interface EnterpriseAddonsConfigurationProps {
  onSubmit: () => void;
  history: History;
  match: Match;
  handleUpdateEnterpriseAddons: (values: string) => void;
  handleUpdateEnterpriseX8Devices: (value: number) => void;
  handleUpdateEnterpriseEdgeImpulseAddon: (value: number) => void;
  enterprisePlanAddons: Array<string>;
  enterprisePlanFrequency: string;
  enterprisePlanThings: number;
  enterprisePlanSelected: boolean;
  enterprisePath: string;
  x8DevicesNumber: number;
  edgeImpulseAddonNumber: number;
}

interface FormBodyProps {
  values: object;
  history: History;
  match: object;
  handleSubmit: () => void;
  handleUpdateEnterpriseAddons: (values: string) => void;
  handleUpdateEnterpriseX8Devices: (value: number) => void;
  handleUpdateEnterpriseEdgeImpulseAddon: (value: number) => void;
  enterprisePlanAddons: Array<string>;
  enterprisePlanFrequency: string;
  enterprisePlanThings: number;
  x8DevicesNumber: number;
}

const FormBody = (props: FormBodyProps) => {
  const {
    x8DevicesNumber,
    enterprisePlanAddons,
    handleUpdateEnterpriseAddons,
    handleUpdateEnterpriseX8Devices,
    handleUpdateEnterpriseEdgeImpulseAddon,
    handleSubmit,
    enterprisePlanFrequency,
    enterprisePlanThings,
    history,
    match,
  } = props;

  const handleSelectAddon = (value) => {
    let selectedAddons = '';
    ENTERPRISE_ADDONS.forEach((addon) => {
      if (addon.id === value) {
        selectedAddons = addon.id + '_' + enterprisePlanFrequency;
      }
    });

    handleUpdateEnterpriseAddons(selectedAddons);
  };

  return (
    <form id="form-enterprise-addons" className="form-fields" onSubmit={handleSubmit}>
      <div className="plan">
        <div className="plan__type">
          <div className="plan-product">
            {ENTERPRISE_ADDONS.map((addon) => {
              return (
                <EnterprisePlanAddonCard
                  key={addon.id}
                  id={addon.id}
                  history={history}
                  match={match}
                  name={addon.name}
                  description={addon.description}
                  features={addon.features}
                  yearlyPrice={addon.yearlyPrice}
                  monthlyPrice={addon.monthlyPrice}
                  hasFeatures={addon.hasFeatures}
                  mandatory={addon.mandatory}
                  changeAddon={(value) => handleSelectAddon(value)}
                  handleUpdateEnterpriseX8Devices={(value) => handleUpdateEnterpriseX8Devices(value)}
                  handleUpdateEnterpriseEdgeImpulseAddon={(value) => handleUpdateEnterpriseEdgeImpulseAddon(value)}
                  enterprisePlanThings={enterprisePlanThings}
                  enterprisePlanAddons={enterprisePlanAddons}
                  enterprisePlanFrequency={enterprisePlanFrequency}
                  x8DevicesNumber={x8DevicesNumber}
                />
              );
            })}
          </div>
        </div>
      </div>
    </form>
  );
};

export default function EnterpriseAddonsConfiguration(props: EnterpriseAddonsConfigurationProps) {
  const { match, enterprisePlanAddons, onSubmit, enterprisePath, enterprisePlanSelected } = props;

  if (enterprisePath && !enterprisePlanSelected) {
    return <Redirect push to={match.path} />;
  }
  const initialValues = {};

  initialValues[FIELD_ADDONS] = enterprisePlanAddons;

  return (
    <div className="billing-info-form arduino-form">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
        component={(formikProps) => <FormBody {...formikProps} {...props} />}
      />
    </div>
  );
}
