import types from './types';

const readInvoiceList = (params) => ({
  type: types.INVOICE_LIST_READ,
  params,
});

const readInvoiceListRequest = () => ({
  type: types.INVOICE_LIST_READ_REQUESTED,
});

const readInvoiceListSuccess = (invoices) => ({
  type: types.INVOICE_LIST_READ_SUCCEEDED,
  invoices,
});

const readInvoiceListFailure = (error) => ({
  type: types.INVOICE_LIST_READ_FAILED,
  error,
});

const readInvoiceListBySubscription = (subscriptionId) => ({
  type: types.INVOICE_LIST_BY_SUBSCRIPTION_READ,
  subscriptionId,
});

const readInvoiceListBySubscriptionRequest = () => ({
  type: types.INVOICE_LIST_BY_SUBSCRIPTION_READ_REQUESTED,
});

const readInvoiceListBySubscriptionSuccess = (invoices) => ({
  type: types.INVOICE_LIST_BY_SUBSCRIPTION_READ_SUCCEEDED,
  invoices,
});

const readInvoiceListBySubscriptionFailure = (error) => ({
  type: types.INVOICE_LIST_BY_SUBSCRIPTION_READ_FAILED,
  error,
});

export default {
  readInvoiceList,
  readInvoiceListRequest,
  readInvoiceListSuccess,
  readInvoiceListFailure,
  readInvoiceListBySubscription,
  readInvoiceListBySubscriptionRequest,
  readInvoiceListBySubscriptionSuccess,
  readInvoiceListBySubscriptionFailure,
};
