import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Modal, Button, Input, Select } from '@arduino/arc';
import { OTHER, SPACE_ENTERPRISE_PLAN } from '../../../../utils/utilities';
import NewSharedSpace from '../../../../assets/newSharedSpace.png';
import './EnterprisePlanNewSharedSpaceModal.scss';
import { OpenErrorNotification } from '../../../common/Toast/Toast';

interface EnterprisePlanRedeemModalProps {
  onClose: () => void;
  isOpen: boolean;
  onConfirm: (val: string) => void;
  organizations: Array<any>;
  readOrganizationList: () => void;
  createOrganization: (val: any) => void;
  country: any;
}

const EnterprisePlanNewSharedSpaceModal = (props: EnterprisePlanRedeemModalProps): React.ReactElement => {
  const { isOpen, onClose, onConfirm, createOrganization, organizations, readOrganizationList, country } = props;
  const [selectedOrganization, setSelectedOrganization] = useState<any>(null);
  const [onLoading, setOnLoading] = useState(false);
  const [newOrganizationName, setNewOrganizationName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');

  const formattedCountryOption = country.countriesList.map((e) => {
    return { label: e.name, value: e.code };
  });

  const activateDisabled = useMemo(() => {
    if ((!selectedOrganization && newOrganizationName === '') || selectedCountry === '') return true;
    return false;
  }, [newOrganizationName, selectedCountry, selectedOrganization]);

  const handleActivate = useCallback(async () => {
    setOnLoading(true);
    try {
      const organization = {
        name: newOrganizationName,
        type: OTHER,
        country: selectedCountry,
        space: SPACE_ENTERPRISE_PLAN,
      };
      await createOrganization(organization);
      setTimeout(() => {
        readOrganizationList();
      }, 4000);
    } catch (e) {
      OpenErrorNotification(e.body.message);
    } finally {
      setTimeout(() => {
        readOrganizationList();
        setOnLoading(false);
        setSelectedOrganization(true);
      }, 7000);
    }
  }, [createOrganization, newOrganizationName, readOrganizationList, selectedCountry]);

  useEffect(() => {
    const choosenOrganization = organizations.find((org) => org.name === newOrganizationName);
    if (selectedOrganization && choosenOrganization) {
      onConfirm(choosenOrganization?.id);
      setSelectedOrganization(null);
    }
  }, [onClose, onConfirm, selectedOrganization, organizations, newOrganizationName]);

  return (
    <Modal
      isOpen={isOpen}
      isDismissable={true}
      title="Shared Space name"
      onClose={() => onClose()}
      className="enterprise-new-space-modal"
    >
      <div className="enterprise-new-space-modal">
        <div className="maker-plan-redeem-modal__header">
          <img src={NewSharedSpace} alt="new shared space image" />
        </div>
        <div className="enterprise-new-space-modal__description">
          <h4>Give a name to your Shared Space</h4>
          To get started with your plan, give a name to your organization
        </div>

        <div className="enterprise-new-space-modal__input">
          <Input
            className="custom-margin"
            label="Name your Shared Space"
            value={newOrganizationName}
            name={'Name your Shared Space'}
            onChange={(event) => setNewOrganizationName(event.target.value)}
            required
          />
          <Select
            className="select-custom-margin"
            label="Country"
            placeholder="Select your country"
            name="Country"
            value={country}
            options={formattedCountryOption}
            onChange={(value) => setSelectedCountry(value as string)}
            isRequired
          />
        </div>
        <div className="enterprise-new-space-modal__buttons">
          <Button type="button" loading={onLoading} isDisabled={activateDisabled} onPress={() => handleActivate()}>
            Activate Plan
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EnterprisePlanNewSharedSpaceModal;
