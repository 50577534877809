import types from './types';

const checkUserCountry = () => ({
  type: types.CHECK_USER_COUNTRY,
});

const checkUserCountryRequested = () => ({
  type: types.CHECK_USER_COUNTRY_REQUESTED,
});

const checkUserCountrySucceeded = (flag) => ({
  type: types.CHECK_USER_COUNTRY_SUCCEDED,
  flag,
});

const checkUserCountryFailed = (error) => ({
  type: types.CHECK_USER_COUNTRY_FAILED,
  error,
});

const readAvailableCountries = () => ({
  type: types.COUNTRIES_AVAILABLE_READ,
});

const readAvailableCountriesRequest = () => ({
  type: types.COUNTRIES_AVAILABLE_READ_REQUESTED,
});

const readAvailableCountriesSuccess = (data) => ({
  type: types.COUNTRIES_AVAILABLE_READ_SUCCEEDED,
  data,
});

const readAvailableCountriesFailure = (error) => ({
  type: types.COUNTRIES_AVAILABLE_READ_FAILED,
  error,
});

export default {
  checkUserCountry,
  checkUserCountryRequested,
  checkUserCountrySucceeded,
  checkUserCountryFailed,
  readAvailableCountries,
  readAvailableCountriesRequest,
  readAvailableCountriesSuccess,
  readAvailableCountriesFailure,
};
