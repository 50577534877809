import { connect } from 'react-redux';
import { subscriptionActions } from '../../store/subscription';

import CreateSubscriptionCard from './CreateSubscriptionCard';

const mapStateToProps = (state) => {
  return {
    subscriptionList: state.subscription,
    billingInfo: state.billing.billingInfo,
    invoice: state.invoice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelChangeSubscription: (id) => dispatch(subscriptionActions.reactivateSubscription(id)),
  };
};

const ConnectedCreateSubscriptionCard = connect(mapStateToProps, mapDispatchToProps)(CreateSubscriptionCard);

export default ConnectedCreateSubscriptionCard;
