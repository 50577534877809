import create from './create';
import read from './read';

const initialState = {
  token: {},
  created: {},
  create: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  read: {
    error: null,
    inProgress: false,
    lastUpdate: null,
    id: null,
  },
  awsList: [],
};

export default function (state = initialState, action) {
  return create(state, action) || read(state, action) || state;
}
