import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.CARD_DELETE:
      return {
        ...state,
        delete: {
          ...state.delete,
          inProgress: true,
        },
      };

    case types.CARD_DELETE_REQUESTED:
      return {
        ...state,
        delete: {
          ...state.delete,
          inProgress: true,
        },
      };

    case types.CARD_DELETE_SUCCEEDED:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.CARD_DELETE_FAILED:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
  }
}
