import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import AbsoluteSpinner from '../AbsoluteSpinner';

import './StackCard.scss';

function CardContainer({ children, className }) {
  return <div className={classnames('stack-card', className)}>{children}</div>;
}

CardContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

function StackCard(props) {
  if (props.loading === true) {
    return (
      <CardContainer>
        <AbsoluteSpinner height={200} />
      </CardContainer>
    );
  }

  let sideElement = null;

  if (props.side) {
    sideElement = <div className="side">{props.side}</div>;
  }
  return (
    <CardContainer className={classnames(props.className, { sideless: !props.side })}>
      <div className="main">{props.children}</div>
      {sideElement}
    </CardContainer>
  );
}

StackCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
  side: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default StackCard;
