import types from './types';

const readDevice = (iccid) => ({
  type: types.SIM_DEVICE_READ,
  iccid,
});

const readDeviceRequest = (iccid) => ({
  type: types.SIM_DEVICE_READ_REQUESTED,
  iccid,
});

const readDeviceSuccess = (device) => ({
  type: types.SIM_DEVICE_READ_SUCCEEDED,
  device,
});

const readDeviceFailure = (error) => ({
  type: types.SIM_DEVICE_READ_FAILED,
  error,
});

const updateDevice = (iccid, payload) => ({
  type: types.SIM_DEVICE_UPDATE,
  iccid,
  payload,
});

const updateDeviceRequest = (iccid, payload) => ({
  type: types.SIM_DEVICE_UPDATE_REQUESTED,
  iccid,
  payload,
});

const updateDeviceSuccess = (device) => ({
  type: types.SIM_DEVICE_UPDATE_SUCCEEDED,
  device,
});

const updateDeviceFailure = (error) => ({
  type: types.SIM_DEVICE_UPDATE_FAILED,
  error,
});

const pauseDevice = (iccid) => ({
  type: types.SIM_DEVICE_PAUSE,
  iccid,
});

const pauseDeviceRequest = (iccid) => ({
  type: types.SIM_DEVICE_PAUSE_REQUESTED,
  iccid,
});

const pauseDeviceSuccess = (device) => ({
  type: types.SIM_DEVICE_PAUSE_SUCCEEDED,
  device,
});

const pauseDeviceFailure = (error) => ({
  type: types.SIM_DEVICE_PAUSE_FAILED,
  error,
});

const unpauseDevice = (iccid) => ({
  type: types.SIM_DEVICE_UNPAUSE,
  iccid,
});

const unpauseDeviceRequest = (iccid) => ({
  type: types.SIM_DEVICE_UNPAUSE_REQUESTED,
  iccid,
});

const unpauseDeviceSuccess = (device) => ({
  type: types.SIM_DEVICE_UNPAUSE_SUCCEEDED,
  device,
});

const unpauseDeviceFailure = (error) => ({
  type: types.SIM_DEVICE_UNPAUSE_FAILED,
  error,
});

const verifyIccid = (iccid) => ({
  type: types.SIM_ICCID_VERIFY,
  iccid,
});

const verifyIccidRequest = () => ({
  type: types.SIM_ICCID_VERIFY_REQUESTED,
});

const verifyIccidSuccess = (validity) => ({
  type: types.SIM_ICCID_VERIFY_SUCCEEDED,
  validity,
});

const verifyIccidFailure = (error) => ({
  type: types.SIM_ICCID_VERIFY_FAILED,
  error,
});

export default {
  readDevice,
  readDeviceRequest,
  readDeviceSuccess,
  readDeviceFailure,
  updateDevice,
  updateDeviceRequest,
  updateDeviceSuccess,
  updateDeviceFailure,
  pauseDevice,
  pauseDeviceRequest,
  pauseDeviceSuccess,
  pauseDeviceFailure,
  unpauseDevice,
  unpauseDeviceRequest,
  unpauseDeviceSuccess,
  unpauseDeviceFailure,
  verifyIccid,
  verifyIccidRequest,
  verifyIccidSuccess,
  verifyIccidFailure,
};
