const AWS_CREATE_SUBSCRIPTION = 'app/aws/AWS_CREATE_SUBSCRIPTION';
const AWS_CREATE_SUBSCRIPTION_REQUESTED = 'app/aws/AWS_CREATE_SUBSCRIPTION_REQUESTED';
const AWS_CREATE_SUBSCRIPTION_SUCCEEDED = 'app/aws/AWS_CREATE_SUBSCRIPTION_SUCCEEDED';
const AWS_CREATE_SUBSCRIPTION_FAILED = 'app/aws/AWS_CREATE_SUBSCRIPTION_FAILED';

const AWS_GET_SUBSCRIPTION_LIST = 'app/aws/AWS_GET_SUBSCRIPTION_LIST';
const AWS_GET_SUBSCRIPTION_LIST_REQUESTED = 'app/aws/AWS_GET_SUBSCRIPTION_LIST_REQUESTED';
const AWS_GET_SUBSCRIPTION_LIST_SUCCEEDED = 'app/aws/AWS_GET_SUBSCRIPTION_LIST_SUCCEEDED';
const AWS_GET_SUBSCRIPTION_LIST_FAILED = 'app/aws/AWS_GET_SUBSCRIPTION_LIST_FAILED';

export default {
  AWS_CREATE_SUBSCRIPTION,
  AWS_CREATE_SUBSCRIPTION_REQUESTED,
  AWS_CREATE_SUBSCRIPTION_SUCCEEDED,
  AWS_CREATE_SUBSCRIPTION_FAILED,
  AWS_GET_SUBSCRIPTION_LIST,
  AWS_GET_SUBSCRIPTION_LIST_REQUESTED,
  AWS_GET_SUBSCRIPTION_LIST_SUCCEEDED,
  AWS_GET_SUBSCRIPTION_LIST_FAILED,
};
