import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.COUNTRIES_READ_REQUESTED:
      return {
        ...state,
        countriesList: [],
        readList: {
          error: null,
          inProgress: true,
        },
      };
    case types.COUNTRIES_READ_SUCCEEDED:
      return {
        ...state,
        countriesList: action.data,
        readList: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.COUNTRIES_READ_FAILED:
      return {
        ...state,
        readList: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.COUNTRIES_STATES_READ_REQUESTED:
      return {
        ...state,
        statesList: [],
        readStatesList: {
          error: null,
          inProgress: true,
        },
      };
    case types.COUNTRIES_STATES_READ_SUCCEEDED:
      return {
        ...state,
        statesList: action.data.states || [],
        readStatesList: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.COUNTRIES_STATES_READ_FAILED:
      return {
        ...state,
        readStatesList: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
  }
}
