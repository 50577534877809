import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PauseModal from './PauseModal';

import { SubscriptionCardContext } from '../../../components/common/SubscriptionCard/SubscriptionCardContext';
import { OpenSuccessNotification, OpenErrorNotification } from '../../../components/common/Toast/Toast.jsx';
import { simActions } from '../../../store/sim';

function PauseButton(props) {
  const { pauseDevice, unpauseDevice, plan, sim, subscription } = props;

  // States for the pause subscription modal
  const [pauseModalOpen, setDeleteModalOpen] = useState(false);
  const [pausing, setPausing] = useState(false);
  const [unpausing, setUnpausing] = useState(false);

  const handlePauseModalShow = function handlePauseModalShow() {
    setDeleteModalOpen(true);
  };

  const handlePauseModalHide = function handlePauseModalHide() {
    setDeleteModalOpen(false);
  };

  const handleConfirmPauseSubscription = function handleConfirmPauseSubscription() {
    if (sim.byIccid[subscription.iccid].status === 'active') {
      setPausing(true);
      pauseDevice(subscription.iccid);
    } else {
      setUnpausing(true);
      unpauseDevice(subscription.iccid);
    }
  };

  // When `subscription.pause.inProgress` changes, and the new value is false, close the modal
  useEffect(() => {
    if (sim.pause.inProgress || sim.unpause.inProgress) {
      return;
    }

    if (pausing || unpauseDevice) {
      handlePauseModalHide();
    }

    if (pausing) {
      setPausing(false);

      if (sim.pause.error) {
        OpenErrorNotification('An error has occurred');
      } else {
        OpenSuccessNotification('Your SIM has been paused.');
      }
    }

    if (unpausing) {
      setUnpausing(false);

      if (sim.unpause.error) {
        OpenErrorNotification('An error has occurred');
      } else {
        OpenSuccessNotification('Your SIM has been reactivated.');
      }
    }
  }, [
    sim.pause.inProgress,
    sim.unpause.inProgress,
    pausing,
    sim.pause.error,
    sim.unpause.error,
    unpauseDevice,
    unpausing,
  ]);

  let button = null;
  let simStatus = null;

  if (!sim.read.inProgress && sim.byIccid[subscription.iccid]) {
    simStatus = sim.byIccid[subscription.iccid].status;

    if (subscription.status === 'trialing' || simStatus === 'disabled' || simStatus === 'suspended') {
      return null;
    }

    const pauseButtonText = simStatus === 'active' ? 'Pause SIM' : 'Reactivate SIM';

    button = (
      <button className="pause-button stack-card-action-button" type="button" onClick={handlePauseModalShow}>
        {pauseButtonText}
      </button>
    );
  } else {
    button = (
      <div className="subscription-button-loader">
        <span className="spinner" />
      </div>
    );
  }

  if (plan.pauseable) {
    return (
      <>
        {button}
        <PauseModal
          open={pauseModalOpen}
          busy={sim.pause.inProgress || sim.unpause.inProgress}
          onConfirmPauseSubscription={handleConfirmPauseSubscription}
          onToggle={handlePauseModalHide}
          unpause={simStatus === 'paused'}
        />
      </>
    );
  }

  return null;
}

PauseButton.propTypes = {
  plan: PropTypes.object.isRequired,
  planDescription: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  pauseDevice: PropTypes.func.isRequired,
  unpauseDevice: PropTypes.func.isRequired,
  sim: PropTypes.object.isRequired,
};

function PauseButtonConsumer(props) {
  return (
    <SubscriptionCardContext.Consumer>
      {({ plan, planDescription, subscription }) => (
        <PauseButton {...props} plan={plan} planDescription={planDescription} subscription={subscription} />
      )}
    </SubscriptionCardContext.Consumer>
  );
}

PauseButtonConsumer.propTypes = {
  pauseDevice: PropTypes.func.isRequired,
  sim: PropTypes.object.isRequired,
  unpauseDevice: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    sim: state.sim,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pauseDevice: (iccid) => dispatch(simActions.pauseDevice(iccid)),
    unpauseDevice: (iccid) => dispatch(simActions.unpauseDevice(iccid)),
  };
};

const ConnectedPauseButtonComponent = connect(mapStateToProps, mapDispatchToProps)(PauseButtonConsumer);

export default ConnectedPauseButtonComponent;
