import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import BillingInfoForm from '../../common/BillingInfoForm';
import { OpenSuccessNotification } from '../../common/Toast/Toast';
import { currencyCountries } from '../../../currencyCountries';

import './PaymentMethodSection.scss';

function BillingInfo(props) {
  const { readCountries } = props;
  //const loading = (props.profile.inProgress && !props.profile.lastUpdate) || !props.country.readList.lastUpdate;

  useEffect(() => {
    readCountries();
  }, [readCountries]);

  const [edit, setEdit] = useState(false);
  const [save, setSave] = useState(false);

  useEffect(() => {
    if (!props.billing.inProgress) {
      if (save) {
        OpenSuccessNotification('Billing info updated!');
        setSave(false);
      }
      setEdit(false);
    }
  }, [props.billing.inProgress, save]);

  if (!props.profile) {
    return null;
  }

  const closeEdit = () => {
    setEdit(false);
  };

  const handleEditBillingInfo = (values) => {
    const billingValues = { name: values.firstName.concat(' ').concat(values.lastName), ...values };
    delete billingValues.firstName;
    delete billingValues.lastName;

    if (billingValues.country !== 'US') {
      billingValues.state = billingValues.region;
      delete billingValues.tax_certificate;
    }

    if (currencyCountries.euCountries.findIndex((code) => code === billingValues.country) === -1) {
      billingValues.vat = '';
    }

    if (billingValues.type === 'P') {
      billingValues.company = '';
      billingValues.vat = '';
      delete billingValues.tax_certificate;
    }

    props.updateBillingInfo(billingValues);
  };

  const wellContent = (
    <BillingInfoForm
      profile={props.profile}
      cancelButtonText="Cancel"
      edit={edit}
      country={props.country}
      certificates={props.certificates}
      onSubmit={handleEditBillingInfo}
      handleCancelInfoEditing={closeEdit}
      billingInfo={props.billing.billingInfo}
      wellContent={true}
      readStates={props.readStates}
      submitButtonText="Update"
      changeButtonText="Change"
      progress={props.billing.inProgress}
      match={props.match}
      location={props.location}
      educationPath={props.educationPath}
      organizations={props.organizations}
      history={props.history}
      billingOrganization={{
        name: '',
        country: '',
      }}
      handleBillingDisabled={() => null}
    />
  );

  return (
    <>
      <div className="info-title">Billing Info</div>
      {wellContent}
    </>
  );
}

BillingInfo.propTypes = {
  billing: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  readCountries: PropTypes.func.isRequired,
  readStates: PropTypes.func.isRequired,
  country: PropTypes.object.isRequired,
  updateBillingInfo: PropTypes.func.isRequired,
  certificates: PropTypes.array,
  subscription: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  educationPath: PropTypes.bool,
  organizations: PropTypes.object,
  history: PropTypes.object,
  billingOrganization: PropTypes.object,
};

export default BillingInfo;
