import { call, put, takeEvery } from 'redux-saga/effects';

import actions from './actions';
import cardTypes from './types';
import * as paymentsAPI from '../../apis/payments';

const readCardList = function* readCardList() {
  yield put(actions.readCardListRequest());
  try {
    const result = yield call(paymentsAPI.readCards);
    yield put(actions.readCardListSuccess(result));
  } catch (error) {
    yield put(actions.readCardListFailure(error));
  }
};

const createCard = function* createCard({ params }) {
  yield put(actions.createCardRequest());
  try {
    const result = yield call(paymentsAPI.createCard, params);
    yield put(actions.createCardSuccess(result));
  } catch (error) {
    yield put(actions.createCardFailure(error));
  }
};

const deleteCard = function* deleteCard({ id }) {
  yield put(actions.deleteCardRequest());
  try {
    const result = yield call(paymentsAPI.deleteCard, id);
    yield put(actions.deleteCardSuccess(result));
  } catch (error) {
    yield put(actions.deleteCardFailure(error));
  }
};

/**
 * Listens for a CARDS_READ action to be dispatched. Once it is dispatched,
 * runs the createCard*() generator to refresh the list of cards
 */
const watchCardListRead = function* watchCardListRead() {
  yield takeEvery(cardTypes.CARD_LIST_READ, readCardList);
};

const watchCreateCard = function* watchCreateCard() {
  yield takeEvery(cardTypes.CARD_CREATE, createCard);
};

const watchDeleteCard = function* watchDeleteCard() {
  yield takeEvery(cardTypes.CARD_DELETE, deleteCard);
};

const watchDeleteCardRefreshCards = function* watchDeleteCardRefreshCards() {
  yield takeEvery(cardTypes.CARD_DELETE_SUCCEEDED, readCardList);
};

export default [watchCardListRead, watchDeleteCard, watchDeleteCardRefreshCards, watchCreateCard];
