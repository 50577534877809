import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import CreateLanding from './CreateLanding';
import CreateThankYou from './CreateThankYou';
export default function CreateRouter({ match: { path } }) {
  return (
    <Switch>
      <Route exact path={path} component={CreateLanding} />
      <Route path={`${path}/purchase-completed`} render={() => <Redirect to={`${path}/thank-you`} />} />
      <Route path={`${path}/thank-you`} component={CreateThankYou} />
      <Route path={`${path}/certification/thank-you`} component={CreateThankYou} />
      <Route path={`${path}/inquiry/thank-you`} component={CreateThankYou} />
      <Route path={`${path}/junior-certification/thank-you`} component={CreateThankYou} />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
}

CreateRouter.propTypes = {
  match: PropTypes.object.isRequired,
};
