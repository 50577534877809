import types from './types';

const initialState = {};

function userRecapReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_USER_RECAP:
      return {
        ...state,
      };
    case types.LOAD_USER_RECAP_SUCCEEDED:
      return {
        ...state,
        userRecap: action.userRecap,
      };
    case types.LOAD_USER_RECAP_FAILED:
      return {
        ...state,
        userRecap: initialState,
      };

    default:
      return state;
  }
}

export default userRecapReducer;
