import { connect } from 'react-redux';

import ThankYou from './ThankYouComponent';

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    plan: state.plan,
    profile: state.authentication.profile.data,
    navigation: state.navigation,
    subscription: state.subscription,
  };
};

const ConnectedThankYou = connect(mapStateToProps)(ThankYou);

export default ConnectedThankYou;
