const INVOICE_LIST_READ = 'app/invoice/INVOICE_LIST_READ';
const INVOICE_LIST_READ_REQUESTED = 'app/invoice/INVOICE_LIST_READ_REQUESTED';
const INVOICE_LIST_READ_SUCCEEDED = 'app/invoice/INVOICE_LIST_READ_SUCCEEDED';
const INVOICE_LIST_READ_FAILED = 'app/invoice/INVOICE_LIST_READ_FAILED';

const INVOICE_LIST_BY_SUBSCRIPTION_READ = 'app/invoice/INVOICE_LIST_BY_SUBSCRIPTION_READ';
const INVOICE_LIST_BY_SUBSCRIPTION_READ_REQUESTED = 'app/invoice/INVOICE_LIST_BY_SUBSCRIPTION_READ_REQUESTED';
const INVOICE_LIST_BY_SUBSCRIPTION_READ_SUCCEEDED = 'app/invoice/INVOICE_LIST_BY_SUBSCRIPTION_READ_SUCCEEDED';
const INVOICE_LIST_BY_SUBSCRIPTION_READ_FAILED = 'app/invoice/INVOICE_LIST_BY_SUBSCRIPTION_READ_FAILED';

export default {
  INVOICE_LIST_READ,
  INVOICE_LIST_READ_REQUESTED,
  INVOICE_LIST_READ_SUCCEEDED,
  INVOICE_LIST_READ_FAILED,
  INVOICE_LIST_BY_SUBSCRIPTION_READ,
  INVOICE_LIST_BY_SUBSCRIPTION_READ_REQUESTED,
  INVOICE_LIST_BY_SUBSCRIPTION_READ_SUCCEEDED,
  INVOICE_LIST_BY_SUBSCRIPTION_READ_FAILED,
};
