import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.INVOICE_LIST_READ_REQUESTED: {
      return {
        ...state,
        byId: [],
        bySubscription: {},
        list: [],
        readList: {
          error: null,
          inProgress: true,
        },
      };
    }
    case types.INVOICE_LIST_READ_SUCCEEDED: {
      const invoices = action.invoices.sort((a, b) => new Date(b) - new Date(a));

      return {
        ...state,
        byId: invoices.reduce(
          (byId, invoice) => ({
            ...byId,
            [invoice.id]: invoice,
          }),
          {}
        ),
        bySubscription: invoices.reduce(
          (bySubscription, invoice) => ({
            // Obscure code to group invoices by subscriptions
            ...bySubscription,
            [invoice.subscription_id]: (bySubscription[invoice.subscription_id] || []).concat(invoice.id),
          }),
          {}
        ),
        list: [...state.list, ...invoices],
        readList: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    }
    case types.INVOICE_LIST_READ_FAILED: {
      return {
        ...state,
        readList: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    }
    case types.INVOICE_LIST_BY_SUBSCRIPTION_READ_REQUESTED: {
      return {
        ...state,
        byId: [],
        bySubscription: {},
        readListBySubscription: {
          error: null,
          inProgress: true,
        },
      };
    }
    case types.INVOICE_LIST_BY_SUBSCRIPTION_READ_SUCCEEDED: {
      const invoices = action.invoices.sort((a, b) => new Date(b) - new Date(a));

      return {
        ...state,
        byId: invoices.reduce(
          (byId, invoice) => ({
            ...byId,
            [invoice.id]: invoice,
          }),
          { ...state.byId }
        ),
        bySubscription: invoices.reduce(
          (bySubscription, invoice) => ({
            // Obscure code to group invoices by subscriptions
            ...bySubscription,
            [invoice.subscription_id]: (bySubscription[invoice.subscription_id] || []).concat(invoice.id),
          }),
          { ...state.bySubscription }
        ),
        readListBySubscription: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    }
    case types.INVOICE_LIST_BY_SUBSCRIPTION_READ_FAILED: {
      return {
        ...state,
        readListBySubscription: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    }
  }
}
