import types from './types';

const readActivationList = (params) => ({
  type: types.ACTIVATION_LIST_READ,
  params,
});

const readActivationListRequest = () => ({
  type: types.ACTIVATION_LIST_READ_REQUESTED,
});

const readActivationListSuccess = (data) => ({
  type: types.ACTIVATION_LIST_READ_SUCCEEDED,
  data,
});

const readActivationListFailure = (error) => ({
  type: types.ACTIVATION_LIST_READ_FAILED,
  error,
});

const readAllActivationList = (params) => ({
  type: types.ALL_ACTIVATION_LIST_READ,
  params,
});

const readAllActivationListRequest = () => ({
  type: types.ALL_ACTIVATION_LIST_READ_REQUESTED,
});

const readAllActivationListSuccess = (data) => ({
  type: types.ALL_ACTIVATION_LIST_READ_SUCCEEDED,
  data,
});

const readAllActivationListFailure = (error) => ({
  type: types.ALL_ACTIVATION_LIST_READ_FAILED,
  error,
});

const readActivationResellersList = (params) => ({
  type: types.ACTIVATION_RESELLERS_LIST_READ,
  params,
});

const readActivationResellersListRequest = () => ({
  type: types.ACTIVATION_RESELLERS_LIST_READ_REQUESTED,
});

const readActivationResellersListSuccess = (data) => ({
  type: types.ACTIVATION_RESELLERS_LIST_READ_SUCCEEDED,
  data,
});

const readActivationResellersListFailure = (data) => ({
  type: types.ACTIVATION_RESELLERS_LIST_READ_FAILED,
  data,
});

const readActivationRead = (activationId) => ({
  type: types.ACTIVATION_READ,
  activationId,
});

const readActivationReadRequest = (subscriptionId) => ({
  type: types.ACTIVATION_READ_REQUESTED,
  subscriptionId,
});

const readActivationReadSuccess = (subscriptionId, data) => ({
  type: types.ACTIVATION_READ_SUCCEEDED,
  subscriptionId,
  data,
});

const readActivationReadFailure = (error) => ({
  type: types.ACTIVATION_READ_FAILED,
  error,
});

const createActivationRedeem = (params) => ({
  type: types.ACTIVATION_CREATE_REDEEM,
  params,
});

const createActivationRedeemRequest = () => ({
  type: types.ACTIVATION_CREATE_REDEEM_REQUESTED,
});

const createActivationRedeemSuccess = (data) => ({
  type: types.ACTIVATION_CREATE_REDEEM_SUCCEEDED,
  data,
});

const createActivationRedeemFailure = (error) => ({
  type: types.ACTIVATION_CREATE_REDEEM_FAILED,
  error,
});

const createEnterpriseUpgradeRequest = (activationId) => ({
  type: types.ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST,
  activationId,
});

const createEnterpriseUpgradeRequestRequest = () => ({
  type: types.ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_REQUESTED,
});

const createEnterpriseUpgradeRequestSuccess = (data) => ({
  type: types.ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_SUCCEEDED,
  data,
});

const createEnterpriseUpgradeRequestFailure = (error) => ({
  type: types.ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_FAILED,
  error,
});

const createEducationUpgradeRequest = (activationId) => ({
  type: types.ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST,
  activationId,
});

const createEducationUpgradeRequestRequest = () => ({
  type: types.ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_REQUESTED,
});

const createEducationUpgradeRequestSuccess = (data) => ({
  type: types.ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_SUCCEEDED,
  data,
});

const createEducationUpgradeRequestFailure = (error) => ({
  type: types.ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_FAILED,
  error,
});

const readAvailableOrganizations = (activationId) => ({
  type: types.ACTIVATION_READ_AVAILABLE_ORGANIZATION,
  activationId,
});

const readAvailableOrganizationsRequest = (activationId) => ({
  type: types.ACTIVATION_READ_AVAILABLE_ORGANIZATION_REQUESTED,
  activationId,
});

const readAvailableOrganizationsSuccess = (data) => ({
  type: types.ACTIVATION_READ_AVAILABLE_ORGANIZATION_SUCCEEDED,
  data,
});

const readAvailableOrganizationsFailure = (error) => ({
  type: types.ACTIVATION_READ_AVAILABLE_ORGANIZATION_FAILED,
  error,
});

export default {
  readActivationList,
  readActivationListRequest,
  readActivationListSuccess,
  readActivationListFailure,
  createActivationRedeem,
  createActivationRedeemRequest,
  createActivationRedeemSuccess,
  createActivationRedeemFailure,
  readActivationRead,
  readActivationReadRequest,
  readActivationReadSuccess,
  readActivationReadFailure,
  readActivationResellersList,
  readActivationResellersListRequest,
  readActivationResellersListSuccess,
  readActivationResellersListFailure,
  readAllActivationList,
  readAllActivationListRequest,
  readAllActivationListSuccess,
  readAllActivationListFailure,
  createEnterpriseUpgradeRequest,
  createEnterpriseUpgradeRequestRequest,
  createEnterpriseUpgradeRequestSuccess,
  createEnterpriseUpgradeRequestFailure,
  createEducationUpgradeRequest,
  createEducationUpgradeRequestRequest,
  createEducationUpgradeRequestSuccess,
  createEducationUpgradeRequestFailure,
  readAvailableOrganizations,
  readAvailableOrganizationsRequest,
  readAvailableOrganizationsSuccess,
  readAvailableOrganizationsFailure,
};
