import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Formik } from 'formik';
import { FREQUENCY_MONTHLY, FREQUENCY_YEARLY } from '../../../utils/utilities';
import ArduinoSlider from '../ArduinoSlider/ArduinoSlider';
import './EnterprisePlanConfiguration.scss';
import { debounce } from 'lodash';
import { getOrganization } from '../../../apis/profile';
import { ReactComponent as Briefcase } from '../../../assets/briefcase.svg';

const FIELD_FREQUENCY = 'frequency';
const FIELD_PLAN_THINGS = 'things';

interface EnterprisePlanConfigurationProps {
  onSubmit: () => void;
  enterprisePlanFrequency: string;
  enterprisePlanThings: number;
  handleUpdateEnterprisePlanFrequency: (value: string) => void;
  handleUpdateEnterprisePlanThings: (value: number) => void;
  handleEnterpriseConfigurePlanCta: (value: boolean) => void;
  spaceId?: string;
}

interface FormBodyProps {
  values: object;
  handleSubmit: () => void;
  setFieldValue: (field: string, value: string) => void;
  handleUpdateEnterprisePlanFrequency: (value: string) => void;
  handleUpdateEnterprisePlanThings: (value: number) => void;
  enterprisePlanFrequency: string;
  enterprisePlanThings: number;
  handleEnterpriseConfigurePlanCta: (value: boolean) => void;
  spaceId?: string;
}

const FormBody = (props: FormBodyProps) => {
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleUpdateEnterprisePlanFrequency,
    handleUpdateEnterprisePlanThings,
    enterprisePlanFrequency,
    enterprisePlanThings,
    handleEnterpriseConfigurePlanCta,
    spaceId,
  } = props;

  const [space, setSpace] = useState(null);

  useEffect(() => {
    if (spaceId) {
      getOrganization(spaceId).then((res) => setSpace(res));
    }
  }, [spaceId]);

  useEffect(() => {
    handleEnterpriseConfigurePlanCta(!values[FIELD_FREQUENCY] || !values[FIELD_PLAN_THINGS]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleSelectFrequency = (value) => {
    setFieldValue(FIELD_FREQUENCY, value);
  };

  const debouncedThings = debounce(async (things) => {
    setFieldValue(FIELD_PLAN_THINGS, things);
  }, 500);

  const handleChangeThings = (things: any) => {
    debouncedThings(things.target.value);
  };

  useEffect(() => {
    if (enterprisePlanFrequency !== values[FIELD_FREQUENCY] || enterprisePlanThings !== values[FIELD_PLAN_THINGS]) {
      handleUpdateEnterprisePlanFrequency(values[FIELD_FREQUENCY]);
      handleUpdateEnterprisePlanThings(values[FIELD_PLAN_THINGS]);
    }
  }, [
    values,
    enterprisePlanThings,
    enterprisePlanFrequency,
    handleUpdateEnterprisePlanFrequency,
    handleUpdateEnterprisePlanThings,
  ]);

  return (
    <form id="form-enterprise-plan" className="form-fields" onSubmit={handleSubmit}>
      <div className="plan">
        {space && (
          <span className="plan__org-name">
            <Briefcase />
            {space.name}
          </span>
        )}
        <div className="plan__frequency">
          <div className="plan-title">Choose billing frequency:</div>
          <div className="frequencies">
            <button
              type="button"
              className={classnames('card', { 'card-selected': values[FIELD_FREQUENCY] === FREQUENCY_MONTHLY })}
              onClick={() => handleSelectFrequency(FREQUENCY_MONTHLY)}
            >
              <div className="card-title">
                <strong>Monthly</strong>
              </div>
              <div className="card-subtitle">Pay once per month, cancel anytime</div>
            </button>
            <button
              type="button"
              className={classnames('card', { 'card-selected': values[FIELD_FREQUENCY] === FREQUENCY_YEARLY })}
              onClick={() => handleSelectFrequency(FREQUENCY_YEARLY)}
            >
              <div className="right">
                <div className="card__left">
                  <div className="card-title">
                    <strong>Yearly</strong>
                  </div>
                  <div className="card-subtitle">Pay once per year</div>
                </div>
                <div className="card__right">
                  <div className="card-box"> Save 15%</div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="plan">
        <div className="plan__frequency">
          <div className="plan-title">Select the number of Things:</div>
          <ArduinoSlider enterprisePlanThings={enterprisePlanThings} handleChangeThings={handleChangeThings} />
        </div>
      </div>
      <div className="contacts">
        Looking for more Devices?{' '}
        <a className="contacts-link" href="https://www.arduino.cc/pro/contact-us" target="_blank" rel="noreferrer">
          Contact us
        </a>{' '}
        for tailored solutions.
      </div>
    </form>
  );
};

export default function EnterprisePlanConfiguration(props: EnterprisePlanConfigurationProps) {
  const { enterprisePlanFrequency, enterprisePlanThings, onSubmit } = props;

  const initialValues = {};

  initialValues[FIELD_FREQUENCY] = enterprisePlanFrequency;
  initialValues[FIELD_PLAN_THINGS] = enterprisePlanThings;
  return (
    <div className="billing-info-form arduino-form">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
        component={(formikProps) => <FormBody {...formikProps} {...props} />}
      />
    </div>
  );
}
