import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { add, format, differenceInMonths } from 'date-fns';

import { STRIPE_PUBLIC_KEY } from '../../../env';
import { subscriptionActions } from '../../../store/subscription';
import { SubscriptionCardContext } from './SubscriptionCardContext';
import CreditCardAddForm from '../CreditCardAddForm/CreditCardAddForm';
import CreditCardInfo from '../CreditCardInfo';
import { OpenSuccessNotification, OpenErrorNotification } from '../Toast/Toast';
import BillingInfo from './BillingInfo';
import Well from '../Well/Well';
import {
  CLOUD_CLASSROOM,
  CLOUD_CREATE,
  CREATE_FREE,
  ENTERPRISE_PRODUCT,
  SPACE_EDUCATION_PLAN,
  SPACE_ENTERPRISE_PLAN,
} from '../../../utils/utilities';
import { ReactComponent as Paper } from '../../../assets/paper.svg';
import classnames from 'classnames';

import './PaymentMethodSection.scss';

class PaymentMethodSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.stateSubscription.updateCard.inProgress &&
      !this.props.stateSubscription.updateCard.inProgress &&
      this.state.edit
    ) {
      if (this.props.stateSubscription.updateCard.error) {
        switch (this.props.stateSubscription.updateCard.error.status) {
          case 400:
            OpenErrorNotification(
              this.props.stateSubscription.updateCard.error.body?.detail ||
                this.props.stateSubscription.updateCard.error.message
            );
            break;
          case 500:
            OpenErrorNotification('An error has occurred while changing the credit card');
            break;
          default:
            OpenErrorNotification('An error has occurred while changing the credit card');
        }
      } else {
        OpenSuccessNotification('Credit card updated successfully');
      }

      this.setState({
        edit: false,
      });
    }
  };

  submitUpdateCard = (stripeToken) => {
    this.props.updateSubscriptionCard(this.props.subscription.id, null, stripeToken.token.id);
  };

  submitUpdateBillingInfo = (billingInfo) => {
    this.props.updateBillingInfo(billingInfo);
    setTimeout(() => {
      OpenSuccessNotification('Your payment method has been updated');
    }, 5000);
  };

  handleChangeCardClick = () => {
    this.setState({ edit: true });
  };

  handleCreditCardFormCancel = () => {
    this.setState({ edit: false });
  };

  render() {
    let content = null;
    const { paymentMethod, voucher } = this.props;
    const { edit } = this.state;

    let activationQuantity = 0;
    let activationType = null;

    const copyActivationId = (text) => {
      navigator.clipboard.writeText(text);
    };

    function calcDuration(duration) {
      if (duration < 12) return duration + ' MONTHS';
      else if (duration === 12) return '1 YEAR';
      else if (duration > 12 && duration % 12 === 0) return duration / 12 + ' YEARS';
      else if (duration > 12 && duration % 12 !== 0) return duration + ' MONTHS';
    }

    let activationReseller = null;
    let activationStartDate = 0;
    let activationEndDate = 0;
    let activationDuration = 0;

    if (this.props.activationId) {
      // It's a subscription activated from Activation Portal
      if (this.props.subscription.activation_id === this.props.activationId) {
        activationQuantity = this.props.subscription.quantity;
        const product = this.props.subscription.product;
        if (product === CLOUD_CLASSROOM) activationType = SPACE_EDUCATION_PLAN;
        if (product === ENTERPRISE_PRODUCT) activationType = SPACE_ENTERPRISE_PLAN;
        if (product === CLOUD_CREATE) activationType = 'maker';

        //Get Reseller
        if (this.props.subscription.reseller_id) {
          this.props.activationList.resellerList.forEach((reseller) => {
            if (reseller.reseller_id === this.props.subscription.reseller_id) {
              activationReseller = reseller.reseller_name;
            }
          });
        }

        if (this.props.subscription.activation_date) {
          const activationDate = new Date(this.props.subscription.activation_date);
          const subscriptionCancelAt = new Date(this.props.subscription.cancel_at);
          activationDate.setHours(0, 0, 0, 0);
          subscriptionCancelAt.setHours(0, 0, 0, 0);

          activationDuration = differenceInMonths(subscriptionCancelAt, activationDate);

          activationStartDate = format(activationDate, 'dd/MM/yyyy');
          activationEndDate = format(add(activationDate, { months: activationDuration }), 'dd/MM/yyyy');
        }
      }
    }

    if (paymentMethod) {
      if (edit) {
        content = (
          <>
            <div className="info-title">Payment Method</div>
            <Well className="payment-method-well" white>
              <CreditCardAddForm
                stripePublicKey={STRIPE_PUBLIC_KEY}
                cancelButtonText={'Cancel'}
                submitButtonText={'Update card'}
                updateCard={true}
                onSubmit={this.submitUpdateCard}
                onCancel={this.handleCreditCardFormCancel}
                submittingProgress={this.props.stateSubscription.updateCard.inProgress}
              />
            </Well>
            <BillingInfo
              billing={this.props.billing}
              profile={this.props.profile}
              certificates={this.props.certificates}
              country={this.props.country}
              readCountries={this.props.readCountries}
              readStates={this.props.readStates}
              updateBillingInfo={(billingInfo) => this.submitUpdateBillingInfo(billingInfo)}
              subscription={this.props.subscription}
              match={this.props.match}
              location={this.props.location}
              educationPath={this.educationPath}
              organizations={this.props.organizations}
              history={this.props.history}
              billingOrganization={{
                name: '',
                country: '',
              }}
            />
          </>
        );
      } else {
        content = (
          <>
            <div className="info-title">Payment Method</div>
            <Well className="payment-method-well" white>
              <CreditCardInfo
                card={paymentMethod}
                icon
                holderName
                lastFour
                rightChildren={
                  <button className="change-card-button" onClick={this.handleChangeCardClick} type="button">
                    {'Change'}
                  </button>
                }
              />
            </Well>
            <BillingInfo
              billing={this.props.billing}
              profile={this.props.profile}
              certificates={this.props.certificates}
              country={this.props.country}
              readCountries={this.props.readCountries}
              readStates={this.props.readStates}
              updateBillingInfo={(billingInfo) => this.submitUpdateBillingInfo(billingInfo)}
              subscription={this.props.subscription}
              match={this.props.match}
              location={this.props.location}
              educationPath={this.educationPath}
              organizations={this.props.organizations}
              history={this.props.history}
              billingOrganization={{
                name: '',
                country: '',
              }}
            />
          </>
        );
      }
    } else if (this.props.plan.id !== CREATE_FREE && this.props.isOwner) {
      //No payment method is only used for trial version, create free plan and Enterprise (purchased with coupon)
      if (this.props.subscription.coupon) {
        content = (
          <>
            {/* <EnterpriseMissingPaymentMethod
              stripePublicKey={STRIPE_PUBLIC_KEY}
              verifyCoupon={this.props.verifyCoupon}
              coupon={this.props.coupon}
              onSubmit={this.submitUpdateCard}
              onCancel={this.handleCreditCardFormCancel}
              submittingProgress={this.props.stateSubscription.updateCard.inProgress}
            /> */}
            <CreditCardAddForm
              stripePublicKey={STRIPE_PUBLIC_KEY}
              cancelButtonText={'Cancel'}
              submitButtonText={'Update card'}
              updateCard={true}
              onSubmit={this.submitUpdateCard}
              onCancel={this.handleCreditCardFormCancel}
              submittingProgress={this.props.stateSubscription.updateCard.inProgress}
            />
            <BillingInfo
              billing={this.props.billing}
              profile={this.props.profile}
              certificates={this.props.certificates}
              country={this.props.country}
              readCountries={this.props.readCountries}
              readStates={this.props.readStates}
              updateBillingInfo={(billingInfo) => this.submitUpdateBillingInfo(billingInfo)}
              subscription={this.props.subscription}
              match={this.props.match}
              location={this.props.location}
              educationPath={this.educationPath}
              organizations={this.props.organizations}
              history={this.props.history}
              billingOrganization={{
                name: '',
                country: '',
              }}
            />
          </>
        );
      } else {
        content = (
          <>
            <div className="info-title">Payment Method</div>
            <Well className="payment-method-well" white>
              <CreditCardAddForm
                stripePublicKey={STRIPE_PUBLIC_KEY}
                cancelButtonText={'Cancel'}
                submitButtonText={'Add card'}
                updateCard={true}
                onSubmit={this.submitUpdateCard}
                onCancel={this.handleCreditCardFormCancel}
                submittingProgress={this.props.stateSubscription.updateCard.inProgress}
              />
            </Well>
          </>
        );
      }
    }

    if (this.props.activationId) {
      content = (
        <div className="subscription-activations__content">
          <div className="subscription-activations__wrapper--left">
            <div className="subscription-activations__plan-details">
              <div className="subscription-activations__sub-title">PLAN DETAILS</div>
              <div className="subscription-activations__container">
                {activationType !== 'maker' && (
                  <div className="subscription-activations__box">{this.props.organizationName}</div>
                )}
                <div className="subscription-activations__box">
                  {calcDuration(activationDuration)} - {activationStartDate} » {activationEndDate}
                </div>
                {activationType !== 'maker' && (
                  <div className="subscription-activations__box">
                    {activationQuantity} {activationType === SPACE_EDUCATION_PLAN ? 'Members' : 'Things'}
                  </div>
                )}
                {/* {!isEduPlan && activation.addons && (
                  <div className="activations__box">
                    <Addons addons={activation.addons} />
                  </div>
                )} */}
              </div>
            </div>
            <div className="subscription-activations__container">
              <div className="subscription-activations__payment-method">
                <div className="subscription-activations__sub-title">PAYMENT METHOD</div>
                {activationReseller ? (
                  <div className="subscription-activations__box">Purchased through {activationReseller}</div>
                ) : (
                  <div className="subscription-activations__box">
                    {voucher ? 'Voucher Arduino Cloud' : 'Purchased through Arduino Sales'}
                  </div>
                )}
              </div>
              <div className="subscription-activations__activation-id">
                <div className="subscription-activations__sub-title">{voucher ? 'VOUCHER CODE' : 'ACTIVATION ID'}</div>
                <div className="subscription-activations__box">
                  <button
                    onClick={() => copyActivationId(this.props.activationId)}
                    className={classnames('subscription-activations__icon', {
                      ['subscription-activations__icon--voucher']: voucher,
                    })}
                  >
                    <span
                      className={classnames('subscription-activations__icon--mr', {
                        ['subscription-activations__voucher']: voucher,
                      })}
                    >
                      {voucher ? voucher : this.props.activationId}
                    </span>
                    <Paper />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return content;
  }
}

PaymentMethodSection.propTypes = {
  paymentMethod: PropTypes.object,
  subscription: PropTypes.object.isRequired,
  stateSubscription: PropTypes.object.isRequired,
  updateSubscriptionCard: PropTypes.func.isRequired,

  billing: PropTypes.object.isRequired,
  profile: PropTypes.object,
  certificates: PropTypes.array,
  country: PropTypes.object,
  readCountries: PropTypes.func,
  readStates: PropTypes.func,
  updateBillingInfo: PropTypes.func.isRequired,
  match: PropTypes.object,
  location: PropTypes.object,
  educationPath: PropTypes.bool,
  organizations: PropTypes.object,
  history: PropTypes.object,
  readOrganizationList: PropTypes.func,
  plan: PropTypes.object,
  isAdmin: PropTypes.bool,
  isOwner: PropTypes.bool,
  coupon: PropTypes.object,
  verifyCoupon: PropTypes.func,
  activationId: PropTypes.string,
  readActivation: PropTypes.func,
  organizationName: PropTypes.string,
  activationList: PropTypes.object,
};

function PaymentMethodSectionConsumer(props) {
  return (
    <SubscriptionCardContext.Consumer>
      {({
        paymentMethod,
        subscription,
        profile,
        billing,
        certificates,
        country,
        updateBillingInfo,
        match,
        location,
        educationPath,
        organizations,
        history,
        readCountries,
        readStates,
        plan,
        isOwner,
        isAdmin,
        isProAdmin,
        coupon,
        verifyCoupon,
        activationId,
        readActivation,
        organizationName,
        activationList,
        voucher,
      }) => {
        return (
          <PaymentMethodSection
            paymentMethod={paymentMethod}
            subscription={subscription}
            profile={profile}
            billing={billing}
            certificates={certificates}
            country={country}
            coupon={coupon}
            verifyCoupon={verifyCoupon}
            updateBillingInfo={updateBillingInfo}
            match={match}
            location={location}
            educationPath={educationPath}
            organizations={organizations}
            history={history}
            readCountries={readCountries}
            readStates={readStates}
            plan={plan}
            isOwner={isOwner}
            isAdmin={isAdmin}
            isProAdmin={isProAdmin}
            activationId={activationId}
            readActivation={readActivation}
            organizationName={organizationName}
            activationList={activationList}
            voucher={voucher}
            {...props}
          />
        );
      }}
    </SubscriptionCardContext.Consumer>
  );
}

const mapStateToProps = (state) => {
  return {
    stateSubscription: state.subscription,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSubscriptionCard: (subscriptionId, cardId, updateSubscriptionCard) =>
      dispatch(subscriptionActions.updateSubscriptionCard(subscriptionId, cardId, updateSubscriptionCard)),
  };
};

const ConnectedPaymentMethod = connect(mapStateToProps, mapDispatchToProps)(PaymentMethodSectionConsumer);

export default ConnectedPaymentMethod;
