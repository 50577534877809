import React from 'react';
import classnames from 'classnames';
import { History } from 'history';
import { Route } from 'react-router';
import {
  ENTERPRISE_PURCHASE_PLAN,
  ENTERPRISE_PURCHASE_ADDONS,
  ENTERPRISE_PURCHASE_BILLING,
  ENTERPRISE_PURCHASE_PAYMENT,
  ENTERPRISE_PURCHASE_ALTERNATIVE_PAYMENT,
} from '../../../utils/utilities';

import './PurchaseBreadcrumbs.scss';
interface EnterpriseBreadcrumbsProps {
  history: History;
  active: string;
  selectPlanPageVisited: boolean;
  addonsPlanPageVisited: boolean;
  billingPageVisited: boolean;
  paymentPageVisited: boolean;
}

export default function EnterpriseBreadcrumbs(props: EnterpriseBreadcrumbsProps) {
  const { active, history, selectPlanPageVisited, addonsPlanPageVisited, billingPageVisited, paymentPageVisited } =
    props;
  return (
    <div className="breadcrumbs">
      <ul>
        <Route>
          <li
            className={classnames('br-elem', {
              active: `${active}` === ENTERPRISE_PURCHASE_PLAN,
              pointer: selectPlanPageVisited,
            })}
            onClick={() => (selectPlanPageVisited ? history.push(ENTERPRISE_PURCHASE_PLAN) : null)}
          >
            <span>{'Plan details'}</span>
          </li>
        </Route>
        <li className="br-elem">
          <span>{'>'}</span>
        </li>
        <Route>
          <li
            className={classnames('br-elem', {
              active: `${active}` === ENTERPRISE_PURCHASE_ADDONS,
              pointer: addonsPlanPageVisited,
            })}
            onClick={() => (addonsPlanPageVisited ? history.push(ENTERPRISE_PURCHASE_ADDONS) : null)}
          >
            <span>{'Integrations'}</span>
          </li>
        </Route>
        <li className="br-elem">
          <span>{'>'}</span>
        </li>
        <Route>
          <li
            className={classnames('br-elem', {
              active: `${active}` === ENTERPRISE_PURCHASE_BILLING,
              pointer: billingPageVisited,
            })}
            onClick={() => (billingPageVisited ? history.push(ENTERPRISE_PURCHASE_BILLING) : null)}
          >
            <span>{'Billing information'}</span>
          </li>
        </Route>
        <li className="br-elem">
          <span>{'>'}</span>
        </li>
        <Route>
          <li
            className={classnames('br-elem', {
              active:
                `${active}` === ENTERPRISE_PURCHASE_PAYMENT || `${active}` === ENTERPRISE_PURCHASE_ALTERNATIVE_PAYMENT,
              pointer: paymentPageVisited,
            })}
            onClick={() => (paymentPageVisited ? history.push(ENTERPRISE_PURCHASE_PAYMENT) : null)}
          >
            <span>{'Payment'}</span>
          </li>
        </Route>
      </ul>
    </div>
  );
}
