const TAXES_CALCULATE = 'app/taxes/TAXES_CALCULATE';
const TAXES_CALCULATE_REQUESTED = 'app/taxes/TAXES_CALCULATE_REQUESTED';
const TAXES_CALCULATE_SUCCEEDED = 'app/taxes/TAXES_CALCULATE_SUCCEEDED';
const TAXES_CALCULATE_FAILED = 'app/taxes/TAXES_CALCULATE_FAILED';
const TAXES_CALCULATE_CLEAR = 'app/taxes/TAXES_CALCULATE_CLEAR';

export default {
  TAXES_CALCULATE,
  TAXES_CALCULATE_REQUESTED,
  TAXES_CALCULATE_SUCCEEDED,
  TAXES_CALCULATE_FAILED,
  TAXES_CALCULATE_CLEAR,
};
