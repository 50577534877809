import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import StackCard from '../../../../common/StackCard';

import { ReactComponent as PaymentIssue } from '../../../../../assets/icons/paymentIssue.svg';
import { SCHOOL_PLAN } from '../../../../../utils/utilities';

//const capitalize = (str) => (str && str.length > 0 ? `${str[0].toUpperCase()}${str.slice(1, str.length)}` : '');

const InvoicesCard = ({ children, loading }) => (
  <StackCard loading={loading}>
    <div className="stack-card-title">
      <div className="transaction-title">{'TRANSACTIONS HISTORY'}</div>
    </div>
    {children}
  </StackCard>
);

InvoicesCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool.isRequired,
};

function Invoices(props) {
  const loading = props.invoice.readList.inProgress || !props.invoice.readList.lastUpdate;

  let cardContent = null;
  let invoicesList = {};
  let invoicesElements = [];

  if (!loading) {
    if (props.invoice.list.length > 0) {
      invoicesList = props.invoice.list.reduce((invoicesList, invoice) => {
        const month = moment(invoice.date).startOf('month').toISOString();
        invoicesList[month] = invoicesList[month] || [];
        invoicesList[month].push(invoice);
        return invoicesList;
      }, {});

      invoicesElements = Object.keys(invoicesList).map((month) => {
        return [
          <div className="month" key={month}>
            <h6>{moment(month).format('MMM YYYY')}</h6>
          </div>,
        ].concat(
          invoicesList[month].map((invoice, index) => {
            const invoicePlan = props.plan.plans[invoice.plan];
            let invoiceProductElement;
            if (invoicePlan) {
              //const invoiceProduct = props.product.products[invoicePlan.productId];
              //const invoicePlanDescription = props.plan.planDescriptions[invoicePlan.planDescription];
              // const planName = invoicePlanDescription?.name.length > 0 ? ` ${capitalize(invoicePlanDescription?.name)}` : '';

              invoiceProductElement = (
                <span className="invoice-plan">
                  {invoicePlan.fullName && invoicePlan.fullName === 'Cloud Classroom Plan'
                    ? SCHOOL_PLAN
                    : invoicePlan.fullName}
                </span>
              );
            } else {
              invoiceProductElement = (
                <span className="invoice-plan">
                  <b>{'-'}</b>
                </span>
              );
            }

            let amount = `${invoice.amount.toFixed(2)} $`;

            if (invoice.paid === false) {
              amount = (
                <span>
                  {`${amount} - UNPAID`}
                  <PaymentIssue />
                </span>
              );
            }

            return (
              <div className="invoice-info" key={index}>
                {invoiceProductElement}
                <span className="invoice-date">{moment(invoice.date).format('LL')}</span>
                <span className="invoice-amount">{amount}</span>
              </div>
            );
          })
        );
      });

      cardContent = <div className="invoices-list">{invoicesElements}</div>;
    } else {
      cardContent = <span>{'No transaction made so far.'}</span>;
    }
  }

  return <InvoicesCard loading={loading}>{cardContent}</InvoicesCard>;
}

Invoices.propTypes = {
  invoice: PropTypes.object.isRequired,
  plan: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};

export default Invoices;
