import { call, put, takeEvery } from 'redux-saga/effects';

import actions from './actions';
import couponTypes from './types';
import * as paymentsApi from '../../apis/payments';

const verifyCoupon = function* verifyCoupon({ coupon, planId }) {
  yield put(actions.verifyCouponRequest());
  try {
    const data = yield call(paymentsApi.validateCoupon, coupon, planId);
    yield put(actions.verifyCouponSuccess(data));
  } catch (error) {
    yield put(actions.verifyCouponFailure(error));
  }
};

const watchVerifyCoupon = function* watchVerifyCoupon() {
  yield takeEvery(couponTypes.COUPON_VERIFY, verifyCoupon);
};

export default [watchVerifyCoupon];
