import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import classnames from 'classnames';

// import { ReactComponent as Amex } from '../../../assets/amex.svg';
// import { ReactComponent as Discover } from '../../../assets/discover.svg';
// import { ReactComponent as Mastercard } from '../../../assets/mastercard.svg';
// import { ReactComponent as Visa } from '../../../assets/visa.svg';
import { withArduinoStripeStyle, withArduinoElementFonts } from '../arduinoStripeHOCs';
import AbsoluteSpinner from '../AbsoluteSpinner';
import { OpenErrorNotification } from '../../common/Toast/Toast';

import './CreditCardAddForm.scss';

const WrappedCardNumberElement = withArduinoStripeStyle(CardNumberElement);
const WrappedCardExpiryElement = withArduinoStripeStyle(CardExpiryElement);
const WrappedCardCVCElement = withArduinoStripeStyle(CardCVCElement);
const WrappedElements = withArduinoElementFonts(Elements);

// Match field names used by Stripe
const FIELD_CARD_NUMBER = 'cardnumber';
const FIELD_CARD_EXPIRY = 'exp-date';
const FIELD_CARD_CVC = 'cvc';

class CreditCardAddForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      showValidation: true,
    };

    this.submitButtonRef = React.createRef();

    if (props.addSubmitHook) {
      props.addSubmitHook(() => this.submitButtonRef.current.click());
    }
  }

  handleChange =
    (field) =>
    ({ error }) => {
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: error,
        },
      });
    };

  /* If NOT ENTERPRISE plan:
   * 1 - If have token and no error, purchase.
   * 2 - If no token and no error, show the error messages.
   * If ENTERPRISE plan:
   * 1 - If have token, no error and no coupon, purchase.
   * 2 - If no token but have error and coupon, purchase without show error messages.
   */
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.props.stripe) {
      this.props.stripe.createToken().then((token) => {
        if (!this.props.enterprisePath && !this.props.educationPath && this.props.isEduUpgrading) {
          if (token && !token.error) {
            this.props.onSubmit(token);
          } else if (token && token.error) {
            // Don't show validation error on snackbar but under the card number field
            if (token.error.type !== 'validation_error') {
              OpenErrorNotification(token.error.message);
            }
          }
        } else {
          //Enterprise || School
          if (
            (token && !token.error && !this.props?.coupon?.data) ||
            (token && token.error && this.props?.coupon?.data && this.props?.coupon.data?.valid)
          ) {
            this.setState({ showValidation: false });
            this.props.onSubmit(token);
          } else {
            OpenErrorNotification(token.error.message);
          }
        }
      });
    } else {
      throw new Error("Stripe.js hasn't loaded yet.");
    }
  };

  handleCreateCard = (event) => {
    event.preventDefault();
    if (this.props.stripe) {
      this.props.stripe.createToken().then((token) => {
        if (token && !token.error) {
          sessionStorage.setItem('newCard', token.token.card.id);
          this.props.onCreateCard(token);
          this.props.elements.getElement('cardNumber')?.clear();
          this.props.elements.getElement('cardExpiry')?.clear();
          this.props.elements.getElement('cardCvc')?.clear();
        } else if (token && token.error) {
          // Don't show validation error on snackbar
          if (token.error.type !== 'validation_error') {
            OpenErrorNotification(token.error.message);
          }
        }
      });
    } else {
      throw new Error("Stripe.js hasn't loaded yet.");
    }
  };

  handleCancel = (event) => {
    event.preventDefault();
    this.props.onCancel();
  };

  render() {
    if (this.props.progress) {
      return (
        <div className="payment-spinner-wrapper">
          <AbsoluteSpinner height={'100%'} white />
        </div>
      );
    }

    return (
      <div className="credit-card-add-form arduino-form">
        {/*
        <div className="card-group">
          <div className={classnames({ 'card-icon': true })}>
            <Amex />
          </div>
          <div className={classnames({ 'card-icon': true })}>
            <Discover />
          </div>
          <div className={classnames({ 'card-icon': true })}>
            <Mastercard />
          </div>
          <div className={classnames({ 'card-icon': true })}>
            <Visa />
          </div>
        </div> */}
        <div className="form-group">
          <div className="form-item">
            <div className="form-label">
              <label htmlFor={FIELD_CARD_NUMBER}>{'Card number'}</label>
            </div>
            <WrappedCardNumberElement
              style={{
                base: {
                  fontWeight: 500,
                  fontSize: '20px',
                  fontSmoothing: 'antialiased',
                },
              }}
              className={classnames({ error: this.state.showValidation ? this.state.errors[FIELD_CARD_NUMBER] : null })}
              onChange={this.handleChange(FIELD_CARD_NUMBER)}
              onReady={this.handleStripeReady}
              disabled={this.props.submittingProgress}
            />
            {this.state.showValidation && (
              <div className="error-message">
                {this.state.errors[FIELD_CARD_NUMBER] && this.state.errors[FIELD_CARD_NUMBER].message}
              </div>
            )}
          </div>
        </div>
        <div className="form-group form-group-6-6">
          <div className="form-item">
            <div className="form-label">
              <label htmlFor={FIELD_CARD_EXPIRY}>{'Expiration date'}</label>
            </div>
            <WrappedCardExpiryElement
              style={{
                base: {
                  fontWeight: 500,
                  fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                  fontSize: '20px',
                  fontSmoothing: 'antialiased',
                },
              }}
              className={classnames({ error: this.state.showValidation ? this.state.errors[FIELD_CARD_EXPIRY] : null })}
              onChange={this.handleChange(FIELD_CARD_EXPIRY)}
              disabled={this.props.submittingProgress}
            />
            {this.state.showValidation && (
              <div className="error-message">
                {this.state.errors[FIELD_CARD_EXPIRY] && this.state.errors[FIELD_CARD_EXPIRY].message}
              </div>
            )}
          </div>
          <div className="form-item">
            <div className="form-label">
              <label htmlFor={FIELD_CARD_CVC}>{'CVC'}</label>
            </div>
            <WrappedCardCVCElement
              style={{
                base: {
                  fontWeight: 500,
                  fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                  fontSize: '20px',
                  fontSmoothing: 'antialiased',
                },
              }}
              className={classnames({ error: this.state.showValidation ? this.state.errors[FIELD_CARD_CVC] : null })}
              onChange={this.handleChange(FIELD_CARD_CVC)}
              disabled={this.props.submittingProgress}
            />
            {this.state.showValidation && (
              <div className="error-message">
                {this.state.errors[FIELD_CARD_CVC] && this.state.errors[FIELD_CARD_CVC].message}
              </div>
            )}
          </div>
        </div>

        {!this.props.firstPayment && (
          <div className="button-container">
            {!this.props.updateCard ? (
              <>
                {/* <button
                  className="reset-button"
                  type="button"
                  onClick={this.handleCancel}
                  disabled={this.props.submittingProgress}
                >
                  {this.props?.cancelButtonText || 'Cancel'}
                </button> */}
                <button
                  className={classnames('submit-button', {
                    busy: this.props.submittingProgress,
                    disabled:
                      this.props.submittingProgress ||
                      !(
                        Object.prototype.hasOwnProperty.call(this.state.errors, 'cardnumber') &&
                        this.state.errors.cardnumber === undefined
                      ) ||
                      !(
                        Object.prototype.hasOwnProperty.call(this.state.errors, 'cvc') &&
                        this.state.errors.cvc === undefined
                      ) ||
                      !(
                        Object.prototype.hasOwnProperty.call(this.state.errors, 'exp-date') &&
                        this.state.errors.expDate === undefined
                      ),
                  })}
                  disabled={
                    this.props.submittingProgress ||
                    !(
                      Object.prototype.hasOwnProperty.call(this.state.errors, 'cardnumber') &&
                      this.state.errors.cardnumber === undefined
                    ) ||
                    !(
                      Object.prototype.hasOwnProperty.call(this.state.errors, 'cvc') &&
                      this.state.errors.cvc === undefined
                    ) ||
                    !(
                      Object.prototype.hasOwnProperty.call(this.state.errors, 'exp-date') &&
                      this.state.errors.expDate === undefined
                    )
                  }
                  onClick={(e) => this.handleCreateCard(e)}
                  type="button"
                  ref={this.submitButtonRef}
                >
                  <span className="ardu-store-button-text">Save</span>
                  <div className="spinner-container">
                    <div className="spinner"></div>
                  </div>
                </button>
              </>
            ) : (
              <>
                <button
                  className="reset-button"
                  type="button"
                  onClick={this.handleCancel}
                  disabled={this.props.submittingProgress}
                >
                  {this.props?.cancelButtonText || 'Cancel'}
                </button>
                <button
                  className={classnames('submit-button', {
                    busy: this.props.submittingProgress,
                  })}
                  disabled={this.props.submittingProgress}
                  onClick={(e) => this.handleSubmit(e)}
                  type="button"
                  ref={this.submitButtonRef}
                >
                  <span className="ardu-store-button-text">Update</span>
                  <div className="spinner-container">
                    <div className="spinner"></div>
                  </div>
                </button>
              </>
            )}
          </div>
        )}

        {!this.props.updateCard && (
          <div className="submit-button-container">
            <button
              className={classnames('reset-button', 'secondary')}
              type="button"
              onClick={this.handleCancel}
              disabled={this.props.submittingProgress}
            >
              {this.props?.cancelButtonText || 'Cancel'}
            </button>
            <button
              className={classnames('submit-button', {
                busy: this.props.submittingProgress,
              })}
              disabled={this.props.submittingProgress}
              onClick={this.handleSubmit}
              type="button"
              ref={this.submitButtonRef}
            >
              <span className="ardu-store-button-text">{this.props.submitButtonText}</span>
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            </button>
          </div>
        )}
      </div>
    );
  }
}

CreditCardAddForm.propTypes = {
  stripe: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  progress: PropTypes.bool,
  submittingProgress: PropTypes.bool,
  addSubmitHook: PropTypes.func,
  onCreateCard: PropTypes.func,
  updateCard: PropTypes.bool,
  firstPayment: PropTypes.bool,
  handleCardCompleted: PropTypes.func,
  enterprisePath: PropTypes.bool,
  educationPath: PropTypes.bool,
  isEduUpgrading: PropTypes.bool,
  coupon: PropTypes.object,
};

const WithStripePaymentForm = injectStripe(CreditCardAddForm);

export default function PaymentComponent({ stripePublicKey, ...props }) {
  return (
    <StripeProvider apiKey={stripePublicKey}>
      <WrappedElements>
        <WithStripePaymentForm {...props} />
      </WrappedElements>
    </StripeProvider>
  );
}

PaymentComponent.propTypes = {
  stripePublicKey: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  progress: PropTypes.bool,
  submittingProgress: PropTypes.bool,
  onCreateCard: PropTypes.func,
  updateCard: PropTypes.bool,
  firstPayment: PropTypes.bool,
  handleCardCompleted: PropTypes.func,
};
