import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from '../../common/Modal/Modal';
import './DeleteCardModal.scss';

function CancelModal(props) {
  const handleSubmit = (event) => {
    event.preventDefault();
    props.onConfirmCancelSubscription();
  };

  const heading = <div className="title">{'Delete credit card'}</div>;

  const fullDescription = (
    <div className="description">
      <p>{'Are you sure you want to delete the credit card?'}</p>
    </div>
  );

  const buttons = (
    <div className="buttons">
      <button className="modal-store-button secondary" type="button" onClick={props.onToggle} disabled={props.busy}>
        {'Cancel'}
      </button>
      <button className={classnames('modal-store-button', { busy: props.busy })} type="button" disabled={props.busy}>
        <span className="ardu-store-button-text" onClick={handleSubmit}>
          {'Yes, delete'}
        </span>
        <div className="spinner-container">
          <div className="spinner" />
        </div>
      </button>
    </div>
  );

  return (
    <Modal
      className="danger delete-card-modal"
      onClose={props.onToggle}
      heading={heading}
      fullDescription={fullDescription}
      buttons={buttons}
      {...props}
    ></Modal>
  );
}

CancelModal.propTypes = {
  busy: PropTypes.bool,
  onConfirmCancelSubscription: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
};

export default CancelModal;
