/**
 * Selects the subscription currently active of a product.
 */
export function selectCurrentSubscription(state, productId) {
  const subscriptionId = state.subscription.byProduct[productId];
  return state.subscription.byId[subscriptionId];
}

/**
 * Selects the configuration for the plan currently active of a product.
 */
export function selectCurrentPlan(state, productId) {
  const subscription = selectCurrentSubscription(state, productId);
  return state.plan.plans[subscription.plan];
}

/**
 * Returns the plan configuration given the plan ID
 */
export function selectPlanById(state, planId) {
  return state.plan.plans[planId];
}
