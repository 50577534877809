import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from '../../../components/common/Modal/Modal';
import config from '../config';

function PauseModal(props) {
  const handleFormSubmit = (event) => {
    event.preventDefault();
    props.onConfirmPauseSubscription();
  };

  const pauseButtonText = props.unpause ? 'Yes, reactivate it' : 'Yes, pause it';

  const modalTitle = props.unpause ? 'Reactivate SIM' : 'Pause subscription';

  const modalSubTitle = props.unpause ? null : <h6>{'Before you go, remember that...'}</h6>;

  const messageText = props.unpause ? (
    <p>{config.planDescriptions.sim_connectivity_plan.resumeSubscriptionMessage}</p>
  ) : (
    <p>{config.planDescriptions.sim_connectivity_plan.pauseSubscriptionMessage}</p>
  );

  const heading = (
    <>
      <h3 id="heading">{modalTitle}</h3>
      {modalSubTitle}
    </>
  );

  const fullDescription = <div id="full_description">{messageText}</div>;

  const buttons = (
    <div className="buttons">
      <button className="modal-store-button secondary" type="button" onClick={props.onToggle} disabled={props.busy}>
        {'Never mind'}
      </button>
      <button
        className={classnames('modal-store-button', { busy: props.busy })}
        type="button"
        onClick={handleFormSubmit}
        disabled={props.busy}
      >
        <span className="ardu-store-button-text">{pauseButtonText}</span>
        <div className="spinner-container">
          <div className="spinner" />
        </div>
      </button>
    </div>
  );

  return (
    <Modal
      className="warning pause-thing"
      onClose={props.onToggle}
      heading={heading}
      fullDescription={fullDescription}
      buttons={buttons}
      {...props}
    ></Modal>
  );
}

PauseModal.propTypes = {
  busy: PropTypes.bool,
  onConfirmPauseSubscription: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  unpause: PropTypes.bool,
};

export default PauseModal;
