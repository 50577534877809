import types from '../types';

const initialState = {
  products: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.PRODUCT_ADD: {
      // Push the product from the action into the products
      // object. Keep only the plans IDs
      return {
        ...state,
        products: {
          ...state.products,
          [action.product.id]: {
            ...action.product,
            plans: {
              ...action.product.plans,
            },
          },
        },
      };
    }

    default:
      return state;
  }
}
