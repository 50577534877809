import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.SUBSCRIPTION_LIST_READ_REQUESTED:
      return {
        ...state,
        list: [],
        readList: {
          error: null,
          inProgress: true,
        },
      };
    case types.SUBSCRIPTION_LIST_READ_SUCCEEDED:
      return {
        ...state,
        list: action.data.map((subscription) => subscription.id),
        byId: action.data.reduce(
          (byId, subscription) => ({
            ...byId,
            [subscription.id]: subscription,
          }),
          {}
        ),
        byProduct: action.data.reduce(
          (byProduct, subscription) => ({
            ...byProduct,
            [subscription.product]: subscription.id,
          }),
          {}
        ),
        byPlan: action.data.reduce(
          (byPlan, subscription) => ({
            ...byPlan,
            [subscription.plan]: subscription.id,
          }),
          {}
        ),
        readList: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.SUBSCRIPTION_LIST_READ_FAILED:
      return {
        ...state,
        readList: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
  }
}
