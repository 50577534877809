import { call, put, takeEvery } from 'redux-saga/effects';

import actions from './actions';
import simTypes from './types';
import * as simAPI from '../../apis/sim';

import subscriptionActions from '../subscription/actions';

const readDevice = function* readDevice({ iccid }) {
  yield put(actions.readDeviceRequest());
  let device;

  try {
    device = yield call(simAPI.readDevice, iccid);
  } catch (error) {
    yield put(actions.readDeviceFailure(error));
  }

  yield put(actions.readDeviceSuccess(device));
};

const updateDevice = function* updateDevice({ iccid, payload }) {
  yield put(actions.updateDeviceRequest());
  let device;

  try {
    device = yield call(simAPI.updateDevice, iccid, payload);
  } catch (error) {
    yield put(actions.updateDeviceFailure(error));
  }

  yield put(actions.updateDeviceSuccess(device));
};

const pauseDevice = function* pauseDevice({ iccid }) {
  yield put(actions.pauseDeviceRequest());
  let device;

  try {
    device = yield call(simAPI.pauseDevice, iccid);
  } catch (error) {
    yield put(actions.pauseDeviceFailure(error));
  }

  yield put(actions.pauseDeviceSuccess(device));
};

const unpauseDevice = function* unpauseDevice({ iccid }) {
  yield put(actions.unpauseDeviceRequest());
  let device;

  try {
    device = yield call(simAPI.unpauseDevice, iccid);
  } catch (error) {
    yield put(actions.unpauseDeviceFailure(error));
  }

  yield put(actions.unpauseDeviceSuccess(device));
};

const verifyIccid = function* verifyIccid({ iccid }) {
  yield put(actions.verifyIccidRequest());
  try {
    const validity = yield call(simAPI.verifyIccid, iccid);
    yield put(actions.verifyIccidSuccess(validity));
  } catch (error) {
    yield put(actions.verifyIccidFailure(error));
  }
};

const refreshDevice = function* ({ device: { iccid } }) {
  yield* readDevice({ iccid });
};

const refreshSubscription = function* () {
  yield put(subscriptionActions.readSubscriptionList());
};

/**
 * Listens for a SIM_DEVICE_READ action to be dispatched. Once it is dispatched,
 * runs the pauseDevice*() generator to add all product plans to redux.
 */
const watchReadSimDevice = function* watchReadSimDevice() {
  yield takeEvery(simTypes.SIM_DEVICE_READ, readDevice);
};

const watchUpdateSimDevice = function* watchUpdateSimDevice() {
  yield takeEvery(simTypes.SIM_DEVICE_UPDATE, updateDevice);
};

const watchUpdateSimDeviceSuccess = function* watchUpdateSimDeviceSuccess() {
  yield takeEvery(simTypes.SIM_DEVICE_UPDATE_SUCCEEDED, refreshDevice);
};

/**
 * Listens for a SIM_DEVICE_PAUSE action to be dispatched. Once it is dispatched,
 * runs the unpauseDevice*() generator to add all product plans to redux.
 */
const watchPauseDevice = function* watchPauseDevice() {
  yield takeEvery(simTypes.SIM_DEVICE_PAUSE, pauseDevice);
};

/**
 * Listens for a SIM_DEVICE_UNPAUSE action to be dispatched. Once it is dispatched,
 * runs the readDevice*() generator to add all product plans to redux.
 */
const watchUnpauseDevice = function* watchUnpauseDevice() {
  yield takeEvery(simTypes.SIM_DEVICE_UNPAUSE, unpauseDevice);
};

/**
 * Listens for a SIM_DEVICE_PAUSE action to be dispatched. Once it is dispatched,
 * runs the refreshDevice*() generator to add all product plans to redux.
 */
const watchPauseDeviceSuccessReadDevice = function* watchPauseDeviceSuccessReadDevice() {
  yield takeEvery(simTypes.SIM_DEVICE_PAUSE_SUCCEEDED, refreshDevice);
};

/**
 * Listens for a SIM_DEVICE_UNPAUSE action to be dispatched. Once it is dispatched,
 * runs the refreshDevice*() generator to add all product plans to redux.
 */
const watchUnpauseDeviceSuccessReadDevice = function* watchUnpauseDeviceSuccessReadDevice() {
  yield takeEvery(simTypes.SIM_DEVICE_UNPAUSE_SUCCEEDED, refreshDevice);
};

const watchUnpauseDeviceSuccessReadSubscriptions = function* watchUnpauseDeviceSuccessReadSubscriptions() {
  yield takeEvery(simTypes.SIM_DEVICE_UNPAUSE_SUCCEEDED, refreshSubscription);
};

const watchVerifyIccid = function* watchVerifyIccid() {
  yield takeEvery(simTypes.SIM_ICCID_VERIFY, verifyIccid);
};

export default [
  watchReadSimDevice,
  watchUpdateSimDevice,
  watchUpdateSimDeviceSuccess,
  watchPauseDevice,
  watchUnpauseDevice,
  watchPauseDeviceSuccessReadDevice,
  watchUnpauseDeviceSuccessReadDevice,
  watchUnpauseDeviceSuccessReadSubscriptions,
  watchVerifyIccid,
];
