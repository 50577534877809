import { PopoverTrigger, TriggerIcon } from '@arduino/arc';
import React, { useState } from 'react';
import { ReactComponent as InfoCheckbox } from '../../../assets/icons/info-checkbox.svg';

import './InfoPopover.scss';

interface Props {
  popoverContent: string;
}

function InfoPopover(props: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const mouseEnterHandler = () => {
    setIsOpen(true);
  };

  const mouseLeaveHandler = () => {
    setIsOpen(false);
  };

  return (
    <span onMouseOver={mouseEnterHandler} onMouseLeave={mouseLeaveHandler}>
      <PopoverTrigger
        isOpen={isOpen}
        TriggerElement={
          <TriggerIcon className="removeButtonStyle info-popover">
            <InfoCheckbox />
          </TriggerIcon>
        }
        isDismissable
        popoverPlacement="bottom-start"
        className="popover-content"
      >
        <span className="select-role__description" dangerouslySetInnerHTML={{ __html: props.popoverContent }} />
      </PopoverTrigger>
    </span>
  );
}

export default InfoPopover;
