export class NetworkError extends Error {
  constructor(response, body) {
    const { status, statusText, url } = response || {};
    super(statusText);

    try {
      Error.captureStackTrace(this, NetworkError);
    } catch (error) {
      // noop
    }

    this.status = status;
    this.statusText = statusText;
    this.url = url;
    this.body = body;
  }
}

export async function defaultJsonResponseHandler(res) {
  if (!res.ok) {
    const body = await res.json();
    throw new NetworkError(res, body);
  }

  return res.json();
}

export async function awsJsonResponseHandler(res) {
  if (!res.ok) {
    const body = await res.json();
    localStorage.setItem('error_message', body.message);
    return body;
  }

  return res.json();
}

export function defaultResponseHandler(res) {
  if (!res.ok) {
    throw new NetworkError(res, null);
  }

  return null;
}
