import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Plus } from '../../../assets/plansConfigurationIcons/Plus.svg';
import { ReactComponent as PlusHover } from '../../../assets/plansConfigurationIcons/PlusHover.svg';
import { ReactComponent as Minus } from '../../../assets/plansConfigurationIcons/Minus.svg';
import { ReactComponent as MinusHover } from '../../../assets/plansConfigurationIcons/MinusHover.svg';
import { ReactComponent as MinusDisabled } from '../../../assets/plansConfigurationIcons/MinusDisabled.svg';

import { CERTIFICATION_PURCHASE_PLAN } from '../../../utils/utilities';

import './SeatsQuantityPicker.scss';

const SeatsQuantityPicker = function SeatsQuantityPicker(props) {
  const { value } = props;

  const minValue = props.minValue || 0;
  const maxValue = props.maxValue || Number.MAX_SAFE_INTEGER;
  const minusDisabled = value === minValue;
  const plusDisabled = value === maxValue;

  const [minusHovering, setMinusHovering] = useState(false);
  const [plusHovering, setPlusHovering] = useState(false);

  const location = useLocation();
  const certificationPath = location.pathname === CERTIFICATION_PURCHASE_PLAN;

  const handleMinusHovering = (value) => {
    setMinusHovering(value);
  };

  const handlePlusHovering = (value) => {
    setPlusHovering(value);
  };

  const handleChange = (e) => {
    if (!e.target.value) {
      e.target.value = value;
      e.target.select();
      return;
    }
    const inputValue = isNaN(parseInt(e.target.value)) ? minValue : parseInt(e.target.value);
    if (inputValue < minValue) {
      return;
    }
    if (inputValue > maxValue || inputValue < minValue) {
      return;
    }
    props.onChange(inputValue);
  };

  const handleClick = (e) => {
    e.target.select();
  };

  return (
    <>
      <div className="seats-quantity-picker">
        <button
          type="button"
          className={classnames('quantity-modifier-minus', { disabled: minusDisabled })}
          onClick={() => {
            props.onChange(value - 1);
          }}
          disabled={minusDisabled}
          onMouseOver={() => handleMinusHovering(true)}
          onMouseOut={() => handleMinusHovering(false)}
        >
          {minusDisabled ? <MinusDisabled /> : minusHovering ? <MinusHover /> : <Minus />}
        </button>
        <div className="placeholder" data-placeholder={certificationPath ? 'Codes' : 'Members'}>
          <input
            className="quantity-input"
            name="quantity-picker"
            type="number"
            min={minValue}
            max={maxValue}
            onChange={handleChange}
            onClick={handleClick}
            value={value}
            autoFocus
          />
        </div>
        <button
          type="button"
          className={classnames('quantity-modifier-plus', { disabled: plusDisabled })}
          onClick={() => {
            props.onChange(value + 1);
          }}
          disabled={plusDisabled}
          onMouseOver={() => handlePlusHovering(true)}
          onMouseOut={() => handlePlusHovering(false)}
        >
          {plusHovering ? <PlusHover /> : <Plus />}
        </button>
      </div>
    </>
  );
};

SeatsQuantityPicker.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
};

export default SeatsQuantityPicker;
