import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Modal, Button, Input, Select, Loader } from '@arduino/arc';
import { ReactComponent as Plus } from '../../../../assets/plansConfigurationIcons/Plus.svg';
import { SPACE_EDUCATION_PLAN, OTHER, SPACE_ENTERPRISE_PLAN, ENTERPRISE_PRODUCT } from '../../../../utils/utilities';
import NotCompatibleComponent from './NotCompatibleComponent';
import './Activations.scss';

function SharedSpaceModal(props) {
  const {
    isOpen,
    onClose,
    isEduPlan,
    redeem,
    organizations,
    activation,
    createOrganization,
    country,
    readOrganizationList,
    readAvailableOrganizations,
    availableOrganizationList,
    activationReadProcess,
    setOrg,
    setCreateRedeem,
  } = props;
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [onLoading, setOnLoading] = useState(false);
  const [isNewOrganization, setIsNewOrganization] = useState(false);
  const [newOrganizationName, setNewOrganizationName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const role = isEduPlan ? SPACE_EDUCATION_PLAN : SPACE_ENTERPRISE_PLAN;

  const newOrganizationDisabled = useMemo(() => activation.duration < 12, [activation.duration]);
  const onFetch = useMemo(() => activationReadProcess.inProgress, [activationReadProcess]);

  useEffect(() => {
    if (isOpen && activation.activation_id) {
      readAvailableOrganizations(activation.activation_id);
    }
  }, [activation.activation_id, isOpen, readAvailableOrganizations]);

  const formattedCountryOption = country.countriesList.map((e) => {
    return { label: e.name, value: e.code };
  });

  const activateDisabled = useMemo(() => {
    if (
      (!selectedOrganization && newOrganizationName === '') ||
      (isNewOrganization && (selectedCountry === '' || newOrganizationName === '')) ||
      onFetch
    )
      return true;
    return false;
  }, [isNewOrganization, newOrganizationName, onFetch, selectedCountry, selectedOrganization]);

  function handleActivate() {
    setOnLoading(true);
    if (isNewOrganization) {
      const organization = {
        name: newOrganizationName,
        type: OTHER,
        country: selectedCountry,
        space: isEduPlan ? SPACE_EDUCATION_PLAN : SPACE_ENTERPRISE_PLAN,
      };
      createOrganization(organization);
      setOrg(activation.activation_id, newOrganizationName);
      setTimeout(() => {
        readOrganizationList();
      }, 2000);
    } else {
      redeem({
        activationId: activation.activation_id,
        organizationId: selectedOrganization.id,
        subscriptionId: selectedOrganization.plan_id,
      });
    }

    setCreateRedeem(true);
    setTimeout(() => {
      setCreateRedeem(false);
    }, 10000);
  }

  function handleNewOrganization() {
    setSelectedOrganization(null);
    setIsNewOrganization(true);
  }

  function handleOrganization(organization) {
    if (!isOrganizationDisabled(organization)) {
      setSelectedOrganization(organization);
    }
    setIsNewOrganization(false);
  }

  const availableOrganizationsId = availableOrganizationList.map((org) => org.organization_id);

  //Not compatibleLogic
  function isOrganizationDisabled(organization) {
    if (activation.product === ENTERPRISE_PRODUCT) {
      return organization.plan_id !== '';
    } else {
      return !availableOrganizationsId.includes(organization.id);
    }
  }

  return (
    <Modal isOpen={isOpen} isDismissable={true} title="Shared Space" onClose={() => onClose()}>
      <div className="modal">
        <h4>{isEduPlan ? 'School Plan' : 'Enterprise Plan'}</h4>
        <div className="modal__description">To get started with your plan, select a Shared Space</div>
        {!onFetch ? (
          <div className="organization">
            {organizations
              ?.filter((institution) => institution.space === role)
              .map((organization) => {
                return (
                  <div
                    className={classnames('organization__wrapper', {
                      'organization--disabled': isOrganizationDisabled(organization),
                    })}
                    key={organization.id}
                    onClick={() => handleOrganization(organization)}
                  >
                    <div className="organization__wrapper--left">
                      <label className="ardu-radio">
                        <input
                          disabled={isOrganizationDisabled(organization)}
                          type="radio"
                          name="use-new-organization"
                          onChange={() => handleOrganization(organization)}
                          onClick={() => handleOrganization(organization)}
                          checked={
                            selectedOrganization?.id === organization.id && !isOrganizationDisabled(organization)
                          }
                        />
                        <span className="custom-radio"></span>
                      </label>
                    </div>
                    <div className="organization__wrapper--right">
                      <h5 className="organization__title">
                        {organization.name}
                        {isOrganizationDisabled(organization) && (
                          <NotCompatibleComponent
                            popupText={'This Shared Space is not eligible for the plan purchased.'}
                          />
                        )}
                      </h5>
                      <div className="organization__members">{organization.members} members</div>
                    </div>
                  </div>
                );
              })}
            {!isNewOrganization ? (
              <div
                className={classnames('organization__wrapper organization__create', {
                  'organization--not-creable': newOrganizationDisabled && isEduPlan,
                })}
                onClick={() => (!newOrganizationDisabled || !isEduPlan ? handleNewOrganization() : null)}
              >
                <div className="organization__wrapper--left">
                  <Plus
                    style={{
                      marginTop: '5px',
                      filter: newOrganizationDisabled && isEduPlan ? 'opacity(50%)' : 'opacity(100%)',
                    }}
                  />
                </div>
                <div className="organization__wrapper--right">
                  SET UP A NEW SHARED SPACE
                  {newOrganizationDisabled && isEduPlan && (
                    <NotCompatibleComponent popupText={'You cannot create an organization for this plan.'} />
                  )}
                </div>
              </div>
            ) : (
              <div
                className="organization__wrapper organization__wrapper--reverse"
                onClick={() => handleNewOrganization()}
              >
                <div className="organization__wrapper--left">
                  <div className="organization__checkbox">
                    <label className="ardu-radio">
                      {organizations.filter((institution) => institution.space === role).length > 0 && (
                        <>
                          <input
                            type="radio"
                            name="use-new-organization"
                            onChange={() => handleNewOrganization()}
                            onClick={() => handleNewOrganization()}
                            checked={selectedOrganization === null}
                          />
                          <span className="custom-radio"></span>
                        </>
                      )}
                      <span>Set up a new Shared Space</span>
                    </label>
                  </div>
                </div>
                <Input
                  className="custom-margin"
                  label="Organization name"
                  value={newOrganizationName}
                  name={'Organization Name'}
                  onChange={(event) => setNewOrganizationName(event.target.value)}
                  required
                />
                <Select
                  className="select-custom-margin"
                  label="Country"
                  placeholder="Select your country"
                  name={'Country'}
                  value={country}
                  options={formattedCountryOption}
                  onChange={(value) => setSelectedCountry(value)}
                  isRequired
                />
              </div>
            )}
          </div>
        ) : (
          <div className="modal__loader">
            <Loader tiny linecolor="#008184" bgcolor="#fff" />
          </div>
        )}
        <div className="modal__buttons">
          <Button type="button" loading={onLoading} isDisabled={activateDisabled} onPress={() => handleActivate()}>
            Activate Plan
          </Button>
        </div>
        <hr />
        <p>
          If you don’t see your Shared Space here or can’t activate the plan,{' '}
          <a
            className="modal__link"
            target="_blank"
            href="https://wiki-content.arduino.cc/en/contact-us/"
            rel="noreferrer"
          >
            contact us.
          </a>
        </p>
      </div>
    </Modal>
  );
}

SharedSpaceModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  isEduPlan: PropTypes.bool,
  redeem: PropTypes.func,
  organizations: PropTypes.array,
  activation: PropTypes.object,
  readActivationList: PropTypes.func,
  readOrganizationList: PropTypes.func,
  setOrg: PropTypes.func,
  availableOrganizationList: PropTypes.array,
  readAvailableOrganizations: PropTypes.func,
  activationReadProcess: PropTypes.object,
  setCreateRedeem: PropTypes.func,
  country: PropTypes.shape({
    countriesList: PropTypes.array,
    readStatesList: PropTypes.object,
    statesList: PropTypes.array,
    readList: PropTypes.object.isRequired,
  }),
};

export default SharedSpaceModal;
