/* eslint-disable no-unused-vars */
import { useCallback } from 'react';

/**
 * Returns a function of type `(args: T) => void` which can be used to track an event
 * with GA4.
 *
 * Basically it's a wrapper around `dataLayer.push`, where we can provide
 *  a function
 * `eventGeneratorFunction` of type `(args: T) => any`
 * to generate the specific event to send to GA4.
 *
 * Usage:
 * ```
 *  const myEventGeneratorFunction = () => ({ event: { name: "button_clicked" } });
 *
 *  const trackThisEvent = useGA4(myEventGeneratorFunction);
 *  trackThisEvent({plan, subscription})
 * ```
 * @param {(args: T) => any } eventGeneratorFunction a function which builds the event to send to GA4
 * @returns a function which will add the event generated by `eventGeneratorFunction` to `window.dataLayer`
 */

function useGA4<T>(eventGeneratorFunction: (args: T) => any) {
  const trackingFunction = useCallback(
    (args: T): void => {
      try {
        const event = eventGeneratorFunction(args);
        if (event) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(event);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error tracking event [${eventGeneratorFunction.name}] with args [${args}]`);
      }
    },
    [eventGeneratorFunction]
  );
  return trackingFunction;
}

export { useGA4 };
