import React, { useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { Modal, Button, Input, Select } from '@arduino/arc';
import { ReactComponent as Plus } from '../../../../assets/plansConfigurationIcons/Plus.svg';
import { OTHER, SPACE_ENTERPRISE_PLAN } from '../../../../utils/utilities';
import './EnterprisePlanRedeemModal.scss';
import NotCompatibleComponent from '../activations/NotCompatibleComponent';

interface EnterprisePlanRedeemModalProps {
  onClose: () => void;
  isOpen: boolean;
  onConfirm: (val: string) => void;
  organizations: Array<any>; //We don't have org entity?
  readOrganizationList: () => void;
  createOrganization: (val: any) => void;
  country: any;
  redeemLoading: boolean;
}

const EnterprisePlanRedeemModal = (props: EnterprisePlanRedeemModalProps): React.ReactElement => {
  const {
    isOpen,
    onClose,
    onConfirm,
    organizations,
    createOrganization,
    readOrganizationList,
    country,
    redeemLoading,
  } = props;
  const [selectedOrganization, setSelectedOrganization] = useState<any>();
  const [onLoading, setOnLoading] = useState(false);
  const [isNewOrganization, setIsNewOrganization] = useState(false);
  const [newOrganizationName, setNewOrganizationName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');

  const formattedCountryOption = country.countriesList.map((e) => {
    return { label: e.name, value: e.code };
  });

  const activateDisabled = useMemo(() => {
    if (
      (!selectedOrganization && newOrganizationName === '') ||
      (isNewOrganization && (newOrganizationName === '' || selectedCountry === '')) ||
      onLoading
    )
      return true;
    return false;
  }, [isNewOrganization, newOrganizationName, onLoading, selectedCountry, selectedOrganization]);

  function handleActivate() {
    setOnLoading(true);
    if (isNewOrganization) {
      const organization = {
        name: newOrganizationName,
        type: OTHER,
        country: selectedCountry,
        space: SPACE_ENTERPRISE_PLAN,
      };
      createOrganization(organization);
      setTimeout(() => {
        readOrganizationList();
        setTimeout(() => {
          setSelectedOrganization(true);
        }, 2000);
      }, 2000);
    } else {
      onConfirm(selectedOrganization?.id);
      setTimeout(() => {
        setOnLoading(redeemLoading);
        setNewOrganizationName('');
        setIsNewOrganization(false);
        setSelectedCountry('');
        setSelectedOrganization(null);
      }, 2000);
    }
  }

  useEffect(() => {
    if (selectedOrganization && isNewOrganization) {
      const choosenOrganization = organizations.find((org) => org.name === newOrganizationName);
      onConfirm(choosenOrganization?.id);
      onClose();
      setSelectedOrganization(null);
      setOnLoading(redeemLoading);
      setSelectedOrganization(null);
      setNewOrganizationName('');
      setIsNewOrganization(false);
    }
  }, [onClose, onConfirm, selectedOrganization, organizations, newOrganizationName, isNewOrganization, redeemLoading]);

  function handleNewOrganization() {
    setSelectedOrganization(null);
    setIsNewOrganization(true);
  }

  function handleOrganization(organization) {
    setSelectedOrganization(organization);
    setIsNewOrganization(false);
  }

  return (
    <Modal isOpen={isOpen} isDismissable title="Shared Space" onClose={() => onClose()} className="enterprise-reedeem">
      <div className="modal">
        <h4>{'Enterprise Plan'}</h4>
        <div className="modal__description">To get started with your plan, select a Shared Space</div>

        <div className="organization">
          {organizations
            ?.filter(
              (institution) =>
                institution.space === SPACE_ENTERPRISE_PLAN &&
                institution.name !== newOrganizationName &&
                institution.roles.includes('role:pro-admin')
            )
            .map((organization) => {
              return (
                <div
                  className={classnames('organization__wrapper', {
                    organization__disabled: organization.plan_id !== '',
                  })}
                  key={organization.id}
                  onClick={() => organization.plan_id === '' && handleOrganization(organization)}
                >
                  <div className="organization__wrapper--left">
                    <label className="ardu-radio">
                      <input
                        type="radio"
                        name="use-new-organization"
                        onChange={() => organization.plan_id === '' && handleOrganization(organization)}
                        onClick={() => organization.plan_id === '' && handleOrganization(organization)}
                        checked={selectedOrganization?.id === organization.id}
                        disabled={organization.plan_id !== ''}
                      />
                      <span className="custom-radio"></span>
                    </label>
                  </div>
                  <div className="organization__wrapper--right">
                    <h5 className="organization__title">{organization.name}</h5>
                    <div className="organization__members">{organization.members} members</div>
                  </div>
                  {organization.plan_id !== '' && (
                    <NotCompatibleComponent popupText="This Shared Space has already a Enterprise Plan associated." />
                  )}
                </div>
              );
            })}
          {!isNewOrganization ? (
            <div
              className={classnames('organization__wrapper organization__create')}
              onClick={() => handleNewOrganization()}
            >
              <div className="organization__wrapper--left">
                <Plus
                  style={{
                    marginTop: '5px',
                    filter: 'opacity(100%)',
                  }}
                />
              </div>
              <div className="organization__wrapper--right">SET UP A NEW SHARED SPACE</div>
            </div>
          ) : (
            <div
              className="organization__wrapper organization__wrapper--reverse"
              onClick={() => handleNewOrganization()}
            >
              <div className="organization__wrapper--left">
                <div className="organization__checkbox">
                  <label className="ardu-radio">
                    {organizations.filter((institution) => institution.space === SPACE_ENTERPRISE_PLAN).length > 0 && (
                      <>
                        <input
                          type="radio"
                          name="use-new-organization"
                          onChange={() => handleNewOrganization()}
                          onClick={() => handleNewOrganization()}
                          checked={selectedOrganization === null}
                        />
                        <span className="custom-radio"></span>
                      </>
                    )}
                    <span>Set up a new Shared Space</span>
                  </label>
                </div>
              </div>
              <Input
                className="custom-margin"
                label="Shared Space name"
                value={newOrganizationName}
                name={'Shared Space Name'}
                onChange={(event) => setNewOrganizationName(event.target.value)}
                required
              />
              <Select
                className="select-custom-margin"
                label="Country"
                placeholder="Select your country"
                name="Country"
                value={country}
                options={formattedCountryOption}
                onChange={(value) => setSelectedCountry(value as string)}
                isRequired
              />
            </div>
          )}
        </div>
        <div className="modal__buttons">
          <Button type="button" loading={onLoading} isDisabled={activateDisabled} onPress={() => handleActivate()}>
            Activate Plan
          </Button>
        </div>
        <hr />
        <p>
          If you don’t see your Shared Space here or can’t activate the plan,{' '}
          <a
            className="modal__link"
            target="_blank"
            href="https://wiki-content.arduino.cc/en/contact-us/"
            rel="noreferrer"
          >
            contact us.
          </a>
        </p>
      </div>
    </Modal>
  );
};

export default EnterprisePlanRedeemModal;
