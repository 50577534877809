import React, { useState } from 'react';
import { Button, Modal } from '@arduino/arc';

import { SubscriptionCardContext } from '../SubscriptionCardContext';
import SendRequest from '../../../../assets/sendRequest.png';

import './ExtendActivation.scss';
import { OpenErrorNotification } from '../../Toast/Toast';

interface ExtendActivationButtonProps {
  isEnterprisePlan: boolean;
  isCloudPlan: boolean;
  activationId: string;
  hasReseller: boolean;
  isActivationUpgradable: boolean;
  createEnterpriseUpgradeRequest: (activationId: string) => void; //TBD - zap hooks
  createEducationUpgradeRequest: (activationId: string) => void;
}

function ExtendActivationButton(props: ExtendActivationButtonProps) {
  const {
    isEnterprisePlan,
    activationId,
    hasReseller,
    isActivationUpgradable,
    isCloudPlan,
    //createEnterpriseUpgradeRequest,
    //createEducationUpgradeRequest,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleUpgradeRequest = () => {
    // if (isEnterprisePlan) {
    //   createEnterpriseUpgradeRequest(activationId);
    // } else {
    //   createEducationUpgradeRequest(activationId);
    // }
    if (isEnterprisePlan) {
      window.location.replace('https://www.arduino.cc/pro/contact-us');
    } else {
      window.location.replace('https://www.arduino.cc/en/contact-us/');
    }
    setIsOpen(false);
    OpenErrorNotification('Request sent successfully');
  };

  if (!activationId) return null;

  return (
    <>
      {!isCloudPlan && (
        <div className="enterprise-banner">
          {hasReseller ? (
            isEnterprisePlan ? (
              <div>To increase the number of things for this plan or extend its duration, contact your reseller.</div>
            ) : (
              <div>To increase the number of members for this plan or extend its duration, contact your reseller.</div>
            )
          ) : isEnterprisePlan ? (
            <div>To increase the number of things or extend the plan, contact us with the button below.</div>
          ) : (
            <div>To increase the number of members or extend the plan, contact us with the button below.</div>
          )}
        </div>
      )}
      {isActivationUpgradable && !hasReseller && (
        <button type="button" className="extend-button" onClick={() => setIsOpen(true)}>
          Upgrade / Extend
        </button>
      )}
      <Modal
        isOpen={isOpen}
        isDismissable
        title={isEnterprisePlan ? 'Enterprise Plan' : isCloudPlan ? 'Individual plan' : 'School Plan'}
        onClose={() => setIsOpen(false)}
        className="extend-modal"
      >
        <div>
          <img src={SendRequest} alt="Send Request img" className="extend-img" />
          <div className="extend-modal__content">
            <h2>Upgrade or extend your plan</h2>
            <p>
              We are willing to help you shape your plan to suit your needs; just notify us and we will get back to you.
            </p>
            <div className="extend-modal__button">
              <Button onPress={handleUpgradeRequest} className="extend-modal__button">
                SEND REQUEST
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default function UpgradeButtonConsumer() {
  return (
    <SubscriptionCardContext.Consumer>
      {({
        isEnterprisePlan,
        isCloudPlan,
        activationId,
        hasReseller,
        isActivationUpgradable,
        createEnterpriseUpgradeRequest,
        createEducationUpgradeRequest,
      }) => (
        <ExtendActivationButton
          isEnterprisePlan={isEnterprisePlan}
          isCloudPlan={isCloudPlan}
          activationId={activationId}
          hasReseller={hasReseller}
          isActivationUpgradable={isActivationUpgradable}
          createEnterpriseUpgradeRequest={createEnterpriseUpgradeRequest}
          createEducationUpgradeRequest={createEducationUpgradeRequest}
        />
      )}
    </SubscriptionCardContext.Consumer>
  );
}
