import React from 'react';
import ArduinoLogo from '../../../assets/arduino-logo.svg';
import Blob1 from '../../../assets/blob1.svg';
import Blob2 from '../../../assets/blob2.svg';

import './Aws.scss';
import { CLOUD_HOME } from '../../../env';

const AwsInfo = function AwsInfo() {
  // After click, login and put it in second page
  return (
    <section className="aws-page">
      <img src={Blob1} alt="blob1" className="aws-page__blob1" />
      <img src={Blob2} alt="blob2" className="aws-page__blob2" />
      <div className="aws-page__content aws-page__content--info">
        <img src={ArduinoLogo} alt="Arduino Logo" className="aws-page__logo" />
        <div className="aws-page__title">This plan has been already redeemed</div>
        <a className="aws-page__button aws-page__button--space" href={CLOUD_HOME}>
          GO TO ARDUINO CLOUD
        </a>
      </div>
    </section>
  );
};

export default AwsInfo;
