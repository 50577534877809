import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as ThankYouImage } from '../../../assets/thank-you.svg';
import { CLOUD_HOME } from '../../../env';
import AbsoluteSpinner from '../../common/AbsoluteSpinner';
import './ThankYouComponent.scss';

const ThankYouComponent = function ThankYouComponent(props) {
  localStorage.removeItem('path');
  const fullName = props.plan.plans[props.cart.plan] ? props.plan.plans[props.cart.plan].fullName : 'Cloud Maker Plan';

  useEffect(() => {
    window.arduinoHeader.renderMenuButtons([]);
  });

  let RedirectButton = null;

  if (!props.redirectButton) {
    switch (props.navigation.source) {
      case 'webide':
        RedirectButton = (
          <a className="box-button" href={props.navigation.redirect}>
            {'Back to the Web Editor'}
          </a>
        );
        break;
      case 'devices':
        RedirectButton = (
          <a className="box-button" href={props.navigation.redirect}>
            {'Back to My Devices'}
          </a>
        );
        break;
      case 'iotcloud':
        RedirectButton = (
          <a className="box-button" href={props.navigation.redirect}>
            {'Back to the IoT Cloud'}
          </a>
        );
        break;
      case 'create':
        RedirectButton = (
          <a className="box-button" href={CLOUD_HOME}>
            {'Back to Arduino Cloud'}
          </a>
        );
        break;
      case 'gettingstarted':
        RedirectButton = (
          <a className="box-button" href={props.navigation.redirect}>
            {'Back to the Getting Started'}
          </a>
        );
        break;
      case 'oplakit':
        RedirectButton = (
          <a className="box-button" href={props.navigation.redirect}>
            {'Back to the Oplà IoT Kit'}
          </a>
        );
        break;
      default:
        RedirectButton = (
          <a className="box-button" href={CLOUD_HOME}>
            {'Back to Arduino Cloud'}
          </a>
        );
    }
  } else {
    RedirectButton = props.redirectButton;
  }

  const title = props.title || <h1 className="title">{'Thank you for subscribing!'}</h1>;

  let subtitle;
  if (props.subtitle) {
    const email = props.profile.contact ? props.profile.contact.email : 'your account email address';
    const subscription = props.subscription || null;
    subtitle = props.subtitle(email, subscription);
  } else {
    subtitle = (
      <div className="subtitle">
        <h3>
          {'Your purchase was successful.'} <br /> {`Your access to ${fullName} has been granted!`}
        </h3>
      </div>
    );
  }

  if (props.subscription && props.subscription.readList.inProgress) {
    return <AbsoluteSpinner height={'100%'} clouds />;
  }

  return (
    <div className={classnames('thank-you-page', { center: !props.banners })}>
      <div className="thank-you-page-container">
        <div className="thank-you-box-container">
          <div className="thank-you-box">
            <ThankYouImage />
            {title}
            {subtitle}
            {RedirectButton}
          </div>
        </div>
        <div className="thank-you-layout-100">
          <div className="thank-you-layout-box">
            <div className="thank-you-wrapper">
              <div className="banner-box-container">{props.banners}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ThankYouComponent.propTypes = {
  cart: PropTypes.object,
  plan: PropTypes.object,
  profile: PropTypes.object,
  subscription: PropTypes.object,
  navigation: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.func,
  redirectButton: PropTypes.element,
  banners: PropTypes.element,
};

export default ThankYouComponent;
