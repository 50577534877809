import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SubscriptionCardContext } from '../../../components/common/SubscriptionCard/SubscriptionCardContext';
import AbsoluteSpinner from '../../../components/common/AbsoluteSpinner';
import SimInfoModal from '../SimInfoModal/SimInfoModal';
import SimEditModal from '../SimEditModal/SimEditModal';
import { ReactComponent as PencilIcon } from '../../../assets/pencil_icon.svg';

import './InfoPanel.scss';

function InfoPanel(props) {
  const { sim, subscription } = props;

  const [openShowDialog, setOpenShowDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  let details = <AbsoluteSpinner white height="100%" />;

  if (!sim.read.inProgress && sim.byIccid[subscription.iccid]) {
    const selectedSim = sim.byIccid[subscription.iccid];
    details = (
      <ul>
        <li className="sim-info-row">
          {'Name'} <span className="value">{selectedSim.name}</span>
          <div className="spacer"></div>
          <button className="edit-modal-icon" onClick={() => setOpenEditDialog(true)} type="button">
            <PencilIcon className="close-icon" />
          </button>
        </li>
        <li className="sim-info-row">
          {'APN'} <span className="value">{'prepay.pelion'}</span>
        </li>
        <li className="sim-info-row">
          {'Traffic available'}{' '}
          <span className="value">
            {selectedSim.remaining_data ? Math.round((selectedSim.remaining_data / 1000000) * 10) / 10 : 0}
            {' MB'}
          </span>
        </li>
      </ul>
    );
  }

  return (
    <>
      <div className="sim-info">
        <h5>{'SIM INFO AND USAGE'}</h5>
        {details}
      </div>
      <button className="show-all-link" onClick={() => setOpenShowDialog(true)}>
        {'SHOW ALL'}
      </button>
      <div className="spacer-side"></div>
      <SimInfoModal
        open={openShowDialog}
        onToggle={() => setOpenShowDialog(false)}
        simData={sim.byIccid[subscription.iccid]}
      />
      <SimEditModal
        open={openEditDialog}
        onToggle={() => setOpenEditDialog(false)}
        simData={sim.byIccid[subscription.iccid]}
      />
    </>
  );
}

InfoPanel.propTypes = {
  sim: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
};

function InfoPlanConsumer(props) {
  return (
    <SubscriptionCardContext.Consumer>
      {({ subscription }) => <InfoPanel {...props} subscription={subscription} />}
    </SubscriptionCardContext.Consumer>
  );
}

InfoPlanConsumer.propTypes = {
  sim: PropTypes.object.isRequired,
  subscription: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    sim: state.sim,
  };
};

const ConnectedInfoPanelComponent = connect(mapStateToProps)(InfoPlanConsumer);

export default ConnectedInfoPanelComponent;
