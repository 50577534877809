const PLAN_ADD = 'app/plan/PLAN_ADD';
const PLAN_DESCRIPTION_ADD = 'app/plan/PLAN_DESCRIPTION_ADD';
const PLAN_FEATURES_ADD = 'app/plan/PLAN_FEATURES_ADD';
const PLAN_TRIAL_READ = 'app/card/PLAN_FREE_TRIAL_READ';
const PLAN_TRIAL_READ_REQUESTED = 'app/card/PLAN_TRIAL_READ_REQUESTED';
const PLAN_TRIAL_READ_SUCCEEDED = 'app/card/PLAN_TRIAL_READ_SUCCEEDED';
const PLAN_TRIAL_READ_FAILED = 'app/card/PLAN_TRIAL_READ_FAILED';
const PLAN_TRIAL_CLEAR = 'app/card/PLAN_TRIAL_CLEAR';

export default {
  PLAN_ADD,
  PLAN_DESCRIPTION_ADD,
  PLAN_FEATURES_ADD,
  PLAN_TRIAL_READ,
  PLAN_TRIAL_READ_REQUESTED,
  PLAN_TRIAL_READ_SUCCEEDED,
  PLAN_TRIAL_READ_FAILED,
  PLAN_TRIAL_CLEAR,
};
