import readList from './readList';
import create from './create';
import deleteCard from './delete';

const initialState = {
  list: [],
  byId: {},
  readList: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  create: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  delete: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
};

export default function (state = initialState, action) {
  return readList(state, action) || deleteCard(state, action) || create(state, action) || state;
}
