import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import { Formik } from 'formik';
import SeatsQuantityPicker from '../SeatsQuantityPicker';
import { CERTIFICATION_PLAN } from '../../../utils/utilities';
import './CertificationPlanConfiguration.scss';

const FIELD_SEATS = 'seats';

interface CertificationPlanConfigurationProps {
  onSubmit: () => void;
  onViewButtonClick: () => void;
  changeQuantityHandler: () => void;
  examCodes: number;
  certificationType: string;
}

interface FormBodyProps {
  values: object;
  handleSubmit: () => void;
  setFieldValue: (field: string, value: number) => void;
  changeQuantityHandler: (value: number) => void;
  certificationType: string;
}

const FormBody = (props: FormBodyProps) => {
  const { values, handleSubmit, setFieldValue, changeQuantityHandler, certificationType } = props;

  const [quantity, setQuantity] = useState(values[FIELD_SEATS]);

  const fundamentalsCertification = useMemo(() => certificationType === CERTIFICATION_PLAN, [certificationType]);

  const handleQuantityUpdate = (value) => {
    setQuantity(value);
    setFieldValue(FIELD_SEATS, value);
    changeQuantityHandler(value);
  };

  return (
    <form id="certification-configuration-plan" className="form-fields" onSubmit={handleSubmit}>
      <div className="plan">
        <div className="plan-title">Price changes based on the number of codes you purchase:</div>
        <div className="plan-seats">
          <div className={classnames('box', { active: values[FIELD_SEATS] <= 99 })}>
            <div className="title">Up to 99</div>
            <div className="price">
              <span className="dollars">$ {fundamentalsCertification ? 30 : 20}</span> / exam
            </div>
          </div>
          <div className={classnames('box', { active: values[FIELD_SEATS] >= 100 && values[FIELD_SEATS] <= 249 })}>
            <div className="title">From 100 to 249</div>
            <div className="price">
              <span className="dollars">$ {fundamentalsCertification ? (28.5).toFixed(2) : 19}</span> / exam
            </div>
          </div>
          <div className={classnames('box', { active: values[FIELD_SEATS] >= 250 })}>
            <div className="title">from 250 +</div>
            <div className="price">
              <span className="dollars">$ {fundamentalsCertification ? 27 : 18}</span> / exam
            </div>
          </div>
        </div>

        <div className="plan-title">Select the number of exam codes you want to purchase:</div>
        <SeatsQuantityPicker value={quantity} minValue={1} maxValue={9999} onChange={(e) => handleQuantityUpdate(e)} />
      </div>
    </form>
  );
};

export default function CertificationPlanConfiguration(props: CertificationPlanConfigurationProps) {
  const { examCodes, onSubmit } = props;
  const initialValues = {};
  initialValues[FIELD_SEATS] = examCodes;

  return (
    <div className="billing-info-form arduino-form">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
        component={(formikProps) => <FormBody {...formikProps} {...props} />}
      />
    </div>
  );
}
