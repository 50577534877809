import React, { useState } from 'react';
import { Snackbar } from '@arduino/arc';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SharedSpaceModal from './SharedSpaceModal';
import { ReactComponent as Paper } from '../../../../assets/paper.svg';
import { differenceInDays, add, subDays, format } from 'date-fns';
import { CLOUD_CLASSROOM, CLOUD_CREATE, ENTERPRISE_ADDON_X8, ENTERPRISE_PRODUCT } from '../../../../utils/utilities';
import RedeemModal from './RedeemModal';
import fetch from '../../../../utils/authenticatedFetch';

import './Activations.scss';
import { defaultJsonResponseHandler } from '../../../../apis/utils';
import { ACTIVATION_API_URL } from '../../../../env';

const ACTIVATIONS_API = `${ACTIVATION_API_URL}/activation/v1`;
function Addons({ addons }) {
  return addons?.map((addon) => (
    <div key={addon.addon_id}>
      {addon.addon_id.includes(ENTERPRISE_ADDON_X8) ? 'Portenta X8 Board Manager' : 'Machine Learning Tools'} -{' '}
      {addon.quantity} Things
    </div>
  ));
}

const ActivationsWrapperComponent = function ActivationsComponent(props) {
  const {
    activation,
    redeem,
    organizations,
    readActivationList,
    createOrganization,
    country,
    readOrganizationList,
    setOrg,
    resellers,
    readAvailableOrganizations,
    availableOrganizationList,
    activationReadProcess,
    setCreateRedeem,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRedeemModal, setIsOpenRedeemModal] = useState(false);
  const [isRedeemInProgress, setIsRedeemInProgress] = useState(false);

  const planType = () => {
    switch (activation.product) {
      case CLOUD_CLASSROOM:
        return 'edu';
      case ENTERPRISE_PRODUCT:
        return 'pro';
      case CLOUD_CREATE:
      default:
        return 'maker';
    }
  };

  const getTitle = () => {
    switch (activation.product) {
      case CLOUD_CLASSROOM:
        return 'School plan';
      case ENTERPRISE_PRODUCT:
        return 'Enterprise plan';
      case CLOUD_CREATE:
        if (activation.plan_id.includes('makerplus')) {
          return 'Maker plus plan';
        } else if (activation.plan_id.includes('entry')) {
          return 'Entry plan';
        } else {
          return 'Maker plan';
        }
      default:
        return '-';
    }
  };

  const getBorderColor = () => {
    switch (activation.product) {
      case CLOUD_CLASSROOM:
        return '#f39c12';
      case ENTERPRISE_PRODUCT:
        return '#000';
      case CLOUD_CREATE:
      default:
        return '#008184';
    }
  };

  const activationDate = new Date(activation.activation_date);
  activationDate.setHours(0, 0, 0, 0);

  const dayPassed = differenceInDays(new Date(), activationDate);
  const dayLeft = differenceInDays(subDays(add(new Date(), { months: 12 }), dayPassed), new Date());

  const startDate = format(activationDate, 'dd/MM/yyyy');
  const endDate = format(add(activationDate, { months: activation.duration }), 'dd/MM/yyyy');

  let resellerName = null;
  resellers.forEach((reseller) => {
    if (reseller.reseller_id === activation.reseller_id) {
      resellerName = reseller.reseller_name;
    }
  });

  function calcDuration() {
    if (activation.duration < 12) return activation.duration + ' MONTHS';
    else if (activation.duration === 12) return '1 YEAR';
    else if (activation.duration > 12 && activation.duration % 12 === 0) return activation.duration / 12 + ' YEARS';
    else if (activation.duration > 12 && activation.duration % 12 !== 0) return activation.duration + ' MONTHS';
  }

  const duration = calcDuration();

  function handleUpgrade() {
    setIsOpen(true);
  }

  function openRedeemModal() {
    setIsOpenRedeemModal(true);
  }

  const redeemSubscription = async () => {
    const res = await fetch(`${ACTIVATIONS_API}/plans/redeem/${activation.activation_id}`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: { 'Content-Type': 'application/json' },
    });

    return await defaultJsonResponseHandler(res);
  };
  function activateMakerPlan() {
    setIsRedeemInProgress(true);
    redeemSubscription().then(() => {
      setIsOpenRedeemModal(false);
      setCreateRedeem(true);
      setIsRedeemInProgress(false);
      setTimeout(() => {
        setCreateRedeem(false);
      }, 10000);
    });
  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const copyActivationId = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbarOpen(true);
  };

  return (
    <div className="activations__wrapper" style={{ borderLeftColor: getBorderColor() }}>
      <div className="activations__title">
        {getTitle()}
        {dayLeft <= 10 && <div className="activations__time">{dayLeft} days left</div>}
      </div>
      <div className="activations__content">
        <div className="activations__wrapper--left">
          <div className="activations__plan-details">
            <h6 className="activations__sub-title">PLAN DETAILS</h6>
            <div className="activations__container">
              <div className="activations__box">
                {duration} - {startDate} » {endDate}
              </div>
              {planType() !== 'maker' && (
                <>
                  <div className="activations__box">
                    {activation.quantity} {planType() === 'edu' ? 'Members' : 'Things'}
                  </div>
                  {planType() !== 'edu' && activation.addons && (
                    <div className="activations__box">
                      <Addons addons={activation.addons} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="activations__container">
            <div className="activations__payment-method">
              <h6 className="activations__sub-title">PAYMENT METHOD</h6>
              <div className="activations__box">
                {activation.reseller_id ? 'Purchased through ' + resellerName : 'Purchased through Arduino sales'}
              </div>
            </div>
            <div className="activations__activation-id">
              <h6 className="activations__sub-title">ACTIVATION ID</h6>
              <div className="activations__box">
                <button onClick={() => copyActivationId(activation.activation_id)} className="activations__icon">
                  <span className="subscription-activations__icon--mr">{activation.activation_id}</span>
                  <Paper />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="activations__wrapper--right">
          {planType() === 'maker' ? (
            <button
              id="redeem-button"
              type="button"
              className={classnames('activations__cta')}
              onClick={openRedeemModal}
            >
              START PLAN USAGE
            </button>
          ) : (
            <button
              id="update-button"
              type="button"
              className={classnames('activations__cta')}
              onClick={() => handleUpgrade()}
              data-testid={activation.activation_id}
            >
              ASSIGN SHARED SPACE
            </button>
          )}
        </div>
      </div>
      <RedeemModal
        isOpen={isOpenRedeemModal}
        onClose={() => setIsOpenRedeemModal(false)}
        activatePlan={activateMakerPlan}
        planId={activation?.plan_id}
        isRedeemInProgress={isRedeemInProgress}
        startDate={startDate}
        endDate={endDate}
      />
      <SharedSpaceModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        isEduPlan={planType() === 'edu'}
        redeem={redeem}
        organizations={organizations}
        activation={activation}
        readActivationList={readActivationList}
        createOrganization={createOrganization}
        country={country}
        readOrganizationList={readOrganizationList}
        readAvailableOrganizations={readAvailableOrganizations}
        availableOrganizationList={availableOrganizationList}
        setOrg={setOrg}
        activationReadProcess={activationReadProcess}
        setCreateRedeem={setCreateRedeem}
      />
      <Snackbar
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        isOpen={snackbarOpen}
        message="Activation ID copied in clipboard"
        onClose={() => setSnackbarOpen(false)}
        theme="success"
        closeable
        className="custom-snackbar"
      />
    </div>
  );
};

ActivationsWrapperComponent.propTypes = {
  activation: PropTypes.object,
  redeem: PropTypes.func,
  organizations: PropTypes.array,
  readActivationList: PropTypes.func,
  createOrganization: PropTypes.func,
  readOrganizationList: PropTypes.func,
  setOrg: PropTypes.func,
  resellers: PropTypes.array,
  availableOrganizationList: PropTypes.array,
  readAvailableOrganizations: PropTypes.func,
  activationReadProcess: PropTypes.object,
  setCreateRedeem: PropTypes.func,
  country: PropTypes.shape({
    countriesList: PropTypes.array,
    readStatesList: PropTypes.object,
    statesList: PropTypes.array,
    readList: PropTypes.object.isRequired,
  }),
};

export default ActivationsWrapperComponent;
