import * as React from 'react';
import Slider from '@mui/material/Slider';
import './ArduinoSlider.scss';
import { marksEnterpriseThing } from '../../../utils/utilities';

function valuetext(value: number) {
  return `${value}`;
}

interface ArduinoSliderProps {
  devicesNumber: number;
  enterprisePlanThings: number;
  handleChangeThings: (e: any) => any;
}

export default function ArduinoSlider(props: ArduinoSliderProps) {
  const { enterprisePlanThings, devicesNumber } = props;
  return (
    <div className="slider-container">
      <Slider
        aria-label="Always visible"
        defaultValue={devicesNumber}
        getAriaValueText={valuetext}
        step={50}
        marks={marksEnterpriseThing}
        min={enterprisePlanThings === 50 ? 0 : 50}
        disabled={enterprisePlanThings === 50 ? true : false}
        max={enterprisePlanThings}
        onChange={props.handleChangeThings}
      />
    </div>
  );
}
