import React from 'react';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';

import { SubscriptionCardContext } from './SubscriptionCardContext';

function DowngradeButton({ product, plan }) {
  if (plan.downgradeable) {
    let url = '';
    if (product.choosePlanContainerId) {
      url = `/${product.id}#${product.choosePlanContainerId}`;
    } else {
      url = `/${product.id}`;
    }
    return (
      <Link to={url} className="downgrade-button stack-card-action-button" type="button">
        {'DOWNGRADE PLAN'}
      </Link>
    );
  }

  return null;
}

DowngradeButton.propTypes = {
  plan: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};

export default function DowngradeButtonConsumer() {
  return (
    <SubscriptionCardContext.Consumer>
      {({ product, plan }) => <DowngradeButton product={product} plan={plan} />}
    </SubscriptionCardContext.Consumer>
  );
}
