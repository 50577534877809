import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Toast.scss';

export function ConfigToast() {
  toast.configure({
    autoClose: 7000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: false,
    rtl: false,
    pauseOnVisibilityChange: false,
    draggable: false,
    pauseOnHover: false,
  });
}

const CloseButton = ({ closeToast }) => (
  <span className="close-icon" onClick={closeToast}>
    {'×'}
  </span>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

export function OpenSuccessNotification(message) {
  toast.dismiss();
  toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    className: 'new-toast success',
    bodyClassName: 'toast-body',
    closeButton: <CloseButton />,
    icon: false,
  });
}

export function OpenWarningNotification(message) {
  toast.dismiss();
  toast.success(message, {
    position: toast.POSITION.BOTTOM_CENER,
    className: 'new-toast warning',
    bodyClassName: 'toast-body',
    closeButton: <CloseButton />,
    icon: false,
  });
}

export function OpenErrorNotification(message) {
  toast.dismiss();
  toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    className: 'new-toast error',
    bodyClassName: 'toast-body',
    closeButton: <CloseButton />,
    icon: false,
  });
}

const CloseSnackbarButton = ({ closeToast }) => (
  <span className="close-icon" onClick={closeToast}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M15.6213 14.3787C15.7033 14.4601 15.7684 14.5569 15.8128 14.6635C15.8572 14.7701 15.8801 14.8845 15.8801 15C15.8801 15.1155 15.8572 15.2299 15.8128 15.3365C15.7684 15.4431 15.7033 15.5399 15.6213 15.6212C15.5399 15.7033 15.4431 15.7684 15.3365 15.8128C15.2299 15.8572 15.1155 15.8801 15 15.8801C14.8845 15.8801 14.7701 15.8572 14.6635 15.8128C14.5569 15.7684 14.4601 15.7033 14.3788 15.6212L8.00001 9.2425L1.62126 15.6212C1.53991 15.7033 1.44314 15.7684 1.33651 15.8128C1.22989 15.8572 1.11552 15.8801 1.00001 15.8801C0.884497 15.8801 0.77013 15.8572 0.663503 15.8128C0.556876 15.7684 0.4601 15.7033 0.378758 15.6212C0.296745 15.5399 0.23165 15.4431 0.187228 15.3365C0.142805 15.2299 0.119934 15.1155 0.119934 15C0.119934 14.8845 0.142805 14.7701 0.187228 14.6635C0.23165 14.5569 0.296745 14.4601 0.378758 14.3787L6.75751 8L0.378758 1.62125C0.297174 1.53966 0.232458 1.44281 0.188306 1.33622C0.144153 1.22962 0.121427 1.11537 0.121427 0.999998C0.121427 0.884621 0.144153 0.770374 0.188306 0.66378C0.232458 0.557186 0.297174 0.460332 0.378758 0.378748C0.460341 0.297164 0.557195 0.232449 0.663789 0.188296C0.770384 0.144143 0.884631 0.121418 1.00001 0.121418C1.11538 0.121418 1.22963 0.144143 1.33623 0.188296C1.44282 0.232449 1.53967 0.297164 1.62126 0.378748L8.00001 6.7575L14.3788 0.378748C14.5435 0.213982 14.767 0.121418 15 0.121418C15.233 0.121418 15.4565 0.213982 15.6213 0.378748C15.786 0.543514 15.8786 0.766984 15.8786 0.999998C15.8786 1.23301 15.786 1.45648 15.6213 1.62125L9.24251 8L15.6213 14.3787Z" />
    </svg>
  </span>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

export function OpenSuccessSnackbar(message) {
  toast.dismiss();
  toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    className: 'snackbar success',
    bodyClassName: 'snackbar-body',
    closeButton: <CloseSnackbarButton />,
    icon: () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path
          d="M11 0.5C8.9233 0.5 6.89323 1.11581 5.16652 2.26957C3.4398 3.42332 2.09399 5.0632 1.29927 6.98182C0.504549 8.90045 0.296614 11.0116 0.701759 13.0484C1.1069 15.0852 2.10693 16.9562 3.57538 18.4246C5.04383 19.8931 6.91476 20.8931 8.95156 21.2982C10.9884 21.7034 13.0996 21.4955 15.0182 20.7007C16.9368 19.906 18.5767 18.5602 19.7304 16.8335C20.8842 15.1068 21.5 13.0767 21.5 11C21.5 8.21523 20.3938 5.54451 18.4246 3.57538C16.4555 1.60625 13.7848 0.5 11 0.5ZM16.7825 8.5325L10.0325 15.2825C9.96278 15.3528 9.87983 15.4086 9.78844 15.4467C9.69704 15.4847 9.59901 15.5043 9.5 15.5043C9.401 15.5043 9.30297 15.4847 9.21157 15.4467C9.12018 15.4086 9.03723 15.3528 8.9675 15.2825L5.2175 11.5325C5.07628 11.3913 4.99694 11.1997 4.99694 11C4.99694 10.8003 5.07628 10.6087 5.2175 10.4675C5.35873 10.3263 5.55028 10.2469 5.75 10.2469C5.94973 10.2469 6.14128 10.3263 6.2825 10.4675L9.5 13.6925L15.7175 7.4675C15.8587 7.32627 16.0503 7.24693 16.25 7.24693C16.4497 7.24693 16.6413 7.32627 16.7825 7.4675C16.9237 7.60873 17.0031 7.80027 17.0031 8C17.0031 8.19973 16.9237 8.39127 16.7825 8.5325Z"
          fill="#F7F9F9"
        />
      </svg>
    ),
  });
}

export function OpenWarningSnackbar(message) {
  toast.dismiss();
  toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    className: 'snackbar warning',
    bodyClassName: 'snackbar-body',
    closeButton: <CloseSnackbarButton />,
    icon: () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
        <path
          d="M21.1925 19.6625L11.6675 0.9125C11.6046 0.789649 11.509 0.68655 11.3912 0.614558C11.2734 0.542565 11.138 0.504471 11 0.504471C10.862 0.504471 10.7266 0.542565 10.6088 0.614558C10.4911 0.68655 10.3955 0.789649 10.3325 0.9125L0.807512 19.6625C0.741686 19.7765 0.707031 19.9058 0.707031 20.0375C0.707031 20.1692 0.741686 20.2985 0.807512 20.4125C0.879512 20.5222 0.979053 20.6112 1.09619 20.6704C1.21332 20.7296 1.34395 20.7571 1.47501 20.75H20.525C20.6532 20.7495 20.7791 20.7161 20.8907 20.6531C21.0023 20.59 21.0959 20.4995 21.1625 20.39C21.2289 20.2813 21.2665 20.1574 21.2717 20.0301C21.277 19.9029 21.2497 19.7763 21.1925 19.6625ZM10.25 7.25C10.25 7.05109 10.329 6.86032 10.4697 6.71967C10.6103 6.57902 10.8011 6.5 11 6.5C11.1989 6.5 11.3897 6.57902 11.5303 6.71967C11.671 6.86032 11.75 7.05109 11.75 7.25V13.25C11.75 13.4489 11.671 13.6397 11.5303 13.7803C11.3897 13.921 11.1989 14 11 14C10.8011 14 10.6103 13.921 10.4697 13.7803C10.329 13.6397 10.25 13.4489 10.25 13.25V7.25ZM11 18.5C10.7775 18.5 10.56 18.434 10.375 18.3104C10.19 18.1868 10.0458 18.0111 9.96065 17.8055C9.8755 17.6 9.85322 17.3738 9.89663 17.1555C9.94004 16.9373 10.0472 16.7368 10.2045 16.5795C10.3619 16.4222 10.5623 16.315 10.7805 16.2716C10.9988 16.2282 11.225 16.2505 11.4305 16.3356C11.6361 16.4208 11.8118 16.565 11.9354 16.75C12.059 16.935 12.125 17.1525 12.125 17.375C12.125 17.6734 12.0065 17.9595 11.7955 18.1705C11.5845 18.3815 11.2984 18.5 11 18.5Z"
          fill="#374146"
        />
      </svg>
    ),
  });
}

export function OpenErrorSnackbar(message) {
  toast.dismiss();
  toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    className: 'snackbar error',
    bodyClassName: 'snackbar-body',
    closeButton: <CloseSnackbarButton />,
    icon: () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path
          d="M11 0.5C8.9233 0.5 6.89323 1.11581 5.16652 2.26957C3.4398 3.42332 2.09399 5.0632 1.29927 6.98182C0.504549 8.90045 0.296614 11.0116 0.701759 13.0484C1.1069 15.0852 2.10693 16.9562 3.57538 18.4246C5.04383 19.8931 6.91476 20.8931 8.95156 21.2982C10.9884 21.7034 13.0996 21.4955 15.0182 20.7007C16.9368 19.906 18.5767 18.5602 19.7304 16.8335C20.8842 15.1068 21.5 13.0767 21.5 11C21.5 8.21523 20.3938 5.54451 18.4246 3.57538C16.4555 1.60625 13.7848 0.5 11 0.5ZM10.25 4.25C10.25 4.05109 10.329 3.86032 10.4697 3.71967C10.6103 3.57902 10.8011 3.5 11 3.5C11.1989 3.5 11.3897 3.57902 11.5303 3.71967C11.671 3.86032 11.75 4.05109 11.75 4.25V13.25C11.75 13.4489 11.671 13.6397 11.5303 13.7803C11.3897 13.921 11.1989 14 11 14C10.8011 14 10.6103 13.921 10.4697 13.7803C10.329 13.6397 10.25 13.4489 10.25 13.25V4.25ZM11 18.5C10.7775 18.5 10.56 18.434 10.375 18.3104C10.19 18.1868 10.0458 18.0111 9.96064 17.8055C9.87549 17.6 9.85321 17.3738 9.89662 17.1555C9.94003 16.9373 10.0472 16.7368 10.2045 16.5795C10.3618 16.4222 10.5623 16.315 10.7805 16.2716C10.9988 16.2282 11.225 16.2505 11.4305 16.3356C11.6361 16.4208 11.8118 16.565 11.9354 16.75C12.059 16.935 12.125 17.1525 12.125 17.375C12.125 17.6734 12.0065 17.9595 11.7955 18.1705C11.5845 18.3815 11.2984 18.5 11 18.5Z"
          fill="white"
        />
      </svg>
    ),
  });
}
