import { connect } from 'react-redux';
import { authenticationActions } from '../../../store/authentication';

import CreateLandingComponent from './CreateLandingComponent';

const mapDispatchToProps = (dispatch) => {
  return {
    login: (redirect) => dispatch(authenticationActions.login(redirect)),
  };
};

const ConnectedCreateLandingComponent = connect(null, mapDispatchToProps)(CreateLandingComponent);

export default ConnectedCreateLandingComponent;
