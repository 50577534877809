import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * This uses the black magic of sass-loader to import a SCSS file
 * as a JS object. This object will contain all properties exported
 * in withArduinoStripeStyle.scss (within the :export block).
 */
import style from './withArduinoStripeStyle.scss';

const inputStyle = {
  base: {
    fontSize: style.fontSize,
    fontFamily: style.typonineSansRegular,
    lineHeight: style.height,
    '::placeholder': {
      color: style.placeHolderColor,
      letterSpacing: style.placeHolderLetterSpacing,
    },
  },
  invalid: {
    color: style.color,
  },
};

/**
 * Inject into a component (a Stripe input field) an object
 * containing all the style to make the rendered component
 * have the same look & feel of an arduino-sass input.
 */
export default function withArduinoStripeStyle(WrappedComponent) {
  class WithArduinoStripeStyle extends Component {
    render() {
      return (
        <WrappedComponent
          style={inputStyle}
          {...this.props}
          className={classnames('stripe-input', this.props.className)}
        />
      );
    }
  }

  WithArduinoStripeStyle.propTypes = {
    className: PropTypes.string,
  };

  return WithArduinoStripeStyle;
}
