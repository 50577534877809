import { call, put, takeEvery } from 'redux-saga/effects';

import actions from './actions';
import BillingTypes from './types';
import authenticationTypes from '../authentication/types';
import { saveBillingInfo } from '../../apis/profile';
import * as paymentsApi from '../../apis/payments';

const updateBilling = function* updateBilling({ billingInfo }) {
  yield put(actions.updateBillingInfoRequested());
  try {
    if (billingInfo.tax_certificate && billingInfo.tax_certificate instanceof File) {
      const data = yield call(paymentsApi.createPresignedUrl, billingInfo.type === 'S' ? 'tax_exempt' : 'resale');
      yield call(updateBucketCertificate, billingInfo.tax_certificate, data.presigned_url);
      delete billingInfo.tax_certificate;
    }
    const result = yield call(saveBillingInfo, billingInfo);
    yield put(actions.updateBillingInfoSucceeded(result));
  } catch (error) {
    yield put(actions.updateBillingInfoFailed(error));
  }
};

const updateBucketCertificate = (certificate, url) => {
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/pdf',
    },
    body: certificate,
  });
};

const updateBillingFromLogin = function* updateBillingFromLogin({ profile }) {
  yield put(actions.initializeBilling(profile.billing));
};

const watchUpdateBilling = function* watchUpdateBilling() {
  yield takeEvery(BillingTypes.UPDATE_BILLING, updateBilling);
};

const watchLoginSuccessUpdateBilling = function* watchLoginSuccessUpdateBilling() {
  yield takeEvery(authenticationTypes.GET_PROFILE_SUCCEDED, updateBillingFromLogin);
};

export default [watchUpdateBilling, watchLoginSuccessUpdateBilling];
