import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as UpGreenArrow } from '../../../assets/icons/upGreenArrow.svg';
import { ReactComponent as DownGreenArrow } from '../../../assets/icons/downGreenArrow.svg';

import './CertificationProductCard.scss';

function CertificationProductCard(props) {
  const { plan, name, description, features, selectedCertification, changeCertification } = props;
  const [featureOpen, setFeatureOpen] = useState(false);

  return (
    <div className="certification-plan-product-card-wrapper">
      <div className="certification-card">
        <label className="ardu-radio">
          <input
            type="radio"
            name="create-product"
            onChange={() => changeCertification(plan)}
            onClick={() => changeCertification(plan)}
            checked={selectedCertification === plan}
          />
          <span className="custom-radio"></span>
        </label>
        <div className="certification-card__text" onClick={() => changeCertification(plan)}>
          <span className="certification-card__title">{name}</span>
          <span className="certification-card__sub-text-description" onClick={() => changeCertification(plan)}>
            {description}
          </span>
        </div>
        <div className="certification-card__more">
          <button className={'more-button'} type="button" onClick={() => setFeatureOpen(!featureOpen)}>
            More {featureOpen ? <UpGreenArrow /> : <DownGreenArrow />}
          </button>
        </div>
      </div>

      {featureOpen && (
        <div className="feature-wrapper">
          <div className="feature__list">{features}</div>
        </div>
      )}
    </div>
  );
}

CertificationProductCard.propTypes = {
  name: PropTypes.string.isRequired,
  selectedCertification: PropTypes.string.isRequired,
  plan: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  features: PropTypes.string.isRequired,
  changeCertification: PropTypes.func,
};

export default CertificationProductCard;
