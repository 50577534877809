const SIM_DEVICE_READ = 'app/sim/SIM_DEVICE_READ';
const SIM_DEVICE_READ_REQUESTED = 'app/sim/SIM_DEVICE_READ_REQUESTED';
const SIM_DEVICE_READ_SUCCEEDED = 'app/sim/SIM_DEVICE_READ_SUCCEEDED';
const SIM_DEVICE_READ_FAILED = 'app/sim/SIM_DEVICE_READ_FAILED';

const SIM_DEVICE_UPDATE = 'app/sim/SIM_DEVICE_UPDATE';
const SIM_DEVICE_UPDATE_REQUESTED = 'app/sim/SIM_DEVICE_UPDATE_REQUESTED';
const SIM_DEVICE_UPDATE_SUCCEEDED = 'app/sim/SIM_DEVICE_UPDATE_SUCCEEDED';
const SIM_DEVICE_UPDATE_FAILED = 'app/sim/SIM_DEVICE_UPDATE_FAILED';

const SIM_DEVICE_PAUSE = 'app/sim/SIM_DEVICE_PAUSE';
const SIM_DEVICE_PAUSE_REQUESTED = 'app/sim/SIM_DEVICE_PAUSE_REQUESTED';
const SIM_DEVICE_PAUSE_SUCCEEDED = 'app/sim/SIM_DEVICE_PAUSE_SUCCEEDED';
const SIM_DEVICE_PAUSE_FAILED = 'app/sim/SIM_DEVICE_PAUSE_FAILED';

const SIM_DEVICE_UNPAUSE = 'app/sim/SIM_DEVICE_UNPAUSE';
const SIM_DEVICE_UNPAUSE_REQUESTED = 'app/sim/SIM_DEVICE_UNPAUSE_REQUESTED';
const SIM_DEVICE_UNPAUSE_SUCCEEDED = 'app/sim/SIM_DEVICE_UNPAUSE_SUCCEEDED';
const SIM_DEVICE_UNPAUSE_FAILED = 'app/sim/SIM_DEVICE_UNPAUSE_FAILED';

const SIM_ICCID_VERIFY = 'app/sim/SIM_ICCID_VERIFY';
const SIM_ICCID_VERIFY_REQUESTED = 'app/sim/SIM_ICCID_VERIFY_REQUESTED';
const SIM_ICCID_VERIFY_SUCCEEDED = 'app/sim/SIM_ICCID_VERIFY_SUCCEEDED';
const SIM_ICCID_VERIFY_FAILED = 'app/sim/SIM_ICCID_VERIFY_FAILED';

export default {
  SIM_DEVICE_READ,
  SIM_DEVICE_READ_REQUESTED,
  SIM_DEVICE_READ_SUCCEEDED,
  SIM_DEVICE_READ_FAILED,
  SIM_DEVICE_UPDATE,
  SIM_DEVICE_UPDATE_REQUESTED,
  SIM_DEVICE_UPDATE_SUCCEEDED,
  SIM_DEVICE_UPDATE_FAILED,
  SIM_DEVICE_PAUSE,
  SIM_DEVICE_PAUSE_REQUESTED,
  SIM_DEVICE_PAUSE_SUCCEEDED,
  SIM_DEVICE_PAUSE_FAILED,
  SIM_DEVICE_UNPAUSE,
  SIM_DEVICE_UNPAUSE_REQUESTED,
  SIM_DEVICE_UNPAUSE_SUCCEEDED,
  SIM_DEVICE_UNPAUSE_FAILED,
  SIM_ICCID_VERIFY,
  SIM_ICCID_VERIFY_REQUESTED,
  SIM_ICCID_VERIFY_SUCCEEDED,
  SIM_ICCID_VERIFY_FAILED,
};
