/* eslint-disable */
import types from './types';

const initialState = {
  login: {
    inProgress: false,
    didInvalidate: false,
    lastUpdate: null,
    loggedIn: false,
    token: null,
    error: null,
  },
  profile: {
    data: {},
    inProgress: false,
    lastUpdate: null,
    error: null,
  },
  organizations: {
    data: [],
    inProgress: false,
    lastUpdate: null,
    error: null,
  },
  members: {
    data: [],
    inProgress: false,
    lastUpdate: null,
    error: null,
  },
  organizations: [],
  certificates: [],
  members: [],
  create: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
};

// https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns

export default function example(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_REQUESTED:
      return {
        ...state,
        login: {
          ...state.login,
          inProgress: true,
          loggedIn: false,
        },
      };
    case types.LOGIN_SUCCEEDED:
      return {
        ...state,
        login: {
          ...state.login,
          inProgress: false,
          loggedIn: true,
          lastUpdate: Date.now(),
          profile: action.profile,
        },
      };
    case types.LOGIN_FAILED:
      return {
        ...state,
        login: {
          ...state.login,
          inProgress: false,
          loggedIn: false,
          lastUpdate: Date.now(),
          error: action.error,
        },
      };
    case types.GET_PROFILE_REQUESTED:
      return {
        ...state,
        profile: {
          ...state.profile,
          inProgress: true,
        },
      };
    case types.GET_PROFILE_SUCCEDED:
      return {
        ...state,
        login: {
          ...state.login,
          loggedIn: true,
        },
        profile: {
          ...state.profile,
          data: action.profile,
          lastUpdate: Date.now(),
          inProgress: false,
        },
        certificates: action.certificates,
      };
    case types.GET_PROFILE_FAILED:
      return {
        ...state,
        profile: {
          ...state.profile,
          error: action.error,
          lastUpdate: Date.now(),
          inProgress: false,
        },
      };

    case types.ORGANIZATION_LIST_READ_REQUESTED:
      return {
        ...state,
        organizations: {
          ...state.organizations,
          inProgress: true,
        },
      };
    case types.ORGANIZATION_LIST_READ_SUCCEDED:
      return {
        ...state,
        organizations: {
          ...state.organizations,
          data: action.organizations,
          lastUpdate: Date.now(),
          inProgress: false,
        },
      };
    case types.ORGANIZATION_LIST_READ_FAILED:
      return {
        ...state,
        organizations: {
          ...state.organizations,
          error: action.error,
          lastUpdate: Date.now(),
          inProgress: false,
        },
      };

    case types.ORGANIZATION_CREATE_REQUESTED:
      return {
        ...state,
        create: {
          ...state.create,
          inProgress: true,
        },
      };

    case types.ORGANIZATION_CREATE_SUCCEEDED:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.ORGANIZATION_CREATE_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
        organization
      };

    case types.ORGANIZATION_MEMBERS_READ_REQUESTED:
      return {
        ...state,
        members: {
          ...state.members,
          inProgress: true,
        },
      };
    case types.ORGANIZATION_MEMBERS_READ_SUCCEDED:
      return {
        ...state,
        members: {
          ...state.members,
          data: action.members,
          lastUpdate: Date.now(),
          inProgress: false,
        },
      };
    case types.ORGANIZATION_MEMBERS_READ_FAILED:
      return {
        ...state,
        members: {
          ...state.members,
          error: action.error,
          lastUpdate: Date.now(),
          inProgress: false,
        },
      };

    default:
      return state;
  }
}
