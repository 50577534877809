const ACTIVATION_LIST_READ = 'app/activation/ACTIVATION_LIST_READ';
const ACTIVATION_LIST_READ_REQUESTED = 'app/activation/ACTIVATION_LIST_READ_REQUESTED';
const ACTIVATION_LIST_READ_SUCCEEDED = 'app/activation/ACTIVATION_LIST_READ_SUCCEEDED';
const ACTIVATION_LIST_READ_FAILED = 'app/activation/ACTIVATION_LIST_READ_FAILED';

const ALL_ACTIVATION_LIST_READ = 'app/activation/ALL_ACTIVATION_LIST_READ';
const ALL_ACTIVATION_LIST_READ_REQUESTED = 'app/activation/ALL_ACTIVATION_LIST_READ_REQUESTED';
const ALL_ACTIVATION_LIST_READ_SUCCEEDED = 'app/activation/ALL_ACTIVATION_LIST_READ_SUCCEEDED';
const ALL_ACTIVATION_LIST_READ_FAILED = 'app/activation/ALL_ACTIVATION_LIST_READ_FAILED';

const ACTIVATION_READ = 'app/activation/ACTIVATION_READ';
const ACTIVATION_READ_REQUESTED = 'app/activation/ACTIVATION_READ_REQUESTED';
const ACTIVATION_READ_SUCCEEDED = 'app/activation/ACTIVATION_READ_SUCCEEDED';
const ACTIVATION_READ_FAILED = 'app/activation/ACTIVATION_READ_FAILED';

const ACTIVATION_RESELLERS_LIST_READ = 'app/activation/ACTIVATION_RESELLERS_LIST_READ';
const ACTIVATION_RESELLERS_LIST_READ_REQUESTED = 'app/activation/ACTIVATION_RESELLERS_LIST_READ_REQUESTED';
const ACTIVATION_RESELLERS_LIST_READ_SUCCEEDED = 'app/activation/ACTIVATION_RESELLERS_LIST_READ_SUCCEEDED';
const ACTIVATION_RESELLERS_LIST_READ_FAILED = 'app/activation/ACTIVATION_RESELLERS_LIST_READ_FAILED';

const ACTIVATION_CREATE_REDEEM = 'app/activation/ACTIVATION_CREATE_REDEEM';
const ACTIVATION_CREATE_REDEEM_REQUESTED = 'app/activation/ACTIVATION_CREATE_REDEEM_REQUESTED';
const ACTIVATION_CREATE_REDEEM_SUCCEEDED = 'app/activation/ACTIVATION_CREATE_REDEEM_SUCCEEDED';
const ACTIVATION_CREATE_REDEEM_FAILED = 'app/activation/ACTIVATION_CREATE_REDEEM_FAILED';

const ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST = 'app/activation/ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST';
const ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_REQUESTED =
  'app/activation/ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_REQUESTED';
const ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_SUCCEEDED =
  'app/activation/ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_SUCCEEDED';
const ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_FAILED =
  'app/activation/ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_FAILED';

const ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST = 'app/activation/ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST';
const ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_REQUESTED =
  'app/activation/ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_REQUESTED';
const ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_SUCCEEDED =
  'app/activation/ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_SUCCEEDED';
const ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_FAILED =
  'app/activation/ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_FAILED';

const ACTIVATION_READ_AVAILABLE_ORGANIZATION = 'app/activation/ACTIVATION_READ_AVAILABLE_ORGANIZATION';
const ACTIVATION_READ_AVAILABLE_ORGANIZATION_REQUESTED =
  'app/activation/ACTIVATION_READ_AVAILABLE_ORGANIZATION_REQUESTED';
const ACTIVATION_READ_AVAILABLE_ORGANIZATION_SUCCEEDED =
  'app/activation/ACTIVATION_READ_AVAILABLE_ORGANIZATION_SUCCEEDED';
const ACTIVATION_READ_AVAILABLE_ORGANIZATION_FAILED = 'app/activation/ACTIVATION_READ_AVAILABLE_ORGANIZATION_FAILED';

export default {
  ACTIVATION_LIST_READ,
  ACTIVATION_LIST_READ_REQUESTED,
  ACTIVATION_LIST_READ_SUCCEEDED,
  ACTIVATION_LIST_READ_FAILED,
  ACTIVATION_CREATE_REDEEM,
  ACTIVATION_CREATE_REDEEM_REQUESTED,
  ACTIVATION_CREATE_REDEEM_SUCCEEDED,
  ACTIVATION_CREATE_REDEEM_FAILED,
  ACTIVATION_READ,
  ACTIVATION_READ_REQUESTED,
  ACTIVATION_READ_SUCCEEDED,
  ACTIVATION_READ_FAILED,
  ACTIVATION_RESELLERS_LIST_READ,
  ACTIVATION_RESELLERS_LIST_READ_REQUESTED,
  ACTIVATION_RESELLERS_LIST_READ_SUCCEEDED,
  ACTIVATION_RESELLERS_LIST_READ_FAILED,
  ALL_ACTIVATION_LIST_READ,
  ALL_ACTIVATION_LIST_READ_REQUESTED,
  ALL_ACTIVATION_LIST_READ_SUCCEEDED,
  ALL_ACTIVATION_LIST_READ_FAILED,
  ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST,
  ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_REQUESTED,
  ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_SUCCEEDED,
  ACTIVATION_CREATE_ENTERPRISE_UPGRADE_REQUEST_FAILED,
  ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST,
  ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_REQUESTED,
  ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_SUCCEEDED,
  ACTIVATION_CREATE_EDUCATION_UPGRADE_REQUEST_FAILED,
  ACTIVATION_READ_AVAILABLE_ORGANIZATION,
  ACTIVATION_READ_AVAILABLE_ORGANIZATION_REQUESTED,
  ACTIVATION_READ_AVAILABLE_ORGANIZATION_SUCCEEDED,
  ACTIVATION_READ_AVAILABLE_ORGANIZATION_FAILED,
};
