import { connect } from 'react-redux';
import { simActions } from '../../store/sim';
import { subscriptionActions } from '../../store/subscription';

import SimSubscriptionCard from './SimSubscriptionCard';

const mapStateToProps = (state) => {
  return {
    sim: state.sim,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    readDevice: (iccid) => dispatch(simActions.readDevice(iccid)),
    cancelChangeSubscription: (id) => dispatch(subscriptionActions.reactivateSubscription(id)),
  };
};

const ConnectedSimSubscriptionCard = connect(mapStateToProps, mapDispatchToProps)(SimSubscriptionCard);

export default ConnectedSimSubscriptionCard;
