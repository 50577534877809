import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SubscriptionCard from '../../../common/SubscriptionCard/SubscriptionCard';
import { SubscriptionCardContext } from '../../../common/SubscriptionCard/SubscriptionCardContext';
import AbsoluteSpinner from '../../../common/AbsoluteSpinner';
import { OpenErrorNotification } from '../../../common/Toast/Toast';

import Invoices from './Invoices/Invoices';

import { subscriptionCards } from '../../../../products';
import {
  CLASSROOM_TEACHER,
  CLOUD_CREATE,
  CLOUD_CLASSROOM,
  CLASSROOM_ADMIN,
  EDUCATION_PURCHASE,
  ENTERPRISE_PRODUCT,
  ENTERPRISE_ADMIN,
  ENTERPRISE_EDITOR,
} from '../../../../utils/utilities';

import './PlansTab.scss';
import ActivationsComponent from '../activations/Activations';
import AwsSubscriptionList from '../aws/AwsSubscriptionList';

const Layout = ({ children }) => <div className="my-subscriptions-tab">{children}</div>;

Layout.propTypes = {
  children: PropTypes.node,
};

const PlansTabComponent = function PlansTabComponent(props) {
  const {
    readCardList,
    readSubscriptionList,
    readActivationList,
    createRedeemActivation,
    readInvoiceList,
    readOrganizationList,
    readInvoiceListBySubscription,
    readOrganizationMembers,
    createOrganization,
    organizations,
    activation,
    readActivation,
    readResellerList,
    readCountries,
    createEnterpriseUpgradeRequest,
    createEducationUpgradeRequest,
    readAvailableOrganizations,
    readAwsSubscriptionList,
    awsSubscriptionList,
  } = props;
  const [allMembers, setAllMembers] = useState([]);
  const [lastMembersCall, setLastMembersCall] = useState('');
  const [newActivationOrganization, setNewActivationOrganization] = useState({ activationId: '', orgName: '' });
  const [createError, setCreateError] = useState(false);
  const [createRedeem, setCreateRedeem] = useState(false);

  const setOrg = (actId, orgName) => {
    setNewActivationOrganization({ activationId: actId, orgName: orgName });
  };

  useEffect(() => {
    if (activation && activation?.create?.error?.status && activation.create?.error?.status !== 201 && !createError) {
      OpenErrorNotification(activation.create.error.body.message);
      setCreateError(true);
    }
  }, [activation, createError]);

  useEffect(() => {
    for (let i = 0; i < props.organizations?.organizations.length; i++) {
      if (newActivationOrganization.orgName === props.organizations?.organizations[i].name) {
        createRedeemActivation({
          activationId: newActivationOrganization.activationId,
          organizationId: props.organizations?.organizations[i].id,
        });
        setNewActivationOrganization({ activationId: '', orgName: '' });
        setTimeout(() => readActivationList(), 2000);
      }
    }
  }, [createRedeemActivation, newActivationOrganization, props.organizations, readActivationList]);

  // Fetch credit cards after mount
  useEffect(() => {
    readCardList();
    readSubscriptionList();
    readInvoiceList();
    readOrganizationList();
    readActivationList();
    readResellerList();
    readCountries();
    readAwsSubscriptionList();
  }, [
    readCardList,
    readSubscriptionList,
    readInvoiceList,
    readOrganizationList,
    readActivationList,
    readResellerList,
    readCountries,
    createRedeem,
    readAwsSubscriptionList,
  ]);

  useEffect(() => {
    // Set Arduino logo in header
    localStorage.removeItem('path');
    window.arduinoHeader.changeLogo({
      src: '<svg width="52" height="26" viewBox="0 0 52 26" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2848_54927)"><path fill-rule="evenodd" clip-rule="evenodd" d="M51.25 13C51.25 6.10219 45.5773 0.5 38.6242 0.5C37.984 0.5 37.3261 0.536496 36.6859 0.645985C31.2799 1.43066 27.6522 5.42701 25.625 8.4927C23.5978 5.42701 19.9701 1.43066 14.5641 0.645985C13.9239 0.554745 13.266 0.5 12.6258 0.5C5.65493 0.5 0 6.10219 0 13C0 19.8978 5.67271 25.5 12.6258 25.5C13.266 25.5 13.9239 25.4635 14.5819 25.354C19.9879 24.5511 23.6155 20.5547 25.6428 17.4891C27.67 20.5547 31.2977 24.5511 36.7037 25.354C37.3439 25.4453 38.0018 25.5 38.6598 25.5C45.5773 25.5 51.25 19.8978 51.25 13ZM13.9416 20.9744C13.4971 21.0474 13.0525 21.0657 12.6079 21.0657C8.01996 21.0657 4.30336 17.4343 4.30336 13C4.30336 8.54744 8.03774 4.9343 12.6257 4.9343C13.0703 4.9343 13.5148 4.9708 13.9594 5.02554C19.0631 5.77372 22.1751 10.9379 23.242 13C22.1573 15.0803 19.0275 20.2263 13.9416 20.9744ZM37.2905 5.02554C32.1868 5.77372 29.057 10.9379 28.0079 13C29.057 15.062 32.1868 20.2263 37.2905 20.9744C37.735 21.0292 38.1796 21.0657 38.6242 21.0657C43.1944 21.0657 46.9287 17.4525 46.9287 13C46.9287 8.56569 43.2121 4.9343 38.6242 4.9343C38.1796 4.9343 37.735 4.9708 37.2905 5.02554ZM9.06449 11.661H16.7004V14.0642H9.06449V11.661ZM42.1479 14.0817H39.5728V16.6077H37.1049V14.0817H34.5298V11.661H37.1049V9.13499H39.5728V11.661H42.1479V14.0817Z" fill="#00979D"/></g><defs><clipPath id="clip0_2848_54927"><rect width="51.25" height="25" fill="white" transform="translate(0 0.5)"/></clipPath></defs></svg>',
      inline: true,
      cssClass: 'digital-store',
    });
  });

  // When the subscription finishes to load, load the invoices list for each subscription
  useEffect(() => {
    for (let i = 0; i < props.subscription.list.length; i++) {
      readInvoiceListBySubscription(props.subscription.list[i]);
    }
  }, [props.subscription.list.lastUpdate, readInvoiceListBySubscription, props.subscription.list]);

  useEffect(() => {
    for (let i = 0; i < props.organizations?.organizations.length; i++) {
      readOrganizationMembers(props.organizations?.organizations[i].id);
    }
  }, [props.organizations, readOrganizationMembers]);

  useEffect(() => {
    if (props.members && (lastMembersCall === '' || props.members?.members[0].id !== lastMembersCall)) {
      setLastMembersCall(props.members?.members[0].id);
      setAllMembers([...allMembers, props.members?.members]);
    }
  }, [props.members, allMembers, lastMembersCall]);

  const pageLoading =
    props.subscription.readList.inProgress ||
    props.card.readList.inProgress ||
    props.activation.create.inProgress ||
    props.activation.readList.inProgress ||
    props.invoice.readListBySubscription.inProgress ||
    createRedeem;
  let subscriptionsElements = null;
  if (!pageLoading) {
    subscriptionsElements = props.subscription.list
      .map((subscriptionId) => {
        const subscription = props.subscription.byId[subscriptionId];
        const product = props.product.products[subscription.product]
          ? props.product.products[subscription.product]
          : subscription.product === ENTERPRISE_PRODUCT
          ? {
              id: ENTERPRISE_PRODUCT,
              name: 'Enterprise Plan',
              organizationName: '', // space
            }
          : {
              id: CLOUD_CLASSROOM,
              name: 'School Plan',
              organizationName: '',
            };
        if (!product && product !== CLOUD_CLASSROOM) {
          // The product doesn't exists or it has been disabled via env flag
          return null;
        }

        const activationId = subscription.activation_id;

        let hasReseller = !!subscription.reseller_id;
        const isActivationUpgradable = true; //TBD

        const plan = props.plan.plans[subscription.plan];
        const planDescription = props.plan.planDescriptions[plan.planDescription];
        const planFeatures = props.plan.planFeatures[plan.planFeatures];

        const invoicesBySubscription = props.invoice.bySubscription[subscription.id] || [];
        const invoices = invoicesBySubscription.map((invoiceId) => props.invoice.byId[invoiceId]);

        const coupon = props.coupon;
        const verifyCoupon = props.verifyCoupon;

        const profile = props.profile;
        const billing = props.billing;
        const certificates = props.certificates;
        const country = props.country;
        const updateBillingInfo = props.updateBillingInfo;
        const match = props.match;
        const location = props.location;
        const educationPath = props.match.path === EDUCATION_PURCHASE;
        const organizations = props.organizations;

        const voucher = subscription.coupon;

        let members = {};

        let isOwner = false;
        let isAdmin = false;
        let isTeacher = false;
        let isProAdmin = false;
        let isProEditor = false;
        members = props?.members?.members;
        if (members && subscription.user_id === profile.data.id) {
          members?.forEach((member) => {
            if (member.id === subscription.user_id) {
              isOwner = true;
            }
          });
        }

        const readOrganizationMembers = props.readOrganizationMembers;
        const history = props.history;
        const readCountries = props.readCountries;
        const readStates = props.readStates;
        const billingInfo = props.billingInfo;

        const isEducationPlan = subscription.plan === CLOUD_CLASSROOM;
        const isEnterprisePlan = subscription.product === ENTERPRISE_PRODUCT;
        const isCloudPlan = subscription.product === CLOUD_CREATE;

        let organizationName = '';

        organizations?.organizations.forEach((organization) => {
          if (organization.id === subscription.organization_id) {
            product.organizationName = organization.name;
            organizationName = organization.name;
            if (organization && organization.roles.includes(CLASSROOM_ADMIN)) isAdmin = true;
            if (organization && organization.roles.includes(CLASSROOM_TEACHER)) isTeacher = true;
            if (organization && organization.roles.includes(ENTERPRISE_ADMIN)) isProAdmin = true;
            if (organization && organization.roles.includes(ENTERPRISE_EDITOR)) isProEditor = true;
          }
        });

        let SubscriptionCardComponent = SubscriptionCard;

        if (subscriptionCards[product.id]) {
          SubscriptionCardComponent = subscriptionCards[product.id];
        } else if (product.id === CLOUD_CLASSROOM || product.id === ENTERPRISE_PRODUCT) {
          SubscriptionCardComponent = subscriptionCards['create'];
        }

        return (
          <SubscriptionCardContext.Provider
            key={subscription.id}
            value={{
              subscription: subscription,
              product: product,
              plan: plan,
              planDescription: planDescription,
              planFeatures: planFeatures,
              paymentMethod: props.card?.byId[subscription.payment_source],
              invoices: invoices,

              coupon: coupon,
              verifyCoupon: verifyCoupon,
              // voucher
              voucher: voucher,
              //Billing info
              profile: profile,
              billing: billing,
              certificates: certificates,
              country: country,
              updateBillingInfo: updateBillingInfo,
              match: match,
              location: location,
              educationPath: educationPath,
              organizations: organizations,
              history: history,
              readCountries: readCountries,
              readStates: readStates,
              billingInfo: billingInfo,
              isEducationPlan: isEducationPlan,
              isEnterprisePlan: isEnterprisePlan,
              isOwner: isOwner,
              isAdmin: isAdmin,
              isProAdmin: isProAdmin,
              isProEditor: isProEditor,
              isTeacher: isTeacher,
              isCloudPlan: isCloudPlan,
              members: members,
              readOrganizationMembers: readOrganizationMembers,
              allMembers: allMembers,
              activationId: activationId,
              hasReseller: hasReseller,
              readActivation: readActivation,
              organizationName: organizationName,
              activationList: props.activation,
              isActivationUpgradable: isActivationUpgradable,
              createEnterpriseUpgradeRequest: createEnterpriseUpgradeRequest,
              createEducationUpgradeRequest: createEducationUpgradeRequest,
            }}
          >
            <SubscriptionCardComponent isActivation={subscription.activation_id ? subscription.product : null} />
          </SubscriptionCardContext.Provider>
        );
      })
      .filter((element) => element !== null);

    if (subscriptionsElements.length === 0) {
      return <span>{'There are no subscriptions active'}</span>;
    }
  } else return <AbsoluteSpinner white height="100%" />;
  return (
    <Layout>
      <div key="subscription-cards-list" className="subscription-cards-list">
        <AbsoluteSpinner white height="100%" hidden={!pageLoading} />
        <div className="plans-title">Your Plans</div>
        <AwsSubscriptionList
          list={awsSubscriptionList}
          profile={props.profile}
          organizations={organizations?.organizations}
        />
        <ActivationsComponent
          activations={activation.activationList}
          resellers={activation.resellerList}
          redeem={createRedeemActivation}
          organizations={organizations?.organizations}
          readAvailableOrganizations={readAvailableOrganizations}
          availableOrganizationList={activation.availableOrganizationList}
          readActivationList={readActivationList}
          createOrganization={createOrganization}
          country={props.country}
          readOrganizationList={readOrganizationList}
          setOrg={setOrg}
          activationReadProcess={activation.read}
          setCreateRedeem={setCreateRedeem}
        />
        {subscriptionsElements}
        <Invoices invoice={props.invoice} plan={props.plan} product={props.product} />
      </div>
    </Layout>
  );
};

PlansTabComponent.propTypes = {
  coupon: PropTypes.object,
  verifyCoupon: PropTypes.func,
  card: PropTypes.object,
  cancelSubscription: PropTypes.func,
  subscription: PropTypes.object,
  plan: PropTypes.object,
  product: PropTypes.object,
  readCardList: PropTypes.func,
  readInvoiceListBySubscription: PropTypes.func,
  readSubscriptionList: PropTypes.func,
  readAwsSubscriptionList: PropTypes.func,
  readActivationList: PropTypes.func,
  readResellerList: PropTypes.func,
  createRedeemActivation: PropTypes.func.isRequired,
  createOrganization: PropTypes.func,
  readOrganizationMembers: PropTypes.func,
  invoice: PropTypes.object,
  billing: PropTypes.object.isRequired,
  profile: PropTypes.object,
  certificates: PropTypes.array,
  readInvoiceList: PropTypes.func,
  country: PropTypes.object,
  readCountries: PropTypes.func,
  readStates: PropTypes.func,
  updateBillingInfo: PropTypes.func.isRequired,
  match: PropTypes.object,
  location: PropTypes.object,
  educationPath: PropTypes.bool,
  organizations: PropTypes.object,
  history: PropTypes.object,
  readOrganizationList: PropTypes.func,
  billingInfo: PropTypes.object,
  members: PropTypes.object,
  readActivation: PropTypes.func,
  readAvailableOrganizations: PropTypes.func,
  activation: PropTypes.object,
};

export default PlansTabComponent;
