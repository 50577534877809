import types from './types';

const loadUserRecap = () => ({
  type: types.LOAD_USER_RECAP,
});

const loadUserRecapSucceeded = (userRecap) => {
  return {
    type: types.LOAD_USER_RECAP_SUCCEEDED,
    userRecap,
  };
};

const loadUserRecapFailed = () => {
  //console.error(`Error loading user recap [${error.message}]`);
  return {
    type: types.LOAD_USER_RECAP_FAILED,
  };
};

export default {
  loadUserRecap,
  loadUserRecapSucceeded,
  loadUserRecapFailed,
};
