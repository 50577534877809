import types from './types';

const addProduct = (product) => ({
  type: types.PRODUCT_ADD,
  product,
});

export default {
  addProduct,
};
