import React, { Component, useState, useEffect, useMemo } from 'react';
import { Input, Select, Loader } from '@arduino/arc';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Formik } from 'formik';
import InfoPopover from '../InfoPopover/InfoPopover';
import { OTHER, CLASSROOM_ADMIN, SPACE_EDUCATION_PLAN } from '../../../utils/utilities';
import './InstitutionConfiguration.scss';

const institutionType = [
  { type: 'Middle school', value: 'Middle school' },
  { type: 'High school', value: 'High school' },
  { type: 'University', value: 'University' },
  { type: 'Fablab', value: 'Fablab' },
  { type: 'Other', value: 'Other' },
];

const FIELD_INSTITUTION_NAME = 'institution_name';
const FIELD_INSTITUTION_TYPE = 'institution_type';
const FIELD_INSTITUTION_TYPE_OTHER = 'institution_type_other';
const FIELD_COUNTRY = 'country';

const validate = () => () => {
  const errors = {};
  return errors;
};

const FormBody = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    onCreateOrganization,
    country,
    actualOrganization,
    handleOrganizationSelected,
    handleEduInstitutionCta,
    subscriptions,
  } = props;

  const formattedCountryOption = country.countriesList.map((e) => {
    return { label: e.name, value: e.code };
  });

  const formattedSchoolTypeOption = institutionType.map((e) => {
    return { label: e.type, value: e.value };
  });

  const myOrganizations = props?.organizations?.organizations;
  let hasMinOneAdmin = false;
  myOrganizations?.forEach((organization) => {
    if (organization && organization.roles.includes(CLASSROOM_ADMIN)) hasMinOneAdmin = true;
  });
  const hasOrganizations = props.organizations?.count > 0 && hasMinOneAdmin;
  const [addNewOrganizations, setAddNewOrganization] = useState(false);
  const [organizationSelected, setOrganizationSelected] = useState(
    actualOrganization !== null ? actualOrganization : 0
  );

  useEffect(() => {
    if (addNewOrganizations && organizationSelected !== 0) {
      setOrganizationSelected(0);
    }
  }, [addNewOrganizations, organizationSelected]);

  useEffect(() => {
    if (organizationSelected !== 0 && organizationSelected !== actualOrganization) {
      handleOrganizationSelected(organizationSelected);
    }
  }, [organizationSelected, actualOrganization, handleOrganizationSelected]);

  const isFetching = useMemo(() => {
    return subscriptions.readList.inProgress;
  }, [subscriptions]);

  const submitDisabled =
    ((organizationSelected === null || organizationSelected === 0) && hasOrganizations) ||
    (!hasOrganizations &&
      (values[FIELD_INSTITUTION_NAME] === '' ||
        values[FIELD_INSTITUTION_TYPE] === '' ||
        values[FIELD_COUNTRY] === '' ||
        (values[FIELD_INSTITUTION_TYPE] === OTHER && values[FIELD_INSTITUTION_TYPE_OTHER] === '')));

  const saveDisabled =
    organizationSelected === null ||
    (organizationSelected === 0 &&
      (values[FIELD_INSTITUTION_NAME] === '' ||
        values[FIELD_INSTITUTION_TYPE] === '' ||
        values[FIELD_COUNTRY] === '' ||
        (values[FIELD_INSTITUTION_TYPE] === OTHER && values[FIELD_INSTITUTION_TYPE_OTHER] === '')));

  useEffect(() => {
    handleEduInstitutionCta(submitDisabled);
  }, [submitDisabled, handleEduInstitutionCta]);

  const isOrganizationDisabled = (planId) => {
    if (planId !== '' && subscriptions.byId[planId]?.activation_id) {
      return true;
    }
    return false;
  };

  if (isFetching) {
    return (
      <div>
        <Loader linecolor="#008184" bgcolor="#ecf1f1" />;
      </div>
    );
  }

  return (
    <form id="institution-form" className="form-fields" onSubmit={handleSubmit}>
      {hasOrganizations ? (
        <>
          {myOrganizations
            .filter(
              (institution) => institution.roles.includes(CLASSROOM_ADMIN) && institution.space === SPACE_EDUCATION_PLAN
            )
            .map((organization) => {
              return (
                <div
                  className={classnames('organization', {
                    'organization--selected': organization.id === organizationSelected,
                    'organization--disabled': isOrganizationDisabled(organization.plan_id),
                  })}
                  key={organization.id}
                  onClick={() => {
                    if (!isOrganizationDisabled(organization.plan_id)) {
                      setOrganizationSelected(organization.id);
                      setAddNewOrganization(false);
                    }
                  }}
                >
                  <div className="organization-box">
                    <label className="ardu-radio">
                      <input
                        type="radio"
                        name="use-new-organization"
                        onChange={() =>
                          !isOrganizationDisabled(organization.plan_id)
                            ? setOrganizationSelected(organization.id)
                            : null
                        }
                        onClick={() =>
                          !isOrganizationDisabled(organization.plan_id)
                            ? setOrganizationSelected(organization.id)
                            : null
                        }
                        checked={organizationSelected === organization.id}
                      />
                      <span className="custom-radio"></span>
                    </label>
                    <div className="organization-text">
                      {organization.name}
                      <span className="organization-sub-text">{organization.members} members</span>
                    </div>
                  </div>
                  {isOrganizationDisabled(organization.plan_id) && (
                    <div className="organization__info">
                      <InfoPopover
                        popoverContent={
                          'You can not choose this organization, as the plan to which it is connected is a presaled order. If you want to upgrade this plan, <a style="color: #008184; font-weight: 700;" rel="noreferrer" target="_blank" href="https://wiki-content.arduino.cc/en/contact-us/">contact us</a>.'
                        }
                        className="info"
                      />
                    </div>
                  )}
                </div>
              );
            })}
          {addNewOrganizations ? (
            <div
              className={classnames('new-organization-wrapper', {
                selected: addNewOrganizations,
              })}
              onClick={() => setOrganizationSelected(0)}
            >
              <div className="new-organization-title">
                <label className="ardu-radio">
                  <input
                    type="radio"
                    name="use-new-organization"
                    onChange={() => setOrganizationSelected(0)}
                    onClick={() => setOrganizationSelected(0)}
                    checked={organizationSelected === 0}
                  />
                  <span className="custom-radio"></span>
                  <div>Set up new shared Space</div>
                </label>
              </div>
              <div className="form-group form-group-12-12">
                <div className="form-item">
                  <Input
                    label="Institution name"
                    value={values[FIELD_INSTITUTION_NAME]}
                    name={FIELD_INSTITUTION_NAME}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors[FIELD_INSTITUTION_NAME] &&
                      touched[FIELD_INSTITUTION_NAME] &&
                      errors[FIELD_INSTITUTION_NAME]
                    }
                  />
                </div>
              </div>
              <div className="form-group form-group-6-6">
                <div className="form-item">
                  <Select
                    label="Country"
                    placeholder="Select your country"
                    name={FIELD_COUNTRY}
                    value={values[FIELD_COUNTRY]}
                    options={formattedCountryOption}
                    onChange={(value) => {
                      setFieldValue(FIELD_COUNTRY, value), handleChange;
                    }}
                    onBlur={handleBlur}
                    error={errors[FIELD_COUNTRY] && touched[FIELD_COUNTRY]}
                  />
                </div>

                <div className="form-item">
                  <Select
                    label="Institution Type"
                    placeholder="Select institution type"
                    name={FIELD_INSTITUTION_TYPE}
                    value={values[FIELD_INSTITUTION_TYPE]}
                    options={formattedSchoolTypeOption}
                    onChange={(value) => {
                      setFieldValue(FIELD_INSTITUTION_TYPE, value), handleChange;
                    }}
                    onBlur={handleBlur}
                    error={
                      errors[FIELD_INSTITUTION_TYPE] &&
                      touched[FIELD_INSTITUTION_TYPE] &&
                      errors[FIELD_INSTITUTION_TYPE]
                    }
                  />
                </div>
              </div>
              {values[FIELD_INSTITUTION_TYPE] === OTHER && (
                <div className="form-group form-group-12-12">
                  <div className="form-item">
                    <Input
                      label="Add institution type"
                      value={values[FIELD_INSTITUTION_TYPE_OTHER]}
                      name={FIELD_INSTITUTION_TYPE_OTHER}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors[FIELD_INSTITUTION_TYPE_OTHER] &&
                        touched[FIELD_INSTITUTION_NAME] &&
                        errors[FIELD_INSTITUTION_TYPE_OTHER]
                      }
                    />
                  </div>
                </div>
              )}

              <div className="institutions-buttons">
                <button
                  className="cancel-button"
                  type="button"
                  onClick={() => {
                    setAddNewOrganization(false);
                    setOrganizationSelected(null);
                  }}
                >
                  {'Cancel'}
                </button>
                <button
                  className={classnames('submit-button', { disabled: saveDisabled }, { busy: !addNewOrganizations })}
                  type="button"
                  disabled={saveDisabled}
                >
                  <span
                    className="ardu-store-button-text"
                    onClick={() => {
                      onCreateOrganization({
                        name: values[FIELD_INSTITUTION_NAME],
                        type: values[FIELD_INSTITUTION_TYPE],
                        other: values[FIELD_INSTITUTION_TYPE_OTHER],
                        country: values[FIELD_COUNTRY],
                      }),
                        setAddNewOrganization(false);
                    }}
                  >
                    {'Save'}
                  </span>
                  <div className="spinner-container">
                    <div className="spinner" />
                  </div>
                </button>
              </div>
            </div>
          ) : (
            <div
              className="add-new-organization"
              onClick={() => {
                setAddNewOrganization(true);
                setOrganizationSelected(null);
              }}
            >
              Set up new shared Space
            </div>
          )}
        </>
      ) : (
        <>
          <div className="form-group form-group-12-12">
            <div className="form-item">
              <Input
                label="Institution name"
                value={values[FIELD_INSTITUTION_NAME]}
                name={FIELD_INSTITUTION_NAME}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors[FIELD_INSTITUTION_NAME] && touched[FIELD_INSTITUTION_NAME] && errors[FIELD_INSTITUTION_NAME]
                }
              />
            </div>
          </div>
          <div className="form-group form-group-6-6">
            <div className="form-item">
              <Select
                label="Country"
                placeholder="Select your country"
                name={FIELD_COUNTRY}
                value={values[FIELD_COUNTRY]}
                options={formattedCountryOption}
                onChange={(value) => {
                  setFieldValue(FIELD_COUNTRY, value), handleChange;
                }}
                onBlur={handleBlur}
                error={errors[FIELD_COUNTRY] && touched[FIELD_COUNTRY]}
              />
            </div>

            <div className="form-item">
              <Select
                label="Institution Type"
                placeholder="Select institution type"
                name={FIELD_INSTITUTION_TYPE}
                value={values[FIELD_INSTITUTION_TYPE]}
                options={formattedSchoolTypeOption}
                onChange={(value) => {
                  setFieldValue(FIELD_INSTITUTION_TYPE, value), handleChange;
                }}
                onBlur={handleBlur}
                error={
                  errors[FIELD_INSTITUTION_TYPE] && touched[FIELD_INSTITUTION_TYPE] && errors[FIELD_INSTITUTION_TYPE]
                }
              />
            </div>
          </div>
          {values[FIELD_INSTITUTION_TYPE] === OTHER && (
            <div className="form-group form-group-12-12">
              <div className="form-item">
                <Input
                  label="Add institution type"
                  value={values[FIELD_INSTITUTION_TYPE_OTHER]}
                  name={FIELD_INSTITUTION_TYPE_OTHER}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors[FIELD_INSTITUTION_TYPE_OTHER] &&
                    touched[FIELD_INSTITUTION_NAME] &&
                    errors[FIELD_INSTITUTION_TYPE_OTHER]
                  }
                />
              </div>
            </div>
          )}
        </>
      )}
    </form>
  );
};

FormBody.propTypes = {
  country: PropTypes.shape({
    countriesList: PropTypes.array,
    readStatesList: PropTypes.object,
    statesList: PropTypes.array,
    readList: PropTypes.object.isRequired,
  }),
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  organizations: PropTypes.object.isRequired,
  onCreateOrganization: PropTypes.func,
  handleOrganizationSelected: PropTypes.func,
  actualOrganization: PropTypes.string,
};

export default class InstitutionConfiguration extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.organizations?.count === 0) {
      this.props.history.push(`${this.props.match.path}/plan`);
    }
    const initialValues = {};

    const billingInfo = this.props.profile.data.billing;

    if (billingInfo && Object.entries(billingInfo).length === 0 && billingInfo?.constructor === Object) {
      initialValues[FIELD_INSTITUTION_NAME] = '';
      initialValues[FIELD_INSTITUTION_TYPE] = '';
      initialValues[FIELD_INSTITUTION_TYPE_OTHER] = '';
      initialValues[FIELD_COUNTRY] = '';
    } else {
      initialValues[FIELD_INSTITUTION_NAME] = billingInfo.institutionName ? billingInfo.institutionName : '';
      initialValues[FIELD_INSTITUTION_TYPE] = billingInfo.institutionType ? billingInfo.institutionType : '';
      initialValues[FIELD_INSTITUTION_TYPE_OTHER] = billingInfo.institutionOther ? billingInfo.institutionOther : '';
      initialValues[FIELD_COUNTRY] = billingInfo.country ? billingInfo.country : '';
    }
    return (
      <div className="billing-info-form arduino-form">
        <Formik
          initialValues={initialValues}
          validate={validate()}
          onSubmit={this.props.onSubmit}
          component={(formikProps) => <FormBody {...formikProps} {...this.props} />}
        />
      </div>
    );
  }
}

InstitutionConfiguration.propTypes = {
  country: PropTypes.shape({
    countriesList: PropTypes.array,
    readStatesList: PropTypes.object,
    statesList: PropTypes.array,
    readList: PropTypes.object.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
  profile: PropTypes.object,
  organizations: PropTypes.object,
  subscriptions: PropTypes.object,
  onCreateOrganization: PropTypes.func,
  handleOrganizationSelected: PropTypes.func,
  actualOrganization: PropTypes.string,
  handleEduInstitutionCta: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
};
