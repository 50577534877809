import React from 'react';

import PaymentMethodSection from './PaymentMethodSection';
import UpgradeButton from './UpgradeButton';
import DowngradeButton from './DowngradeButton';
import CancelButton from './CancelButton/CancelButton';
import SubscriptionTitle from './SubscriptionTitle';
import StackCard from '../StackCard/StackCard';

function Subscription() {
  const sideButtons = [
    <UpgradeButton key="upgrade" />,
    <DowngradeButton key="downgrade" />,
    <CancelButton key="cancel" />,
  ];
  return (
    <StackCard className="subscription-card" side={sideButtons}>
      <SubscriptionTitle />
      <PaymentMethodSection />
    </StackCard>
  );
}

export default Subscription;
