import React, { useCallback } from 'react';
import { Modal, Button } from '@arduino/arc';
import HeaderImage from '../../../../assets/voucher-redeem.svg';
import './MakerPlanRedeemModal.scss';

interface IMakerPlanRedeemModal {
  isOpen: boolean;
  onClose: () => void;
  currentPlan: string;
  newPlan: string;
  onConfirm: () => void;
  loading: boolean;
}
const MakerPlanRedeemModal = ({ isOpen, onClose, currentPlan, newPlan, onConfirm, loading }: IMakerPlanRedeemModal) => {
  const handleConfirm = useCallback(async () => {
    await onConfirm();
    onClose();
  }, [onClose, onConfirm]);
  return (
    <Modal isOpen={isOpen} title="Plan Replace" onClose={() => onClose()} className="maker-plan-redeem-modal">
      <div className="maker-plan-redeem-modal__header">
        <img src={HeaderImage} alt="plan replace" />
      </div>
      <div className="maker-plan-redeem-modal__description">
        <h4>Do you want to replace your plan?</h4>
        Your current plan <b>{currentPlan}</b> will be canceled and replaced with this new plan <b>{newPlan}</b>.
        <br />
        <br />
        <b>All of your resources will be maintained.</b>
      </div>
      <div className="maker-plan-redeem-modal__buttons">
        <Button className="button" onPress={handleConfirm} loading={loading}>
          Yes, replace it
        </Button>
      </div>
    </Modal>
  );
};

export default MakerPlanRedeemModal;
