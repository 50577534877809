import { API_URL } from '../env';
import fetch from '../utils/authenticatedFetch';
import { NetworkError } from './utils';

const SIM_API = `${API_URL}/sim/v1`;

export function readDevice(iccd) {
  return fetch(`${SIM_API}/devices/${iccd}`).then((res) => {
    if (!res.ok) {
      return res.json().then((body) => {
        throw new NetworkError(res, body);
      });
    }
    return res.json();
  });
}

export function updateDevice(iccd, payload) {
  return fetch(`${SIM_API}/devices/${iccd}`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  }).then((res) => {
    if (!res.ok) {
      return res.json().then((body) => {
        throw new NetworkError(res, body);
      });
    }
    return res.json();
  });
}

export function pauseDevice(iccd) {
  return fetch(`${SIM_API}/devices/${iccd}/pause`, {
    method: 'PUT',
  }).then((res) => {
    if (!res.ok) {
      return res.json().then((body) => {
        throw new NetworkError(res, body);
      });
    }
    return res.json();
  });
}

export function unpauseDevice(iccd) {
  return fetch(`${SIM_API}/devices/${iccd}/unpause`, {
    method: 'PUT',
  }).then((res) => {
    if (!res.ok) {
      return res.json().then((body) => {
        throw new NetworkError(res, body);
      });
    }
    return res.json();
  });
}

export function verifyIccid(iccd) {
  return fetch(`${SIM_API}/devices/${iccd}/verify`).then((res) => {
    if (!res.ok) {
      return res.json().then((body) => {
        throw new NetworkError(res, body);
      });
    }
    return res.json();
  });
}
