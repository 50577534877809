import types from './types';

const initialState = {
  billingInfo: {
    email: '',
    city: '',
    country: '',
    name: '',
    postcode: '',
    state: '',
    street1: '',
    street2: '',
  },
};

// https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns

export default function example(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_BILLING_REQUEST:
      return Object.assign({}, state, {
        inProgress: true,
      });
    case types.UPDATE_BILLING_SUCCESS:
      return { ...state, inProgress: false, lastUpdate: Date.now(), billingInfo: action.profile.billing, error: null };
    case types.UPDATE_BILLING_COUNTRY:
      return { ...state, billingInfo: { ...state.billingInfo, country: action.country } };
    case types.UPDATE_BILLING_STATE:
      return { ...state, billingInfo: { ...state.billingInfo, state: action.state } };
    case types.INITIALIZE_BILLING_INFO:
      return { ...state, billingInfo: { ...state.billingInfo, ...action.billingInfo } };
    case types.UPDATE_BILLING_FAILURE:
      return Object.assign({}, state, {
        inProgress: false,
        lastUpdate: Date.now(),
        error: action.error,
      });
    default:
      return state;
  }
}
