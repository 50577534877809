const CARD_LIST_READ = 'app/card/CARD_LIST_READ';
const CARD_LIST_READ_REQUESTED = 'app/card/CARD_LIST_READ_REQUESTED';
const CARD_LIST_READ_SUCCEEDED = 'app/card/CARD_LIST_READ_SUCCEEDED';
const CARD_LIST_READ_FAILED = 'app/card/CARD_LIST_READ_FAILED';

const CARD_CREATE = 'app/card/CARD_CREATE';
const CARD_CREATE_REQUESTED = 'app/card/CARD_CREATE_REQUESTED';
const CARD_CREATE_SUCCEEDED = 'app/card/CARD_CREATE_SUCCEEDED';
const CARD_CREATE_FAILED = 'app/card/CARD_CREATE_FAILED';

const CARD_DELETE = 'app/card/CARD_LIST_DELETE';
const CARD_DELETE_REQUESTED = 'app/card/CARD_DELETE_REQUESTED';
const CARD_DELETE_SUCCEEDED = 'app/card/CARD_DELETE_SUCCEEDED';
const CARD_DELETE_FAILED = 'app/card/CARD_DELETE_FAILED';

export default {
  CARD_LIST_READ,
  CARD_LIST_READ_REQUESTED,
  CARD_LIST_READ_SUCCEEDED,
  CARD_LIST_READ_FAILED,
  CARD_CREATE,
  CARD_CREATE_REQUESTED,
  CARD_CREATE_SUCCEEDED,
  CARD_CREATE_FAILED,
  CARD_DELETE,
  CARD_DELETE_REQUESTED,
  CARD_DELETE_SUCCEEDED,
  CARD_DELETE_FAILED,
};
