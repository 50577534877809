import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.SUBSCRIPTION_UPGRADE:
      return {
        ...state,
        upgrade: {
          ...state.upgrade,
          inProgress: true,
        },
      };

    case types.SUBSCRIPTION_UPGRADE_REQUESTED:
      return {
        ...state,
        upgrade: {
          ...state.upgrade,
          inProgress: true,
        },
      };

    case types.SUBSCRIPTION_UPGRADE_SUCCEEDED:
      return {
        ...state,
        upgrade: {
          ...state.upgrade,
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.SUBSCRIPTION_UPGRADE_FAILED:
      return {
        ...state,
        upgrade: {
          ...state.upgrade,
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.SUBSCRIPTION_UPGRADE_CLEAR_FAILED:
      return {
        ...state,
        upgrade: {
          ...state.upgrade,
          error: null,
        },
      };

    case types.SUBSCRIPTION_UPDATE_CARD_REQUESTED:
      return {
        ...state,
        updateCard: {
          ...state.updateCard,
          inProgress: true,
        },
      };

    case types.SUBSCRIPTION_UPDATE_CARD_SUCCEEDED:
      return {
        ...state,
        updateCard: {
          ...state.updateCard,
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.SUBSCRIPTION_UPDATE_CARD_FAILED:
      return {
        ...state,
        updateCard: {
          ...state.updateCard,
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
  }
}
