/* eslint-disable */
import { API_URL } from '../env';
import fetch from '../utils/authenticatedFetch';

const RECAP_API = `${API_URL}/restrictions/v1/recap/me`;

export async function readUserRecap() {
  const res = await fetch(RECAP_API);
  const data = await res.json();
  if (res.ok) {
    return data;
  }
  throw new NetworkError(res, data);
}
