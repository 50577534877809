import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactModal from 'react-modal';
import { ReactComponent as CloseIcon } from '../../../assets/close_icon.svg';

import './Modal.scss';

ReactModal.setAppElement('#root');

function getParent() {
  return document.querySelector('#root');
}

const Modal = function Modal(props) {
  return (
    <ReactModal
      isOpen={props.open}
      className={classnames('arduino-store-modal', props.className)}
      parentSelector={getParent}
      overlayClassName="arduino-store-modal-overlay"
      closeTimeoutMS={300}
      onRequestClose={props.onClose}
      htmlOpenClassName={'ReactModal__Html--open'}
      aria={{
        labelledby: 'heading',
        describedby: 'full_description',
      }}
      shouldCloseOnOverlayClick={true}
    >
      {props.closeIcon ? (
        <button className="close-modal-icon" onClick={props.onClose} type="button">
          <CloseIcon className="close-icon" />
        </button>
      ) : null}
      {props.heading}
      {props.fullDescription}
      {props.body}
      {props.buttons}
    </ReactModal>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  heading: PropTypes.object.isRequired,
  fullDescription: PropTypes.object,
  body: PropTypes.node,
  buttons: PropTypes.object,
  closeIcon: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string.isRequired), PropTypes.string]).isRequired,
};

export default Modal;
