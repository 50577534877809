import classNames from 'classnames';
import { History } from 'history';
import React, { useMemo } from 'react';
import ArduinoDevicesSlider from '../ArduinoSlider/ArduinoDevicesSlider';
import InfoPopover from '../InfoPopover/InfoPopover';
import { ReactComponent as Board } from '../../../assets/icons/board.svg';
import { debounce } from 'lodash';

import foundriesLogo from '../../../assets/foundrieslogo.png';
import { FREQUENCY_MONTHLY, FREQUENCY_YEARLY } from '../../../utils/utilities';

import './EnterprisePlanAddonCard.scss';

interface Match {
  path?: string;
}

interface EnterprisePlanAddonCardProps {
  id: string;
  name: string;
  history: History;
  match: Match;
  description: string;
  features: string;
  yearlyPrice: number;
  monthlyPrice: number;
  mandatory: boolean;
  hasFeatures: boolean;
  changeAddon: (arg: any) => void;
  handleUpdateEnterpriseX8Devices: (value: number) => void;
  handleUpdateEnterpriseEdgeImpulseAddon: (value: number) => void;
  enterprisePlanThings: number;
  enterprisePlanAddons: Array<string>;
  enterprisePlanFrequency: string;
  x8DevicesNumber: number;
}

export default function EnterprisePlanAddonCard(props: EnterprisePlanAddonCardProps) {
  const {
    id,
    name,
    mandatory,
    features,
    description,
    hasFeatures,
    changeAddon,
    enterprisePlanThings,
    enterprisePlanAddons,
    enterprisePlanFrequency,
    handleUpdateEnterpriseX8Devices,
    history,
    match,
    x8DevicesNumber,
  } = props;
  const featureOpen = useMemo(() => {
    let opened = false;
    for (let i = 0; enterprisePlanAddons.length > i; i++) {
      if (enterprisePlanAddons[i].includes(id)) {
        opened = true;
        return opened;
      }
    }
    return opened;
  }, [enterprisePlanAddons, id]);

  const handleClick = (id: string) => {
    changeAddon(id);
  };

  const debouncedThings = debounce(async (devicesNumber) => {
    if (id.includes('cloud_enterprise_board_manager')) {
      handleUpdateEnterpriseX8Devices(devicesNumber);
    }
  }, 500);

  const handleChangeThings = (things: any) => {
    debouncedThings(things.target.value);
  };

  return (
    <div className="enterprise-plan-addon-card-wrapper">
      <div className="addon-card">
        <div className="addon-card__text-section">
          <label className={classNames('ardu-checkbox', { disabled: mandatory })}>
            <input
              type="checkbox"
              name="create-addon"
              onChange={() => handleClick(id)}
              onClick={() => handleClick(id)}
              checked={
                enterprisePlanAddons.includes(id + '_monthly') ||
                enterprisePlanAddons.includes(id + '_yearly') ||
                mandatory
              }
              disabled={mandatory}
            />
            <span className="custom-checkbox"></span>
          </label>
          <div className="addon-card__text" onClick={() => handleClick(id)}>
            <span className="addon-card__title">
              <span>{name}</span>
              <span className="addon-card__powered">
                <span className="addon-card__sub-title">Powered by</span>
                {id.includes('cloud_enterprise_board_manager') && (
                  <span>
                    <picture>
                      <img
                        loading="lazy"
                        className="addon-card__img--foundries"
                        src={foundriesLogo}
                        alt={'foundries'}
                      />
                    </picture>
                  </span>
                )}
              </span>
            </span>
            <span>{description}</span>
          </div>
        </div>
        <div className="addon-card__price" onClick={() => handleClick(id)}>
          $
          {id.includes('cloud_enterprise_board_manager') && enterprisePlanFrequency === FREQUENCY_YEARLY
            ? '2500'
            : id.includes('cloud_enterprise_board_manager') && enterprisePlanFrequency === FREQUENCY_MONTHLY
            ? '250'
            : null}
          {id.includes('cloud_enterprise_board_manager') && (
            <div className="addon-card__price--details">Each 50 Devices</div>
          )}
        </div>
      </div>
      {featureOpen && hasFeatures && (
        <div className="feature-wrapper">
          <div className="feature-wrapper__devices">
            <h6>{features}</h6>
            <div>
              <ArduinoDevicesSlider
                enterprisePlanThings={enterprisePlanThings}
                handleChangeThings={handleChangeThings}
                devicesNumber={x8DevicesNumber}
              />
            </div>
          </div>
          <div className="feature-wrapper__change">
            <div className="feature-wrapper__device-numbers">
              {enterprisePlanThings} <Board />
            </div>
            <div className="feature-wrapper__available-devices">
              <span className="feature-wrapper__available-devices--text">Available Devices</span>
              <button className="tertiary-button" type="button" onClick={() => history.push(`${match.path}/plan`)}>
                CHANGE
              </button>
            </div>
            <div className="feature-wrapper__popover">
              <InfoPopover
                popoverContent={
                  'You get this quota based on the number of devices you selected in step one.  Simply press Change if you want to modify the amount. '
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
