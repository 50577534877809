import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Loader } from '@arduino/arc';
import { getVoucherByCode } from '../../../../apis/activations';
import { CLOUD_CLASSROOM, CLOUD_CREATE, ENTERPRISE_PRODUCT } from '../../../../utils/utilities';
import './RedeemForm.scss';

const RedeemForm = ({ onSubmit, redeemLoading, voucherValue, setVoucherValue, voucherData, setVoucherData }: any) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const checkVoucher = useCallback(
    async (code) => {
      try {
        const voucher = await getVoucherByCode(code);
        setVoucherData(voucher);
        setError(null);
        setLoading(false);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    },
    [setVoucherData]
  );

  useEffect(() => {
    if (voucherValue.length === 27) {
      setLoading(true);
      checkVoucher(voucherValue);
    } else {
      setVoucherData(null);
      setError(null);
    }
  }, [voucherValue, checkVoucher, setVoucherData]);

  const getProductType = useMemo(() => {
    switch (voucherData?.product) {
      case CLOUD_CLASSROOM:
        return 'School plan';
      case ENTERPRISE_PRODUCT:
        return 'Enterprise plan';
      case CLOUD_CREATE:
        if (voucherData?.plan_id?.includes('makerplus')) {
          return 'Maker plus plan';
        } else if (voucherData?.plan_id?.includes('entry')) {
          return 'Entry plan';
        } else {
          return 'Maker plan';
        }
      default:
        return '-';
    }
  }, [voucherData]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      onSubmit(voucherData);
      setLoading(false);
    },
    [onSubmit, voucherData]
  );

  return (
    <form className="redeem-voucher-form" onSubmit={handleSubmit}>
      <h5>Redeem your Cloud plan</h5>
      <p>Insert your Arduino voucher and redeem your Cloud plan</p>
      <div className="redeem-voucher-form__input-container">
        <Input
          className="redeem-voucher-form__input"
          label="Type your code"
          value={voucherValue}
          name="voucher-code"
          onChange={(e) => setVoucherValue(e.target.value)}
          isReadOnly={isLoading || redeemLoading}
          maxLength={27}
          successMsg={voucherData && `${getProductType} valid code`}
          error={((voucherData && !voucherData?.valid) || error) && 'This voucher is not valid'}
          buttons={[
            <div className="redeem-voucher-form__loader" key="loader">
              {isLoading || redeemLoading ? <Loader tiny linecolor="#4E5B61" bgcolor="#ecf1f1" /> : null}
            </div>,
          ]}
          autoFocus
        />
        <button
          type="submit"
          className="redeem-voucher-form__button"
          disabled={!voucherData || !voucherData?.valid || redeemLoading}
        >
          Activate
        </button>
      </div>
    </form>
  );
};

export default RedeemForm;
