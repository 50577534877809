import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.AWS_GET_SUBSCRIPTION_LIST_REQUESTED:
      return {
        ...state,
        awsList: [],
        read: {
          error: null,
          inProgress: true,
          id: action.activationId,
        },
      };
    case types.AWS_GET_SUBSCRIPTION_LIST_SUCCEEDED:
      return {
        ...state,
        awsList: action.data,
        read: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
          id: null,
        },
      };
    case types.AWS_GET_SUBSCRIPTION_LIST_FAILED:
      return {
        ...state,
        read: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
          id: null,
        },
      };
  }
}
