import fetch from '../utils/authenticatedFetch';
import { NetworkError, defaultJsonResponseHandler } from './utils';

import { API_URL } from '../env';

const PROFILE_API = `${API_URL}/users/v1`;

export async function getProfile() {
  try {
    const res = await fetch(`${PROFILE_API}/users/byID/me?scopes=public&scopes=contact&scopes=billing&scopes=private`);
    return await defaultJsonResponseHandler(res);
  } catch (e) {
    throw new NetworkError(e);
  }
}

export async function getOrganizations() {
  try {
    const res = await fetch(`${API_URL}/classroom/v1/organizations`);
    return await defaultJsonResponseHandler(res);
  } catch (e) {
    throw new NetworkError(e);
  }
}

export async function getOrganization(id) {
  try {
    const res = await fetch(`${API_URL}/classroom/v1/organizations/${id}`);
    return await defaultJsonResponseHandler(res);
  } catch (e) {
    throw new NetworkError(e);
  }
}
export async function getOrganizationsMembers(organizationId) {
  try {
    const res = await fetch(`${API_URL}/classroom/v1/organizations/${organizationId.organizationId}/members`);
    return await defaultJsonResponseHandler(res);
  } catch (e) {
    throw new NetworkError(e);
  }
}

export async function createOrganization(organizationData) {
  try {
    return await fetch(`${PROFILE_API}/organizations`, {
      method: 'POST',
      body: JSON.stringify(organizationData),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((data) => {
        {
          return data;
        }
      });
  } catch (e) {
    throw new NetworkError(e);
  }
}

export async function saveBillingInfo(billingInfo) {
  const res = await fetch(`${PROFILE_API}/users/byID/me/billing`, {
    method: 'PUT',
    body: JSON.stringify(billingInfo),
    headers: { 'Content-Type': 'application/json' },
  });

  return await defaultJsonResponseHandler(res);
}
