import React, { useEffect } from 'react';

import { productCards } from '../../../products';

import './HomeComponent.scss';

const HomeComponent = function HomeComponent() {
  useEffect(() => {
    //redirect to Enterprise
  }, []);

  //Product card: Create and Sim
  const productCardElements = Object.keys(productCards).map((productId) => {
    const CardComponent = productCards[productId];
    return <CardComponent key={productId} />;
  });

  return (
    <div className="page-home">
      <div className="products-list">{productCardElements}</div>
    </div>
  );
};

export default HomeComponent;
