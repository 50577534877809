import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route } from 'react-router';
import { withRouter } from 'react-router-dom';

import { STRIPE_PUBLIC_KEY } from '../../../env';
import AbsoluteSpinner from '../../common/AbsoluteSpinner';
import Payment from './Payment/PaymentComponent';
import { currencyCountries } from '../../../currencyCountries';

import { OpenErrorNotification } from '../../common/Toast/Toast';

class PaymentInfoComponent extends Component {
  constructor(props) {
    super(props);

    this.handleBillingSubmit = this.handleBillingSubmit.bind(this);
    this.handlePaymentSubmit = this.handlePaymentSubmit.bind(this);
    this.handlePaymentBack = this.handlePaymentBack.bind(this);
    this.handleSelectedCountry = this.handleSelectedCountry.bind(this);
    this.handleSelectedState = this.handleSelectedState.bind(this);
    this.handleNextButton = this.handleNextButton.bind(this);
    this.handleCreateCard = this.handleCreateCard.bind(this);
  }

  componentDidMount() {
    this.props.readSubscriptionList();
    this.props.readCardList();
    this.props.readCountries();
    this.props.createSubscriptionClearError();
    this.props.readAvailableCountriesList();
    this.props.clearCoupon();
    this.props.clearPlanTrial();
    this.props.clearChargeError();
    this.subscription = this.props.location.state;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.billing.inProgress && !this.props.billing.inProgress) {
      if (!this.props.billing.error) {
        this.props.history.push(`${this.props.match.path}/payment`);
      }
    }
  };

  handleCreateCard(stripeToken) {
    this.props.createCard(stripeToken.token);
  }

  handleBillingSubmit(values, { setSubmitting }) {
    const billingValues = { name: values.firstName.concat(' ').concat(values.lastName), ...values };
    delete billingValues.firstName;
    delete billingValues.lastName;

    if (billingValues.country !== 'US') {
      billingValues.state = billingValues.region;
      delete billingValues.tax_certificate;
    }

    if (currencyCountries.euCountries.findIndex((code) => code === billingValues.country) === -1) {
      billingValues.vat = '';
    }

    if (billingValues.type === 'P') {
      billingValues.company = '';
      billingValues.vat = '';
      delete billingValues.tax_certificate;
    }

    this.props.updateBillingInfo(billingValues);
    setSubmitting(false);
  }

  async handlePaymentSubmit(submitData, newCard) {
    try {
      if (newCard) {
        // add new card
        await this.props.updateSubscriptionCard(
          this.props.subscription.byId[Object.keys(this.props.subscription.byId)[0]].id,
          null,
          submitData.token.id
        );
        await this.props.history.push('/subscriptions/');
      } else {
        //  add existing card
        await this.props.updateSubscriptionCard(
          this.props.subscription.byId[Object.keys(this.props.subscription.byId)[0]].id,
          submitData
        );
        await this.props.history.push('/subscriptions/');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  handlePaymentBack() {
    this.props.createSubscriptionClearError();
    this.props.upgradeSubscriptionClearError();
    this.props.history.push(`${this.props.match.path}/payment`);
  }

  handleSelectedCountry(code) {
    this.props.updateBillingCountry(code);
  }

  handleSelectedState(code) {
    this.props.updateBillingState(code);
  }

  handleNextButton() {
    const billingInfo = this.props.profile.data.billing;
    const countriesList = this.props.userCountry.availableCountriesList;
    if (countriesList.find((country) => country.iso_code === billingInfo.country).allowed) {
      this.props.history.push(`${this.props.match.path}/payment`);
    } else {
      OpenErrorNotification(
        'This product is currently not available in the selected Country, change your billing country to continue your purchase'
      );
    }
  }

  render() {
    const spinner = (
      <div className="purchase">
        <AbsoluteSpinner height={'calc(100vh - 50px)'} clouds />
      </div>
    );

    if (
      this.props.plan.trial.inProgress ||
      this.props.profile.lastUpdate === null ||
      this.props.country.readList.lastUpdate === null ||
      !this.props.userCountry.readList ||
      this.props.userCountry.readList.lastUpdate === null ||
      !this.props.subscription.readList ||
      this.props.subscription.readList.lastUpdate === null
    ) {
      return spinner;
    }

    const purchaseInProgress =
      this.props.subscription.create.inProgress ||
      this.props.subscription.upgrade.inProgress ||
      this.props.charge.create.inProgress;

    return (
      <div className="purchase">
        <div className="product-section">
          <div className="form-section">
            <div className="form-name-container">
              <Switch>
                <Route path={`${this.props.match.path}/payment`}>
                  <h3>{'Update your payment information'}</h3>
                  <Payment
                    stripePublicKey={STRIPE_PUBLIC_KEY}
                    onNewCardSubmit={(stripeData) => this.handlePaymentSubmit(stripeData, true)}
                    onExistingCardSubmit={(cardId) => this.handlePaymentSubmit(cardId, false)}
                    createCard={(stripeToken) => this.handleCreateCard(stripeToken)}
                    onBack={this.handlePaymentBack}
                    card={this.props.card}
                    onCardDelete={this.handleCardDelete}
                    retrievingCards={this.props.card.readList.inProgress}
                    actionInProgress={purchaseInProgress || this.props.taxes.inProgress}
                    errorOnBuying={
                      this.props.subscription.create.error ||
                      this.props.subscription.upgrade.error ||
                      this.props.charge.create.error
                    }
                  />
                </Route>
              </Switch>
            </div>
          </div>
          <div className="details-section"></div>
        </div>
      </div>
    );
  }
}

PaymentInfoComponent.propTypes = {
  charge: PropTypes.object.isRequired,
  createSubscription: PropTypes.func,
  createCharge: PropTypes.func.isRequired,
  upgradeSubscription: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  updateBillingInfo: PropTypes.func,
  cart: PropTypes.object,
  product: PropTypes.object,
  plan: PropTypes.object,
  readCardList: PropTypes.func,
  deleteCard: PropTypes.func,
  card: PropTypes.object,
  subscription: PropTypes.object,
  createSubscriptionClearError: PropTypes.func,
  upgradeSubscriptionClearError: PropTypes.func,
  getPlanTrial: PropTypes.func,
  clearPlanTrial: PropTypes.func,
  getProfile: PropTypes.func,
  profile: PropTypes.object,
  certificates: PropTypes.array,
  readCountries: PropTypes.func,
  readStates: PropTypes.func,
  readSubscriptionList: PropTypes.func,
  updateBillingCountry: PropTypes.func,
  updateBillingState: PropTypes.func,
  setCart: PropTypes.func,
  unsetCart: PropTypes.func,
  clearCoupon: PropTypes.func,
  coupon: PropTypes.object,
  country: PropTypes.shape({
    countriesList: PropTypes.array,
    statesList: PropTypes.array,
    readList: PropTypes.object,
  }),
  billing: PropTypes.object,
  readAvailableCountriesList: PropTypes.func,
  clearChargeError: PropTypes.func,
  userCountry: PropTypes.object,
  taxes: PropTypes.object,
  updateSubscriptionCard: PropTypes.func,
};

/**
 * HOC to wrap the Purchase component and prevent it do make double calls to APIs
 * on mount
 */
function withPurchaseRedirecRouter(Wrapped) {
  const PurchaseRedirecRouter = function PurchaseRedirecRouter(props) {
    return (
      <Switch>
        <Route
          exact
          path={props.match.path}
          render={() => (
            <Redirect
              to={{
                pathname: `${props.match.path}/payment`,
                state: props.location.state,
              }}
            />
          )}
        />
        <Route render={() => <Wrapped {...props} />} />
      </Switch>
    );
  };

  PurchaseRedirecRouter.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
  };

  return PurchaseRedirecRouter;
}

export default withPurchaseRedirecRouter(withRouter(PaymentInfoComponent));
