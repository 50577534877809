import types from './types';

const login = (redirect) => ({
  type: types.LOGIN,
  redirect,
});

const loginRequested = () => ({
  type: types.LOGIN_REQUESTED,
});

const loginSucceeded = (profile) => ({
  type: types.LOGIN_SUCCEEDED,
  profile,
});

const loginFailed = (error) => ({
  type: types.LOGIN_FAILED,
  error,
});

const getProfile = () => ({
  type: types.GET_PROFILE,
});

const getProfileRequested = () => ({
  type: types.GET_PROFILE_REQUESTED,
});

const getProfileSucceeded = (profile, certificates) => ({
  type: types.GET_PROFILE_SUCCEDED,
  profile,
  certificates,
});

const getProfileFailed = (error) => ({
  type: types.GET_PROFILE_FAILED,
  error,
});

const readOrganizationList = () => ({
  type: types.ORGANIZATION_LIST_READ,
});

const readOrganizationListRequested = () => ({
  type: types.ORGANIZATION_LIST_READ_REQUESTED,
});

const readOrganizationListSucceeded = (organizations) => ({
  type: types.ORGANIZATION_LIST_READ_SUCCEDED,
  organizations,
});

const readOrganizationListFailed = (error) => ({
  type: types.ORGANIZATION_LIST_READ_FAILED,
  error,
});

const readOrganizationMembers = (organizationId) => ({
  type: types.ORGANIZATION_MEMBERS_READ,
  organizationId: organizationId,
});

const readOrganizationMembersRequested = () => ({
  type: types.ORGANIZATION_MEMBERS_READ_REQUESTED,
});

const readOrganizationMembersSucceeded = (members) => ({
  type: types.ORGANIZATION_MEMBERS_READ_SUCCEDED,
  members,
});

const readOrganizationMembersFailed = (error) => ({
  type: types.ORGANIZATION_MEMBERS_READ_FAILED,
  error,
});

const createOrganization = (organization) => ({
  type: types.ORGANIZATION_CREATE,
  organization: organization,
});

const createOrganizationRequested = () => ({
  type: types.ORGANIZATION_CREATE_REQUESTED,
});

const createOrganizationSucceeded = (organization, results) => ({
  type: types.ORGANIZATION_CREATE_SUCCEDED,
  organization,
  results,
});

const createOrganizationFailed = (error) => ({
  type: types.ORGANIZATION_CREATE_FAILED,
  error,
});

export default {
  login,
  loginRequested,
  loginSucceeded,
  loginFailed,
  getProfile,
  getProfileRequested,
  getProfileSucceeded,
  getProfileFailed,
  readOrganizationList,
  readOrganizationListRequested,
  readOrganizationListSucceeded,
  readOrganizationListFailed,
  createOrganization,
  createOrganizationRequested,
  createOrganizationSucceeded,
  createOrganizationFailed,
  readOrganizationMembers,
  readOrganizationMembersRequested,
  readOrganizationMembersSucceeded,
  readOrganizationMembersFailed,
};
