import authenticatedFetch from '../utils/authenticatedFetch';
import { API_URL } from '../env';
import { NetworkError } from './utils';

const CHECK_COUNTRY_API = `${API_URL}/payments/v1/countries`;

export async function checkUserCountry() {
  let res;
  if (await window.auth.isAuthenticated()) {
    res = await authenticatedFetch(`${CHECK_COUNTRY_API}/allowed`);
  } else {
    res = await fetch(`${CHECK_COUNTRY_API}/allowed`);
  }
  if (res.ok) {
    return true;
  }
  if (res.status === 401) {
    return false;
  }
  throw new NetworkError(res);
}

export async function readAvailableCountriesList() {
  const res = await fetch(`${CHECK_COUNTRY_API}`);
  const data = await res.json();
  if (res.ok) {
    return data;
  }
  throw new NetworkError(res, data);
}
