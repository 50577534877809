import React from 'react';
import ThankYou from '../../components/pages/thank-you';
import {
  CREATE_BASE_URL,
  CLOUD_HOME,
  CERTIFICATION_DEMO_URL,
  CERTIFICATION_GUIDE_PDF_URL,
  CERTIFICATION_ACTIVATION_URL,
  JUNIOR_CERTIFICATION_ACTIVATION_URL,
} from '../../env';
import { CERTIFICATION_THANK_YOU, JUNIOR_CERTIFICATION_THANK_YOU, INQUIRY_THANK_YOU } from '../../utils/utilities';
import './CreateThankYou.scss';

function Title() {
  const certification = window.location.pathname === CERTIFICATION_THANK_YOU;
  const juniorCertification = window.location.pathname === JUNIOR_CERTIFICATION_THANK_YOU;
  const inquiry = window.location.pathname === INQUIRY_THANK_YOU;

  if (certification || juniorCertification) {
    return <h1 className="title">{'Thank you for Purchasing!'}</h1>;
  } else if (inquiry) {
    return <h1 className="title">{'Thank you for you inquiry!'}</h1>;
  }
  return <h1 className="title">{'Thank you for Subscribing!'}</h1>;
}

const subTitle = (email: string) => {
  const certification = window.location.pathname === CERTIFICATION_THANK_YOU;
  const inquiry = window.location.pathname === INQUIRY_THANK_YOU;

  const juniorCertification = window.location.pathname === JUNIOR_CERTIFICATION_THANK_YOU;
  if (certification) {
    return (
      <div className="subtitle">
        <h3>
          {`We've just sent to your email the codes to access the Arduino exam. Please, note that each code corresponds to one attempt. Once redeemed, you or the person you shared it with will have 1 year to take the exam`}
        </h3>
        <h3>{'Good luck!'}</h3>
      </div>
    );
  } else if (inquiry) {
    return (
      <div className="subtitle">
        <h3>{`An Arduino representative will get in touch with you shortly through your email.`}</h3>
      </div>
    );
  } else if (juniorCertification) {
    return (
      <div className="subtitle">
        <h3>
          We’ve just sent to your email the codes to access the Arduino Junior exam. Please note that each code
          corresponds to on attempt. Once redeemed, you or the person you shared it with will have 1 year to take the
          exam.
        </h3>
        <h3>Good luck!</h3>
      </div>
    );
  }
  return (
    <div className="subtitle">
      <h3>{`Your purchase was successful. Your access to the Cloud Plan has been granted!`}</h3>
      <h3>
        {'A receipt has been emailed to '}
        <span className="email-address">{`${email}.`}</span>
        <p />
      </h3>
    </div>
  );
};

function RedirectButton() {
  const certification = window.location.pathname === CERTIFICATION_THANK_YOU;
  const inquiry = window.location.pathname === INQUIRY_THANK_YOU;
  const juniorCertification = window.location.pathname === JUNIOR_CERTIFICATION_THANK_YOU;
  if (certification) {
    return (
      <a className={'box-button'} href={`${CERTIFICATION_ACTIVATION_URL}`}>
        <span className="ardu-store-button-text">{'Start the exam'}</span>
      </a>
    );
  } else if (inquiry) {
    return null;
  } else if (juniorCertification) {
    return (
      <a className={'box-button'} href={`${JUNIOR_CERTIFICATION_ACTIVATION_URL}`}>
        <span className="ardu-store-button-text">{'Start the exam'}</span>
      </a>
    );
  }
  return (
    <a className={'box-button'} href={`${CLOUD_HOME}`}>
      <span className="ardu-store-button-text">{'Back to Arduino Cloud'}</span>
    </a>
  );
}

function Banners() {
  const certification = window.location.pathname === CERTIFICATION_THANK_YOU;
  const juniorCertification = window.location.pathname === JUNIOR_CERTIFICATION_THANK_YOU;
  if (certification || juniorCertification) {
    return (
      <div className="banner-box">
        <h3 className="banner-title">{'Get ready for the exam'}</h3>
        <div className="banners-container">
          <div className="banner">
            <h2>{'GET A LOOK BEFORE STARTING'}</h2>
            {certification ? (
              <div className="banner-description">
                Take a look at the exam structure and type of questions. The Certification exam is web-based, and
                consists of 36 questions with a max completion time of 75 minutes.
              </div>
            ) : (
              <div className="banner-description">
                Take a look at the exam structure and type of question. The Junior certification exam is web based, and
                cosists of 25 questions with a max completition of 60 minutes.
              </div>
            )}
            <a className="banner-link" href={`${CERTIFICATION_DEMO_URL}`}>
              {'Try the demo'}
            </a>
          </div>
          <div className="banner">
            <h2>{"LET'S REVISE FOR THE EXAM"}</h2>
            {certification ? (
              <>
                <div className="banner-description">
                  The certification is based on the Arduino Starter kit and validates your knowledge in the field of
                  programming and electronics, as it relates to Arduino. Let’s take a closer look at the subjects
                  covered and the structure of exam.
                </div>
                <a className="banner-link" href={`${CERTIFICATION_GUIDE_PDF_URL}`}>
                  {'DOWNLOAD GUIDE'}
                </a>
              </>
            ) : (
              <div className="banner-description">
                The Arduino Junior certification is based on the Arduino Starter kit or Arduino Student kit and
                validates your knowledge in the field of programming and elettronics, as it relats to Arduino. Let’s
                take a closer look at the subjects covered and the structure of the exam.
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="banner-box">
      <h3 className="banner-title">{'Exploring Arduino Cloud'}</h3>
      <div className="banners-container create-banners-container">
        <div className="banner">
          <h2>{'web editor'}</h2>
          <div className="banner-description">
            {
              'Start coding online, save your sketches in the cloud, and always have the most up-to-date version of the IDE.'
            }
          </div>
          <a className="banner-link" href={`${CREATE_BASE_URL}/editor`}>
            {'START CODING'}
          </a>
        </div>
        <div className="banner">
          <h2>{'arduino ioT cloud'}</h2>
          <div className="banner-description">
            {
              'Jump into the IoT world with Arduino IoT Cloud, a simple tool that will make the creation of connected products quick, simple and secure.'
            }
          </div>
          <a className="banner-link" href={`${CREATE_BASE_URL}/iot`}>
            {'CREATE YOUR THING'}
          </a>
        </div>
        <div className="banner">
          <h2>{'getting started'}</h2>
          <div className="banner-description">
            {
              'Configure your cloud-enabled devices quickly and easily. In this app you will find several guides to configure your devices for the first time.'
            }
          </div>
          <a className="banner-link" href={`${CREATE_BASE_URL}/getting-started`}>
            {'SET UP YOUR DEVICE '}
          </a>
        </div>
      </div>
    </div>
  );
}

function CreateThankYou() {
  localStorage.removeItem('path');
  return <ThankYou title={<Title />} subtitle={subTitle} banners={<Banners />} redirectButton={<RedirectButton />} />;
}

export default CreateThankYou;
