import readList from './readList';

const initialState = {
  byId: {},
  bySubscription: {},
  readList: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
  readListBySubscription: {
    error: null,
    inProgress: false,
    lastUpdate: null,
  },
};

export default function (state = initialState, action) {
  return readList(state, action) || state;
}
