const LOGIN = 'app/authentication/LOGIN';
const LOGIN_REQUESTED = 'app/authentication/LOGIN_REQUESTED';
const LOGIN_SUCCEEDED = 'app/authentication/LOGIN_SUCCEEDED';
const LOGIN_FAILED = 'app/authentication/LOGIN_FAILED';

const GET_PROFILE = 'app/authentication/GET_PROFILE';
const GET_PROFILE_REQUESTED = 'app/authentication/GET_PROFILE_REQUESTED';
const GET_PROFILE_SUCCEDED = 'app/authentication/GET_PROFILE_SUCCEDED';
const GET_PROFILE_FAILED = 'app/authentication/GET_PROFILE_FAILED';

const ORGANIZATION_LIST_READ = 'app/authentication/ORGANIZATION_LIST_READ';
const ORGANIZATION_LIST_READ_REQUESTED = 'app/authentication/ORGANIZATION_LIST_READ_REQUESTED';
const ORGANIZATION_LIST_READ_SUCCEDED = 'app/authentication/ORGANIZATION_LIST_READ_SUCCEDED';
const ORGANIZATION_LIST_READ_FAILED = 'app/authentication/ORGANIZATION_LIST_READ_FAILED';

const ORGANIZATION_CREATE = 'app/authentication/ORGANIZATION_CREATE';
const ORGANIZATION_CREATE_REQUESTED = 'app/authentication/ORGANIZATION_CREATE_REQUESTED';
const ORGANIZATION_CREATE_SUCCEDED = 'app/authentication/ORGANIZATION_CREATE_SUCCEDED';
const ORGANIZATION_CREATE_FAILED = 'app/authentication/ORGANIZATION_CREATE_FAILED';

const ORGANIZATION_MEMBERS_READ = 'app/authentication/ORGANIZATION_MEMBERS_READ';
const ORGANIZATION_MEMBERS_READ_REQUESTED = 'app/authentication/ORGANIZATION_MEMBERS_READ_REQUESTED';
const ORGANIZATION_MEMBERS_READ_SUCCEDED = 'app/authentication/ORGANIZATION_MEMBERS_READ_SUCCEDED';
const ORGANIZATION_MEMBERS_READ_FAILED = 'app/authentication/ORGANIZATION_MEMBERS_READ_FAILED';

export default {
  LOGIN,
  LOGIN_REQUESTED,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,

  GET_PROFILE,
  GET_PROFILE_REQUESTED,
  GET_PROFILE_SUCCEDED,
  GET_PROFILE_FAILED,

  ORGANIZATION_LIST_READ,
  ORGANIZATION_LIST_READ_REQUESTED,
  ORGANIZATION_LIST_READ_SUCCEDED,
  ORGANIZATION_LIST_READ_FAILED,

  ORGANIZATION_CREATE,
  ORGANIZATION_CREATE_REQUESTED,
  ORGANIZATION_CREATE_SUCCEDED,
  ORGANIZATION_CREATE_FAILED,

  ORGANIZATION_MEMBERS_READ,
  ORGANIZATION_MEMBERS_READ_REQUESTED,
  ORGANIZATION_MEMBERS_READ_SUCCEDED,
  ORGANIZATION_MEMBERS_READ_FAILED,
};
