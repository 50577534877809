import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './PaymentMethodSection.scss';

function AddPaymentInfoButton({ subscription, hideDescription }) {
  return (
    <div className="payment-button">
      <Link
        to={{ pathname: '/payment-info', state: subscription }}
        className="payment-info-button stack-card-action-button custom-button"
        type="button"
      >
        {'ADD PAYMENT INFO'}
      </Link>
      {!hideDescription && (
        <div className="payment-info-description">
          {'Add payment info to keep enjoying the Maker plan after your trial ends.'}
        </div>
      )}
    </div>
  );
}

AddPaymentInfoButton.propTypes = {
  subscription: PropTypes.object,
  hideDescription: PropTypes.bool,
};

export default AddPaymentInfoButton;
