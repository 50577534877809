import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as Amex } from '../../../assets/amex.svg';
import { ReactComponent as Discover } from '../../../assets/discover.svg';
import { ReactComponent as Mastercard } from '../../../assets/mastercard.svg';
import { ReactComponent as Visa } from '../../../assets/visa.svg';

import './CreditCardInfo.scss';

const padMonth = (month) => (`${month}`.length === 2 ? month : `0${month}`);

const CreditCardInfo = function CreditCardInfo({ card, rightChildren = null, ...props }) {
  let cardIcon = null;
  let lastFour = null;
  let brand = null;

  if (props.icon) {
    switch (card.brand) {
      case 'Visa':
        cardIcon = <Visa />;
        break;
      case 'MasterCard':
        cardIcon = <Mastercard />;
        break;
      case 'American Express':
        cardIcon = <Amex />;
        break;
      case 'Discover':
        cardIcon = <Discover />;
        break;
    }
  }

  if (props.lastFour) {
    lastFour = <span className="last-4">{` **** **** **** ${card.last4}`}</span>;
  }

  let rightContent = null;

  if (rightChildren) {
    rightContent = <div>{rightChildren}</div>;
  }

  let expiry = null;
  if (props.expiry) {
    expiry = (
      <span className="expiry-container">
        {'EXP. '}
        <span className="expiry">{`${padMonth(card.exp_month)}/${card.exp_year}`}</span>
      </span>
    );
  }

  if (props.brand) {
    brand = <span className="brand">{card.brand}</span>;
  }

  return (
    <div className={classnames('credit-card-info', { clickable: props.clickable })} onClick={props.onClick}>
      {cardIcon}
      <div className="mobile-column-content">
        {brand}
        {lastFour}
        {expiry}
      </div>
      <div className="right-content">{rightContent}</div>
    </div>
  );
};

CreditCardInfo.propTypes = {
  card: PropTypes.object.isRequired,
  rightChildren: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  brand: PropTypes.bool,
  expiry: PropTypes.bool,
  holderName: PropTypes.bool,
  icon: PropTypes.bool,
  lastFour: PropTypes.bool,
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
};

export default CreditCardInfo;
