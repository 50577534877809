import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.SUBSCRIPTION_READ_REQUESTED:
      return {
        ...state,
        read: {
          error: null,
          inProgress: true,
          id: action.subscriptionId,
        },
      };
    case types.SUBSCRIPTION_READ_SUCCEEDED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.id]: action.data,
        },
        byProduct: {
          ...state.byId,
          [action.data.product]: action.data,
        },
        byPlan: {
          ...state.byId,
          [action.data.plan]: action.data,
        },
        read: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
          id: null,
        },
      };
    case types.SUBSCRIPTION_READ_FAILED:
      return {
        ...state,
        read: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
          id: null,
        },
      };

    case types.SUBSCRIPTION_UPGRADE_PREVIEW_REQUESTED:
      return {
        ...state,
        read: {
          error: null,
          inProgress: true,
          id: action.subscriptionId,
        },
      };
    case types.SUBSCRIPTION_UPGRADE_PREVIEW_SUCCEEDED:
      return {
        ...state,
        lastTotalUpgradePrice: action.data,
        read: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
          id: null,
        },
      };
    case types.SUBSCRIPTION_UPGRADE_PREVIEW_FAILED:
      return {
        ...state,
        read: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
          id: null,
        },
      };
  }
}
