import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { HF_ENV, CREATE_BASE_URL, CLOUD_HOME } from '../../../env';
import './CreateLanding.scss';
import { CloudLogo } from '../../../assets/cloud-logo';

const title = 'Arduino Cloud';
const description = 'Develop your Arduino code in the cloud and build IoT projects.';

function CreateLandingComponent(props) {
  const { pathname } = props.location;

  //We use this just for redirect to cloud.arduino.cc. This page will be dismissed soon.
  useEffect(() => {
    window.location.replace(CLOUD_HOME);
  }, []);

  useEffect(() => {
    if (pathname === '/create') {
      // Set Create logo in header
      window.arduinoHeader.changeLogo({
        src: CloudLogo,
        inline: true,
        cssClass: 'digital-store',
        url: CREATE_BASE_URL,
      });
    }

    new window.arduinoHF.Footer(document.getElementById('footer'), {
      env: HF_ENV,
      style: 'platform',
      copyright: {
        url: 'https://www.arduino.cc/en/Main/CopyrightNotice',
      },
      helpCenter: {
        blank: true,
      },
    });
  }, [pathname]);

  return (
    <>
      <div className="create-landing-page landing-page">
        <Helmet>
          {/* Default metadata - can be overridden in nested components */}
          <title>{`${title} LaunchPad`}</title>
          {/*The following are quite useless as crawlers can't read them*/}
          <meta name="description" content={description} />
          <link
            rel="icon"
            href="https://cdn.arduino.cc/header-footer/prod/assets/favicon-arduino/favicon-48x48.png"
            type="image/x-icon"
          ></link>
          <link
            rel="shortcut icon"
            href="https://cdn.arduino.cc/header-footer/prod/assets/favicon-arduino/favicon-48x48.png"
            type="image/x-icon"
          ></link>
        </Helmet>
        {/* <div className="landing-page-container">{<WelcomeSection />}</div> */}
      </div>
      <div id="footer"></div>
    </>
  );
}

CreateLandingComponent.propTypes = {
  login: PropTypes.func,
  location: PropTypes.object,
};

export default CreateLandingComponent;
