import types from '../types';

export default function (state, action) {
  switch (action.type) {
    case types.SUBSCRIPTIONS_STATES_READ_REQUESTED:
      return {
        ...state,
        statesList: [],
        readList: {
          error: null,
          inProgress: true,
        },
      };
    case types.SUBSCRIPTIONS_STATES_READ_SUCCEEDED:
      return {
        ...state,
        statesList: action.data.states || [],
        readList: {
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };
    case types.SUBSCRIPTIONS_STATES_READ_FAILED:
      return {
        ...state,
        readList: {
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.SUBSCRIPTION_CREATE_REQUESTED:
      return {
        ...state,
        create: {
          ...state.create,
          inProgress: true,
        },
      };

    case types.SUBSCRIPTION_CREATE_SUCCEEDED:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.SUBSCRIPTION_CREATE_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.SUBSCRIPTION_CREATE_CLEAR_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
        },
      };

    case types.SUBSCRIPTION_REQUEST_CREATE_REQUESTED:
      return {
        ...state,
        createRequest: {
          ...state.createRequest,
          inProgress: true,
        },
      };

    case types.SUBSCRIPTION_REQUEST_CREATE_SUCCEEDED:
      return {
        ...state,
        createRequest: {
          ...state.createRequest,
          error: null,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.SUBSCRIPTION_REQUEST_CREATE_FAILED:
      return {
        ...state,
        createRequest: {
          ...state.createRequest,
          error: action.error,
          inProgress: false,
          lastUpdate: Date.now(),
        },
      };

    case types.SUBSCRIPTION_REQUEST_CREATE_CLEAR_FAILED:
      return {
        ...state,
        createRequest: {
          ...state.createRequest,
          error: null,
        },
      };
  }
}
