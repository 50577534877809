import { ACTIVATION_API_URL } from '../env';
import fetch from '../utils/authenticatedFetch';
import { defaultJsonResponseHandler } from './utils';

const ACTIVATIONS_API = `${ACTIVATION_API_URL}/activation/v1`;

export async function readAvailableOrganizations(activationId) {
  const res = await fetch(`${ACTIVATIONS_API}/plans/${activationId}/compatible_orgs`);
  return await defaultJsonResponseHandler(res);
}

export async function readAvailableActivations() {
  const res = await fetch(`${ACTIVATIONS_API}/plans/available`);
  return await defaultJsonResponseHandler(res);
}

export async function readAllPlansActivations() {
  const res = await fetch(`${ACTIVATIONS_API}/plans`);
  return await defaultJsonResponseHandler(res);
}

export async function readActivationById(id) {
  const res = await fetch(`${ACTIVATIONS_API}/plans/${id}`);
  return await defaultJsonResponseHandler(res);
}

export async function readResellerList() {
  const res = await fetch(`${ACTIVATIONS_API}/resellers`);
  return await defaultJsonResponseHandler(res);
}

export async function redeemPlans({ activationId: activationsId, organizationId: orgId, subscriptionId: subId }) {
  let res = null;
  {
    /*
     * subscription_id is sensed only if present. If present it means that the organizations is binded with a specific plan.
     * This information will be used to BE to understand if we are making an upgrading or a simple redeem.
     */
  }
  if (subId) {
    res = await fetch(`${ACTIVATIONS_API}/plans/redeem/${activationsId}`, {
      method: 'POST',
      body: JSON.stringify({
        organization_id: orgId,
        subscription_id: subId,
      }),
      headers: { 'Content-Type': 'application/json' },
    });
  } else {
    res = await fetch(`${ACTIVATIONS_API}/plans/redeem/${activationsId}`, {
      method: 'POST',
      body: JSON.stringify({
        organization_id: orgId,
      }),
      headers: { 'Content-Type': 'application/json' },
    });
  }
  return await defaultJsonResponseHandler(res);
}

export async function createEnterpriseUpgradeRequest(activationId) {
  const res = await fetch(`${ACTIVATIONS_API}/endpoint_to_create/${activationId}`);
  return await defaultJsonResponseHandler(res);
}

export async function createEducationUpgradeRequest(activationId) {
  const res = await fetch(`${ACTIVATIONS_API}/endpoint_to_create/${activationId}`);
  return await defaultJsonResponseHandler(res);
}

export async function getVoucherByCode(code) {
  const res = await fetch(`${ACTIVATIONS_API}/vouchers/${code}`);
  return await defaultJsonResponseHandler(res);
}

export async function redeemVoucherByCode(code, payload) {
  const res = await fetch(`${ACTIVATIONS_API}/vouchers/redeem/${code}`, {
    method: 'POST',
    body: JSON.stringify({ ...payload }),
    headers: { 'Content-Type': 'application/json' },
  });
  return await defaultJsonResponseHandler(res);
}

export async function getVouchersListByUserId() {
  const res = await fetch(`${ACTIVATIONS_API}/vouchers/list`);
  return await defaultJsonResponseHandler(res);
}
