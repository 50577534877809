import React from 'react';
import { History } from 'history';
import classnames from 'classnames';
import { Route } from 'react-router';
import {
  EDUCATION_PURCHASE,
  EDUCATION_PURCHASE_PLAN,
  EDUCATION_PURCHASE_INSTITUTION,
  EDUCATION_PURCHASE_BILLING,
  EDUCATION_PURCHASE_PAYMENT,
  EDUCATION_PURCHASE_PAYMENT_INFO,
  EDUCATION_PURCHASE_ALTERNATIVE_PAYMENT,
} from '../../../utils/utilities';

import './PurchaseBreadcrumbs.scss';

interface Location {
  hash: string;
  key: string;
  pathname: string;
  search: string;
  state?: string;
}

interface EducationBreadcrumbsProps {
  history: History;
  path: string;
  location: Location;
  active: string;
  isEduUpgrading: boolean;
  institutionPageVisited: boolean;
  selectPlanPageVisited: boolean;
  billingPageVisited: boolean;
  paymentPageVisited: boolean;
}

export default function EducationBreadcrumbs(props: EducationBreadcrumbsProps) {
  const {
    path,
    active,
    isEduUpgrading,
    history,
    institutionPageVisited,
    selectPlanPageVisited,
    billingPageVisited,
    paymentPageVisited,
  } = props;
  const educationPath = path === EDUCATION_PURCHASE;
  return (
    <div className="breadcrumbs">
      <ul>
        {educationPath && (
          <>
            {
              <>
                <Route>
                  <li
                    className={classnames('br-elem', {
                      active: `${active}` === EDUCATION_PURCHASE_INSTITUTION,
                      pointer: institutionPageVisited,
                    })}
                    onClick={() => (institutionPageVisited ? history.push(EDUCATION_PURCHASE_INSTITUTION) : null)}
                  >
                    <span>{'Your institution'}</span>
                  </li>
                </Route>
                <li className="br-elem">
                  <span>{'>'}</span>
                </li>
              </>
            }
            <Route>
              <li
                className={classnames('br-elem', {
                  active: `${active}` === EDUCATION_PURCHASE_PLAN,
                  pointer: selectPlanPageVisited,
                })}
                onClick={() => (selectPlanPageVisited ? history.push(EDUCATION_PURCHASE_PLAN) : null)}
              >
                <span>{'Plan details'}</span>
              </li>
            </Route>
            <li className="br-elem">
              <span>{'>'}</span>
            </li>
          </>
        )}

        {educationPath && !isEduUpgrading && (
          <>
            <Route>
              <li
                className={classnames('br-elem', {
                  active: `${active}` === EDUCATION_PURCHASE_BILLING,
                  pointer: billingPageVisited,
                })}
                onClick={() => (billingPageVisited ? history.push(EDUCATION_PURCHASE_BILLING) : null)}
              >
                <span>{'Billing information'}</span>
              </li>
            </Route>
            <li className="br-elem">
              <span>{'>'}</span>
            </li>
            <Route>
              <li
                className={classnames('br-elem', {
                  active:
                    `${active}` === EDUCATION_PURCHASE_PAYMENT ||
                    `${active}` === EDUCATION_PURCHASE_ALTERNATIVE_PAYMENT,
                  pointer: paymentPageVisited,
                })}
                onClick={() => (paymentPageVisited ? history.push(EDUCATION_PURCHASE_PAYMENT) : null)}
              >
                <span>{'Payment'}</span>
              </li>
            </Route>
          </>
        )}

        {educationPath && isEduUpgrading && (
          <Route>
            <li
              className={classnames('br-elem', {
                active: `${active}` === EDUCATION_PURCHASE_PAYMENT_INFO,
                pointer: isEduUpgrading && selectPlanPageVisited,
              })}
              onClick={() =>
                isEduUpgrading && selectPlanPageVisited ? history.push(EDUCATION_PURCHASE_PAYMENT_INFO) : null
              }
            >
              <span>{'Payment information'}</span>
            </li>
          </Route>
        )}
      </ul>
    </div>
  );
}
