import authenticationSagas from './authentication/sagas';
import billingSagas from './billing/sagas';
import cardSagas from './card/sagas';
import chargeSagas from './charge/sagas';
import countrySagas from './country/sagas';
import invoiceSagas from './invoice/sagas';
import planSagas from './plan/sagas';
import simSagas from './sim/sagas';
import subscriptionSagas from './subscription/sagas';
import activationSagas from './activations/sagas';
import awsSagas from './aws/sagas';
import checkUserCountrySagas from './checkUserCountry/sagas';
import couponSagas from './coupon/sagas';
import taxesSagas from './taxes/sagas';
import userRecapSagas from './userRecap/sagas';

export default [
  ...authenticationSagas,
  ...billingSagas,
  ...cardSagas,
  ...chargeSagas,
  ...countrySagas,
  ...invoiceSagas,
  ...planSagas,
  ...simSagas,
  ...subscriptionSagas,
  ...activationSagas,
  ...awsSagas,
  ...checkUserCountrySagas,
  ...couponSagas,
  ...taxesSagas,
  ...userRecapSagas,
];
